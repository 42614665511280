import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '../common/Button';
import Loading from '../common/Loading';
import { WorksheetStudier_worksheet } from './__generated__/WorksheetStudier_worksheet.graphql';
import { WorksheetStudier_viewer } from './__generated__/WorksheetStudier_viewer.graphql';
import { CompleteStudyRecordMutationResponse } from '../../mutations/__generated__/CompleteStudyRecordMutation.graphql';
import CreateStudyRecordMutation from '../../mutations/CreateStudyRecordMutation';
import CompleteStudyRecordMutation from '../../mutations/CompleteStudyRecordMutation';
import CreateTranslationAnswerMutation from '../../mutations/CreateTranslationAnswerMutation';
import CreateWordOrderAnswerMutation from '../../mutations/CreateWordOrderAnswerMutation';
import CreateMultipleChoiceAnswerMutation from '../../mutations/CreateMultipleChoiceAnswerMutation';
import CreateVocabAnswerMutation from '../../mutations/CreateVocabAnswerMutation';
import TranslatorWidget from '../TranslatorWidget/index';
import WordOrderWidget from '../WordOrderWidget/index';
import VocabWidget from '../VocabWidget/index';
import MultipleChoiceWidget from '../MultipleChoiceWidget/index';
import { vocabTerm } from '../VocabWidget/index';
import { preloadImage } from '../../lib/utils';

import './index.css';

type Props = {
  worksheet: WorksheetStudier_worksheet;
  viewer: WorksheetStudier_viewer;
  relay: RelayProp;
  isDemo: boolean;
  isEmbed: boolean;
};

type State = {
  hasStarted: boolean;
  hasFinished: boolean;
  hasCreatedStudyRecord: boolean;
  studyRecordId: null | string;
  answersSyncQueue: Array<(answer: string) => Promise<any>>;
  currentExercise: number;
  currentExerciseProgress: number;
  completedStudyRecordResponse: null | CompleteStudyRecordMutationResponse;
};

class WorksheetStudier extends Component<Props, State> {
  state: State = {
    hasStarted: false,
    hasFinished: false,
    studyRecordId: null,
    hasCreatedStudyRecord: false,
    answersSyncQueue: [],
    currentExercise: 0,
    currentExerciseProgress: 0,
    completedStudyRecordResponse: null,
  };

  componentDidMount() {
    this.props.worksheet.exercises.forEach(exercise => {
      if (exercise.content && exercise.content.terms) {
        exercise.content.terms.forEach(term => {
          if (term.image && term.image.url) {
            preloadImage(term.image.url);
          }
        });
      }
    });
  }

  syncAnswers = () => {
    const studyRecordId = this.state.studyRecordId;
    if (!studyRecordId) return;
    this.state.answersSyncQueue.forEach(curriedAnswerMutation => {
      curriedAnswerMutation(studyRecordId);
    });
    this.setState({ answersSyncQueue: [] });
  };

  getExerciseById(exerciseId: string) {
    return this.props.worksheet.exercises.find(ex => ex.id === exerciseId);
  }

  onStart = () => {
    this.setState({ hasStarted: true });
    window.heap.track('study_start', {
      identifier: this.props.worksheet.identifier,
      isDemo: this.props.isDemo,
      numExercises: this.props.worksheet.exercises.length,
    });
  };

  onRecordAnswer = (
    isCorrect: boolean,
    curriedAnswerMutation: (id: string) => Promise<any>,
  ) => {
    if (!this.state.hasCreatedStudyRecord) {
      CreateStudyRecordMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
      ).then(res => {
        if (res.createStudyRecord && res.createStudyRecord.studyRecord) {
          this.setState({
            studyRecordId: res.createStudyRecord.studyRecord.id,
          });
          this.syncAnswers();
        }
      });
      this.setState({ hasCreatedStudyRecord: true });
    }
    const answersSyncQueue = this.state.answersSyncQueue.slice();
    answersSyncQueue.push(curriedAnswerMutation);
    this.setState({ answersSyncQueue }, this.syncAnswers);
    window.heap.track('study_answer', {
      identifier: this.props.worksheet.identifier,
      isDemo: this.props.isDemo,
      isCorrect,
      numExercises: this.props.worksheet.exercises.length,
      currentExercise: this.state.currentExercise,
    });
  };

  onRecordTranslationAnswer = (isCorrect: boolean, response: string[]) => {
    const exerciseId = this.props.worksheet.exercises[
      this.state.currentExercise
    ].id;
    const curriedMutation = (studyRecordId: string) =>
      CreateTranslationAnswerMutation.commit(
        this.props.relay.environment,
        studyRecordId,
        exerciseId,
        isCorrect,
        response,
      );
    this.onRecordAnswer(isCorrect, curriedMutation);
  };

  onRecordWordOrderAnswer = (isCorrect: boolean, response: string[]) => {
    const exerciseId = this.props.worksheet.exercises[
      this.state.currentExercise
    ].id;
    const curriedMutation = (studyRecordId: string) =>
      CreateWordOrderAnswerMutation.commit(
        this.props.relay.environment,
        studyRecordId,
        exerciseId,
        isCorrect,
        response,
      );
    this.onRecordAnswer(isCorrect, curriedMutation);
  };

  onRecordMultipleChoiceAnswer = (
    isCorrect: boolean,
    choices: { isChosen: boolean; text: string }[],
  ) => {
    const exerciseId = this.props.worksheet.exercises[
      this.state.currentExercise
    ].id;
    const curriedMutation = (studyRecordId: string) =>
      CreateMultipleChoiceAnswerMutation.commit(
        this.props.relay.environment,
        studyRecordId,
        exerciseId,
        isCorrect,
        choices,
      );
    this.onRecordAnswer(isCorrect, curriedMutation);
  };

  onRecordVocabAnswer = (
    isCorrect: boolean,
    responseTerm: vocabTerm,
    correctTerm: null | vocabTerm,
  ) => {
    const exerciseId = this.props.worksheet.exercises[
      this.state.currentExercise
    ].id;
    const fixTermImage = (term: vocabTerm) => ({
      word: term.word,
      definition: term.definition,
      imageId: term.image && term.image.id,
    });
    const curriedMutation = (studyRecordId: string) =>
      CreateVocabAnswerMutation.commit(
        this.props.relay.environment,
        studyRecordId,
        exerciseId,
        isCorrect,
        fixTermImage(responseTerm),
        fixTermImage(correctTerm || responseTerm),
      );
    this.onRecordAnswer(isCorrect, curriedMutation);
  };

  onNextExercise = async () => {
    const { currentExercise, studyRecordId } = this.state;
    if (currentExercise === this.props.worksheet.exercises.length - 1) {
      this.setState({ hasFinished: true });
      window.heap.track('study_done', {
        identifier: this.props.worksheet.identifier,
        isDemo: this.props.isDemo,
        numExercises: this.props.worksheet.exercises.length,
      });
      if (studyRecordId) {
        await this.syncAnswers();
        const completedStudyRecordResponse = await CompleteStudyRecordMutation.commit(
          this.props.relay.environment,
          studyRecordId,
        );
        this.setState({ completedStudyRecordResponse });
      }
    } else {
      window.heap.track('study_next', {
        identifier: this.props.worksheet.identifier,
        isDemo: this.props.isDemo,
        numExercises: this.props.worksheet.exercises.length,
        currentExercise: this.state.currentExercise,
      });
      this.setState({
        currentExercise: this.state.currentExercise + 1,
        currentExerciseProgress: 0,
      });
    }
  };

  getExerciseProgressLength(exerciseNum: number) {
    const exercise = this.props.worksheet.exercises[exerciseNum];
    // $FlowFixMe
    if (exercise.__typename === 'VocabExercise' && exercise.content)
      return exercise.content.terms.length;
    return 1;
  }

  getTotalProgressLength() {
    let totalLength = 0;
    for (let i = 0; i < this.props.worksheet.exercises.length; i++) {
      totalLength += this.getExerciseProgressLength(i);
    }
    return totalLength;
  }

  getCurrentProgress() {
    let progress = 1;
    for (let i = 0; i < this.state.currentExercise; i++) {
      progress += this.getExerciseProgressLength(i);
    }
    progress += this.state.currentExerciseProgress;
    return progress;
  }

  renderFinish() {
    if (!this.state.completedStudyRecordResponse) return <Loading />;
    const completeStudyRecord = this.state.completedStudyRecordResponse
      .completeStudyRecord;
    if (
      completeStudyRecord &&
      completeStudyRecord.studyRecord &&
      completeStudyRecord.studyRecord.identifier
    ) {
      const resultPageType = this.props.isDemo ? 'demo-result' : 'result';
      let resultUrl = `/${resultPageType}/${completeStudyRecord.studyRecord.identifier}`;
      if (this.props.isEmbed) {
        resultUrl += '/embed';
      }
      return <Redirect to={resultUrl} />;
    }
  }

  renderExercise() {
    const exercise = this.props.worksheet.exercises[this.state.currentExercise];
    const { __typename } = exercise;
    let Widget: any = TranslatorWidget;
    if (__typename === 'WordOrderExercise') Widget = WordOrderWidget;
    if (__typename === 'VocabExercise') Widget = VocabWidget;
    if (__typename === 'MultipleChoiceExercise') Widget = MultipleChoiceWidget;
    let answerRecorder: any = this.onRecordTranslationAnswer;
    if (__typename === 'WordOrderExercise')
      answerRecorder = this.onRecordWordOrderAnswer;
    if (__typename === 'VocabExercise')
      answerRecorder = this.onRecordVocabAnswer;
    if (__typename === 'MultipleChoiceExercise')
      answerRecorder = this.onRecordMultipleChoiceAnswer;
    return (
      <React.Fragment>
        <div className="WorksheetStudier-exerciseHeader f4 pa2">
          <div className="WorksheetStudier-progressBar">
            <div
              className="WorksheetStudier-progressBarInner"
              style={{
                width: `${(100 * this.getCurrentProgress()) /
                  this.getTotalProgressLength()}%`,
              }}
            />
          </div>
        </div>
        <div className="WorksheetStudier-widgetContainer">
          <div className="WorksheetStudier-widgetContainerInner">
            <Widget
              key={exercise.id}
              exercise={exercise}
              onDone={this.onNextExercise}
              hasNext={
                this.state.currentExercise + 1 <
                this.props.worksheet.exercises.length
              }
              onMistake={(...args: any[]) => answerRecorder(false, ...args)}
              onCorrect={(...args: any[]) => answerRecorder(true, ...args)}
              onProgress={(progress: number) =>
                this.setState({ currentExerciseProgress: progress })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderIntro() {
    return (
      <div className="WorksheetStudier-vertCenter">
        <div>
          <h1 className="mt0">{this.props.worksheet.title}</h1>
          {!this.props.worksheet.description ? null : (
            <p>{this.props.worksheet.description}</p>
          )}
          <p className="gray">
            <FormattedMessage
              id="WorksheetStudier.num_exercises"
              defaultMessage="{numExercises, number} {numExercises, plural,
                one {exercise}
                other {exercises}
              }"
              values={{ numExercises: this.props.worksheet.exercises.length }}
            />
          </p>
          <div className="pt4 mw5 center">
            <Button
              size="large"
              buttonType="success"
              fullWidth
              onClick={this.onStart}
            >
              <FormattedMessage
                id="WorksheetStudier.start_button"
                defaultMessage="Start"
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log(this.props.worksheet);
    return (
      <div className="WorksheetStudier">
        {this.state.hasStarted ? null : this.renderIntro()}
        {!this.state.hasStarted || this.state.hasFinished
          ? null
          : this.renderExercise()}
        {!this.state.hasFinished ? null : this.renderFinish()}
      </div>
    );
  }
}

export default createFragmentContainer(WorksheetStudier, {
  viewer: graphql`
    fragment WorksheetStudier_viewer on Viewer {
      user {
        id
      }
    }
  `,
  worksheet: graphql`
    fragment WorksheetStudier_worksheet on Worksheet {
      id
      title
      description
      identifier
      isPublished
      exercises {
        id
        __typename
        ... on TranslationExercise {
          ...TranslatorWidget_exercise
        }
        ... on WordOrderExercise {
          ...WordOrderWidget_exercise
        }
        ... on MultipleChoiceExercise {
          ...MultipleChoiceWidget_exercise
        }
        ... on VocabExercise {
          ...VocabWidget_exercise
          content {
            terms {
              word
              image {
                url(size: LARGE_SQUARE)
              }
            }
          }
        }
      }
    }
  `,
});
