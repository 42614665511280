/* tslint:disable */
/* eslint-disable */
/* @relayHash f5efb95f906b191f8d225b0a2a6db820 */

import { ConcreteRequest } from "relay-runtime";
export type SubscriptionType = "TEACHER_FOREVER" | "TEACHER_MONTHLY" | "TEACHER_YEARLY" | "%future added value";
export type CancelUpgradeSubscriptionInput = {
    clientMutationId?: string | null;
};
export type CancelUpgradeSubscriptionMutationVariables = {
    input: CancelUpgradeSubscriptionInput;
};
export type CancelUpgradeSubscriptionMutationResponse = {
    readonly cancelUpgradeSubscription: {
        readonly viewer: {
            readonly user: {
                readonly id: string;
                readonly isUpgradedTeacher: boolean;
                readonly subscriptionStatus: {
                    readonly subscriptionCancelAtPeriodEnd: boolean;
                    readonly subscriptionCurrentPeriodEnd: number | null;
                    readonly subscriptionCardEndDigits: string | null;
                    readonly plan: {
                        readonly price: number;
                    } | null;
                    readonly subscriptionType: SubscriptionType | null;
                } | null;
            } | null;
        } | null;
    } | null;
};
export type CancelUpgradeSubscriptionMutation = {
    readonly response: CancelUpgradeSubscriptionMutationResponse;
    readonly variables: CancelUpgradeSubscriptionMutationVariables;
};



/*
mutation CancelUpgradeSubscriptionMutation(
  $input: CancelUpgradeSubscriptionInput!
) {
  cancelUpgradeSubscription(input: $input) {
    viewer {
      user {
        id
        isUpgradedTeacher
        subscriptionStatus {
          subscriptionCancelAtPeriodEnd
          subscriptionCurrentPeriodEnd
          subscriptionCardEndDigits
          plan {
            price
            id
          }
          subscriptionType
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CancelUpgradeSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpgradedTeacher",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subscriptionCancelAtPeriodEnd",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subscriptionCurrentPeriodEnd",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subscriptionCardEndDigits",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subscriptionType",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CancelUpgradeSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cancelUpgradeSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelUpgradeSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "subscriptionStatus",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserSubscriptionStatus",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "plan",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SubscriptionPlan",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CancelUpgradeSubscriptionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cancelUpgradeSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelUpgradeSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "subscriptionStatus",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserSubscriptionStatus",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "plan",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SubscriptionPlan",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CancelUpgradeSubscriptionMutation",
    "id": null,
    "text": "mutation CancelUpgradeSubscriptionMutation(\n  $input: CancelUpgradeSubscriptionInput!\n) {\n  cancelUpgradeSubscription(input: $input) {\n    viewer {\n      user {\n        id\n        isUpgradedTeacher\n        subscriptionStatus {\n          subscriptionCancelAtPeriodEnd\n          subscriptionCurrentPeriodEnd\n          subscriptionCardEndDigits\n          plan {\n            price\n            id\n          }\n          subscriptionType\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2975526982128f756dda875b750d7d53';
export default node;
