import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Location } from 'history';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
  // @ts-ignore
} from 'react-simple-dropdown';
import { IntlShape } from 'react-intl';
import { NavHeader_viewer } from './__generated__/NavHeader_viewer.graphql';
import auth from '../../lib/auth';
import FeedbackModal from '../common/FeedbackModal';
import { messageType } from '../common/FeedbackModal';
import './index.css';
import DropdownLink from '../common/DropdownLink';
import DropdownBackground from '../common/DropdownBackground';
import FullScreenSlideoutModal from './FullScreenSlideoutModal';
import MobileMenuLink from './MobileMenuLink';
import MobileMenuDivider from './MobileMenuDivider';
import { exists } from '../../lib/utils';
import Button from '../common/Button';
import EditClassroomModal from '../EditClassroomModal';
import { clearCampaign } from '../../lib/campaignTracker';

interface NavHeaderProps extends RouteComponentProps {
  viewer?: NavHeader_viewer | null;
  intl: IntlShape;
  location: Location;
}
interface NavHeaderState {
  showingFeedbackModalType: messageType | undefined | null;
  isShowingMobileMenu: boolean;
  isNewClassroomModalOpen: boolean;
  prevLocation?: Location;
}

class NavHeader extends Component<NavHeaderProps, NavHeaderState> {
  state: NavHeaderState = {
    showingFeedbackModalType: null,
    isShowingMobileMenu: false,
    isNewClassroomModalOpen: false,
  };

  static getDerivedStateFromProps(
    nextProps: NavHeaderProps,
    state: NavHeaderState,
  ) {
    if (nextProps.location !== state.prevLocation) {
      return {
        prevLocation: nextProps.location,
        isShowingMobileMenu: false,
        isNewClassroomModalOpen: false,
      };
    }
    return null;
  }

  signOut = () => {
    auth.clearToken();
    window.heap.resetIdentity();
    window.heap.track('signout');
    window.location.reload();
  };
  isLoggedIn = () =>
    this.props.viewer && this.props.viewer.user && this.props.viewer.user.id;
  isAdmin = () =>
    this.props.viewer &&
    this.props.viewer.user &&
    this.props.viewer.user.isAdmin;

  renderMobileMenuContents() {
    const { viewer } = this.props;
    const classroomMemberships = (
      viewer?.user?.classroomMemberships || []
    ).filter(membership => membership && membership.level !== 'BANNED');
    const shouldShowClasses =
      viewer?.user?.accountType === 'TEACHER' ||
      classroomMemberships.length > 0;

    const classesSection = shouldShowClasses && (
      <>
        <MobileMenuDivider
          label={
            <FormattedMessage
              id="NavHeader.your_classes_label"
              defaultMessage="Your classes"
            />
          }
        />
        {classroomMemberships.filter(exists).map(({ level, classroom }) => (
          <MobileMenuLink
            component={Link}
            to={`/class/${classroom.identifier}`}
            key={classroom.id}
          >
            {level === 'PENDING' && <i className="mr2 fas fa-clock" />}
            {classroom.title}
          </MobileMenuLink>
        ))}
        {viewer?.user?.accountType === 'TEACHER' && (
          <div className="ml3 pv3">
            <Button
              buttonType="default"
              outline
              size="small"
              onClick={() =>
                this.setState({
                  isNewClassroomModalOpen: true,
                  isShowingMobileMenu: false,
                })
              }
            >
              <span className="dib ph3">
                <i className="fas fa-plus mr2" />
                <FormattedMessage
                  id="NavHeader.new_class_button"
                  defaultMessage="New class"
                />
              </span>
            </Button>
          </div>
        )}
      </>
    );

    return (
      <>
        <MobileMenuLink
          to={`/profile/${this.props.viewer?.user?.username || ''}`}
          component={Link}
        >
          {this.props.viewer?.user?.username}
        </MobileMenuLink>
        <MobileMenuLink to={`/`} component={Link}>
          <FormattedMessage id="NavHeader.home_link" defaultMessage="Home" />
        </MobileMenuLink>
        {!this.isAdmin() ? null : (
          <MobileMenuLink to="/admin/users" component={Link}>
            <FormattedMessage
              id="NavHeader.admin_link"
              defaultMessage="Admin"
            />
          </MobileMenuLink>
        )}
        <MobileMenuLink to="/account-settings" component={Link}>
          <FormattedMessage id="NavHeader.account" defaultMessage="Account" />
        </MobileMenuLink>
        {viewer?.user?.accountType === 'TEACHER' && (
          <div className="ml3 pv3">
            <Button
              buttonType="default"
              outline
              size="small"
              component={Link}
              to="/new-sheet"
            >
              <span className="dib ph3">
                <i className="fas fa-plus mr2" />
                <FormattedMessage
                  id="NavHeader.new_worksheet_button"
                  defaultMessage="New wordsheet"
                />
              </span>
            </Button>
          </div>
        )}
        {classesSection}
        <MobileMenuDivider />
        <MobileMenuLink dividerTop onClick={this.signOut}>
          <FormattedMessage id="NavHeader.sign_out" defaultMessage="Sign out" />
        </MobileMenuLink>
      </>
    );
  }

  render() {
    const isLoggedIn = this.isLoggedIn();
    if (isLoggedIn) {
      // hacky to do this here, but if the user is logged in then we should forget about what campaign they came from
      clearCampaign();
    }
    return (
      <div className="NavHeader bg-blue">
        <nav className="dt w-100 mw8 center">
          <div className="dtc v-mid f3 tl pa3 pr0">
            <Link
              to={isLoggedIn ? '/dashboard' : '/'}
              className="dib pv2 white no-underline NavHeader-logo"
            >
              Wordsheet
            </Link>
          </div>
          <div className="dtc v-mid tr pl0">
            {isLoggedIn &&
              !this.props.viewer?.user?.isUpgradedTeacher &&
              this.props.viewer?.user?.accountType === 'TEACHER' && (
                <Link
                  to="/upgrade"
                  className="f6 fw4 hover-white no-underline white-70 pv3 ph2 ph3-ns pointer dn dib-ns"
                >
                  <FormattedMessage
                    id="NavHeader.upgrade_link"
                    defaultMessage="Upgrade"
                  />
                </Link>
              )}
            {/* eslint-disable-next-line */}
            <a
              className="f6 fw4 hover-white no-underline white-70 pv3 ph2 ph3-ns pointer dn dib-ns"
              onClick={() =>
                this.setState({
                  showingFeedbackModalType: 'feedback',
                })
              }
            >
              <FormattedMessage
                id="NavHeader.feedback"
                defaultMessage="Feedback"
                description="Button to send feedback"
              />
            </a>
            {/* eslint-disable-next-line */}
            <a
              className="f6 fw4 hover-white no-underline white-70 dib pv3 ph2 ph3-ns pointer"
              onClick={() =>
                this.setState({
                  showingFeedbackModalType: 'help',
                })
              }
            >
              <FormattedMessage
                id="NavHeader.help"
                defaultMessage="Help"
                description="Button to ask for help"
              />
            </a>
            {isLoggedIn ? (
              <>
                <Dropdown>
                  <DropdownTrigger>
                    <div className="f6 fw4 hover-white no-underline white-70 dn dib-ns pa3 pointer">
                      {this.props.viewer?.user?.username}
                      <span className="dib ml2">▾</span>
                    </div>
                  </DropdownTrigger>
                  <DropdownContent>
                    <DropdownBackground>
                      {!this.isAdmin() ? null : (
                        <DropdownLink to="/admin/users" component={Link}>
                          <FormattedMessage
                            id="NavHeader.admin_link"
                            defaultMessage="Admin"
                          />
                        </DropdownLink>
                      )}
                      <DropdownLink
                        to={`/profile/${this.props.viewer?.user?.username ||
                          ''}`}
                        component={Link}
                      >
                        <FormattedMessage
                          id="NavHeader.profile"
                          defaultMessage="Profile"
                        />
                      </DropdownLink>
                      <DropdownLink to="/account-settings" component={Link}>
                        <FormattedMessage
                          id="NavHeader.account"
                          defaultMessage="Account"
                        />
                      </DropdownLink>
                      <DropdownLink dividerTop onClick={this.signOut}>
                        <FormattedMessage
                          id="NavHeader.sign_out"
                          defaultMessage="Sign out"
                        />
                      </DropdownLink>
                    </DropdownBackground>
                  </DropdownContent>
                </Dropdown>
                <a
                  href="#mobile-menu"
                  className="f6 fw4 hover-white no-underline white-70 dib pa3 dn-ns pointer"
                  onClick={evt => {
                    evt.preventDefault();
                    this.setState({ isShowingMobileMenu: true });
                  }}
                >
                  <i className="fas fa-bars" />
                </a>
              </>
            ) : (
              this.props.viewer && (
                <>
                  <Link
                    to="/log-in"
                    className="f6 fw4 hover-white no-underline white-70 dib-ns pv3 ph2 ph3-ns dn"
                  >
                    <FormattedMessage
                      id="NavHeader.log_in"
                      defaultMessage="Log in"
                    />
                  </Link>
                  <Link
                    to="/sign-up"
                    className="f6 fw4 hover-white no-underline white-70 dib pv3 ph2 ph3-ns"
                    data-testid="NavHeader-signUpLink"
                  >
                    <FormattedMessage
                      id="NavHeader.sign_up"
                      defaultMessage="Sign up"
                    />
                  </Link>
                </>
              )
            )}
          </div>
        </nav>
        <FullScreenSlideoutModal
          isOpen={this.state.isShowingMobileMenu}
          onClose={() => this.setState({ isShowingMobileMenu: false })}
        >
          {this.renderMobileMenuContents()}
        </FullScreenSlideoutModal>
        <FeedbackModal
          viewer={this.props.viewer}
          isOpen={!!this.state.showingFeedbackModalType}
          messageType={this.state.showingFeedbackModalType as messageType}
          onDone={() =>
            this.setState({
              showingFeedbackModalType: null,
            })
          }
        />
        <EditClassroomModal
          isOpen={this.state.isNewClassroomModalOpen}
          onClose={() => this.setState({ isNewClassroomModalOpen: false })}
        />
      </div>
    );
  }
}

export default withRouter(
  injectIntl(
    createFragmentContainer(NavHeader, {
      viewer: graphql`
        fragment NavHeader_viewer on Viewer {
          ...FeedbackModal_viewer
          user {
            id
            username
            isAdmin
            createdAt
            isUpgradedTeacher
            accountType
            classroomMemberships {
              level
              classroom {
                id
                title
                identifier
              }
            }
            worksheets(first: 1) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      `,
    }),
  ),
);
