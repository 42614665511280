import React, { Component } from 'react';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { AddWorksheetModalWorksheet_worksheet } from './__generated__/AddWorksheetModalWorksheet_worksheet.graphql';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/browser';
import './AddWorksheetModalWorksheet.css';
import TimeAgo from '../common/TimeAgo';
import Button from '../common/Button';
import ErrorBox from '../common/ErrorBox';
import CreateClassroomWorksheetMutation from '../../mutations/CreateClassroomWorksheetMutation';

type AddWorksheetModalWorksheetProps = {
  worksheet: AddWorksheetModalWorksheet_worksheet;
  isAddedToClass: boolean;
  classroomId: string;
  relay: RelayProp;
};

type AddWorksheetModalWorksheetState = {
  isAdding: boolean;
  isAdded: boolean;
  error: string | null;
};

class AddWorksheetModalWorksheet extends Component<
  AddWorksheetModalWorksheetProps,
  AddWorksheetModalWorksheetState
> {
  state: AddWorksheetModalWorksheetState = {
    isAdding: false,
    error: null,
    isAdded: false,
  };

  onAdd = async () => {
    const { classroomId } = this.props;
    if (this.state.isAdding) return;
    this.setState({ isAdding: true, error: null });
    try {
      await CreateClassroomWorksheetMutation.commit(
        this.props.relay.environment,
        classroomId,
        this.props.worksheet.id,
      );
      this.setState({ isAdding: false });
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error creating classroomWorksheet', err);
      this.setState({
        error: 'Unable to add wordsheet to class',
        isAdding: false,
      });
    }
  };

  render() {
    const { worksheet, isAddedToClass } = this.props;
    const { isAdding, error } = this.state;
    return (
      <div className="AddWorksheetModalWorksheet">
        <div className="ph3 bg-white flex items-center">
          <div className="flex-grow-1">
            <h1 className="f4 lh-title no-underline pv3 ma0">
              {worksheet.title}
            </h1>
            {error && <ErrorBox>{error}</ErrorBox>}
            <div className="AddWorksheetModalWorksheet-tidbits f6 pb3">
              <FormattedMessage
                id="AddWorksheetModalWorksheet.num_exercises"
                defaultMessage="{numExercises, number} {numExercises, plural,
                  one {exercise}
                  other {exercises}
                }"
                values={{
                  numExercises: worksheet.exercises.length,
                }}
              />
              <div className="AddWorksheetModalWorksheet-tidbitSeparator" />
              <FormattedMessage
                id="AddWorksheetModalWorksheet.wordsheet_created_time"
                defaultMessage="Created {ago}"
                values={{
                  ago: (
                    <TimeAgo since={new Date(worksheet.publishedAt * 1000)} />
                  ),
                }}
              />
            </div>
          </div>
          {isAddedToClass ? (
            <div>
              <i className="fas fa-check pr2 green" />
              <FormattedMessage
                id="AddWorksheetModalWorksheet.added"
                defaultMessage="Added"
              />
            </div>
          ) : (
            <Button
              outline
              buttonType="default"
              onClick={this.onAdd}
              loading={isAdding}
              disabled={isAdding}
            >
              <FormattedMessage
                id="AddWorksheetModalWorksheet.add_button"
                defaultMessage="Add to class"
              />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(AddWorksheetModalWorksheet, {
  worksheet: graphql`
    fragment AddWorksheetModalWorksheet_worksheet on Worksheet {
      id
      title
      publishedAt
      exercises {
        id
      }
    }
  `,
});
