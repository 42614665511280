/* tslint:disable */
/* eslint-disable */
/* @relayHash 0c08ac95252583fc18faee842d2622a1 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateClassroomWorksheetInput = {
    classroomId: string;
    worksheetId: string;
    clientMutationId?: string | null;
};
export type CreateClassroomWorksheetMutationVariables = {
    input: CreateClassroomWorksheetInput;
};
export type CreateClassroomWorksheetMutationResponse = {
    readonly createClassroomWorksheet: {
        readonly classroomClassroomWorksheetsEdge: {
            readonly cursor: string;
            readonly node: {
                readonly id: string;
                readonly classroom: {
                    readonly id: string;
                    readonly numClassroomWorksheets: number;
                };
                readonly " $fragmentRefs": FragmentRefs<"ClassroomWorksheet_classroomWorksheet">;
            } | null;
        };
    } | null;
};
export type CreateClassroomWorksheetMutation = {
    readonly response: CreateClassroomWorksheetMutationResponse;
    readonly variables: CreateClassroomWorksheetMutationVariables;
};



/*
mutation CreateClassroomWorksheetMutation(
  $input: CreateClassroomWorksheetInput!
) {
  createClassroomWorksheet(input: $input) {
    classroomClassroomWorksheetsEdge {
      cursor
      node {
        id
        ...ClassroomWorksheet_classroomWorksheet
        classroom {
          id
          numClassroomWorksheets
        }
      }
    }
  }
}

fragment ClassroomWorksheet_classroomWorksheet on ClassroomWorksheet {
  id
  worksheet {
    ...Worksheet_worksheet
    id
  }
  classroom {
    id
  }
}

fragment Worksheet_worksheet on Worksheet {
  title
  description
  identifier
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateClassroomWorksheetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "classroom",
  "storageKey": null,
  "args": null,
  "concreteType": "Classroom",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numClassroomWorksheets",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateClassroomWorksheetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClassroomWorksheet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClassroomWorksheetPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "classroomClassroomWorksheetsEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ClassroomWorksheetEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomWorksheet",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "ClassroomWorksheet_classroomWorksheet",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateClassroomWorksheetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClassroomWorksheet",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClassroomWorksheetPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "classroomClassroomWorksheetsEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ClassroomWorksheetEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomWorksheet",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "worksheet",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Worksheet",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "identifier",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ]
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateClassroomWorksheetMutation",
    "id": null,
    "text": "mutation CreateClassroomWorksheetMutation(\n  $input: CreateClassroomWorksheetInput!\n) {\n  createClassroomWorksheet(input: $input) {\n    classroomClassroomWorksheetsEdge {\n      cursor\n      node {\n        id\n        ...ClassroomWorksheet_classroomWorksheet\n        classroom {\n          id\n          numClassroomWorksheets\n        }\n      }\n    }\n  }\n}\n\nfragment ClassroomWorksheet_classroomWorksheet on ClassroomWorksheet {\n  id\n  worksheet {\n    ...Worksheet_worksheet\n    id\n  }\n  classroom {\n    id\n  }\n}\n\nfragment Worksheet_worksheet on Worksheet {\n  title\n  description\n  identifier\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9950867fa29f03106cbb948392e1cc99';
export default node;
