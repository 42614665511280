/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExerciseSummary_exercise = {
    readonly id: string;
    readonly worksheet: {
        readonly id: string;
    };
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"TranslationExerciseSummary_exercise" | "WordOrderExerciseSummary_exercise" | "VocabExerciseSummary_exercise" | "MultipleChoiceExerciseSummary_exercise">;
    readonly " $refType": "ExerciseSummary_exercise";
};
export type ExerciseSummary_exercise$data = ExerciseSummary_exercise;
export type ExerciseSummary_exercise$key = {
    readonly " $data"?: ExerciseSummary_exercise$data;
    readonly " $fragmentRefs": FragmentRefs<"ExerciseSummary_exercise">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ExerciseSummary_exercise",
  "type": "Exercise",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "worksheet",
      "storageKey": null,
      "args": null,
      "concreteType": "Worksheet",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "TranslationExercise",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "TranslationExerciseSummary_exercise",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "WordOrderExercise",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "WordOrderExerciseSummary_exercise",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "VocabExercise",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "VocabExerciseSummary_exercise",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "MultipleChoiceExercise",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "MultipleChoiceExerciseSummary_exercise",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'ab4ee9f2376f7abf69ce97647a178901';
export default node;
