import React, { Component, FormEvent } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { WorksheetAuth_worksheet } from './__generated__/WorksheetAuth_worksheet.graphql';
import { WorksheetAuth_viewer } from './__generated__/WorksheetAuth_viewer.graphql';
import {
  FormattedMessage,
  IntlShape,
  injectIntl,
  defineMessages,
} from 'react-intl';
import Button from '../common/Button';
import LogInForm from './LogInForm';
import SignUpForm from './SignUpForm';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import UnlockPasswordedWorksheetMutation from '../../mutations/UnlockPasswordedWorksheetMutation';
import './WorksheetAuth.css';

interface WorksheetAuthProps {
  intl: IntlShape;
  worksheet: WorksheetAuth_worksheet;
  viewer: WorksheetAuth_viewer;
  relay: RelayProp;
}

interface WorksheetAuthState {
  mode: 'intro' | 'signup' | 'login';
  password: string;
  isSubmitting: boolean;
  error: string | null;
}

const messages = defineMessages({
  missingPasswordError: {
    id: 'WorksheetAuth.missing_password_error',
    defaultMessage: 'Enter a password',
  },
});

class WorksheetAuth extends Component<WorksheetAuthProps, WorksheetAuthState> {
  state: WorksheetAuthState = {
    mode: 'intro',
    password: '',
    isSubmitting: false,
    error: null,
  };

  async onSubmitPassword(evt: FormEvent) {
    evt.preventDefault();

    const { password } = this.state;
    const { formatMessage } = this.props.intl;

    if (!password) {
      return this.setState({
        error: formatMessage(messages.missingPasswordError),
      });
    }

    this.setState({
      error: null,
    });

    try {
      this.setState({
        isSubmitting: true,
      });
      await UnlockPasswordedWorksheetMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
        password,
      );
      // hackily just force reload to be safe
      // TODO: Figure out how to get relay to refresh after the mutation
      window.location.reload();
    } catch (err) {
      this.setState({
        error: err.message,
        isSubmitting: false,
      });
    }
  }

  renderAction() {
    const { viewer } = this.props;
    const { visibility } = this.props.worksheet;

    if (!viewer.user) {
      return (
        <div className="WorksheetAuth-loginButton">
          <Button
            buttonType="success"
            size="large"
            onClick={() => this.setState({ mode: 'login' })}
          >
            <FormattedMessage
              id="WorksheedAuth.log_in"
              defaultMessage="Log in"
            />
          </Button>
        </div>
      );
    }

    if (visibility === 'PASSWORD') {
      return (
        <div className="pt3 center measure">
          <form
            onSubmit={evt => this.onSubmitPassword(evt)}
            id="wordsheetPasswordForm"
          >
            <FormGroup
              label={
                <FormattedMessage
                  id="WorksheedAuth.password"
                  defaultMessage="Password"
                />
              }
              hint={
                <FormattedMessage
                  id="WorksheedAuth.contact_creator_for_help"
                  defaultMessage="Contact the creator of this wordsheet if you need help"
                />
              }
            >
              <Input
                value={this.state.password}
                type="password"
                id="wordsheetPassword"
                onChange={evt => this.setState({ password: evt.target.value })}
              />
            </FormGroup>
            {this.state.error && (
              <div className="pa3 mb4 red ba b--light-red">
                {this.state.error}
              </div>
            )}
            <div className="pt3">
              <Button
                buttonType="success"
                size="large"
                type="submit"
                disabled={this.state.password.trim() === ''}
              >
                <FormattedMessage
                  id="WorksheedAuth.submit_password"
                  defaultMessage="Gain access"
                />
              </Button>
            </div>
          </form>
        </div>
      );
    }
  }

  renderVisibility() {
    const { visibility } = this.props.worksheet;
    const { viewer } = this.props;
    if (!viewer.user) {
      return (
        <div className="mt3 gray">
          <i className="fas fa-lock mr2" />
          <FormattedMessage
            id="WorksheetAuth.visible_to_logged_in"
            defaultMessage="This wordsheet is visible to logged-in users only"
          />
        </div>
      );
    }
    if (visibility === 'PASSWORD') {
      return (
        <div className="mt3 gray">
          <p>
            <i className="fas fa-lock mr2" />
            <FormattedMessage
              id="WorksheetAuth.visible_with_password"
              defaultMessage="This wordsheet is password protected"
            />
          </p>
        </div>
      );
    }
  }

  renderLogin() {
    return (
      <div className="ba b--black-10 measure center pa3 tl mb4 br2">
        <h4 className="lh-title mt0 f4 tc">
          <FormattedMessage
            id="WorksheetAuth.log_in_prompt"
            defaultMessage="Log in to view this wordsheet"
          />
        </h4>
        <LogInForm
          environment={this.props.relay.environment}
          oauthRedirect={window.location.pathname}
          onLogIn={() => window.location.reload()}
          background="white"
        />
        <div className="center pa3 mt2 tc gray">
          <FormattedMessage
            id="WorksheetAuth.switch_to_sign_up"
            defaultMessage="Don't have an account yet?"
          />
          {/* eslint-disable-next-line */}
          <a
            className="ml1 pointer"
            onClick={() => this.setState({ mode: 'signup' })}
          >
            <FormattedMessage
              id="WorksheetAuth.sign_up_link"
              defaultMessage="Sign up"
            />
          </a>
        </div>
      </div>
    );
  }

  renderSignup() {
    return (
      <div className="ba b--black-10 measure center pa3 tl mb4 br2">
        <h4 className="lh-title mt0 f4 tc">
          <FormattedMessage
            id="WorksheetAuth.sign_up_prompt"
            defaultMessage="Sign up for free to view this wordsheet"
          />
        </h4>
        <SignUpForm
          environment={this.props.relay.environment}
          oauthRedirect={window.location.pathname}
          onSignUp={() => window.location.reload()}
          probableAccountType="STUDENT"
          background="white"
        />
        <div className="center pa3 mt2 tc gray">
          <FormattedMessage
            id="WorksheetAuth.switch_to_login"
            defaultMessage="Already have an account?"
          />
          {/* eslint-disable-next-line */}
          <a
            className="ml1 pointer WorksheetAuth-logInLink"
            onClick={() => this.setState({ mode: 'login' })}
          >
            <FormattedMessage
              id="WorksheetAuth.login_link"
              defaultMessage="Log in"
            />
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { worksheet } = this.props;
    const { mode } = this.state;
    return (
      <div>
        <div>
          <h1 className="mt0">{worksheet.title}</h1>
          {mode === 'intro' && (
            <>
              <div className="mt5">{this.renderVisibility()}</div>
              <div className="mt4">{this.renderAction()}</div>
            </>
          )}
          {mode === 'login' && this.renderLogin()}
          {mode === 'signup' && this.renderSignup()}
        </div>
      </div>
    );
  }
}

export default injectIntl(
  createFragmentContainer(WorksheetAuth, {
    worksheet: graphql`
      fragment WorksheetAuth_worksheet on Worksheet {
        id
        title
        visibility
        isVisibleToViewer
      }
    `,
    viewer: graphql`
      fragment WorksheetAuth_viewer on Viewer {
        user {
          id
        }
      }
    `,
  }),
);
