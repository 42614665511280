import { FC, ReactNode } from 'react';
import Select from 'react-select';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './AccountTypeSelect.css';

export type ACCOUNT_TYPE = 'TEACHER' | 'STUDENT';

interface AccountTypeSelectProps {
  value: 'TEACHER' | 'STUDENT' | null;
  onChange: (value: ACCOUNT_TYPE | null) => void;
  includeEmpty?: boolean;
}

const baseSelectOptions: {
  value: ACCOUNT_TYPE;
  label: ReactNode;
}[] = [
  {
    value: 'STUDENT',
    label: (
      <span data-testid="AccountTypeSelect-studentOption">
        <i className="fas fa-user-graduate mr2" />
        <FormattedMessage
          id="AccountTypeSelect.student_label"
          defaultMessage="Student"
        />
      </span>
    ),
  },
  {
    value: 'TEACHER',
    label: (
      <span data-testid="AccountTypeSelect-teacherOption">
        <i className="fas fa-chalkboard-teacher mr2" />
        <FormattedMessage
          id="AccountTypeSelect.teacher_label"
          defaultMessage="Teacher"
        />
      </span>
    ),
  },
];

const optionsWithEmpty = [
  {
    value: null,
    label: (
      <FormattedMessage
        id="AccountTypeSelect.none_label"
        defaultMessage="None selected"
      />
    ),
  },
  ...baseSelectOptions,
];

const AccountTypeSelect: FC<AccountTypeSelectProps> = ({
  value,
  onChange,
  includeEmpty,
}) => {
  const selectOptions = includeEmpty ? optionsWithEmpty : baseSelectOptions;
  return (
    <div data-testid="AccountTypeSelect" className="AccountTypeSelect">
      <Select
        value={selectOptions.find(opt => opt.value === value)}
        options={selectOptions}
        onChange={val => val && 'value' in val && onChange(val.value)}
      />
    </div>
  );
};

export default AccountTypeSelect;
