import React, { FC, ReactNode } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import './FullScreenSlideoutModal.css';
import { Link } from 'react-router-dom';

interface FullScreenSlideoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const FullScreenSlideoutModal: FC<FullScreenSlideoutModalProps> = ({
  isOpen,
  onClose,
  children,
}) => (
  <div className="FullScreenSlideoutModal">
    <CSSTransitionGroup
      transitionName="toggle"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {!isOpen
        ? null
        : [
            <div
              className="FullScreenSlideoutModal-backdrop"
              key="backdrop"
              onClick={onClose}
            />,
            <div className="FullScreenSlideoutModal-container" key="container">
              <div className="FullScreenSlideoutModal-main">
                <h3 className="FullScreenSlideoutModal-header bg-blue white tl">
                  <Link to="/" className="white link">
                    Wordsheet
                  </Link>
                  <button
                    onClick={onClose}
                    className="FullScreenSlideoutModal-headerCloseButton"
                  >
                    <i className="fas fa-times" />
                  </button>
                </h3>

                <div className="FullScreenSlideoutModal-body">{children}</div>
              </div>
            </div>,
          ]}
    </CSSTransitionGroup>
  </div>
);

export default FullScreenSlideoutModal;
