import React, { Component } from 'react';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  IntlShape,
} from 'react-intl';
import Modal from '../common/Modal';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import Button from '../common/Button';

interface EditPasswordModalProps {
  password: string;
  isOpen: boolean;
  intl: IntlShape;
  onClose: () => void;
  onDone: (password: string) => void;
}

interface EditPasswordModalState {
  password: string;
  prevIsOpen: boolean;
  prevPassword: string;
}

const messages = defineMessages({
  passwordPlaceholder: {
    id: 'EditPasswordModal.password_placeholder',
    defaultMessage: 'Password',
  },
});

class EditPasswordModal extends Component<
  EditPasswordModalProps,
  EditPasswordModalState
> {
  state: EditPasswordModalState = {
    password: '',
    prevIsOpen: false,
    prevPassword: '',
  };

  static getDerivedStateFromProps(
    { password, isOpen }: EditPasswordModalProps,
    { prevPassword, prevIsOpen }: EditPasswordModalState,
  ) {
    if (prevPassword !== password || prevIsOpen !== isOpen) {
      return {
        password,
        prevPassword: password,
        prevIsOpen: isOpen,
      };
    }
    return null;
  }

  render() {
    const { isOpen, onClose, onDone, intl } = this.props;
    return (
      <Modal
        closeButton={true}
        header={
          <FormattedMessage
            id="EditPasswordModal.modal_header"
            defaultMessage="Choose a password for your Wordsheet"
          />
        }
        onClose={onClose}
        isOpen={isOpen}
        closeOnTapBackdrop={false}
      >
        <div>
          <FormGroup>
            <Input
              type="text"
              value={this.state.password}
              onChange={evt => this.setState({ password: evt.target.value })}
              placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            />
          </FormGroup>
          <Button
            fullWidth
            onClick={() => onDone(this.state.password.trim())}
            disabled={this.state.password.trim() === ''}
          >
            <FormattedMessage
              id="EditPasswordModal.done"
              defaultMessage="Done"
            />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(EditPasswordModal);
