import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CreateWorksheetMutation } from './__generated__/CreateWorksheetMutation.graphql';
const mutation = graphql`
  mutation CreateWorksheetMutation($input: CreateWorksheetInput!) {
    createWorksheet(input: $input) {
      worksheet {
        id
        title
        description
        identifier
        isPublished
      }
    }
  }
`;

const commit = (
  environment: any,
  title?: string | null,
  description?: string | null,
) =>
  commitMutationPromise<CreateWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        title,
        description,
      },
    },
  });

export default {
  commit,
};
