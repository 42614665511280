import React, { Component, FormEvent } from 'react';
import { Environment } from 'react-relay';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import ForgotPasswordMutation from '../../mutations/ForgotPasswordMutation';
import Button from '../common/Button';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';

type State = {
  emailOrUsername: string;
  password: string;
  error: string | undefined | null;
  isDone: boolean;
  isSubmitting: boolean;
};
type Props = {
  intl: IntlShape;
  environment: Environment;
  background?: 'white' | 'gray';
};
const messages = defineMessages({
  missingUsernameOrEmailError: {
    id: 'ForgotPasswordForm.missing_username_or_email_error',
    defaultMessage: 'Enter your username or email',
  },
});

class ForgotPasswordForm extends Component<Props, State> {
  state: State = {
    emailOrUsername: '',
    password: '',
    error: null,
    isDone: false,
    isSubmitting: false,
  };
  static defaultProps: Partial<Props> = {
    background: 'white',
  };
  onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { emailOrUsername } = this.state;
    const { formatMessage } = this.props.intl;

    if (!emailOrUsername) {
      return this.setState({
        error: formatMessage(messages.missingUsernameOrEmailError),
      });
    }

    this.setState({
      error: null,
    });

    try {
      this.setState({
        isSubmitting: true,
      });
      await ForgotPasswordMutation.commit(
        this.props.environment,
        emailOrUsername,
      );
      this.setState({
        isDone: true,
        isSubmitting: false,
      });
    } catch (err) {
      this.setState({
        error: err.message,
        isSubmitting: false,
      });
    }
  };

  renderDone() {
    return (
      <div className="tc">
        <h3 className="f4">
          <FormattedMessage
            id="ForgotPasswordForm.email_sent_header"
            defaultMessage="Check your inbox"
          />
        </h3>
        <p className="mv4 f6">
          <FormattedMessage
            id="ForgotPasswordForm.email_sent_instructions"
            defaultMessage="We sent you an email with instructions to reset your password"
          />
        </p>
        <Button fullWidth component={Link} to="/">
          <FormattedMessage
            id="ForgotPasswordForm.return_home_button"
            defaultMessage="Back home"
          />
        </Button>
      </div>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit}>
        {!this.state.error ? null : (
          <div className="pa3 mb4 red ba b--light-red">{this.state.error}</div>
        )}
        <FormGroup
          label={
            <FormattedMessage
              id="ForgotPasswordForm.username_or_email_label"
              defaultMessage="Username or email"
            />
          }
        >
          <Input
            required
            type="text"
            id="emailOrUsername"
            value={this.state.emailOrUsername}
            onChange={evt =>
              this.setState({
                emailOrUsername: evt.target.value,
              })
            }
          />
        </FormGroup>

        <Button type="submit" fullWidth disabled={this.state.isSubmitting}>
          {this.state.isSubmitting ? (
            <FormattedMessage
              id="ForgotPasswordForm.submitting"
              defaultMessage="Submitting..."
            />
          ) : (
            <FormattedMessage
              id="ForgotPasswordForm.reset_password_button"
              defaultMessage="Reset password"
            />
          )}
        </Button>
      </form>
    );
  }

  render() {
    return this.state.isDone ? this.renderDone() : this.renderForm();
  }
}

export default injectIntl(ForgotPasswordForm);
