/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetEditor_viewer = {
    readonly user: {
        readonly id: string;
    } | null;
    readonly " $refType": "WorksheetEditor_viewer";
};
export type WorksheetEditor_viewer$data = WorksheetEditor_viewer;
export type WorksheetEditor_viewer$key = {
    readonly " $data"?: WorksheetEditor_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetEditor_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WorksheetEditor_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '6ef302fdf1772dd28de61e954bb97f2a';
export default node;
