import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import PageLoading from '../components/common/PageLoading';
import AccountSettings from '../components/AccountSettings';
import { AccountSettingsPageQuery } from './__generated__/AccountSettingsPageQuery.graphql';

const accountSettingsPageQuery = graphql`
  query AccountSettingsPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        ...AccountSettings_user
      }
    }
  }
`;

const AccountSettingsPage = () => (
  <QueryRenderer<AccountSettingsPageQuery>
    environment={environment}
    query={accountSettingsPageQuery}
    variables={{}}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props) return <PageLoading />;
      if (!props.viewer || !props.viewer.user) return <Redirect to="/" />;
      return (
        <div className="AccountSettingsPage">
          <NavHeader viewer={props.viewer} />
          <div className="mw8 center">
            <AccountSettings user={props.viewer.user} />
          </div>
        </div>
      );
    }}
  />
);

export default AccountSettingsPage;
