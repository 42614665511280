import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { DeleteClassroomMutation } from './__generated__/DeleteClassroomMutation.graphql';
const mutation = graphql`
  mutation DeleteClassroomMutation($input: DeleteClassroomInput!) {
    deleteClassroom(input: $input) {
      id
      viewer {
        ...DashboardLayout_viewer
      }
    }
  }
`;

const commit = (environment: any, id: string) =>
  commitMutationPromise<DeleteClassroomMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    },
  });

export default {
  commit,
};
