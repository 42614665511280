import React, { Component, ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';

import FormGroup from '../common/FormGroup';
import WordSplitter from '../common/WordSplitter';
import Distractor from './Distractor';
import Button from '../common/Button';
import AsianLanguagesGroupingNote from '../common/AsianLanguagesGroupingNote';
import RtlLanguagesGroupingNote from '../common/RtlLanguagesGroupingNote';
import EditExtraCorrectAnswerModal from './EditExtraCorrectAnswerModal';
import { getAtoms, findPiecesSpanningText } from '../../lib/atomHelpers';
import {
  removeArrayItem,
  containsChineseChars,
  containsRtlChars,
} from '../../lib/utils';
import './index.css';

export type translatorConfig = {
  prompt: string;
  correctAnswers: ReadonlyArray<string>;
  scrambledParts: ReadonlyArray<string>;
};

type Props = {
  defaultConfig?: null | translatorConfig;
  onUpdateConfig: (config: translatorConfig) => void;
  intl: IntlShape;
};

type State = {
  defaultConfig: null | translatorConfig;
  prompt: string;
  correctAnswer: string;
  answerPieces: string[];
  distractors: string[];
  otherCorrectAnswers: string[];
  fusedPieceLocations: Set<number>;
  isAddingCorrectAnswer: boolean;
};

const messages = defineMessages({
  promptLabel: {
    id: 'TranslatorEditor.prompt_label',
    defaultMessage: 'Prompt',
  },
  promptHint: {
    id: 'TranslatorEditor.prompt_hint',
    defaultMessage:
      'This is the text you will be asking students to translate. Make it concise and clear!',
  },
  promptPlaceholder: {
    id: 'TranslatorEditor.prompt_placeholder',
    defaultMessage: 'Text to translate',
  },
  correctAnswerLabel: {
    id: 'TranslatorEditor.correct_answer_label',
    defaultMessage: 'Correct answer',
  },
  correctAnswerHint: {
    id: 'TranslatorEditor.correct_answer_hint',
    defaultMessage:
      "This is the main correct answer for the exercise. Don't worry if there are other correct answers too - you'll be able to add those below. This should be a different language than the prompt.",
  },
  correctAnswerPlaceholder: {
    id: 'TranslatorEditor.correct_answer_placeholder',
    defaultMessage: 'Correct translation',
  },
  groupWordsLabel: {
    id: 'TranslatorEditor.group_words_label',
    defaultMessage: 'Adjust word grouping',
  },
  groupWordsHint: {
    id: 'TranslatorEditor.group_words_hint',
    defaultMessage:
      'Group letters and words together into chunks that your students will need to assemble into the translation above. The larger the word chunks the easier it will be. Click between words to join them together, and click inside words to split them apart.',
  },
  distractorsLabel: {
    id: 'TranslatorEditor.distractors_label',
    defaultMessage: 'Distractors',
  },
  distractorsHint: {
    id: 'TranslatorEditor.distractors_hint',
    defaultMessage:
      'You can optionally add some extra words to the word bank to make it more challenging for your students.',
  },
  otherCorrectAnswersLabel: {
    id: 'TranslatorEditor.other_correct_answers_label',
    defaultMessage: 'Other correct answers',
  },
  otherCorrectAnswersHint: {
    id: 'TranslatorEditor.other_correct_answers_hint',
    defaultMessage:
      'If there are other ways the words above can be assembled into a correct answer you can list them here.',
  },
});

class TranslatorEditor extends Component<Props, State> {
  state: State = {
    prompt: '',
    correctAnswer: '',
    defaultConfig: null,
    answerPieces: [],
    distractors: [],
    otherCorrectAnswers: [],
    fusedPieceLocations: new Set(),
    isAddingCorrectAnswer: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const defaultConfig = nextProps.defaultConfig;
    if (prevState.defaultConfig !== defaultConfig) {
      if (!defaultConfig) {
        return {
          prompt: '',
          correctAnswer: '',
          defaultConfig: null,
          answerPieces: [],
          distractors: [],
          otherCorrectAnswers: [],
        };
      }
      const correctAnswer = defaultConfig.correctAnswers[0];
      const otherCorrectAnswers: string[] = defaultConfig.correctAnswers.slice(
        1,
      );
      const answerPiecePositions = findPiecesSpanningText(
        correctAnswer,
        defaultConfig.scrambledParts.slice(),
      );
      const answerPieces = [];
      const distractors = [];
      if (answerPiecePositions) {
        for (let i = 0; i < defaultConfig.scrambledParts.length; i++) {
          const word = defaultConfig.scrambledParts[i];
          if (answerPiecePositions.indexOf(i) >= 0) {
            answerPieces.push(word);
          } else {
            distractors.push(word);
          }
        }
      }
      return {
        prompt: defaultConfig.prompt,
        correctAnswer,
        defaultConfig,
        answerPieces,
        distractors,
        otherCorrectAnswers,
      };
    }
    return null;
  }

  onChangeCorrectAnswer = (evt: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      correctAnswer: evt.target.value,
      answerPieces: getAtoms(evt.target.value),
      fusedPieceLocations: new Set(),
    });
  };

  onFuseAnswerPieces = (connectorIndex: number) => {
    const fusedPieceLocations = new Set(this.state.fusedPieceLocations);
    fusedPieceLocations.add(connectorIndex);
    this.setState({ fusedPieceLocations });
  };

  onSplitAnswerPieces = (connectorIndex: number) => {
    const fusedPieceLocations = new Set(this.state.fusedPieceLocations);
    fusedPieceLocations.delete(connectorIndex);
    this.setState({ fusedPieceLocations });
  };

  onUpdateDistractor = (distractorIndex: number, text: string) => {
    const distractors = this.state.distractors.slice();
    distractors.splice(distractorIndex, 1, text);
    this.setState({ distractors });
  };

  onDeleteDistractor = (distractorIndex: number) => {
    const distractors = this.state.distractors.slice();
    distractors.splice(distractorIndex, 1);
    this.setState({ distractors });
  };

  onAddDistractor = () => {
    const distractors = this.state.distractors.slice();
    distractors.push('');
    this.setState({ distractors });
  };

  onAddCorrectAnswer = (correctAnswer: string) => {
    const otherCorrectAnswers = this.state.otherCorrectAnswers.slice();
    otherCorrectAnswers.push(correctAnswer);
    this.setState({ otherCorrectAnswers, isAddingCorrectAnswer: false });
  };

  getFusedAnswerPieces(): string[] {
    const fusedAnswerPieces = [];
    let curPiece = '';
    this.state.answerPieces.forEach((piece, index) => {
      if (this.state.fusedPieceLocations.has(index - 1)) {
        curPiece += piece;
      } else {
        if (curPiece !== '') {
          fusedAnswerPieces.push(curPiece);
        }
        curPiece = piece;
      }
    });
    if (curPiece !== '') {
      fusedAnswerPieces.push(curPiece);
    }
    return fusedAnswerPieces;
  }

  getAllAnswerPieces(): string[] {
    return this.getFusedAnswerPieces()
      .concat(this.state.distractors)
      .filter(ans => ans.trim());
  }

  isAnswerValid(extraAnswer: string) {
    return !!findPiecesSpanningText(extraAnswer, this.getAllAnswerPieces());
  }

  onDone = () => {
    const updatedConfig = {
      prompt: this.state.prompt,
      correctAnswers: [this.state.correctAnswer.trim()],
      scrambledParts: this.getAllAnswerPieces(),
    };
    this.state.otherCorrectAnswers.forEach(answer => {
      if (this.isAnswerValid(answer))
        updatedConfig.correctAnswers.push(answer.trim());
    });
    this.props.onUpdateConfig(updatedConfig);
  };

  renderGroupingHint() {
    const baseMessage = this.props.intl.formatMessage(messages.groupWordsHint);
    if (!containsChineseChars(this.state.correctAnswer)) return baseMessage;
    return (
      <React.Fragment>
        {baseMessage}
        <AsianLanguagesGroupingNote />
      </React.Fragment>
    );
  }

  renderRtlHint() {
    if (
      !containsRtlChars(this.state.correctAnswer) &&
      !containsRtlChars(this.state.prompt)
    )
      return null;
    return (
      <div className="mb3">
        <RtlLanguagesGroupingNote />
      </div>
    );
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="TranslatorEditor">
        <div className="TranslatorEditor-body">
          <FormGroup
            label={formatMessage(messages.promptLabel)}
            hint={formatMessage(messages.promptHint)}
          >
            <input
              className="TranslatorEditor-input"
              type="text"
              data-testid="TranslatorEditor-promptInput"
              placeholder={formatMessage(messages.promptPlaceholder)}
              value={this.state.prompt}
              onChange={evt => this.setState({ prompt: evt.target.value })}
            />
          </FormGroup>

          <FormGroup
            label={formatMessage(messages.correctAnswerLabel)}
            hint={formatMessage(messages.correctAnswerHint)}
          >
            <input
              className="TranslatorEditor-input"
              type="text"
              data-testid="TranslatorEditor-correctAnswer"
              placeholder={formatMessage(messages.correctAnswerPlaceholder)}
              value={this.state.correctAnswer}
              onChange={this.onChangeCorrectAnswer}
            />
          </FormGroup>

          {this.renderRtlHint()}

          <FormGroup
            isHidden={!this.state.correctAnswer}
            label={formatMessage(messages.groupWordsLabel)}
            hint={this.renderGroupingHint()}
          >
            <div className="TranslatorEditor-answerParts">
              <WordSplitter
                atoms={this.state.answerPieces}
                fusedPositions={this.state.fusedPieceLocations}
                onFuseAtoms={this.onFuseAnswerPieces}
                onSplitAtoms={this.onSplitAnswerPieces}
              />
            </div>
          </FormGroup>
          <FormGroup
            isHidden={!this.state.correctAnswer}
            label={formatMessage(messages.distractorsLabel)}
            hint={formatMessage(messages.distractorsHint)}
          >
            <div>
              {this.state.distractors.map((distractor, i) => (
                <div className="TranslatorEditor-distractor" key={i}>
                  <Distractor
                    text={distractor}
                    onDelete={() => this.onDeleteDistractor(i)}
                    onChange={text => this.onUpdateDistractor(i, text)}
                  />
                </div>
              ))}
              <button
                className="TranslatorEditor-addButton TranslatorEditor-addDistractor"
                onClick={this.onAddDistractor}
              >
                <i className="fas fa-plus" />
              </button>
            </div>
          </FormGroup>
          <FormGroup
            isHidden={!this.state.correctAnswer || !this.state.prompt}
            label={formatMessage(messages.otherCorrectAnswersLabel)}
            hint={formatMessage(messages.otherCorrectAnswersHint)}
          >
            {this.state.otherCorrectAnswers.map(answer => (
              <div
                className={classNames('TranslatorEditor-otherCorrectAnswer', {
                  'is-invalid': !this.isAnswerValid(answer),
                })}
                key={answer}
              >
                {answer}
                <button
                  className="TranslatorEditor-deleteOtherCorrectAnswer"
                  onClick={() =>
                    this.setState({
                      otherCorrectAnswers: removeArrayItem(
                        this.state.otherCorrectAnswers,
                        answer,
                      ),
                    })
                  }
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            ))}
            <button
              className="TranslatorEditor-addButton TranslatorEditor-addCorrectAnswer"
              onClick={() => this.setState({ isAddingCorrectAnswer: true })}
            >
              <i className="fas fa-plus" />
            </button>
          </FormGroup>
        </div>
        <EditExtraCorrectAnswerModal
          isOpen={this.state.isAddingCorrectAnswer}
          onCancel={() => this.setState({ isAddingCorrectAnswer: false })}
          onChange={this.onAddCorrectAnswer}
          correctAnswerText={''}
          otherCorrectAnswers={[this.state.correctAnswer.trim()].concat(
            this.state.otherCorrectAnswers,
          )}
          translateText={this.state.prompt}
          answerPieces={this.getAllAnswerPieces()}
        />
        <div className="TranslatorEditor-doneButton">
          <Button
            buttonType="success"
            disabled={!this.state.correctAnswer || !this.state.prompt}
            onClick={this.onDone}
            fullWidth
          >
            <FormattedMessage
              id="TranslatorEditor.done_button"
              defaultMessage="Done"
            />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(TranslatorEditor);
