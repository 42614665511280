/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type ClassroomActions_viewer = {
    readonly user: {
        readonly isAdmin: boolean;
        readonly classroomMemberships: ReadonlyArray<{
            readonly id: string;
            readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
            readonly classroom: {
                readonly id: string;
            };
        } | null>;
    } | null;
    readonly " $refType": "ClassroomActions_viewer";
};
export type ClassroomActions_viewer$data = ClassroomActions_viewer;
export type ClassroomActions_viewer$key = {
    readonly " $data"?: ClassroomActions_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomActions_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ClassroomActions_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAdmin",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "classroomMemberships",
          "storageKey": null,
          "args": null,
          "concreteType": "ClassroomMembership",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "level",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "classroom",
              "storageKey": null,
              "args": null,
              "concreteType": "Classroom",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'a80e10b413d99ff3ee202f2b8fef4855';
export default node;
