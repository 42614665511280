import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import classNames from 'classnames';

import { MultipleChoiceWidget_exercise } from './__generated__/MultipleChoiceWidget_exercise.graphql';
import Button from '../common/Button';
import CorrectAnswerModal from '../common/CorrectAnswerModal';
import CheckBox from '../common/CheckBox';
import './index.css';

type Props = {
  hasNext: boolean;
  exercise: MultipleChoiceWidget_exercise;
  onCorrect?: (answers: { isChosen: boolean; text: string }[]) => void;
  onMistake?: (answers: { isChosen: boolean; text: string }[]) => void;
  onDone: () => void;
};

type State = {
  selectedChoiceIndices: Set<number>;
  buttonState: 'correct' | 'incorrect' | 'default';
  isComplete: boolean;
};

const CHOICE_LABELS = ['A', 'B', 'C', 'D', 'E'];

const checkAnswer = (
  exercise: MultipleChoiceWidget_exercise,
  selectedChoiceIndices: Set<number>,
) => {
  for (let i = 0; i < exercise.content.choices.length; i++) {
    if (exercise.content.choices[i].isCorrect !== selectedChoiceIndices.has(i))
      return false;
  }
  return true;
};

class MultipleChoiceWidget extends Component<Props, State> {
  state: State = {
    selectedChoiceIndices: new Set(),
    buttonState: 'default',
    isComplete: false,
  };

  static defaultProps = {
    hasNext: false,
    allowedMistakes: 0,
  };

  getSelectedChoices(): { isChosen: boolean; text: string }[] {
    return this.props.exercise.content.choices.map((choice, index) => ({
      isChosen: this.state.selectedChoiceIndices.has(index),
      text: choice.text,
    }));
  }

  onToggleChoice = (index: number) => {
    const isToggled = !this.state.selectedChoiceIndices.has(index);
    const selectedChoiceIndices = new Set(this.state.selectedChoiceIndices);
    if (isToggled) {
      selectedChoiceIndices.add(index);
    } else {
      selectedChoiceIndices.delete(index);
    }
    this.setState({ selectedChoiceIndices });
  };

  onCheckResponse = () => {
    const { onCorrect, onMistake, exercise } = this.props;
    if (this.state.selectedChoiceIndices.size === 0) return;
    const isCorrect = checkAnswer(exercise, this.state.selectedChoiceIndices);
    if (isCorrect) {
      this.setState({ buttonState: 'correct', isComplete: true });
      if (onCorrect) onCorrect(this.getSelectedChoices());
    } else {
      this.setState({ buttonState: 'incorrect', isComplete: true });
      if (onMistake) onMistake(this.getSelectedChoices());
    }
  };

  getButtonType() {
    if (this.state.buttonState === 'default') return 'default';
    return this.state.buttonState === 'correct' ? 'success' : 'failure';
  }

  getButtonText() {
    if (this.state.buttonState === 'default') {
      return (
        <FormattedMessage
          id="MultipleChoiceWidget.check_answer"
          defaultMessage="Check answer"
        />
      );
    }
    if (this.state.buttonState === 'correct') {
      if (this.props.hasNext) {
        return (
          <FormattedMessage
            id="MultipleChoiceWidget.correct_next_exercise"
            defaultMessage=":D Correct! Next exercise →"
          />
        );
      } else {
        return (
          <FormattedMessage
            id="MultipleChoiceWidget.correct_finish"
            defaultMessage=":D Correct! Finish →"
          />
        );
      }
    }
    return (
      <FormattedMessage
        id="MultipleChoiceWidget.incorrect"
        defaultMessage=":( Incorrect"
      />
    );
  }

  renderCorrectAnswers() {
    return this.props.exercise.content.choices.map((choice, index) => (
      <div className="MultipleChoiceWidget-answer f5 lh-copy">
        <div className="mr2 b MultipleChoiceWidget-label">
          {CHOICE_LABELS[index]}
        </div>
        <div className="mr2">
          <CheckBox isChecked={choice.isCorrect} />
        </div>
        <div className="truncate">{choice.text}</div>
      </div>
    ));
  }

  render() {
    return (
      <div className="MultipleChoiceWidget">
        <div className="MultipleChoiceWidget-prompt">
          <span className="MultipleChoiceWidget-instructions">
            <FormattedMessage
              id="MultipleChoiceWidget.prompt_instructions"
              defaultMessage="Select the correct response(s):"
            />
          </span>
          {this.props.exercise.content.prompt}
        </div>
        <div className="MultipleChoiceWidget-choices">
          {this.props.exercise.content.choices.map(({ text }, index) => (
            <div
              className={classNames(
                'MultipleChoiceWidget-choice mb2 pa2 b--black-10 ba br2 lh-copy',
                { 'is-selected': this.state.selectedChoiceIndices.has(index) },
              )}
              key={index}
              onClick={() => this.onToggleChoice(index)}
            >
              <div className="mr2 f5 b MultipleChoiceWidget-label">
                {CHOICE_LABELS[index]}
              </div>
              <div className="mr2">
                <CheckBox
                  isChecked={this.state.selectedChoiceIndices.has(index)}
                  boxBorderColor={
                    this.state.selectedChoiceIndices.has(index)
                      ? '#17a2b8'
                      : '#CCC'
                  }
                />
              </div>
              <div className="f5">{text}</div>
            </div>
          ))}
        </div>
        <div className="MultipleChoiceWidget-checkResponse">
          <Button
            fullWidth
            size="large"
            buttonType={this.getButtonType()}
            disabled={
              !this.state.isComplete &&
              this.state.selectedChoiceIndices.size === 0
            }
            onClick={() =>
              this.state.isComplete
                ? this.props.onDone()
                : this.onCheckResponse()
            }
          >
            {this.getButtonText()}
          </Button>
        </div>
        <CorrectAnswerModal
          isOpen={this.state.buttonState === 'incorrect'}
          correctAnswer={this.renderCorrectAnswers()}
          onDone={this.props.onDone}
        />
      </div>
    );
  }
}

export default createFragmentContainer(MultipleChoiceWidget, {
  exercise: graphql`
    fragment MultipleChoiceWidget_exercise on MultipleChoiceExercise {
      content {
        prompt
        choices {
          text
          isCorrect
        }
      }
    }
  `,
});
