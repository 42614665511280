import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
type Props = {
  correctAnswers: ReadonlyArray<string>;
  alwaysShowAnswers: boolean;
};
type State = {
  isShowingAnswers: boolean;
};

class CorrectAnswers extends Component<Props, State> {
  state: State = {
    isShowingAnswers: false,
  };

  render() {
    const showAnswers =
      this.props.alwaysShowAnswers || this.state.isShowingAnswers;
    return (
      <div className="pt3">
        {!showAnswers && (
          // eslint-disable-next-line
          <a
            className="pointer f6"
            onClick={() =>
              this.setState({
                isShowingAnswers: true,
              })
            }
          >
            <FormattedMessage
              id="CorrectAnswers.show_answers_button"
              defaultMessage="Show {numAnswers, plural,
                one {answer}
                other {answers}
              }"
              values={{
                numAnswers: this.props.correctAnswers.length,
              }}
            />
          </a>
        )}
        {showAnswers && (
          <React.Fragment>
            <div className="f6 gray mb2">
              <FormattedMessage
                id="CorrectAnswers.answers_label"
                defaultMessage="{numAnswers, plural,
                  one {Answer}
                  other {Answers}
                }"
                values={{
                  numAnswers: this.props.correctAnswers.length,
                }}
              />
            </div>
            {this.props.correctAnswers.map(answer => (
              <div key={answer} className="f6">
                {answer}
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CorrectAnswers;
