import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { AdminUsersList_admin } from './__generated__/AdminUsersList_admin.graphql';
import Loading from '../common/Loading';
import { exists } from '../../lib/utils';
import AdminUser from './AdminUser';
import { ACCOUNT_TYPE } from './__generated__/AdminUsersListQuery.graphql';
type Props = {
  admin: AdminUsersList_admin;
  relay: RelayPaginationProp;
  upgradedOnly: boolean;
  query: string | null;
  accountType: ACCOUNT_TYPE | null;
};

const AdminUsersList = ({ admin, relay }: Props) => (
  <div className="AdminUsersList pt3 tl">
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading />}
    >
      {admin &&
      admin.recentUsers.edges &&
      admin.recentUsers.edges.length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="AdminUsersList.no_content_message"
            defaultMessage="No users found"
          />
        </div>
      )}
      {admin &&
        admin.recentUsers.edges &&
        admin.recentUsers.edges.filter(exists).map(
          edge =>
            edge.node && (
              <div
                className="AdminUsersList-user "
                key={edge.node && edge.node.id}
              >
                <AdminUser user={edge.node} />
              </div>
            ),
        )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  AdminUsersList,
  {
    admin: graphql`
      fragment AdminUsersList_admin on Admin
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "String" }
          accountType: { type: "ACCOUNT_TYPE" }
          upgradedOnly: { type: "Boolean" }
          query: { type: "String" }
        ) {
        recentUsers(
          accountType: $accountType
          upgradedOnly: $upgradedOnly
          query: $query
          first: $count
          after: $cursor
        ) @connection(key: "AdminUsersList_recentUsers") {
          edges {
            node {
              id
              ...AdminUser_user
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props => props.admin && props.admin.recentUsers,
    getVariables: (
      { accountType, query, upgradedOnly },
      { count, cursor },
    ) => ({
      count,
      cursor,
      accountType,
      query,
      upgradedOnly,
    }),
    query: graphql`
      query AdminUsersListQuery(
        $accountType: ACCOUNT_TYPE
        $query: String
        $upgradedOnly: Boolean
        $count: Int!
        $cursor: String
      ) {
        admin: admin {
          ...AdminUsersList_admin
            @arguments(
              accountType: $accountType
              query: $query
              upgradedOnly: $upgradedOnly
              count: $count
              cursor: $cursor
            )
        }
      }
    `,
  },
);
