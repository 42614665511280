import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  ChangeClassroomMembershipLevelMutation,
  ChangeClassroomMembershipLevelInput,
} from './__generated__/ChangeClassroomMembershipLevelMutation.graphql';
const mutation = graphql`
  mutation ChangeClassroomMembershipLevelMutation(
    $input: ChangeClassroomMembershipLevelInput!
  ) {
    changeClassroomMembershipLevel(input: $input) {
      classroomMembership {
        id
        level
        classroom {
          id
          numActiveMemberships
        }
      }
    }
  }
`;

const commit = (environment: any, input: ChangeClassroomMembershipLevelInput) =>
  commitMutationPromise<ChangeClassroomMembershipLevelMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default {
  commit,
};
