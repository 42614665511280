/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PageLoading_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"NavHeader_viewer">;
    readonly " $refType": "PageLoading_viewer";
};
export type PageLoading_viewer$data = PageLoading_viewer;
export type PageLoading_viewer$key = {
    readonly " $data"?: PageLoading_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PageLoading_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "PageLoading_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NavHeader_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '43bd9111f072a4cbb5680b7ec205dd8a';
export default node;
