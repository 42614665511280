import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { JoinClassroomMutation } from './__generated__/JoinClassroomMutation.graphql';
const mutation = graphql`
  mutation JoinClassroomMutation($input: JoinClassroomInput!) {
    joinClassroom(input: $input) {
      classroomMembership {
        id
        level
      }
      viewer {
        ...ClassroomViewer_viewer
        ...DashboardLayout_viewer
      }
    }
  }
`;

const commit = (environment: any, classroomId: string) =>
  commitMutationPromise<JoinClassroomMutation>(environment, {
    mutation,
    variables: {
      input: {
        classroomId,
      },
    },
  });

export default {
  commit,
};
