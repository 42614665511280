/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type ClassroomMember_classroomMembership = {
    readonly id: string;
    readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
    readonly user: {
        readonly username: string;
    };
    readonly classroom: {
        readonly id: string;
    };
    readonly " $refType": "ClassroomMember_classroomMembership";
};
export type ClassroomMember_classroomMembership$data = ClassroomMember_classroomMembership;
export type ClassroomMember_classroomMembership$key = {
    readonly " $data"?: ClassroomMember_classroomMembership$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomMember_classroomMembership">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ClassroomMember_classroomMembership",
  "type": "ClassroomMembership",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "level",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "classroom",
      "storageKey": null,
      "args": null,
      "concreteType": "Classroom",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = 'bd0902007ca2c65b5ad1003cda865603';
export default node;
