import React, { FC } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import { UpgradeSuccessPageQuery } from './__generated__/UpgradeSuccessPageQuery.graphql';
import PageLoading from '../components/common/PageLoading';
import { FormattedMessage } from 'react-intl';
import Button from '../components/common/Button';

const upgradeQuery = graphql`
  query UpgradeSuccessPageQuery {
    viewer {
      ...NavHeader_viewer
      ...PageLoading_viewer
      user {
        id
        isUpgradedTeacher
      }
    }
  }
`;

const UpgradeSuccessPage: FC = () => (
  <QueryRenderer<UpgradeSuccessPageQuery>
    environment={environment}
    variables={{}}
    query={upgradeQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props || !props.viewer) {
        return <PageLoading viewer={props?.viewer} />;
      }

      const viewer = props && props.viewer;
      if (
        props &&
        (!props.viewer.user ||
          !props.viewer.user.id ||
          !props.viewer.user.isUpgradedTeacher)
      ) {
        return <Redirect to="/" />;
      }

      return (
        <div className="UpgradeSuccess">
          <NavHeader viewer={viewer} />
          <div className="pa3 pt5 center mw8">
            <h1>
              <FormattedMessage
                id="UpgradeSuccessPage.congratulations"
                defaultMessage="Congratulations!"
              />
            </h1>
            <p>
              <FormattedMessage
                id="UpgradeSuccessPage.you_have_been_upgraded"
                defaultMessage="You've been upgraded to Teacher Plus!"
              />
            </p>
            <div className="pt5">
              <Button component={Link} to="/dashboard">
                <FormattedMessage
                  id="UpgradeSuccessPage.back_home"
                  defaultMessage="Back home"
                />
              </Button>
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default UpgradeSuccessPage;
