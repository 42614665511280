/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetResultsTab_worksheet = {
    readonly id: string;
    readonly " $refType": "WorksheetResultsTab_worksheet";
};
export type WorksheetResultsTab_worksheet$data = WorksheetResultsTab_worksheet;
export type WorksheetResultsTab_worksheet$key = {
    readonly " $data"?: WorksheetResultsTab_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetResultsTab_worksheet">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WorksheetResultsTab_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'e58a4e341ea352a164834914d94969c8';
export default node;
