import React, { Component } from 'react';
import { Redirect, withRouter, match } from 'react-router-dom';
import { Location, History } from 'history';
import * as Sentry from '@sentry/browser';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import auth from '../lib/auth';
import environment from '../environment';
import PageLoading from '../components/common/PageLoading';
import { GoogleOauthPageQuery } from './__generated__/GoogleOauthPageQuery.graphql';

const googleOauthPageQuery = graphql`
  query GoogleOauthPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        id
      }
    }
  }
`;
type Props = {
  location: Location;
  history: History;
  match: match;
};

class GoogleOauthPage extends Component<Props> {
  componentDidMount() {
    const { search } = this.props.location;
    fetch(
      `//${process.env.REACT_APP_API_HOST ||
        ''}/oauth/google/callback${search}`,
      {
        method: 'GET',
      },
    )
      .then(response => response.json())
      .then(response => {
        if (response.token) {
          auth.setToken(response.token);
          if (response.id) {
            window.heap.identify(response.id, 'ID');
            window.heap.track('login', {
              username: response.username,
              email: response.email,
              source: 'google',
            });
            Sentry.configureScope(scope => {
              scope.setUser({
                username: response.username,
                email: response.email,
                id: response.id,
              });
            });
          }
          const redirect = response.redirect || '/dashboard';
          window.location.href = `${window.location.origin}${redirect}`;
        } else {
          this.props.history.push('/sign-up', {
            error: 'Google auth failed',
          });
        }
      });
  }

  render() {
    return (
      <QueryRenderer<GoogleOauthPageQuery>
        environment={environment}
        variables={{}}
        query={googleOauthPageQuery}
        render={({ error, props }) => {
          if (error) {
            return <Redirect to="/sign-up" />;
          }

          if (props && props.viewer && props.viewer.user) {
            return <Redirect to="/dashboad" />;
          }

          return <PageLoading />;
        }}
      />
    );
  }
}

export default withRouter(GoogleOauthPage);
