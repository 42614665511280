import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import Button from '../common/Button';
import { AdminClassroomsList_admin } from './__generated__/AdminClassroomsList_admin.graphql';
import Loading from '../common/Loading';
import { exists } from '../../lib/utils';
type Props = {
  admin: AdminClassroomsList_admin;
  relay: RelayPaginationProp;
};

const AdminClassroomsList = ({ admin, relay }: Props) => (
  <div className="AdminClassroomsList pt3 tl">
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading />}
    >
      {admin &&
      admin.recentClassrooms.edges &&
      admin.recentClassrooms.edges.length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="AdminClassroomsList.no_content_message"
            defaultMessage="No study records found"
          />
        </div>
      )}
      {admin &&
        admin.recentClassrooms.edges &&
        admin.recentClassrooms.edges.filter(exists).map(
          ({ node }) =>
            node && (
              <div
                className="AdminClassroomsList-studyRecord bg-black-10 pa2 mt2 br1"
                key={node.id}
              >
                <div className="fr">
                  <Button
                    component={Link}
                    to={`/class/${node.identifier || ''}`}
                  >
                    View class
                  </Button>
                </div>

                <div>
                  <span className="gray f6 dib mr2">title:</span>
                  {node.title}
                </div>
                {node.description && (
                  <div>
                    <span className="gray f6 dib mr2">description:</span>
                    {node.description}
                  </div>
                )}
                <div>
                  <span className="gray f6 dib mr2">Created at:</span>
                  {new Date(node.createdAt * 1000).toLocaleString()}
                </div>
                {node.requiresApproval && (
                  <div>
                    <i className="fas fa-lock" />{' '}
                    <span className="gray f6">Requires approval</span>
                  </div>
                )}
              </div>
            ),
        )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  AdminClassroomsList,
  {
    admin: graphql`
      fragment AdminClassroomsList_admin on Admin
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "String" }
        ) {
        recentClassrooms(first: $count, after: $cursor)
          @connection(key: "AdminClassroomsList_recentClassrooms") {
          edges {
            node {
              id
              title
              identifier
              description
              requiresApproval
              createdAt
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props =>
      props.admin && props.admin.recentClassrooms,
    getVariables: (props, { count, cursor }) => ({
      count,
      cursor,
    }),
    query: graphql`
      query AdminClassroomsListQuery($count: Int!, $cursor: String) {
        admin: admin {
          ...AdminClassroomsList_admin
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
