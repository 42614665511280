import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Redirect } from 'react-router-dom';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import NavFooter from '../components/NavFooter';
import Home from '../components/Home/index';
import { HomePageQuery } from './__generated__/HomePageQuery.graphql';

const homePageQuery = graphql`
  query HomePageQuery {
    viewer {
      ...NavHeader_viewer
      ...NavFooter_viewer
      user {
        id
      }
    }
  }
`;

class HomePage extends Component<{}> {
  render() {
    return (
      <QueryRenderer<HomePageQuery>
        environment={environment}
        query={homePageQuery}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          }

          const viewer = props && props.viewer;
          if (viewer && viewer.user && viewer.user.id) {
            return <Redirect to="/dashboard" />;
          }

          return (
            <div className="cover bg-left bg-center-l">
              <NavHeader viewer={viewer} />
              <Home />
              <NavFooter viewer={viewer} />
            </div>
          );
        }}
      />
    );
  }
}

export default HomePage;
