import React, { Component } from 'react';
import { QueryRenderer, createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { WorksheetsTab_classroom } from './__generated__/WorksheetsTab_classroom.graphql';
import Loading from '../common/Loading';
import { WorksheetsTabQuery } from './__generated__/WorksheetsTabQuery.graphql';
import ClassroomWorksheetsInfiniteScroll from './ClassroomWorksheetsInfiniteScroll';

type WorksheetsTabProps = {
  classroom: WorksheetsTab_classroom;
  relay: RelayProp;
  canEdit: boolean;
};
const worksheetsTabQuery = graphql`
  query WorksheetsTabQuery($id: ID!) {
    node(id: $id) {
      ... on Classroom {
        id
        ...ClassroomWorksheetsInfiniteScroll_classroom
      }
    }
  }
`;

class WorksheetsTab extends Component<WorksheetsTabProps> {
  render() {
    return (
      <div className="WorksheetsTab">
        <QueryRenderer<WorksheetsTabQuery>
          environment={this.props.relay.environment}
          query={worksheetsTabQuery}
          variables={{
            id: this.props.classroom.id,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            }

            if (!props?.node) {
              return <Loading />;
            }
            return (
              <ClassroomWorksheetsInfiniteScroll
                classroom={props.node}
                canEdit={this.props.canEdit}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default createFragmentContainer(WorksheetsTab, {
  classroom: graphql`
    fragment WorksheetsTab_classroom on Classroom {
      id
    }
  `,
});
