import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { FormattedMessage } from 'react-intl';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
  // @ts-ignore
} from 'react-simple-dropdown';
import { WorksheetViewer_worksheet } from './__generated__/WorksheetViewer_worksheet.graphql';
import { WorksheetViewer_viewer } from './__generated__/WorksheetViewer_viewer.graphql';
import ExerciseSummary from '../ExerciseSummary';
import { externalStudyLink } from '../../lib/utils';
import LabelText from '../common/LabelText';
import Button from '../common/Button';
import AutofocusInput from '../common/AutofocusInput';
import Tabs, { Tab } from '../common/Tabs';
import WorksheetAnalysisTab from './WorksheetAnalysisTab';
import WorksheetResultsTab from './WorksheetResultsTab';
import WorksheetStudiersTab from './WorksheetStudiersTab';
import EmbedModal from './EmbedModal';
import TeacherBadge from '../common/TeacherBadge';
import './index.css';

interface WorksheetViewerProps extends RouteComponentProps {
  worksheet: WorksheetViewer_worksheet;
  viewer: WorksheetViewer_viewer;
  relay: RelayProp;
  history: History;
}
interface WorksheetViewerState {
  tab: tab;
  isEmbedModalOpen: boolean;
  isPasswordRevealed: boolean;
}
type tab = 'exercises' | 'analysis' | 'results' | 'studiers';

class WorksheetViewer extends Component<
  WorksheetViewerProps,
  WorksheetViewerState
> {
  state: WorksheetViewerState = {
    tab: 'exercises',
    isEmbedModalOpen: false,
    isPasswordRevealed: false,
  };
  onSwitchTab = (tab: tab) => {
    if (
      tab !== 'exercises' &&
      tab !== 'studiers' &&
      !this.props.viewer.user?.isUpgradedTeacher
    ) {
      window.heap.track('view_worksheet_tab_upgrade', {
        tab,
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
      });
      this.props.history.push('/upgrade');
      return;
    }

    this.setState({
      tab,
    });
    window.heap.track('view_worksheet_tab', {
      tab,
      identifier: this.props.worksheet.identifier,
      id: this.props.worksheet.id,
    });
  };

  renderVisibility() {
    const { isPasswordRevealed } = this.state;
    const { visibility, password, isEditableByViewer } = this.props.worksheet;
    if (visibility === 'EVERYONE') {
      return (
        <div className="mt3 f7 gray">
          <i className="fas fa-users mr2" />
          <FormattedMessage
            id="WorksheetViewer.visible_to_everyone"
            defaultMessage="Visible to everyone"
          />
        </div>
      );
    }
    if (visibility === 'LOGGED_IN') {
      return (
        <div className="mt3 f7 gray">
          <i className="fas fa-user-check mr2" />
          <FormattedMessage
            id="WorksheetViewer.visible_to_logged_in"
            defaultMessage="Visible to logged-in users"
          />
        </div>
      );
    }
    if (visibility === 'PASSWORD') {
      return (
        <>
          <div className="mt3 f7 gray">
            <i className="fas fa-key mr2" />
            <FormattedMessage
              id="WorksheetViewer.visible_with_password"
              defaultMessage="Visible with password"
            />
            {isEditableByViewer && (
              <a
                href="#revealPassword"
                className="link ml3"
                onClick={evt => {
                  evt.preventDefault();
                  this.setState({ isPasswordRevealed: !isPasswordRevealed });
                }}
              >
                {isPasswordRevealed ? (
                  <FormattedMessage
                    id="WorksheetViewer.hide_password"
                    defaultMessage="Hide password"
                  />
                ) : (
                  <FormattedMessage
                    id="WorksheetViewer.reveal_password"
                    defaultMessage="Reveal password"
                  />
                )}
              </a>
            )}
          </div>
          {isPasswordRevealed && <div className="mt3">{password}</div>}
        </>
      );
    }
  }

  render() {
    const { worksheet } = this.props;
    const canEdit = worksheet.isEditableByViewer;
    const identifier = worksheet.identifier || '';
    return (
      <div className="WorksheetViewer pa3 tl">
        <div className="pb4">
          <div className="fr">
            {!canEdit ? null : (
              <React.Fragment>
                <span className="WorksheetViewer-editButton dib dn-ns ml2">
                  <Button
                    component={Link}
                    buttonType="gray"
                    outline
                    size="small"
                    to={`/edit/${identifier}`}
                  >
                    <i className="fas fa-pen" />
                  </Button>
                </span>
                <span className="WorksheetViewer-editButton dib dn-ns ml2">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button buttonType="gray" outline size="small">
                        <i className="fas fa-ellipsis-v" />
                      </Button>
                    </DropdownTrigger>
                    <DropdownContent>
                      <div className="bg-white shadow-2 tc mt1 br2">
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() =>
                            this.setState({
                              isEmbedModalOpen: true,
                            })
                          }
                          className="bt b--black-10 f6 fw4 link db pa3 pointer"
                        >
                          <FormattedMessage
                            id="WorksheetViewer.embed_button"
                            defaultMessage="Embed"
                          />
                        </a>
                      </div>
                    </DropdownContent>
                  </Dropdown>
                </span>
              </React.Fragment>
            )}
            <div className="dn db-ns">
              <Button component={Link} size="large" to={`/study/${identifier}`}>
                <FormattedMessage id="common.study" defaultMessage="Study" />
              </Button>
              {!canEdit ? null : (
                <React.Fragment>
                  <span className="ml2">
                    <Button
                      component={Link}
                      buttonType="gray"
                      outline
                      size="large"
                      to={`/edit/${identifier}`}
                    >
                      <i className="fas fa-pen" />
                    </Button>
                  </span>
                  <span className="ml2">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button buttonType="gray" outline size="large">
                          <i className="fas fa-ellipsis-v" />
                        </Button>
                      </DropdownTrigger>
                      <DropdownContent>
                        <div className="bg-white shadow-2 tc mt1 br2">
                          {/* eslint-disable-next-line */}
                          <a
                            onClick={() =>
                              this.setState({
                                isEmbedModalOpen: true,
                              })
                            }
                            className="bt b--black-10 f6 fw4 link db pa3 pointer"
                          >
                            <FormattedMessage
                              id="WorksheetViewer.embed_button"
                              defaultMessage="Embed"
                            />
                          </a>
                        </div>
                      </DropdownContent>
                    </Dropdown>
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
          <h1 className="lh-title f4 f3-m f2-l">{worksheet.title}</h1>
          {!worksheet.description ? null : (
            <p className="lh-copy f5">{worksheet.description}</p>
          )}
          <LabelText>
            <FormattedMessage
              id="WorksheetViewer.study_link_label"
              defaultMessage="Study share link"
              description="Label for the shareable link to this sheet"
            />
          </LabelText>
          <AutofocusInput
            value={externalStudyLink(identifier)}
            className="f6 pa2 db w-100 w-40-m w-30-l border-box FeedItem-shareInput"
          />
          {this.renderVisibility()}
          <div className="db dn-ns pt3 tc">
            <Button component={Link} fullWidth to={`/study/${identifier}`}>
              <FormattedMessage id="common.study" defaultMessage="Study" />
            </Button>
          </div>
        </div>
        <div className="mb3">
          <Tabs>
            <Tab
              id="exercises-tab"
              isActive={this.state.tab === 'exercises'}
              onClick={() => this.onSwitchTab('exercises')}
            >
              <FormattedMessage
                id="WorksheetViewer.exercises_label"
                defaultMessage="Exercises"
              />
            </Tab>
            <Tab
              id="studiers-tab"
              isActive={this.state.tab === 'studiers'}
              onClick={() => this.onSwitchTab('studiers')}
            >
              <FormattedMessage
                id="WorksheetViewer.studiers_label"
                defaultMessage="Studiers"
              />
            </Tab>
            {canEdit && this.props.viewer.user?.accountType === 'TEACHER' && (
              <>
                <Tab
                  id="results-tab"
                  isActive={this.state.tab === 'results'}
                  onClick={() => this.onSwitchTab('results')}
                >
                  <span className="dn di-ns">
                    <FormattedMessage
                      id="WorksheetViewer.results_label"
                      defaultMessage="Study results"
                    />
                  </span>
                  <span className="di dn-ns">
                    <FormattedMessage
                      id="WorksheetViewer.results_label_shortened"
                      defaultMessage="Results"
                    />
                  </span>
                  <span className="ml2 WorksheetViewer-badge">
                    <TeacherBadge size="small" shrinkTextOnMobile />
                  </span>
                </Tab>
                <Tab
                  id="analysis-tab"
                  isActive={this.state.tab === 'analysis'}
                  onClick={() => this.onSwitchTab('analysis')}
                >
                  <FormattedMessage
                    id="WorksheetViewer.analysis_label"
                    defaultMessage="Analysis"
                  />
                  <span className="ml2 WorksheetViewer-badge">
                    <TeacherBadge size="small" shrinkTextOnMobile />
                  </span>
                </Tab>
              </>
            )}
          </Tabs>
        </div>
        {this.state.tab !== 'exercises' ? null : (
          <div className="WorksheetViewer-exercises">
            {worksheet.exercises.map(exercise => (
              <div className="mb3 WorksheetViewer-exercise" key={exercise.id}>
                <ExerciseSummary
                  exercise={exercise}
                  scrambleWords
                  alwaysShowAnswers={false}
                />
              </div>
            ))}
          </div>
        )}
        {this.state.tab !== 'studiers' ? null : (
          <WorksheetStudiersTab worksheet={worksheet} />
        )}
        {this.state.tab !== 'results' ? null : (
          <WorksheetResultsTab worksheet={worksheet} />
        )}
        {this.state.tab !== 'analysis' ? null : (
          <WorksheetAnalysisTab worksheet={worksheet} />
        )}

        <EmbedModal
          isOpen={this.state.isEmbedModalOpen}
          worksheetIdentifier={this.props.worksheet.identifier || ''}
          onClose={() =>
            this.setState({
              isEmbedModalOpen: false,
            })
          }
        />
      </div>
    );
  }
}

export default withRouter(
  createFragmentContainer(WorksheetViewer, {
    viewer: graphql`
      fragment WorksheetViewer_viewer on Viewer {
        user {
          isUpgradedTeacher
          accountType
        }
      }
    `,
    worksheet: graphql`
      fragment WorksheetViewer_worksheet on Worksheet {
        ...WorksheetAnalysisTab_worksheet
        ...WorksheetResultsTab_worksheet
        ...WorksheetStudiersTab_worksheet
        id
        title
        description
        identifier
        password
        isPublished
        isVisibleToViewer
        isEditableByViewer
        visibility
        creator {
          id
        }
        exercises {
          id
          ...ExerciseSummary_exercise
        }
      }
    `,
  }),
);
