import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { IntlShape } from 'react-intl';
import classNames from 'classnames';
import { MultipleChoiceExerciseSummary_exercise } from './__generated__/MultipleChoiceExerciseSummary_exercise.graphql';
import ExerciseSummaryContainer from './ExerciseSummaryContainer';
import './MultipleChoiceExerciseSummary.css';
type Props = {
  exercise: MultipleChoiceExerciseSummary_exercise;
  alwaysShowAnswers: boolean;
  scrambleWords: boolean;
  intl: IntlShape;
};
type State = {
  isShowingAnswers: boolean;
};
const CHOICE_LABELS = ['A', 'B', 'C', 'D', 'E'];
const messages = defineMessages({
  typeLabel: {
    id: 'MultipleChoiceExerciseSummary.type_label',
    defaultMessage: 'Multiple choice exercise',
  },
});

class MultipleChoiceExerciseSummary extends Component<Props, State> {
  state: State = {
    isShowingAnswers: false,
  };

  render() {
    const { exercise, alwaysShowAnswers, intl } = this.props;
    const isShowingAnswers = alwaysShowAnswers || this.state.isShowingAnswers;
    return (
      <div className="MultipleChoiceExerciseSummary">
        <ExerciseSummaryContainer
          typeLabel={intl.formatMessage(messages.typeLabel)}
        >
          <div className="mb2">{exercise.content.prompt}</div>
          {exercise.content.choices.map((choice, index) => (
            <div key={index} className="flex mb2">
              {!isShowingAnswers ? null : (
                <div
                  className={classNames(
                    'MultipleChoiceExerciseSummary-answer lh-copy f6',
                    {
                      'is-correct': choice.isCorrect,
                    },
                  )}
                >
                  {choice.isCorrect ? (
                    <i className="fa fa-check" />
                  ) : (
                    <i className="fa fa-times" />
                  )}
                </div>
              )}
              <div className="mid-gray b ph2 f6 lh-copy">
                {CHOICE_LABELS[index]}.
              </div>
              <div className="f6 gray lh-copy">{choice.text}</div>
            </div>
          ))}
          {isShowingAnswers ? null : (
            <div className="pt3">
              {/* eslint-disable-next-line */}
              <a
                className="pointer f6"
                onClick={() =>
                  this.setState({
                    isShowingAnswers: true,
                  })
                }
              >
                <FormattedMessage
                  id="MultipleChoiceExerciseSummary.show_answers_button"
                  defaultMessage="Show answer"
                />
              </a>
            </div>
          )}
        </ExerciseSummaryContainer>
      </div>
    );
  }
} // for testing raw component

export { MultipleChoiceExerciseSummary };
export default injectIntl(
  createFragmentContainer(MultipleChoiceExerciseSummary, {
    exercise: graphql`
      fragment MultipleChoiceExerciseSummary_exercise on MultipleChoiceExercise {
        content {
          prompt
          choices {
            text
            isCorrect
          }
        }
      }
    `,
  }),
);
