import React from 'react';
import { FormattedMessage } from 'react-intl';
import './AsianLanguagesGroupingNote.css';

const AsianLanguagesGroupingNote = () => (
  <div className="AsianLanguagesGroupingNote mt2 pa2 gray">
    <span className="red dib b mr1">
      <FormattedMessage
        id="AsianLanguagesGroupingNote.label"
        defaultMessage="A note on Asian languages:"
      />
    </span>
    <FormattedMessage
      id="AsianLanguagesGroupingNote.message"
      defaultMessage="Because Chinese and Japanese don’t use spaces, we automatically split every kanji/hanzi character into its own word group. You should join these back together into complete words by clicking between characters above. For example, 日本 will become “日” and “本”, but you can join them together into “日本”."
    />
  </div>
);

export default AsianLanguagesGroupingNote;
