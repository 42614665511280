import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { VocabAnswerDetails_answer } from './__generated__/VocabAnswerDetails_answer.graphql';
import './VocabAnswerDetails.css';
type Props = {
  answer: VocabAnswerDetails_answer;
};

const renderTerm = (
  term: NonNullable<VocabAnswerDetails_answer['responseTerm']>,
  isCorrect: boolean,
) => (
  <div
    className={classNames('VocabAnswerDetails-term br1', {
      'is-correct': isCorrect,
      'is-incorrect': !isCorrect,
    })}
  >
    <div
      className={classNames('VocabAnswerDetails-termInner pa2 br1 bg-white', {
        'has-image': term.image,
      })}
    >
      {!term.definition ? null : <div>{term.definition}</div>}
      {!term.image ? null : (
        <img
          className="VocabAnswerDetails-termImage br1 br--right"
          src={term.image.url}
          alt={term.word}
        />
      )}
    </div>
  </div>
);

const VocabAnswerDetails = ({ answer }: Props) => (
  <div className="VocabAnswerDetails">
    <div className="VocabAnswerDetails-prompt">
      {(answer.correctTerm || {}).word}
    </div>
    <div className="VocabAnswerDetails-responses">
      <div className="cb" />
      <div className="VocabAnswerDetails-response fl mr2 mt3">
        <div className="gray f7 mb1">
          <FormattedMessage
            id="VocabAnswerDetails.user_response"
            defaultMessage="Your response"
          />
        </div>
        {answer.responseTerm &&
          renderTerm(answer.responseTerm, answer.isCorrect || false)}
      </div>
      {answer.isCorrect ? null : (
        <div className="VocabAnswerDetails-response fl mt3">
          <div className="gray f7 mb1">
            <FormattedMessage
              id="VocabAnswerDetails.correct_answer"
              defaultMessage="Correct answer"
            />
          </div>
          {answer.correctTerm && renderTerm(answer.correctTerm, true)}
        </div>
      )}
      <div className="cb" />
    </div>
  </div>
);

export default createFragmentContainer(VocabAnswerDetails, {
  answer: graphql`
    fragment VocabAnswerDetails_answer on VocabAnswer {
      isCorrect
      correctTerm {
        word
        definition
        image {
          url(size: SMALL_SQUARE)
        }
      }
      responseTerm {
        word
        definition
        image {
          url(size: SMALL_SQUARE)
        }
      }
    }
  `,
});
