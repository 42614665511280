/* tslint:disable */
/* eslint-disable */
/* @relayHash a3f7bf7dd81864d2a41fa4f79d346cd0 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminWorksheetsListQueryVariables = {
    publishedOnly?: boolean | null;
    count: number;
    cursor?: string | null;
};
export type AdminWorksheetsListQueryResponse = {
    readonly admin: {
        readonly " $fragmentRefs": FragmentRefs<"AdminWorksheetsList_admin">;
    } | null;
};
export type AdminWorksheetsListQuery = {
    readonly response: AdminWorksheetsListQueryResponse;
    readonly variables: AdminWorksheetsListQueryVariables;
};



/*
query AdminWorksheetsListQuery(
  $publishedOnly: Boolean
  $count: Int!
  $cursor: String
) {
  admin {
    ...AdminWorksheetsList_admin_gNtPd
  }
}

fragment AdminWorksheetsList_admin_gNtPd on Admin {
  recentWorksheets(publishedOnly: $publishedOnly, first: $count, after: $cursor) {
    edges {
      node {
        id
        createdAt
        identifier
        title
        isPublished
        visibility
        creator {
          username
          id
        }
        exercises {
          __typename
          ... on VocabExercise {
            content {
              terms {
                word
                definition
                image {
                  id
                }
              }
            }
          }
          ... on WordOrderExercise {
            content {
              correctAnswers
              scrambledParts
            }
          }
          ... on TranslationExercise {
            content {
              prompt
              correctAnswers
              scrambledParts
            }
          }
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "publishedOnly",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "publishedOnly",
  "variableName": "publishedOnly"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "correctAnswers",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scrambledParts",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminWorksheetsListQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AdminWorksheetsList_admin",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminWorksheetsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "recentWorksheets",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "WorksheetConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "WorksheetEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Worksheet",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "identifier",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isPublished",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "visibility",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "creator",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exercises",
                        "storageKey": null,
                        "args": null,
                        "concreteType": null,
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "type": "VocabExercise",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "content",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VocabExerciseContent",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "terms",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VocabExerciseTerm",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "word",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "definition",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "image",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "Image",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "type": "WordOrderExercise",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "content",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "WordOrderExerciseContent",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "type": "TranslationExercise",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "content",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TranslationExerciseContent",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "prompt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "recentWorksheets",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "AdminWorksheetsList_recentWorksheets",
            "filters": [
              "publishedOnly"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdminWorksheetsListQuery",
    "id": null,
    "text": "query AdminWorksheetsListQuery(\n  $publishedOnly: Boolean\n  $count: Int!\n  $cursor: String\n) {\n  admin {\n    ...AdminWorksheetsList_admin_gNtPd\n  }\n}\n\nfragment AdminWorksheetsList_admin_gNtPd on Admin {\n  recentWorksheets(publishedOnly: $publishedOnly, first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        createdAt\n        identifier\n        title\n        isPublished\n        visibility\n        creator {\n          username\n          id\n        }\n        exercises {\n          __typename\n          ... on VocabExercise {\n            content {\n              terms {\n                word\n                definition\n                image {\n                  id\n                }\n              }\n            }\n          }\n          ... on WordOrderExercise {\n            content {\n              correctAnswers\n              scrambledParts\n            }\n          }\n          ... on TranslationExercise {\n            content {\n              prompt\n              correctAnswers\n              scrambledParts\n            }\n          }\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c7f5a167fcf05e33f7bcc494857037d3';
export default node;
