import { graphql } from 'babel-plugin-relay/macro';
import * as Sentry from '@sentry/browser';
import auth from '../lib/auth';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { EmailLoginMutation } from './__generated__/EmailLoginMutation.graphql';
const mutation = graphql`
  mutation EmailLoginMutation($input: EmailLoginInput!) {
    emailLogin(input: $input) {
      user {
        id
        email
        username
      }
      token
    }
  }
`;

const commit = (environment: any, emailOrUsername: string, password: string) =>
  commitMutationPromise<EmailLoginMutation>(environment, {
    mutation,
    variables: {
      input: {
        emailOrUsername,
        password,
      },
    },
    onCompleted: ({ emailLogin }) => {
      if (emailLogin && emailLogin.token) {
        const token = emailLogin.token;
        auth.setToken(token);
        window.heap.track('login', {
          emailOrUsername,
          source: 'email',
        });
        if (emailLogin.user) {
          const { user } = emailLogin;
          Sentry.configureScope(scope => {
            scope.setUser({
              username: user.username || undefined,
              email: user.email || undefined,
              id: user.id,
            });
          });
        }
        window.heap.identify(emailLogin.user && emailLogin.user.id, 'ID');
      }
    },
  });

export default {
  commit,
};
