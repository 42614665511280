/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassroomWorksheet_classroomWorksheet = {
    readonly id: string;
    readonly worksheet: {
        readonly " $fragmentRefs": FragmentRefs<"Worksheet_worksheet">;
    };
    readonly classroom: {
        readonly id: string;
    };
    readonly " $refType": "ClassroomWorksheet_classroomWorksheet";
};
export type ClassroomWorksheet_classroomWorksheet$data = ClassroomWorksheet_classroomWorksheet;
export type ClassroomWorksheet_classroomWorksheet$key = {
    readonly " $data"?: ClassroomWorksheet_classroomWorksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomWorksheet_classroomWorksheet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ClassroomWorksheet_classroomWorksheet",
  "type": "ClassroomWorksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "worksheet",
      "storageKey": null,
      "args": null,
      "concreteType": "Worksheet",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Worksheet_worksheet",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "classroom",
      "storageKey": null,
      "args": null,
      "concreteType": "Classroom",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = 'c9375b8f4d587bc91892c9a3b9f58667';
export default node;
