import React, { Component, HTMLProps, RefObject } from 'react';
interface Props extends HTMLProps<HTMLTextAreaElement> {}

class AutofocusTextarea extends Component<Props> {
  inputRef: RefObject<HTMLTextAreaElement> = React.createRef();

  render() {
    return (
      <textarea
        {...this.props}
        readOnly
        ref={this.inputRef}
        onClick={() => this.inputRef.current && this.inputRef.current.select()}
      />
    );
  }
}

export default AutofocusTextarea;
