import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { NavFooter_viewer } from './__generated__/NavFooter_viewer.graphql';
import FeedbackModal from './common/FeedbackModal';
import { messageType } from './common/FeedbackModal';
type Props = {
  viewer?: NavFooter_viewer | null;
};
type State = {
  showingFeedbackModalType: messageType | null;
};

class NavFooter extends Component<Props, State> {
  state: State = {
    showingFeedbackModalType: null,
  };

  render() {
    return (
      <div className="bg-blue">
        <div className="pv5">
          <span className="white-70 dib pv2 ph2  ph3-ns">
            &copy; wordsheet {new Date().getFullYear()}
          </span>
          <Link
            className="f6 fw4 hover-white no-underline white-70 dib pv2 ph2 ph3-ns pointer"
            to="/privacy"
          >
            <FormattedMessage id="NavFooter.privacy" defaultMessage="Privacy" />
          </Link>
          {/* eslint-disable-next-line */}
          <a
            className="f6 fw4 hover-white no-underline white-70 dib pv2 ph2 ph3-ns pointer"
            onClick={() =>
              this.setState({
                showingFeedbackModalType: 'feedback',
              })
            }
          >
            <FormattedMessage
              id="NavFooter.feedback"
              defaultMessage="Feedback"
              description="Button to send feedback"
            />
          </a>
          {/* eslint-disable-next-line */}
          <a
            className="f6 fw4 hover-white no-underline white-70 dib pv2 ph2 ph3-ns pointer"
            onClick={() =>
              this.setState({
                showingFeedbackModalType: 'help',
              })
            }
          >
            <FormattedMessage
              id="NavFooter.help"
              defaultMessage="Help"
              description="Button to ask for help"
            />
          </a>
          {/* eslint-disable-next-line */}
          <a
            className="f6 fw4 hover-white no-underline white-70 dib pv2 ph2 ph3-ns pointer"
            onClick={() =>
              this.setState({
                showingFeedbackModalType: 'contact',
              })
            }
          >
            <FormattedMessage
              id="NavFooter.contact"
              defaultMessage="Contact"
              description="Button to contact us"
            />
          </a>
        </div>
        <FeedbackModal
          viewer={this.props.viewer}
          isOpen={!!this.state.showingFeedbackModalType}
          messageType={this.state.showingFeedbackModalType as messageType}
          onDone={() =>
            this.setState({
              showingFeedbackModalType: null,
            })
          }
        />
      </div>
    );
  }
}

export default createFragmentContainer(NavFooter, {
  viewer: graphql`
    fragment NavFooter_viewer on Viewer {
      ...FeedbackModal_viewer
    }
  `,
});
