/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PendingMembership_classroomMembership = {
    readonly id: string;
    readonly user: {
        readonly id: string;
        readonly username: string;
        readonly isUpgradedTeacher: boolean;
    };
    readonly classroom: {
        readonly id: string;
    };
    readonly " $refType": "PendingMembership_classroomMembership";
};
export type PendingMembership_classroomMembership$data = PendingMembership_classroomMembership;
export type PendingMembership_classroomMembership$key = {
    readonly " $data"?: PendingMembership_classroomMembership$data;
    readonly " $fragmentRefs": FragmentRefs<"PendingMembership_classroomMembership">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PendingMembership_classroomMembership",
  "type": "ClassroomMembership",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isUpgradedTeacher",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "classroom",
      "storageKey": null,
      "args": null,
      "concreteType": "Classroom",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = '0ec73a670e81745f9cee3a47bab2ded5';
export default node;
