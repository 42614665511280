import React, { Component, FormEvent } from 'react';
import AutosizeInput from 'react-input-autosize';
import { containsChineseChars } from '../../lib/utils';
import './Distractor.css';

type Props = {
  text: string;
  onChange: (distractor: string) => void;
  onDelete: () => void;
};

type State = {
  text: string;
  isEditing: boolean;
  editingVal: string;
};

class Distractor extends Component<Props, State> {
  state: State = {
    isEditing: false,
    text: '',
    editingVal: '',
  };

  inputRef?: null | HTMLInputElement;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.text !== nextProps.text) {
      return {
        text: nextProps.text,
        isEditing: nextProps.text === '',
        editingVal: nextProps.text,
      };
    } else if (nextProps.text === '') {
      return { isEditing: true };
    }
    return false;
  }

  onSubmit = (evt?: FormEvent) => {
    if (evt) evt.preventDefault();
    const editingVal = this.state.editingVal.trim();
    if (editingVal === '') {
      this.props.onDelete();
    } else {
      this.setState({ isEditing: false });
      if (editingVal !== this.props.text) {
        // handle adding a prefix space for non-chinese words
        let updatedVal = editingVal.trim();
        if (!containsChineseChars(updatedVal[0])) {
          updatedVal = ' ' + updatedVal;
        }
        this.props.onChange(updatedVal);
      }
    }
  };

  componentDidMount() {
    this.focusInput();
  }

  focusInput = () => {
    if (this.state.isEditing && this.inputRef) {
      this.inputRef.focus();
    }
  };

  renderEditor() {
    return (
      <form className="Distractor-editor" onSubmit={this.onSubmit}>
        <AutosizeInput
          value={this.state.editingVal}
          className="Distractor-textInput"
          onChange={evt => this.setState({ editingVal: evt.target.value })}
          inputRef={ref => (this.inputRef = ref)}
          onBlur={() => (this.state.editingVal !== '' ? this.onSubmit() : null)}
        />
        <button
          type="submit"
          className="Distractor-button Distractor-saveChanges"
        >
          <i className="fas fa-check"></i>
        </button>
      </form>
    );
  }

  renderViewer() {
    return (
      <div
        className="Distractor-viewer"
        onClick={() => this.setState({ isEditing: true }, this.focusInput)}
      >
        <span className="Distractor-text">{this.props.text}</span>
        <button
          className="Distractor-button Distractor-delete"
          onClick={this.props.onDelete}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="Distractor">
        {this.state.isEditing ? this.renderEditor() : this.renderViewer()}
      </div>
    );
  }
}

export default Distractor;
