import { graphql } from 'babel-plugin-relay/macro';
import {
  WordOrderExerciseContentInput,
  UpdateWordOrderExerciseMutation,
} from './__generated__/UpdateWordOrderExerciseMutation.graphql';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { updateExerciseUpdater } from './helpers/exerciseMutationHelpers';
const mutation = graphql`
  mutation UpdateWordOrderExerciseMutation(
    $input: UpdateWordOrderExerciseInput!
  ) {
    updateWordOrderExercise(input: $input) {
      exercise {
        id
        content {
          correctAnswers
          scrambledParts
        }
      }
    }
  }
`;
type fields = {
  content?: WordOrderExerciseContentInput | null;
  position?: number | null;
};

const commit = (
  environment: any,
  id: string,
  worksheetId: string,
  fields: fields,
) =>
  commitMutationPromise<UpdateWordOrderExerciseMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
        ...fields,
      },
    },
    optimisticUpdater: updateExerciseUpdater(
      'WordOrderExercise',
      id,
      worksheetId,
      fields,
    ),
    updater: updateExerciseUpdater(
      'WordOrderExercise',
      id,
      worksheetId,
      fields,
    ),
  });

export default {
  commit,
};
