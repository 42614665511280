/* tslint:disable */
/* eslint-disable */
/* @relayHash d8c24079bc76544b880b3e0ee70cf21f */

import { ConcreteRequest } from "relay-runtime";
export type CreateTranslationExerciseInput = {
    worksheetId: string;
    content: TranslationExerciseContentInput;
    position: number;
    clientMutationId?: string | null;
};
export type TranslationExerciseContentInput = {
    prompt: string;
    correctAnswers: Array<string>;
    scrambledParts: Array<string>;
};
export type CreateTranslationExerciseMutationVariables = {
    input: CreateTranslationExerciseInput;
};
export type CreateTranslationExerciseMutationResponse = {
    readonly createTranslationExercise: {
        readonly exercise: {
            readonly id: string;
            readonly content: {
                readonly prompt: string;
                readonly correctAnswers: ReadonlyArray<string>;
                readonly scrambledParts: ReadonlyArray<string>;
            };
            readonly worksheet: {
                readonly id: string;
            };
        } | null;
    } | null;
};
export type CreateTranslationExerciseMutation = {
    readonly response: CreateTranslationExerciseMutationResponse;
    readonly variables: CreateTranslationExerciseMutationVariables;
};



/*
mutation CreateTranslationExerciseMutation(
  $input: CreateTranslationExerciseInput!
) {
  createTranslationExercise(input: $input) {
    exercise {
      id
      content {
        prompt
        correctAnswers
        scrambledParts
      }
      worksheet {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTranslationExerciseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTranslationExercise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTranslationExercisePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "exercise",
        "storageKey": null,
        "args": null,
        "concreteType": "TranslationExercise",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "content",
            "storageKey": null,
            "args": null,
            "concreteType": "TranslationExerciseContent",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "prompt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "correctAnswers",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "scrambledParts",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "worksheet",
            "storageKey": null,
            "args": null,
            "concreteType": "Worksheet",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTranslationExerciseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTranslationExerciseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateTranslationExerciseMutation",
    "id": null,
    "text": "mutation CreateTranslationExerciseMutation(\n  $input: CreateTranslationExerciseInput!\n) {\n  createTranslationExercise(input: $input) {\n    exercise {\n      id\n      content {\n        prompt\n        correctAnswers\n        scrambledParts\n      }\n      worksheet {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5fc7d0d6b3710d82529bb89ed08505c1';
export default node;
