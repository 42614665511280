/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetStudier_worksheet = {
    readonly id: string;
    readonly title: string | null;
    readonly description: string | null;
    readonly identifier: string | null;
    readonly isPublished: boolean;
    readonly exercises: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly content?: {
            readonly terms: ReadonlyArray<{
                readonly word: string;
                readonly image: {
                    readonly url: string;
                } | null;
            }>;
        };
        readonly " $fragmentRefs": FragmentRefs<"TranslatorWidget_exercise" | "WordOrderWidget_exercise" | "MultipleChoiceWidget_exercise" | "VocabWidget_exercise">;
    }>;
    readonly " $refType": "WorksheetStudier_worksheet";
};
export type WorksheetStudier_worksheet$data = WorksheetStudier_worksheet;
export type WorksheetStudier_worksheet$key = {
    readonly " $data"?: WorksheetStudier_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetStudier_worksheet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "WorksheetStudier_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isPublished",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercises",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "type": "TranslationExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "TranslatorWidget_exercise",
              "args": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "WordOrderExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "WordOrderWidget_exercise",
              "args": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "MultipleChoiceExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "MultipleChoiceWidget_exercise",
              "args": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "VocabExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "VocabExerciseContent",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "terms",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "VocabExerciseTerm",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "word",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "image",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Image",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "url",
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "size",
                              "value": "LARGE_SQUARE"
                            }
                          ],
                          "storageKey": "url(size:\"LARGE_SQUARE\")"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "VocabWidget_exercise",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '23c863d9562be10994b3539a5467e6f5';
export default node;
