import React, { Component } from 'react';
import { QueryRenderer, createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { WorksheetAnalysisTab_worksheet } from './__generated__/WorksheetAnalysisTab_worksheet.graphql';
import Loading from '../common/Loading';
import WordOrderExerciseAnalysis from './WordOrderExerciseAnalysis';
import TranslationExerciseAnalysis from './TranslationExerciseAnalysis';
import VocabExerciseAnalysis from './VocabExerciseAnalysis';
import MultipleChoiceExerciseAnalysis from './MultipleChoiceExerciseAnalysis';
import './WorksheetAnalysisTab.css';
import {
  WorksheetAnalysisTabQuery,
  WorksheetAnalysisTabQueryResponse,
} from './__generated__/WorksheetAnalysisTabQuery.graphql';
import { exists } from '../../lib/utils';
import ClassroomsSelect from './ClassroomsSelect';
import FormGroup from '../common/FormGroup';

interface WorksheetAnalysisTabProps {
  worksheet: WorksheetAnalysisTab_worksheet;
  relay: RelayProp;
}
interface WorksheetAnalysisTabState {
  filterClassroomId: string | null;
}

const worksheetAnalysisTabQuery = graphql`
  query WorksheetAnalysisTabQuery($id: ID!, $classroomId: ID) {
    node(id: $id) {
      ... on Worksheet {
        id
        ...ClassroomsSelect_worksheet
        studyRecords(first: 500, classroomId: $classroomId) {
          edges {
            node {
              answers {
                isCorrect
                exercise {
                  id
                }
                ... on WordOrderAnswer {
                  response
                }
                ... on TranslationAnswer {
                  response
                }
                ... on VocabAnswer {
                  correctTerm {
                    word
                    definition
                    image {
                      id
                    }
                  }
                }
                ... on MultipleChoiceAnswer {
                  choices {
                    text
                    isChosen
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type worksheetAnswers = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<WorksheetAnalysisTabQueryResponse['node']>['studyRecords']
      >['edges']
    >[0]
  >['node']
>['answers'];

const getAnswersForExercise = (
  exerciseId: string,
  worksheet: NonNullable<WorksheetAnalysisTabQueryResponse['node']>,
) => {
  let answers: worksheetAnswers = [];
  ((worksheet.studyRecords && worksheet.studyRecords.edges) || [])
    .filter(exists)
    .forEach(({ node }) => {
      if (node) answers = answers.concat(node.answers);
    });

  return answers.filter(
    answer => answer.exercise && answer.exercise.id === exerciseId,
  );
};

class WorksheetAnalysisTab extends Component<
  WorksheetAnalysisTabProps,
  WorksheetAnalysisTabState
> {
  state: WorksheetAnalysisTabState = {
    filterClassroomId: null,
  };

  renderExerciseLabel(typename: string) {
    if (typename === 'WordOrderExercise') {
      return (
        <FormattedMessage
          id="WorksheetAnalysisTab.word_order_exercise_label"
          defaultMessage="Word order exercise"
        />
      );
    }

    if (typename === 'TranslationExercise') {
      return (
        <FormattedMessage
          id="WorksheetAnalysisTab.translation_exercise_label"
          defaultMessage="Translation exercise"
        />
      );
    }

    if (typename === 'VocabExercise') {
      return (
        <FormattedMessage
          id="WorksheetAnalysisTab.vocab_exercise_label"
          defaultMessage="Vocab exercise"
        />
      );
    }
  }

  render() {
    const { filterClassroomId } = this.state;
    return (
      <div className="WorksheetAnalysisTab">
        <QueryRenderer<WorksheetAnalysisTabQuery>
          environment={this.props.relay.environment}
          query={worksheetAnalysisTabQuery}
          variables={{
            id: this.props.worksheet.id,
            classroomId: filterClassroomId,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            }

            const node = props && props.node;
            if (!props || !node) return <Loading />;
            return (
              <div>
                <FormGroup
                  label={
                    <FormattedMessage
                      id="WorksheetAnalysisTab.analysis_by_class"
                      defaultMessage="Analysis by class"
                    />
                  }
                >
                  <div className="w-50-ns">
                    <ClassroomsSelect
                      value={filterClassroomId}
                      onChange={filterClassroomId =>
                        this.setState({ filterClassroomId })
                      }
                      worksheet={node}
                    />
                  </div>
                </FormGroup>
                {this.props.worksheet.exercises.map((exercise, i) => (
                  <div
                    className="WorksheetAnalysisTab-exercise mt3 pa3"
                    key={exercise.id}
                  >
                    <div className="f6 gray mb3">
                      {this.renderExerciseLabel(exercise.__typename)}
                    </div>
                    {exercise.__typename !== 'WordOrderExercise' ? null : (
                      <WordOrderExerciseAnalysis
                        exercise={exercise}
                        answers={
                          getAnswersForExercise(exercise.id, node) as any
                        }
                      />
                    )}
                    {exercise.__typename !== 'TranslationExercise' ? null : (
                      <TranslationExerciseAnalysis
                        exercise={exercise}
                        answers={
                          getAnswersForExercise(exercise.id, node) as any
                        }
                      />
                    )}
                    {exercise.__typename !== 'VocabExercise' ? null : (
                      <VocabExerciseAnalysis
                        exercise={exercise}
                        answers={
                          getAnswersForExercise(exercise.id, node) as any
                        }
                      />
                    )}
                    {exercise.__typename !== 'MultipleChoiceExercise' ? null : (
                      <MultipleChoiceExerciseAnalysis
                        exercise={exercise}
                        answers={
                          getAnswersForExercise(exercise.id, node) as any
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default createFragmentContainer(WorksheetAnalysisTab, {
  worksheet: graphql`
    fragment WorksheetAnalysisTab_worksheet on Worksheet {
      id
      exercises {
        id
        __typename
        ... on TranslationExercise {
          ...TranslationExerciseAnalysis_exercise
        }
        ... on WordOrderExercise {
          ...WordOrderExerciseAnalysis_exercise
        }
        ... on VocabExercise {
          ...VocabExerciseAnalysis_exercise
        }
        ... on MultipleChoiceExercise {
          ...MultipleChoiceExerciseAnalysis_exercise
        }
      }
    }
  `,
});
