import React, { Component } from 'react';
import Username from '../common/Username';
import { Link } from 'react-router-dom';
import Button from '../common/Button';
import * as Sentry from '@sentry/browser';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { PendingMembership_classroomMembership } from './__generated__/PendingMembership_classroomMembership.graphql';
import ChangeClassroomMembershipLevelMutation from '../../mutations/ChangeClassroomMembershipLevelMutation';
import DeleteClassroomMembershipMutation from '../../mutations/DeleteClassroomMembershipMutation';

interface PendingMembershipProps {
  classroomMembership: PendingMembership_classroomMembership;
  relay: RelayProp;
}

interface PendingMembershipState {
  isApproving: boolean;
  isRejecting: boolean;
}

class PendingMembership extends Component<
  PendingMembershipProps,
  PendingMembershipState
> {
  state: PendingMembershipState = {
    isApproving: false,
    isRejecting: false,
  };

  onApprove = async () => {
    if (this.state.isApproving || this.state.isRejecting) return;
    this.setState({ isApproving: true });
    try {
      await ChangeClassroomMembershipLevelMutation.commit(
        this.props.relay.environment,
        {
          level: 'MEMBER',
          classroomMembershipId: this.props.classroomMembership.id,
        },
      );
      // don't set state here, since component will already be unmounted at this point
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error approving member', err);
      this.setState({ isApproving: false });
    }
  };

  onReject = async () => {
    if (this.state.isApproving || this.state.isRejecting) return;
    this.setState({ isRejecting: true });
    try {
      await DeleteClassroomMembershipMutation.commit(
        this.props.relay.environment,
        this.props.classroomMembership.id,
        this.props.classroomMembership.classroom.id,
      );
      // don't set state here, since component will already be unmounted at this point
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error rejecting member', err);
      this.setState({ isRejecting: false });
    }
  };

  render() {
    const { classroomMembership } = this.props;
    const { isApproving, isRejecting } = this.state;
    return (
      <div
        className="bg-white flex items-stretch pa1 br1"
        data-testid="PendingMembership"
        key={classroomMembership.id}
      >
        <Link
          to={`/profile/${classroomMembership.user.username}`}
          target="_blank"
          className="ph3 link flex items-center hover-bg-black-10 bg-animate br1"
        >
          <Username
            username={classroomMembership.user.username}
            isTeacher={classroomMembership.user.isUpgradedTeacher}
          />
        </Link>
        <span className="dib">
          <span className="ml1">
            <Button
              size="small"
              buttonType="success"
              onClick={this.onApprove}
              loading={isRejecting}
              data-testid="PendingMembership-acceptButton"
              disabled={isApproving || isRejecting}
            >
              <span className="mh2">
                <FormattedMessage
                  id="PendingMembership.accept"
                  defaultMessage="Accept"
                />
              </span>
            </Button>
          </span>
          <span className="ml1">
            <Button
              size="small"
              buttonType="failure"
              onClick={this.onReject}
              data-testid="PendingMembership-rejectButton"
              loading={isRejecting}
              disabled={isApproving || isRejecting}
            >
              <span className="mh2">
                <FormattedMessage
                  id="PendingMembership.reject"
                  defaultMessage="Reject"
                />
              </span>
            </Button>
          </span>
        </span>
      </div>
    );
  }
}

export default createFragmentContainer(PendingMembership, {
  classroomMembership: graphql`
    fragment PendingMembership_classroomMembership on ClassroomMembership {
      id
      user {
        id
        username
        isUpgradedTeacher
      }
      classroom {
        id
      }
    }
  `,
});
