import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './DemoExerciseButton.css';
type Props = {
  name: string;
  numExercises: number;
  identifier: string;
  flag: 'china' | 'france' | 'spain' | 'unitedKingdom';
};

const DemoExerciseButton = ({
  name,
  numExercises,
  identifier,
  flag,
}: Props) => (
  <Link
    className="DemoExerciseButton no-underline pa2 pv3 pv5-l ba db black-90 border-box shadow-hover relative"
    to={`/demo/${identifier}`}
  >
    <div className={classNames('DemoExerciseButton-flag', flag)} />
    <h4 className="lh-title f3">{name}</h4>
    <p className="gray">
      <FormattedMessage
        id="DemoExerciseButton.num_exercises"
        defaultMessage="{numExercises, number} {numExercises, plural,
          one {exercise}
          other {exercises}
        }"
        values={{
          numExercises,
        }}
      />
    </p>
  </Link>
);

export default DemoExerciseButton;
