import React, { Component, FormEvent } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import * as Sentry from '@sentry/browser';
import { RelayProp, createFragmentContainer } from 'react-relay';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Modal from '../common/Modal';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import Textarea from '../common/Textarea';
import Toggle from 'react-toggle';
import Button from '../common/Button';
import CreateClassroomMutation from '../../mutations/CreateClassroomMutation';
import ErrorBox from '../common/ErrorBox';
import { EditClassroomModal_classroom } from './__generated__/EditClassroomModal_classroom.graphql';
import UpdateClassroomMutation from '../../mutations/UpdateClassroomMutation';

interface EditClassroomModalState {
  title: string;
  description: string;
  requiresApproval: boolean;
  isSaving: boolean;
  error: null | string;
  previousClassroom?: EditClassroomModal_classroom;
}

interface EditClassroomModalProps extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
  relay: RelayProp;
  classroom?: EditClassroomModal_classroom;
  history: History;
}

class EditClassroomModal extends Component<
  EditClassroomModalProps,
  EditClassroomModalState
> {
  state: EditClassroomModalState = {
    title: '',
    description: '',
    requiresApproval: false,
    isSaving: false,
    error: null,
  };

  static getDerivedStateFromProps(
    props: EditClassroomModalProps,
    state: EditClassroomModalState,
  ) {
    if (props.classroom) {
      const derivedState: Partial<EditClassroomModalState> = {
        previousClassroom: props.classroom,
      };
      if (props.classroom.title !== state.previousClassroom?.title) {
        derivedState.title = props.classroom.title;
      }
      if (
        props.classroom.description !== state.previousClassroom?.description
      ) {
        derivedState.description = props.classroom.description || '';
      }
      if (
        props.classroom.requiresApproval !==
        state.previousClassroom?.requiresApproval
      ) {
        derivedState.requiresApproval = props.classroom.requiresApproval;
      }
      return derivedState;
    }
    return null;
  }

  onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    const classroomId = this.props.classroom?.id;
    const { title, description, requiresApproval } = this.state;
    if (this.state.isSaving) return;
    this.setState({ isSaving: true, error: null });
    try {
      if (classroomId) {
        await UpdateClassroomMutation.commit(this.props.relay.environment, {
          id: classroomId,
          title,
          description,
          requiresApproval: requiresApproval,
        });
      } else {
        const res = await CreateClassroomMutation.commit(
          this.props.relay.environment,
          {
            title,
            description,
            requiresApproval: requiresApproval,
          },
        );
        if (res.createClassroom?.classroom?.identifier) {
          this.props.history.push(
            `/class/${res.createClassroom.classroom.identifier}`,
          );
        }
      }
      this.setState({ isSaving: false });
      this.props.onClose();
    } catch (err) {
      const verb = classroomId ? 'edit' : 'create';
      Sentry.captureException(err);
      // window.Rollbar.error(`failed to ${verb} classroom`, err);
      this.setState({ error: `Unable to ${verb} class`, isSaving: false });
    }
  };

  render() {
    const {
      title,
      description,
      requiresApproval,
      isSaving,
      error,
    } = this.state;
    const { isOpen, onClose, classroom } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          classroom?.id ? (
            <FormattedMessage
              id="EditClassroomModal.edit_class_header"
              defaultMessage="Edit class"
            />
          ) : (
            <FormattedMessage
              id="EditClassroomModal.new_class_header"
              defaultMessage="New class"
            />
          )
        }
      >
        <form onSubmit={this.onSubmit} className="EditClassroomModal">
          <FormGroup
            label={
              <FormattedMessage
                id="EditClassroomModal.title_label"
                defaultMessage="Title"
              />
            }
          >
            <Input
              value={title}
              id="classroomTitle"
              onChange={evt => this.setState({ title: evt.target.value })}
            />
          </FormGroup>
          <FormGroup
            label={
              <FormattedMessage
                id="EditClassroomModal.description_label"
                defaultMessage="Description"
              />
            }
          >
            <Textarea
              value={description}
              id="classroomDescription"
              onChange={evt => this.setState({ description: evt.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <div className="flex items-center" id="requiresApprovalToggle">
              <Toggle
                checked={requiresApproval}
                onChange={evt =>
                  this.setState({
                    requiresApproval: evt.target.checked,
                  })
                }
              />
              <span
                className="ml2 pointer dark-gray"
                onClick={() =>
                  this.setState({
                    requiresApproval: !requiresApproval,
                  })
                }
              >
                <FormattedMessage
                  id="EditClassroomModal.new_members_require_approval"
                  defaultMessage="New members require approval"
                />
              </span>
            </div>
          </FormGroup>
          {error && <ErrorBox>{error}</ErrorBox>}
          <Button
            buttonType="success"
            type="submit"
            id="submitEditClassroom"
            fullWidth
            loading={isSaving}
            disabled={!title || isSaving}
          >
            {classroom?.id ? (
              <FormattedMessage
                id="EditClassroomModal.edit_class"
                defaultMessage="Edit class"
              />
            ) : (
              <FormattedMessage
                id="EditClassroomModal.create_class"
                defaultMessage="Create class"
              />
            )}
          </Button>
        </form>
      </Modal>
    );
  }
}

export default withRouter(
  createFragmentContainer(EditClassroomModal, {
    classroom: graphql`
      fragment EditClassroomModal_classroom on Classroom {
        id
        title
        description
        requiresApproval
      }
    `,
  }),
);
