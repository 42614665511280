import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';

import TranslatorWordPositioner from '../common/TranslatorWordPositioner';
import WordPart from '../common/WordPart';
import Modal from '../common/Modal';
import Button from '../common/Button';
import { findPiecesSpanningText } from '../../lib/atomHelpers';
import './EditExtraCorrectAnswerModal.css';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  translateText: string;
  correctAnswerText: string;
  otherCorrectAnswers: string[];
  answerPieces: string[];
  onChange: (answer: string) => void;
  intl: IntlShape;
};

type State = {
  correctAnswerText: null | string;
  chosenItems: number[];
  editingVal: string;
};

const messages = defineMessages({
  addCorrectAnswerHeader: {
    id: 'TranslatorEditor.EditExtraCorrectAnswerModal.addCorrectAnswerHeader',
    defaultMessage: 'Add alternate correct answer',
  },
});

class EditExtraCorrectAnswerModal extends Component<Props, State> {
  state: State = {
    correctAnswerText: null,
    editingVal: '',
    chosenItems: [],
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.correctAnswerText !== nextProps.correctAnswerText) {
      return {
        correctAnswerText: nextProps.correctAnswerText,
        chosenItems:
          findPiecesSpanningText(
            nextProps.correctAnswerText,
            nextProps.answerPieces,
          ) || [],
      };
    }
    return null;
  }

  onDone = () => {
    if (this.isValid()) {
      this.setState({ correctAnswerText: null });
      this.props.onChange(this.getChosenText());
    }
  };

  onCancel = () => {
    this.setState({ correctAnswerText: null });
    this.props.onCancel();
  };

  isValid = () => {
    if (this.state.chosenItems.length === 0) return false;
    return !this.isAnswerAlreadyChosen();
  };

  getChosenText() {
    return this.state.chosenItems
      .map(index => this.props.answerPieces[index])
      .join('')
      .trim();
  }

  isAnswerAlreadyChosen() {
    if (this.state.chosenItems.length === 0) return false;
    return this.props.otherCorrectAnswers.indexOf(this.getChosenText()) !== -1;
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="EditExtraCorrectAnswerModal">
        <Modal
          header={formatMessage(messages.addCorrectAnswerHeader)}
          isOpen={this.props.isOpen}
          onClose={this.props.onCancel}
          footer={
            <div className="EditExtraCorrectAnswerModal-footer">
              <div className="EditExtraCorrectAnswerModal-footerButton">
                <Button
                  buttonType="success"
                  disabled={!this.isValid()}
                  onClick={this.onDone}
                >
                  <FormattedMessage
                    id="TranslatorEditor.EditExtraCorrectAnswerModal.done_button"
                    defaultMessage="Done"
                  />
                </Button>
              </div>
              <div className="EditExtraCorrectAnswerModal-footerButton">
                <Button buttonType="default" onClick={this.onCancel}>
                  <FormattedMessage
                    id="TranslatorEditor.EditExtraCorrectAnswerModal.cancel_button"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <div className="EditExtraCorrectAnswerModal-translateText">
            {this.props.translateText}
          </div>
          <TranslatorWordPositioner
            chosenItems={this.state.chosenItems}
            onChangeChosenItems={chosenItems => this.setState({ chosenItems })}
            chosenItemsContainer={minHeight => (
              <div
                className="EditExtraCorrectAnswerModal-chosenItems"
                style={{ height: minHeight }}
              />
            )}
            wordBankLabel={
              <div className="EditExtraCorrectAnswerModal-wordBankLabel">
                <FormattedMessage
                  id="TranslatorEditor.EditExtraCorrectAnswerModal.word_bank_label"
                  defaultMessage="Word bank"
                />
              </div>
            }
            wordBankContainer={minHeight => (
              <div
                className="EditExtraCorrectAnswerModal-wordBank"
                style={{ height: minHeight }}
              />
            )}
            words={this.props.answerPieces.map(text => (
              <WordPart text={text} />
            ))}
          />
          <p className="EditExtraCorrectAnswerModal-hint">
            <FormattedMessage
              id="TranslatorEditor.EditExtraCorrectAnswerModal.translation_hint"
              defaultMessage="Tap the words above to assemble an alterative correct answer to this exercise"
            />
          </p>
          <p
            className={classNames('EditExtraCorrectAnswerModal-failureReason', {
              'is-hidden': !this.isAnswerAlreadyChosen(),
            })}
          >
            <FormattedMessage
              id="TranslatorEditor.EditExtraCorrectAnswerModal.already_added_error"
              defaultMessage="You already added this answer."
            />
          </p>
        </Modal>
      </div>
    );
  }
}

export default injectIntl(EditExtraCorrectAnswerModal);
