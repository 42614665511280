import React, { Component } from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import './Modal.css';

type Props = {
  children?: ReactNode;
  footer?: ReactNode;
  header?: string | ReactNode;
  isOpen: boolean;
  closeButton: boolean;
  closeOnTapBackdrop: boolean;
  width: 'default' | 'narrow';
  onClose?: () => void;
};

class Modal extends Component<Props> {
  static defaultProps = {
    closeButton: false,
    closeOnTapBackdrop: true,
    width: 'default',
  };

  onClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    return (
      <div className={classNames('Modal', `is-${this.props.width}-width`)}>
        <CSSTransitionGroup
          transitionName="toggle"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {!this.props.isOpen
            ? null
            : [
                <div
                  className="Modal-backdrop"
                  key="backdrop"
                  onClick={() =>
                    this.props.closeOnTapBackdrop && this.onClose()
                  }
                />,
                <div className="Modal-container" key="container">
                  <div className="Modal-main">
                    {!this.props.header ? null : (
                      <h3 className="Modal-header">
                        {this.props.header}
                        {!this.props.closeButton ? null : (
                          <button
                            onClick={this.onClose}
                            className="Modal-headerCloseButton"
                          >
                            <i className="fas fa-times" />
                          </button>
                        )}
                      </h3>
                    )}
                    <div className="Modal-body">{this.props.children}</div>
                    {!this.props.footer ? null : (
                      <div className="Modal-footer">{this.props.footer}</div>
                    )}
                  </div>
                </div>,
              ]}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default Modal;
