import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { WordOrderExerciseAnalysis_exercise } from './__generated__/WordOrderExerciseAnalysis_exercise.graphql';
import WordPieceSharedAnalysis from './WordPieceSharedAnalysis';
type answer = {
  isCorrect: boolean;
  response: string[];
};
type Props = {
  exercise: WordOrderExerciseAnalysis_exercise;
  answers: answer[];
};

const WordOrderExerciseAnalysis = ({ exercise, answers }: Props) => {
  return (
    <div className="WordOrderExerciseAnalysis">
      <WordPieceSharedAnalysis
        answers={answers}
        scrambledParts={exercise.content.scrambledParts}
        correctAnswers={exercise.content.correctAnswers}
      />
    </div>
  );
};

export default createFragmentContainer(WordOrderExerciseAnalysis, {
  exercise: graphql`
    fragment WordOrderExerciseAnalysis_exercise on WordOrderExercise {
      content {
        correctAnswers
        scrambledParts
      }
    }
  `,
});
