import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CreateStudyRecordMutation } from './__generated__/CreateStudyRecordMutation.graphql';

const mutation = graphql`
  mutation CreateStudyRecordMutation($input: CreateStudyRecordInput!) {
    createStudyRecord(input: $input) {
      studyRecord {
        id
      }
    }
  }
`;

const commit = (environment: any, worksheetId: string) =>
  commitMutationPromise<CreateStudyRecordMutation>(environment, {
    mutation,
    variables: {
      input: {
        worksheetId,
      },
    },
  });

export default {
  commit,
};
