/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MembersTab_classroom = {
    readonly id: string;
    readonly " $refType": "MembersTab_classroom";
};
export type MembersTab_classroom$data = MembersTab_classroom;
export type MembersTab_classroom$key = {
    readonly " $data"?: MembersTab_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"MembersTab_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "MembersTab_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'e371d95ff1f763c3484996b642ae4116';
export default node;
