import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import ReactTooltip from 'react-tooltip';

import FormGroup from '../common/FormGroup';
import Button from '../common/Button';
import Input from '../common/Input';
import './index.css';

type multipleChoiceConfigChoice = {
  readonly isCorrect: boolean;
  readonly text: string;
};

export type multipleChoiceConfig = {
  readonly prompt: string;
  readonly choices: ReadonlyArray<multipleChoiceConfigChoice>;
};

type Props = {
  defaultConfig?: null | multipleChoiceConfig;
  onUpdateConfig: (config: multipleChoiceConfig) => void;
  intl: IntlShape;
};

type State = {
  defaultConfig: null | multipleChoiceConfig;
  prompt: string;
  choices: multipleChoiceConfigChoice[];
};

const CHOICE_LABELS = ['A', 'B', 'C', 'D', 'E'];

const messages = defineMessages({
  promptLabel: {
    id: 'MultipleChoiceEditor.prompt_label',
    defaultMessage: 'Prompt',
  },
  choicesLabel: {
    id: 'MultipleChoiceEditor.choices_label',
    defaultMessage: 'Answer choices',
  },
  tooFewChoicesError: {
    id: 'MultipleChoiceEditor.too_few_choices_error',
    defaultMessage: 'You need to add at least 2 answer choices',
  },
  correctAnswerNeededError: {
    id: 'MultipleChoiceEditor.correct_answer_needed_error',
    defaultMessage: 'You must select at least 1 correct answer',
  },
});

const isChoiceValid = (choice: multipleChoiceConfigChoice) =>
  !!choice.text.trim();

class MultipleChoiceEditor extends Component<Props, State> {
  state: State = {
    defaultConfig: null,
    prompt: '',
    choices: [
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
    ],
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const defaultConfig = nextProps.defaultConfig;
    if (prevState.defaultConfig !== defaultConfig) {
      if (!defaultConfig) {
        return {
          defaultConfig: null,
          prompt: '',
          choices: [
            { text: '', isCorrect: false },
            { text: '', isCorrect: false },
            { text: '', isCorrect: false },
          ],
        };
      }
      return {
        defaultConfig,
        prompt: defaultConfig.prompt,
        choices: defaultConfig.choices,
      };
    }
    return null;
  }

  getChoiceCounts() {
    let numCorrectChoices = 0;
    let numTotalChoices = 0;
    this.state.choices.forEach(choice => {
      if (isChoiceValid(choice)) {
        numTotalChoices += 1;
        if (choice.isCorrect) numCorrectChoices += 1;
      }
    });
    return { numCorrectChoices, numTotalChoices };
  }

  hasValidChoices() {
    const { numCorrectChoices, numTotalChoices } = this.getChoiceCounts();
    return numCorrectChoices > 0 && numTotalChoices >= 2;
  }

  renderInvalidReason() {
    const { numTotalChoices } = this.getChoiceCounts();
    const { formatMessage } = this.props.intl;
    if (numTotalChoices < 2) return formatMessage(messages.tooFewChoicesError);
    return formatMessage(messages.correctAnswerNeededError);
  }

  onDone = () => {
    if (!this.hasValidChoices()) return;
    const updatedConfig = {
      prompt: this.state.prompt,
      choices: this.state.choices.filter(isChoiceValid),
    };
    this.props.onUpdateConfig(updatedConfig);
  };

  onAddChoice = () => {
    const choices = this.state.choices.slice();
    choices.push({ text: '', isCorrect: false });
    this.setState({ choices });
  };

  onDeleteChoice = (index: number) => {
    const choices = this.state.choices.slice();
    choices.splice(index, 1);
    this.setState({ choices });
  };

  onUpdateChoice = (
    index: number,
    val: Partial<multipleChoiceConfigChoice>,
  ) => {
    const newChoice = { ...this.state.choices[index], ...val };
    const newChoices = this.state.choices.slice();
    newChoices.splice(index, 1, newChoice);
    this.setState({ choices: newChoices });
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="MultipleChoiceEditor">
        <div className="MultipleChoiceEditor-body">
          <FormGroup label={formatMessage(messages.promptLabel)}>
            <Input
              type="text"
              data-testid="MultipleChoiceEditor-promptInput"
              value={this.state.prompt}
              onChange={evt => this.setState({ prompt: evt.target.value })}
            />
          </FormGroup>
          <FormGroup label={formatMessage(messages.choicesLabel)}>
            {this.state.choices.map(({ text, isCorrect }, index) => (
              <div className="MultipleChoiceEditor-choice mb2" key={index}>
                <div className="MultipleChoiceEditor-choiceLabel pr3 f4 gray">
                  {CHOICE_LABELS[index]}
                </div>
                <div className="MultipleChoiceEditor-correctToggle pr2">
                  <Toggle
                    checked={isCorrect}
                    onChange={evt =>
                      this.onUpdateChoice(index, {
                        isCorrect: evt.target.checked,
                      })
                    }
                  />
                  <div
                    className={classNames(
                      'MultipleChoiceEditor-toggleLabel f7',
                      {
                        'is-correct': isCorrect,
                      },
                    )}
                  >
                    {isCorrect ? (
                      <FormattedMessage
                        id="MultipleChoiceEditor.correct_label"
                        defaultMessage="Correct"
                      />
                    ) : (
                      <FormattedMessage
                        id="MultipleChoiceEditor.incorrect_label"
                        defaultMessage="Incorrect"
                      />
                    )}
                  </div>
                </div>
                <Input
                  type="text"
                  value={text}
                  onChange={evt =>
                    this.onUpdateChoice(index, { text: evt.target.value })
                  }
                />
                <div className="MultipleChoiceEditor-removeChoice pl2">
                  <Button
                    outline
                    size="small"
                    buttonType="gray"
                    disabled={this.state.choices.length <= 2}
                    onClick={() => this.onDeleteChoice(index)}
                  >
                    <i className="fas fa-minus" />
                  </Button>
                </div>
              </div>
            ))}
            {this.state.choices.length >= CHOICE_LABELS.length ? null : (
              <Button outline onClick={this.onAddChoice}>
                <i className="fa fa-plus pr1" />
                <FormattedMessage
                  id="MultipleChoiceEditor.add_choice_button"
                  defaultMessage="Add choice"
                />
              </Button>
            )}
          </FormGroup>
        </div>
        <div className="MultipleChoiceEditor-doneButton">
          <div
            data-tip={
              this.hasValidChoices() ? null : this.renderInvalidReason()
            }
            data-type="error"
          >
            <Button
              buttonType="success"
              disabled={!this.hasValidChoices() || !this.state.prompt.trim()}
              onClick={this.onDone}
              fullWidth
            >
              <FormattedMessage
                id="MultipleChoiceEditor.done_button"
                defaultMessage="Done"
              />
            </Button>
          </div>
        </div>
        <ReactTooltip effect="solid" place="top" html={true} />
      </div>
    );
  }
}

export default injectIntl(MultipleChoiceEditor);
