import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import { WordOrderExerciseSummary_exercise } from './__generated__/WordOrderExerciseSummary_exercise.graphql';
import ScrambledWordParts from './ScrambledWordParts';
import ExerciseSummaryContainer from './ExerciseSummaryContainer';
import CorrectAnswers from './CorrectAnswers';
type Props = {
  exercise: WordOrderExerciseSummary_exercise;
  alwaysShowAnswers: boolean;
  scrambleWords: boolean;
  intl: IntlShape;
};
const messages = defineMessages({
  typeLabel: {
    id: 'WordOrderExerciseSummary.type_label',
    defaultMessage: 'Word order exercise',
  },
});

const WordOrderExerciseSummary = ({
  exercise,
  alwaysShowAnswers,
  scrambleWords,
  intl,
}: Props) => (
  <div className="WordOrderExerciseSummary">
    <ExerciseSummaryContainer
      typeLabel={intl.formatMessage(messages.typeLabel)}
    >
      <ScrambledWordParts
        scrambleWords={scrambleWords}
        wordParts={exercise.content.scrambledParts}
      />
      <CorrectAnswers
        alwaysShowAnswers={alwaysShowAnswers}
        correctAnswers={exercise.content.correctAnswers}
      />
    </ExerciseSummaryContainer>
  </div>
); // for testing raw component

export { WordOrderExerciseSummary };
export default injectIntl(
  createFragmentContainer(WordOrderExerciseSummary, {
    exercise: graphql`
      fragment WordOrderExerciseSummary_exercise on WordOrderExercise {
        content {
          correctAnswers
          scrambledParts
        }
      }
    `,
  }),
);
