/* tslint:disable */
/* eslint-disable */
/* @relayHash f99667ec3e47ed8e50fca92a6ba50190 */

import { ConcreteRequest } from "relay-runtime";
export type SubscriptionType = "TEACHER_FOREVER" | "TEACHER_MONTHLY" | "TEACHER_YEARLY" | "%future added value";
export type AdminUpgradeUserInput = {
    userId: string;
    subscriptionNote?: string | null;
    clientMutationId?: string | null;
};
export type AdminUpgradeUserMutationVariables = {
    input: AdminUpgradeUserInput;
};
export type AdminUpgradeUserMutationResponse = {
    readonly adminUpgradeUser: {
        readonly user: {
            readonly id: string;
            readonly isUpgradedTeacher: boolean;
            readonly subscriptionStatus: {
                readonly subscriptionType: SubscriptionType | null;
            } | null;
        };
    } | null;
};
export type AdminUpgradeUserMutation = {
    readonly response: AdminUpgradeUserMutationResponse;
    readonly variables: AdminUpgradeUserMutationVariables;
};



/*
mutation AdminUpgradeUserMutation(
  $input: AdminUpgradeUserInput!
) {
  adminUpgradeUser(input: $input) {
    user {
      id
      isUpgradedTeacher
      subscriptionStatus {
        subscriptionType
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AdminUpgradeUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adminUpgradeUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdminUpgradeUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isUpgradedTeacher",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscriptionStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSubscriptionStatus",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subscriptionType",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminUpgradeUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminUpgradeUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AdminUpgradeUserMutation",
    "id": null,
    "text": "mutation AdminUpgradeUserMutation(\n  $input: AdminUpgradeUserInput!\n) {\n  adminUpgradeUser(input: $input) {\n    user {\n      id\n      isUpgradedTeacher\n      subscriptionStatus {\n        subscriptionType\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5b23ffb6f39b078d03b9a7cf3c65e7b1';
export default node;
