/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassroomActions_classroom = {
    readonly id: string;
    readonly " $refType": "ClassroomActions_classroom";
};
export type ClassroomActions_classroom$data = ClassroomActions_classroom;
export type ClassroomActions_classroom$key = {
    readonly " $data"?: ClassroomActions_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomActions_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ClassroomActions_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '005c58379d2d3d8aaff70cf563294b6c';
export default node;
