import { factorial } from '../../lib/utils';
export const numPossibleOrders = (answerPieces: string[]) => {
  // algorithm from https://www.ck12.org/probability/permutations-with-repetition/worksheet/Permutations-with-Repetition-BSC-PST/
  const counts: {
    [piece: string]: number;
  } = {};

  for (const piece of answerPieces) {
    if (!counts[piece]) counts[piece] = 0;
    counts[piece] += 1;
  } // using Object.keys nonsense here because flow is too stupid to work with Object.values
  // see: https://github.com/facebook/flow/issues/2221

  const countVals = Object.keys(counts).map(key => counts[key]);
  const numerator = factorial(answerPieces.length);
  const denom = countVals.reduce(
    (acc, curCount) => acc * factorial(curCount),
    1,
  );
  return numerator / denom;
};
