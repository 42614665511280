import React, { Component, SyntheticEvent } from 'react';
import { Environment } from 'react-relay';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

import EmailLoginMutation from '../../mutations/EmailLoginMutation';
import Button from '../common/Button';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import OauthHeader from './OauthHeader';
import { isWechat } from '../../lib/utils';

type State = {
  emailOrUsername: string;
  password: string;
  error: string | null;
};

type Props = {
  intl: IntlShape;
  environment: Environment;
  onLogIn: () => void;
  oauthRedirect?: string;
  background: 'white' | 'gray';
};

const messages = defineMessages({
  missingUsernameOrEmailError: {
    id: 'LogInForm.missing_username_or_email_error',
    defaultMessage: 'Enter your username or email',
  },
  missingPasswordError: {
    id: 'LogInForm.missing_email_error',
    defaultMessage: 'Enter your password',
  },
});

class LogInForm extends Component<Props, State> {
  state: State = {
    emailOrUsername: '',
    password: '',
    error: null,
  };

  static defaultProps: Partial<Props> = {
    background: 'white',
  };

  onSubmit = async (evt: SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { emailOrUsername, password } = this.state;
    const { formatMessage } = this.props.intl;
    if (!emailOrUsername) {
      return this.setState({
        error: formatMessage(messages.missingUsernameOrEmailError),
      });
    }
    if (!password) {
      return this.setState({
        error: formatMessage(messages.missingPasswordError),
      });
    }
    this.setState({ error: null });
    try {
      await EmailLoginMutation.commit(
        this.props.environment,
        emailOrUsername,
        password,
      );
      this.props.onLogIn();
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  render() {
    return (
      <form onSubmit={this.onSubmit} className="LogInForm">
        {isWechat() ? null : (
          <OauthHeader
            redirect={this.props.oauthRedirect}
            authType="login"
            background={this.props.background}
          />
        )}
        {!this.state.error ? null : (
          <div className="pa3 mb4 red ba b--light-red">{this.state.error}</div>
        )}
        <FormGroup
          label={
            <FormattedMessage
              id="LogInForm.username_or_email_label"
              defaultMessage="Username or email"
            />
          }
        >
          <Input
            required
            type="text"
            id="emailOrUsername"
            value={this.state.emailOrUsername}
            onChange={evt =>
              this.setState({ emailOrUsername: evt.target.value })
            }
          />
        </FormGroup>

        <FormGroup
          label={
            <FormattedMessage
              id="LogInForm.password_label"
              defaultMessage="Password"
            />
          }
          hint={
            <Link
              to="/forgot-password"
              className="no-underline underline-hover"
            >
              <FormattedMessage
                id="LogInForm.forgot_password_link"
                defaultMessage="Forgot password?"
              />
            </Link>
          }
        >
          <Input
            required
            type="password"
            id="password"
            value={this.state.password}
            onChange={evt => this.setState({ password: evt.target.value })}
          />
        </FormGroup>

        <Button type="submit" fullWidth>
          <FormattedMessage
            id="LogInForm.log_in_button"
            defaultMessage="Log in"
          />
        </Button>
      </form>
    );
  }
}

export default injectIntl(LogInForm);
