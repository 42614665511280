import React, { HTMLProps, FC, ChangeEvent } from 'react';
import classNames from 'classnames';
import './Textarea.css';

interface Props extends HTMLProps<HTMLTextAreaElement> {
  className?: string;
  onChange?: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: FC<Props> = ({ className, ...otherProps }) => (
  <textarea className={classNames('Textarea', className)} {...otherProps} />
);

export default Textarea;
