import React from 'react';
import { ReactNode } from 'react';
type Props = {
  typeLabel: string;
  children: ReactNode;
};

const ExerciseSummaryContainer = ({ typeLabel, children }: Props) => (
  <div className="ExerciseSummaryContainer">
    <div className="f6 gray mb3">{typeLabel}</div>
    {children}
  </div>
);

export default ExerciseSummaryContainer;
