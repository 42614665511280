import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../../environment';
import NavHeader from '../../components/NavHeader';
import AdminHeader from '../../components/AdminHeader';
import PageLoading from '../../components/common/PageLoading';
import AdminStudyRecordsList from '../../components/admin/AdminStudyRecordsList';
import { AdminStudyRecordsPageQuery } from './__generated__/AdminStudyRecordsPageQuery.graphql';

const adminStudyRecordsPageQuery = graphql`
  query AdminStudyRecordsPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        isAdmin
      }
    }
    admin {
      ...AdminStudyRecordsList_admin
    }
  }
`;

const AdminStudyRecordsPage = () => (
  <QueryRenderer<AdminStudyRecordsPageQuery>
    environment={environment}
    variables={{}}
    query={adminStudyRecordsPageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props) return <PageLoading />;

      if (!props.viewer || !props.viewer.user || !props.viewer.user.isAdmin) {
        return <Redirect to="/" />;
      }

      return (
        <div className="AdminStudyRecordsPage">
          <NavHeader viewer={props.viewer} />
          <div className="mw8 center">
            <AdminHeader activeTab="studyRecords" />
            <AdminStudyRecordsList
              admin={
                props.admin as NonNullable<
                  AdminStudyRecordsPageQuery['response']['admin']
                >
              }
            />
          </div>
        </div>
      );
    }}
  />
);

export default AdminStudyRecordsPage;
