/* tslint:disable */
/* eslint-disable */
/* @relayHash 1558c9826c1a37ccfd66fb382fb9ecc7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type HomePageQueryVariables = {};
export type HomePageQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly id: string;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"NavHeader_viewer" | "NavFooter_viewer">;
    } | null;
};
export type HomePageQuery = {
    readonly response: HomePageQueryResponse;
    readonly variables: HomePageQueryVariables;
};



/*
query HomePageQuery {
  viewer {
    ...NavHeader_viewer
    ...NavFooter_viewer
    user {
      id
    }
  }
}

fragment FeedbackModal_viewer on Viewer {
  user {
    id
  }
}

fragment NavFooter_viewer on Viewer {
  ...FeedbackModal_viewer
}

fragment NavHeader_viewer on Viewer {
  ...FeedbackModal_viewer
  user {
    id
    username
    isAdmin
    createdAt
    isUpgradedTeacher
    accountType
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
    worksheets(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HomePageQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": (v1/*: any*/)
          },
          {
            "kind": "FragmentSpread",
            "name": "NavHeader_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "NavFooter_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomePageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isAdmin",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isUpgradedTeacher",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accountType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomMemberships",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomMembership",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "level",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroom",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "identifier",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  (v0/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "worksheets",
                "storageKey": "worksheets(first:1)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  }
                ],
                "concreteType": "WorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Worksheet",
                        "plural": false,
                        "selections": (v1/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "HomePageQuery",
    "id": null,
    "text": "query HomePageQuery {\n  viewer {\n    ...NavHeader_viewer\n    ...NavFooter_viewer\n    user {\n      id\n    }\n  }\n}\n\nfragment FeedbackModal_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment NavFooter_viewer on Viewer {\n  ...FeedbackModal_viewer\n}\n\nfragment NavHeader_viewer on Viewer {\n  ...FeedbackModal_viewer\n  user {\n    id\n    username\n    isAdmin\n    createdAt\n    isUpgradedTeacher\n    accountType\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n    worksheets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '376c1b13ba71c5df42ed42304ea8e7f7';
export default node;
