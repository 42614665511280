import React, { FC } from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { ActiveMemberships_classroom } from './__generated__/ActiveMemberships_classroom.graphql';
import { exists } from '../../lib/utils';
import ClassroomMember from './ClassroomMember';
import LoadMoreContainer from './LoadMoreContainer';

type ActiveMembershipsProps = {
  classroom: ActiveMemberships_classroom;
  relay: RelayPaginationProp;
  canEdit: boolean;
};

const ActiveMemberships: FC<ActiveMembershipsProps> = ({
  classroom,
  relay,
  canEdit,
}) => (
  <div className="ActiveMemberships pt3 tl">
    <LoadMoreContainer relay={relay} pageSize={100}>
      {(classroom?.activeMemberships?.edges || []).length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="ActiveMemberships.no_members_message"
            defaultMessage="This class has no members"
          />
        </div>
      )}
      {classroom?.activeMemberships?.edges?.filter(exists)?.map(
        ({ node }) =>
          node && (
            <span key={node.id}>
              <ClassroomMember classroomMembership={node} canEdit={canEdit} />
            </span>
          ),
      )}
    </LoadMoreContainer>
  </div>
);

export default createPaginationContainer(
  ActiveMemberships,
  {
    classroom: graphql`
      fragment ActiveMemberships_classroom on Classroom
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "String" }
        ) {
        id
        activeMemberships(first: $count, after: $cursor)
          @connection(key: "ActiveMemberships_activeMemberships") {
          edges {
            node {
              id
              ...ClassroomMember_classroomMembership
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props => props.classroom?.activeMemberships,
    getVariables: (props, { count, cursor }) => ({
      classroomId: props.classroom.id,
      count,
      cursor,
    }),
    query: graphql`
      query ActiveMembershipsQuery(
        $classroomId: ID!
        $count: Int!
        $cursor: String
      ) {
        classroom: node(id: $classroomId) {
          ... on Classroom {
            ...ActiveMemberships_classroom
              @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `,
  },
);
