/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type JoinClassroomModal_classroom = {
    readonly id: string;
    readonly title: string;
    readonly description: string | null;
    readonly requiresApproval: boolean;
    readonly " $refType": "JoinClassroomModal_classroom";
};
export type JoinClassroomModal_classroom$data = JoinClassroomModal_classroom;
export type JoinClassroomModal_classroom$key = {
    readonly " $data"?: JoinClassroomModal_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"JoinClassroomModal_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "JoinClassroomModal_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requiresApproval",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'd363939fa5348510a1d9a001926eab13';
export default node;
