import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import Button from '../components/common/Button';
import { NotFoundPageQuery } from './__generated__/NotFoundPageQuery.graphql';

const notFoundPageQuery = graphql`
  query NotFoundPageQuery {
    viewer {
      ...NavHeader_viewer
    }
  }
`;

const NotFoundPage = () => (
  <div className="NotFoundPage">
    <QueryRenderer<NotFoundPageQuery>
      environment={environment}
      variables={{}}
      query={notFoundPageQuery}
      render={({ error, props }) => {
        const viewer = props && props.viewer;
        return <NavHeader viewer={viewer} />;
      }}
    />
    <div className="mw8 center pt5">
      <h2>:(</h2>
      <h4>
        <FormattedMessage
          id="NotFoundPage.not_found"
          defaultMessage="Page not found"
        />
      </h4>
      <div className="pt5">
        <Button component={Link} to="/">
          <FormattedMessage
            id="NotFoundPage.back_home"
            defaultMessage="Back home"
          />
        </Button>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
