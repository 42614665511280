/* tslint:disable */
/* eslint-disable */
/* @relayHash b7b7b36ea0ec2bcf7e57d409f567d432 */

import { ConcreteRequest } from "relay-runtime";
export type WorksheetStudiersTabQueryVariables = {
    id: string;
};
export type WorksheetStudiersTabQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly numStudiers?: number;
        readonly studiers?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly username: string;
                    readonly isUpgradedTeacher: boolean;
                } | null;
            } | null> | null;
        };
    } | null;
};
export type WorksheetStudiersTabQuery = {
    readonly response: WorksheetStudiersTabQueryResponse;
    readonly variables: WorksheetStudiersTabQueryVariables;
};



/*
query WorksheetStudiersTabQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Worksheet {
      id
      numStudiers
      studiers(first: 500) {
        edges {
          node {
            username
            isUpgradedTeacher
            id
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numStudiers",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpgradedTeacher",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WorksheetStudiersTabQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Worksheet",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "studiers",
                "storageKey": "studiers(first:500)",
                "args": (v4/*: any*/),
                "concreteType": "UserConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WorksheetStudiersTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Worksheet",
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "studiers",
                "storageKey": "studiers(first:500)",
                "args": (v4/*: any*/),
                "concreteType": "UserConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WorksheetStudiersTabQuery",
    "id": null,
    "text": "query WorksheetStudiersTabQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Worksheet {\n      id\n      numStudiers\n      studiers(first: 500) {\n        edges {\n          node {\n            username\n            isUpgradedTeacher\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd9480f03d463bb1e0734ba96640d8235';
export default node;
