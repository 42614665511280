import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { BannedMemberships_classroom } from './__generated__/BannedMemberships_classroom.graphql';
import { exists } from '../../lib/utils';
import ClassroomMember from './ClassroomMember';
import LoadMoreContainer from './LoadMoreContainer';

type BannedMembershipsProps = {
  classroom: BannedMemberships_classroom;
  relay: RelayPaginationProp;
  canEdit: boolean;
};

const BannedMemberships = ({
  classroom,
  relay,
  canEdit,
}: BannedMembershipsProps) => {
  const memberships =
    classroom?.bannedMemberships?.edges
      ?.filter(exists)
      ?.map(({ node }) => node)
      ?.filter(exists) || [];
  // it's hacky to do this check and return null in here, but it works
  if (memberships.length === 0) return null;
  return (
    <div className="BannedMemberships pa3 mt4 bg-black-10">
      <h4 className="ma0 mb3">
        <FormattedMessage
          id="BannedMemberships.banned_members_header"
          defaultMessage="Banned members"
        />
      </h4>
      <LoadMoreContainer relay={relay} pageSize={100}>
        {memberships.map(membership => (
          <span key={membership.id}>
            <ClassroomMember
              classroomMembership={membership}
              canEdit={canEdit}
            />
          </span>
        ))}
      </LoadMoreContainer>
    </div>
  );
};

export default createPaginationContainer(
  BannedMemberships,
  {
    classroom: graphql`
      fragment BannedMemberships_classroom on Classroom
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "String" }
        ) {
        id
        bannedMemberships(first: $count, after: $cursor)
          @connection(key: "BannedMemberships_bannedMemberships") {
          edges {
            node {
              id
              ...ClassroomMember_classroomMembership
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props => props.classroom?.bannedMemberships,
    getVariables: (props, { count, cursor }) => ({
      classroomId: props.classroom.id,
      count,
      cursor,
    }),
    query: graphql`
      query BannedMembershipsQuery(
        $classroomId: ID!
        $count: Int!
        $cursor: String
      ) {
        classroom: node(id: $classroomId) {
          ... on Classroom {
            ...BannedMemberships_classroom
              @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `,
  },
);
