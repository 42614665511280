/* tslint:disable */
/* eslint-disable */
/* @relayHash a4943473e074e7a958b84e219af85204 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DeleteClassroomInput = {
    id: string;
    clientMutationId?: string | null;
};
export type DeleteClassroomMutationVariables = {
    input: DeleteClassroomInput;
};
export type DeleteClassroomMutationResponse = {
    readonly deleteClassroom: {
        readonly id: string;
        readonly viewer: {
            readonly " $fragmentRefs": FragmentRefs<"DashboardLayout_viewer">;
        } | null;
    } | null;
};
export type DeleteClassroomMutation = {
    readonly response: DeleteClassroomMutationResponse;
    readonly variables: DeleteClassroomMutationVariables;
};



/*
mutation DeleteClassroomMutation(
  $input: DeleteClassroomInput!
) {
  deleteClassroom(input: $input) {
    id
    viewer {
      ...DashboardLayout_viewer
    }
  }
}

fragment DashboardLayout_viewer on Viewer {
  user {
    id
    isAdmin
    accountType
    isUpgradedTeacher
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteClassroomInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteClassroomMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteClassroom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteClassroomPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "DashboardLayout_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteClassroomMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteClassroom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteClassroomPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isAdmin",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accountType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isUpgradedTeacher",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroomMemberships",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClassroomMembership",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "level",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "classroom",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Classroom",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "identifier",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteClassroomMutation",
    "id": null,
    "text": "mutation DeleteClassroomMutation(\n  $input: DeleteClassroomInput!\n) {\n  deleteClassroom(input: $input) {\n    id\n    viewer {\n      ...DashboardLayout_viewer\n    }\n  }\n}\n\nfragment DashboardLayout_viewer on Viewer {\n  user {\n    id\n    isAdmin\n    accountType\n    isUpgradedTeacher\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '94d1058698c5e863f998f58acb3ec190';
export default node;
