import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CreateTranslationAnswerMutation } from './__generated__/CreateTranslationAnswerMutation.graphql';
const mutation = graphql`
  mutation CreateTranslationAnswerMutation(
    $input: CreateTranslationAnswerInput!
  ) {
    createTranslationAnswer(input: $input) {
      answer {
        id
      }
    }
  }
`;

const commit = (
  environment: any,
  studyRecordId: string,
  exerciseId: string,
  isCorrect: boolean,
  response: string[],
) =>
  commitMutationPromise<CreateTranslationAnswerMutation>(environment, {
    mutation,
    variables: {
      input: {
        studyRecordId,
        exerciseId,
        isCorrect,
        response,
      },
    },
  });

export default {
  commit,
};
