/* tslint:disable */
/* eslint-disable */
/* @relayHash 9fbb14b3aa30f179cc2aa5509209ae3c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ACCOUNT_TYPE = "STUDENT" | "TEACHER" | "%future added value";
export type AdminUsersPageQueryVariables = {
    accountType?: ACCOUNT_TYPE | null;
    query?: string | null;
    upgradedOnly?: boolean | null;
};
export type AdminUsersPageQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly isAdmin: boolean;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"NavHeader_viewer">;
    } | null;
    readonly admin: {
        readonly " $fragmentRefs": FragmentRefs<"AdminUsersList_admin">;
    } | null;
};
export type AdminUsersPageQuery = {
    readonly response: AdminUsersPageQueryResponse;
    readonly variables: AdminUsersPageQueryVariables;
};



/*
query AdminUsersPageQuery(
  $accountType: ACCOUNT_TYPE
  $query: String
  $upgradedOnly: Boolean
) {
  viewer {
    ...NavHeader_viewer
    user {
      isAdmin
      id
    }
  }
  admin {
    ...AdminUsersList_admin_2MqL8V
  }
}

fragment AdminUser_user on User {
  id
  createdAt
  username
  email
  isAdmin
  hasGoogle
  hasPassword
  isUpgradedTeacher
  accountType
  subscriptionStatus {
    subscriptionType
    subscriptionNote
  }
  worksheets {
    edges {
      node {
        id
        title
        identifier
      }
    }
  }
}

fragment AdminUsersList_admin_2MqL8V on Admin {
  recentUsers(accountType: $accountType, upgradedOnly: $upgradedOnly, query: $query, first: 100) {
    edges {
      node {
        id
        ...AdminUser_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment FeedbackModal_viewer on Viewer {
  user {
    id
  }
}

fragment NavHeader_viewer on Viewer {
  ...FeedbackModal_viewer
  user {
    id
    username
    isAdmin
    createdAt
    isUpgradedTeacher
    accountType
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
    worksheets(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountType",
    "type": "ACCOUNT_TYPE",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "upgradedOnly",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdmin",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "accountType",
  "variableName": "accountType"
},
v3 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v4 = {
  "kind": "Variable",
  "name": "upgradedOnly",
  "variableName": "upgradedOnly"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpgradedTeacher",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accountType",
  "args": null,
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "title",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "identifier",
    "args": null,
    "storageKey": null
  }
],
v11 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminUsersPageQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "NavHeader_viewer",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AdminUsersList_admin",
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminUsersPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v1/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomMemberships",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomMembership",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "level",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroom",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": (v10/*: any*/)
                  },
                  (v5/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "worksheets",
                "storageKey": "worksheets(first:1)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  }
                ],
                "concreteType": "WorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Worksheet",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "recentUsers",
            "storageKey": null,
            "args": (v11/*: any*/),
            "concreteType": "UserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v7/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasGoogle",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasPassword",
                        "args": null,
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "subscriptionStatus",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserSubscriptionStatus",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "subscriptionType",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "subscriptionNote",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "worksheets",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorksheetConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "WorksheetEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Worksheet",
                                "plural": false,
                                "selections": (v10/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "recentUsers",
            "args": (v11/*: any*/),
            "handle": "connection",
            "key": "AdminUsersList_recentUsers",
            "filters": [
              "accountType",
              "upgradedOnly",
              "query"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdminUsersPageQuery",
    "id": null,
    "text": "query AdminUsersPageQuery(\n  $accountType: ACCOUNT_TYPE\n  $query: String\n  $upgradedOnly: Boolean\n) {\n  viewer {\n    ...NavHeader_viewer\n    user {\n      isAdmin\n      id\n    }\n  }\n  admin {\n    ...AdminUsersList_admin_2MqL8V\n  }\n}\n\nfragment AdminUser_user on User {\n  id\n  createdAt\n  username\n  email\n  isAdmin\n  hasGoogle\n  hasPassword\n  isUpgradedTeacher\n  accountType\n  subscriptionStatus {\n    subscriptionType\n    subscriptionNote\n  }\n  worksheets {\n    edges {\n      node {\n        id\n        title\n        identifier\n      }\n    }\n  }\n}\n\nfragment AdminUsersList_admin_2MqL8V on Admin {\n  recentUsers(accountType: $accountType, upgradedOnly: $upgradedOnly, query: $query, first: 100) {\n    edges {\n      node {\n        id\n        ...AdminUser_user\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FeedbackModal_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment NavHeader_viewer on Viewer {\n  ...FeedbackModal_viewer\n  user {\n    id\n    username\n    isAdmin\n    createdAt\n    isUpgradedTeacher\n    accountType\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n    worksheets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '080e31941f7ab04f02a3e217eb6f1fff';
export default node;
