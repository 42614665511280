import { commitMutation } from 'react-relay';
import {
  GraphQLTaggedNode,
  SelectorStoreUpdater,
  UploadableMap,
  MutationParameters,
  PayloadError,
  Environment,
  DeclarativeMutationConfig,
} from 'relay-runtime';

export const commitMutationPromise = <T extends MutationParameters>(
  environment: Environment,
  config: {
    mutation: GraphQLTaggedNode;
    configs?: DeclarativeMutationConfig[];
    variables: T['variables'];
    onCompleted?: (
      response: T['response'],
      errors: ReadonlyArray<PayloadError> | null | undefined,
    ) => void;
    onError?: (err: Error) => void;
    optimisticResponse?: T['response'];
    optimisticUpdater?: SelectorStoreUpdater | null;
    updater?: SelectorStoreUpdater | null;
    uploadables?: UploadableMap | null;
  },
): Promise<T['response']> => {
  // commitMutation in a promise wrapper
  return new Promise((resolve, reject) => {
    const onCompleted = (
      resp: T['response'],
      err: ReadonlyArray<PayloadError> | null | undefined,
    ) => {
      if (config.onCompleted) config.onCompleted(resp, err);
      if (err) return reject(err[0]);
      resolve(resp);
    };

    const onError = (err: Error) => {
      if (config.onError) config.onError(err);
      reject(err);
    };

    commitMutation<T>(environment, { ...config, onCompleted, onError });
  });
};
let tempIdCounter = 0;
export const tempId = (prefix: string) => `client:${prefix}:${tempIdCounter++}`;
