/* tslint:disable */
/* eslint-disable */
/* @relayHash 8d0806b0c9f9431b83b7dc2979d5764b */

import { ConcreteRequest } from "relay-runtime";
export type UpdateVocabExerciseInput = {
    id: string;
    position?: number | null;
    content?: VocabExerciseContentInput | null;
    clientMutationId?: string | null;
};
export type VocabExerciseContentInput = {
    terms: Array<VocabExerciseTermInput | null>;
};
export type VocabExerciseTermInput = {
    word: string;
    definition?: string | null;
    imageId?: string | null;
};
export type UpdateVocabExerciseMutationVariables = {
    input: UpdateVocabExerciseInput;
};
export type UpdateVocabExerciseMutationResponse = {
    readonly updateVocabExercise: {
        readonly exercise: {
            readonly id: string;
            readonly content: {
                readonly terms: ReadonlyArray<{
                    readonly word: string;
                    readonly definition: string | null;
                    readonly image: {
                        readonly id: string;
                        readonly url: string;
                    } | null;
                }>;
            };
        } | null;
    } | null;
};
export type UpdateVocabExerciseMutation = {
    readonly response: UpdateVocabExerciseMutationResponse;
    readonly variables: UpdateVocabExerciseMutationVariables;
};



/*
mutation UpdateVocabExerciseMutation(
  $input: UpdateVocabExerciseInput!
) {
  updateVocabExercise(input: $input) {
    exercise {
      id
      content {
        terms {
          word
          definition
          image {
            id
            url(size: SMALL_SQUARE)
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateVocabExerciseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateVocabExercise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateVocabExercisePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "exercise",
        "storageKey": null,
        "args": null,
        "concreteType": "VocabExercise",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "content",
            "storageKey": null,
            "args": null,
            "concreteType": "VocabExerciseContent",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "terms",
                "storageKey": null,
                "args": null,
                "concreteType": "VocabExerciseTerm",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "word",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "definition",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "size",
                            "value": "SMALL_SQUARE"
                          }
                        ],
                        "storageKey": "url(size:\"SMALL_SQUARE\")"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateVocabExerciseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateVocabExerciseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateVocabExerciseMutation",
    "id": null,
    "text": "mutation UpdateVocabExerciseMutation(\n  $input: UpdateVocabExerciseInput!\n) {\n  updateVocabExercise(input: $input) {\n    exercise {\n      id\n      content {\n        terms {\n          word\n          definition\n          image {\n            id\n            url(size: SMALL_SQUARE)\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c2c5837512b093aa2b12e4e54916393d';
export default node;
