import React from 'react';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { ImagePreviewSearchQuery } from './__generated__/ImagePreviewSearchQuery.graphql';
import { ImagePreviewImageQuery } from './__generated__/ImagePreviewImageQuery.graphql';

import environment from '../../environment';

import './ImagePreview.css';
import { exists } from '../../lib/utils';

type Props = {
  selectedImageId: null | string;
  word: null | string;
  onClick: () => void;
};

const imagePreviewSearchQuery = graphql`
  query ImagePreviewSearchQuery($query: String!) {
    imageSearch(query: $query) {
      images(first: 1) {
        edges {
          node {
            id
            url(size: LARGE_SQUARE)
          }
        }
      }
    }
  }
`;

const imagePreviewImageQuery = graphql`
  query ImagePreviewImageQuery($id: ID!) {
    node(id: $id) {
      ... on Image {
        id
        url(size: LARGE_SQUARE)
      }
    }
  }
`;

const renderImage = ({ selectedImageId, word, onClick }: Props) => {
  if (selectedImageId)
    return (
      <QueryRenderer<ImagePreviewImageQuery>
        environment={environment}
        query={imagePreviewImageQuery}
        variables={{ id: selectedImageId }}
        render={({ error, props }) => {
          if (!props) return <span className="gray f7">Loading...</span>;
          return (
            <React.Fragment>
              <img
                className="ImagePreview-selectedImage"
                alt="Selected"
                src={(props.node && props.node.url) || ''}
              />
              {/* eslint-disable-next-line */}
              <a className="ImagePreview-editButton" onClick={onClick}>
                <i className="fas fa-pen" />
              </a>
            </React.Fragment>
          );
        }}
      />
    );
  const addButton = (
    // eslint-disable-next-line
    <a className="ImagePreview-addButton" onClick={onClick}>
      <i className="fas fa-camera" />
      <span className="f7">
        <FormattedMessage
          id="ImagePreview.add_image"
          defaultMessage="Add image"
        />
      </span>
    </a>
  );
  if (!word) return addButton;
  return (
    <QueryRenderer<ImagePreviewSearchQuery>
      environment={environment}
      query={imagePreviewSearchQuery}
      render={({ error, props }) => {
        let sampleImageResult = null;
        if (
          props &&
          props.imageSearch &&
          props.imageSearch.images &&
          props.imageSearch.images.edges &&
          props.imageSearch.images.edges.length > 0
        ) {
          const filteredEdges = props.imageSearch.images.edges.filter(exists);
          sampleImageResult = (
            <React.Fragment>
              <img
                className="ImagePreview-guessedImage"
                alt="Guessed result"
                src={(filteredEdges[0].node && filteredEdges[0].node.url) || ''}
              />
              {addButton}
            </React.Fragment>
          );
        }
        return (
          <React.Fragment>
            {sampleImageResult}
            {addButton}
          </React.Fragment>
        );
      }}
      variables={{ query: word }}
    />
  );
};

const ImagePreview = (props: Props) => (
  <div className="ImagePreview bg-black-05 br2">{renderImage(props)}</div>
);

export default ImagePreview;
