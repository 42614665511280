/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetsTab_classroom = {
    readonly id: string;
    readonly " $refType": "WorksheetsTab_classroom";
};
export type WorksheetsTab_classroom$data = WorksheetsTab_classroom;
export type WorksheetsTab_classroom$key = {
    readonly " $data"?: WorksheetsTab_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetsTab_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WorksheetsTab_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'f3e51297f640bca7df559f12ebdee5e7';
export default node;
