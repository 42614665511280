/* tslint:disable */
/* eslint-disable */
/* @relayHash bd03a84693efa43ff0965330e630df1a */

import { ConcreteRequest } from "relay-runtime";
export type WORKSHEET_VISIBILITY = "EVERYONE" | "LOGGED_IN" | "PASSWORD" | "%future added value";
export type UpdateWorksheetInput = {
    id: string;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    visibility?: WORKSHEET_VISIBILITY | null;
    clientMutationId?: string | null;
};
export type UpdateWorksheetMutationVariables = {
    input: UpdateWorksheetInput;
};
export type UpdateWorksheetMutationResponse = {
    readonly updateWorksheet: {
        readonly worksheet: {
            readonly id: string;
            readonly title: string | null;
            readonly description: string | null;
            readonly identifier: string | null;
            readonly isPublished: boolean;
        } | null;
    } | null;
};
export type UpdateWorksheetMutation = {
    readonly response: UpdateWorksheetMutationResponse;
    readonly variables: UpdateWorksheetMutationVariables;
};



/*
mutation UpdateWorksheetMutation(
  $input: UpdateWorksheetInput!
) {
  updateWorksheet(input: $input) {
    worksheet {
      id
      title
      description
      identifier
      isPublished
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateWorksheetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateWorksheet",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorksheetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "worksheet",
        "storageKey": null,
        "args": null,
        "concreteType": "Worksheet",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "identifier",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isPublished",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateWorksheetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateWorksheetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateWorksheetMutation",
    "id": null,
    "text": "mutation UpdateWorksheetMutation(\n  $input: UpdateWorksheetInput!\n) {\n  updateWorksheet(input: $input) {\n    worksheet {\n      id\n      title\n      description\n      identifier\n      isPublished\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0ac4361ae0e3568260f4c04a2afb5e7c';
export default node;
