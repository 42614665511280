/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassroomsSelect_worksheet = {
    readonly classroomWorksheetsVisibleToViewer: ReadonlyArray<{
        readonly classroom: {
            readonly id: string;
            readonly title: string;
        };
    }>;
    readonly " $refType": "ClassroomsSelect_worksheet";
};
export type ClassroomsSelect_worksheet$data = ClassroomsSelect_worksheet;
export type ClassroomsSelect_worksheet$key = {
    readonly " $data"?: ClassroomsSelect_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomsSelect_worksheet">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ClassroomsSelect_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "classroomWorksheetsVisibleToViewer",
      "storageKey": null,
      "args": null,
      "concreteType": "ClassroomWorksheet",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "classroom",
          "storageKey": null,
          "args": null,
          "concreteType": "Classroom",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'f3ec1a1226326cba206be0d8ee746c4d';
export default node;
