import React, { Component } from 'react';
import { shuffleArray } from '../../lib/utils';
type Props = {
  wordParts: ReadonlyArray<string>;
  scrambleWords: boolean;
};
type State = {
  scrambledPositions: number[];
};

class ScrambledWordParts extends Component<Props, State> {
  state: State = {
    scrambledPositions: [],
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.scrambledPositions.length !== nextProps.wordParts.length) {
      const scrambledPositions = shuffleArray(
        nextProps.wordParts.map((item, index) => index),
      );
      return {
        scrambledPositions,
      };
    }
  }

  getPositions() {
    if (this.props.scrambleWords) {
      return this.state.scrambledPositions;
    }

    return this.props.wordParts.map((item, index) => index);
  }

  render() {
    return (
      <div className="ScrambledWordParts">
        {this.getPositions().map(partIndex => (
          <span className="fl pa2 ph3 mr2 mt2 bg-white br1" key={partIndex}>
            {this.props.wordParts[partIndex]}
          </span>
        ))}
        <div className="cb" />
      </div>
    );
  }
}

export default ScrambledWordParts;
