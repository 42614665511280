import React from 'react';
import { ReactNode } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Worksheet_worksheet } from './__generated__/Worksheet_worksheet.graphql';
import AutofocusInput from '../common/AutofocusInput';
import Button from '../common/Button';
import { externalStudyLink } from '../../lib/utils';
import './Worksheet.css';
type Props = {
  worksheet: Worksheet_worksheet;
  extraInfo: ReactNode[];
  extraButton?: ReactNode;
};

const Worksheet = ({ worksheet, extraInfo, extraButton }: Props) => {
  const tidbits: ReactNode[] = [];
  extraInfo.forEach((info, i) => {
    if (i > 0) {
      tidbits.push(
        <span className="Worksheet-tidbitSeparator" key={`tidbit-${i}`} />,
      );
    }

    tidbits.push(<span key={`info-${i}`}>{info}</span>);
  });
  const identifier = worksheet.identifier || '';
  return (
    <div className="Worksheet">
      <Link
        to={`/view/${identifier}`}
        className="Worksheet-viewLink no-underline dark-gray"
      >
        <div className="pa3 pv2">
          <h1 className="f4 lh-title no-underline">{worksheet.title}</h1>
          <p className="Worksheet-tidbits f6">{tidbits}</p>
        </div>
      </Link>
      <div className="Worksheet-share pa1 pl1 pl3-ns">
        <div className="Worksheet-shareLabel gray mr3 dn db-ns">
          <FormattedMessage
            id="Worksheet.study_link_label"
            defaultMessage="Study link"
          />
        </div>
        <div className="Worksheet-shareInputBox">
          <AutofocusInput
            value={externalStudyLink(identifier)}
            className="f6 pa2 db w-100 w-80-m w-40-l border-box Worksheet-shareInput"
          />
        </div>

        <div className="pl1">
          {extraButton && <span className="pr2 dib">{extraButton}</span>}
          <Button component={Link} to={`/study/${identifier}`}>
            <FormattedMessage
              id="Worksheet.study_button"
              defaultMessage="Study"
            />
          </Button>
        </div>
      </div>
    </div>
  );
}; // for testing raw component

export { Worksheet };
export default createFragmentContainer(Worksheet, {
  worksheet: graphql`
    fragment Worksheet_worksheet on Worksheet {
      title
      description
      identifier
    }
  `,
});
