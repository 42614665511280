import React, { Component } from 'react';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import * as Sentry from '@sentry/browser';
import Button from '../common/Button';
import ErrorBox from '../common/ErrorBox';
import Worksheet from '../common/Worksheet';
import DeleteClassroomWorksheetMutation from '../../mutations/DeleteClassroomWorksheetMutation';
import { ClassroomWorksheet_classroomWorksheet } from './__generated__/ClassroomWorksheet_classroomWorksheet.graphql';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

type ClassroomWorksheetProps = {
  classroomWorksheet: ClassroomWorksheet_classroomWorksheet;
  canEdit: boolean;
  relay: RelayProp;
  intl: IntlShape;
};

type ClassroomWorksheetState = {
  isDeleting: boolean;
  error: string | null;
};

const messages = defineMessages({
  confirmDelete: {
    id: 'ClassroomWorksheet.confirm_delete',
    defaultMessage:
      'Are you sure you want to remove this wordsheet from the class?',
  },
});

class ClassroomWorksheet extends Component<
  ClassroomWorksheetProps,
  ClassroomWorksheetState
> {
  state: ClassroomWorksheetState = {
    isDeleting: false,
    error: null,
  };

  onDelete = async () => {
    const { classroomWorksheet, relay } = this.props;
    if (
      this.state.isDeleting ||
      // eslint-disable-next-line no-restricted-globals
      !confirm(this.props.intl.formatMessage(messages.confirmDelete))
    )
      return;
    this.setState({ isDeleting: true, error: null });
    try {
      await DeleteClassroomWorksheetMutation.commit(
        relay.environment,
        classroomWorksheet.id,
        classroomWorksheet.classroom.id,
      );
      // don't set state after this completes, since this node should be removed from the DOM by then
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error deleting classroomWorksheet', err);
      this.setState({
        error: 'Unable to remove wordsheet from class',
        isDeleting: false,
      });
    }
  };

  render() {
    const { classroomWorksheet, canEdit } = this.props;
    const { isDeleting, error } = this.state;
    const deleteButton = canEdit && (
      <Button
        outline
        buttonType="gray"
        data-testid="ClassroomWorksheet-delete"
        loading={isDeleting}
        disabled={isDeleting}
        onClick={this.onDelete}
      >
        <i className="fas fa-trash" />
      </Button>
    );
    return (
      <div data-testid="ClassroomWorksheet">
        <Worksheet
          worksheet={classroomWorksheet.worksheet}
          extraButton={deleteButton}
          extraInfo={[]}
        />
        {error && <ErrorBox>{error}</ErrorBox>}
      </div>
    );
  }
}

export default injectIntl(
  createFragmentContainer(ClassroomWorksheet, {
    classroomWorksheet: graphql`
      fragment ClassroomWorksheet_classroomWorksheet on ClassroomWorksheet {
        id
        worksheet {
          ...Worksheet_worksheet
        }
        classroom {
          id
        }
      }
    `,
  }),
);
