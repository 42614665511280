import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { AdminDeleteUserMutation } from './__generated__/AdminDeleteUserMutation.graphql';
const mutation = graphql`
  mutation AdminDeleteUserMutation($input: AdminDeleteUserInput!) {
    adminDeleteUser(input: $input) {
      id
    }
  }
`;

const commit = (environment: any, userId: string) =>
  commitMutationPromise<AdminDeleteUserMutation>(environment, {
    mutation,
    variables: {
      input: {
        userId,
      },
    },
    configs: [
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'id',
      },
    ],
  });

export default {
  commit,
};
