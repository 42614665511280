/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DraggableExercise_exercise = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"ExerciseSummary_exercise">;
    readonly " $refType": "DraggableExercise_exercise";
};
export type DraggableExercise_exercise$data = DraggableExercise_exercise;
export type DraggableExercise_exercise$key = {
    readonly " $data"?: DraggableExercise_exercise$data;
    readonly " $fragmentRefs": FragmentRefs<"DraggableExercise_exercise">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DraggableExercise_exercise",
  "type": "Exercise",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExerciseSummary_exercise",
      "args": null
    }
  ]
};
(node as any).hash = '207fb8e335e22be9d1ab96014446fb8f';
export default node;
