import React, { Component, MouseEvent } from 'react';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import * as Sentry from '@sentry/browser';
import Button from '../common/Button';
import { ClassroomMember_classroomMembership } from './__generated__/ClassroomMember_classroomMembership.graphql';
import {
  defineMessages,
  injectIntl,
  IntlShape,
  FormattedMessage,
} from 'react-intl';
import { Link } from 'react-router-dom';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
  // @ts-ignore
} from 'react-simple-dropdown';
import DeleteClassroomMembershipMutation from '../../mutations/DeleteClassroomMembershipMutation';
import BanClassroomMembershipMutation from '../../mutations/BanClassroomMembershipMutation';
import ChangeClassroomMembershipLevelMutation from '../../mutations/ChangeClassroomMembershipLevelMutation';
import Spinner from '../common/Spinner';
import DropdownLink from '../common/DropdownLink';
import DropdownBackground from '../common/DropdownBackground';

type ClassroomMemberProps = {
  classroomMembership: ClassroomMember_classroomMembership;
  canEdit: boolean;
  relay: RelayProp;
  intl: IntlShape;
};

type ClassroomMemberState = {
  isLoading: boolean;
};

const messages = defineMessages({
  confirmDelete: {
    id: 'ClassroomMember.confirm_delete',
    defaultMessage: 'Are you sure you want to remove this user from the class?',
  },
  confirmBan: {
    id: 'ClassroomMember.confirm_ban',
    defaultMessage:
      'Are you sure you want to permanently ban this user from the class? They will be kicked from the class and not allowed to rejoin.',
  },
  confirmDemote: {
    id: 'ClassroomMember.confirm_demote',
    defaultMessage:
      'Are you sure you want to demote this admin to a regular member?',
  },
});

class ClassroomMember extends Component<
  ClassroomMemberProps,
  ClassroomMemberState
> {
  state: ClassroomMemberState = {
    isLoading: false,
  };

  onDelete = async (evt: MouseEvent) => {
    evt.preventDefault();
    const { classroomMembership, relay } = this.props;
    if (
      this.state.isLoading ||
      (classroomMembership.level !== 'BANNED' &&
        // eslint-disable-next-line no-restricted-globals
        !confirm(this.props.intl.formatMessage(messages.confirmDelete)))
    )
      return;
    this.setState({ isLoading: true });
    try {
      await DeleteClassroomMembershipMutation.commit(
        relay.environment,
        classroomMembership.id,
        classroomMembership.classroom.id,
      );
      // don't set state after this completes, since this node should be removed from the DOM by then
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error deleting classroomMembership', err);
      window.alert(err.message || 'Could not remove user from the class');
      this.setState({
        isLoading: false,
      });
    }
  };

  onBan = async (evt: MouseEvent) => {
    evt.preventDefault();
    const { classroomMembership, relay } = this.props;
    if (
      this.state.isLoading ||
      // eslint-disable-next-line no-restricted-globals
      !confirm(this.props.intl.formatMessage(messages.confirmBan))
    )
      return;
    this.setState({ isLoading: true });
    try {
      await BanClassroomMembershipMutation.commit(
        relay.environment,
        classroomMembership.id,
        classroomMembership.classroom.id,
      );
      // don't set state after this completes, since this node should be removed from the DOM by then
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error deleting classroomMembership', err);
      window.alert(err.message || 'Could not ban user from the class');
      this.setState({
        isLoading: false,
      });
    }
  };

  onPromoteToAdmin = async (evt: MouseEvent) => {
    evt.preventDefault();
    const { classroomMembership, relay } = this.props;
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    try {
      await ChangeClassroomMembershipLevelMutation.commit(relay.environment, {
        classroomMembershipId: classroomMembership.id,
        level: 'ADMIN',
      });
      this.setState({ isLoading: false });
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error promoting classroomMembership', err);
      window.alert(err.message || 'Could not make user a class admin');
      this.setState({
        isLoading: false,
      });
    }
  };

  onDemoteToMember = async (evt: MouseEvent) => {
    evt.preventDefault();
    const { classroomMembership, relay } = this.props;
    if (
      this.state.isLoading ||
      // eslint-disable-next-line no-restricted-globals
      !confirm(this.props.intl.formatMessage(messages.confirmDemote))
    )
      return;
    this.setState({ isLoading: true });
    try {
      await ChangeClassroomMembershipLevelMutation.commit(relay.environment, {
        classroomMembershipId: classroomMembership.id,
        level: 'MEMBER',
      });
      this.setState({ isLoading: false });
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('Error demoting classroomMembership', err);
      window.alert(err.message || 'Could not make user a class regular member');
      this.setState({
        isLoading: false,
      });
    }
  };

  renderDropdownContent() {
    if (this.state.isLoading) {
      return (
        <div className="pv3">
          <Spinner size="large" />
        </div>
      );
    }
    const { level } = this.props.classroomMembership;
    return (
      <>
        {level === 'MEMBER' && (
          <DropdownLink href="#" onClick={this.onPromoteToAdmin}>
            <FormattedMessage
              id="ClassroomMember.make_user_admin"
              defaultMessage="Make admin"
            />
          </DropdownLink>
        )}
        {(level === 'ADMIN' || level === 'CREATOR') && (
          <DropdownLink href="#" onClick={this.onDemoteToMember}>
            <FormattedMessage
              id="ClassroomMember.demote_admin"
              defaultMessage="Demote admin"
            />
          </DropdownLink>
        )}
        <DropdownLink href="#" onClick={this.onDelete}>
          {level === 'BANNED' ? (
            <FormattedMessage
              id="ClassroomMember.unban_user"
              defaultMessage="Unban user"
            />
          ) : (
            <FormattedMessage
              id="ClassroomMember.kick_user"
              defaultMessage="Remove user"
            />
          )}
        </DropdownLink>
        {level !== 'BANNED' && (
          <DropdownLink href="#" color="red" onClick={this.onBan}>
            <FormattedMessage
              id="ClassroomMember.ban_user"
              defaultMessage="Permanently ban user"
            />
          </DropdownLink>
        )}
      </>
    );
  }

  render() {
    const { classroomMembership, canEdit } = this.props;
    const { isLoading } = this.state;
    return (
      <span className="dib mr2 pa2 bg-white" data-testid="ClassroomMember">
        {(classroomMembership.level === 'CREATOR' ||
          classroomMembership.level === 'ADMIN') && (
          <span className="f6 white mr2 ph1 br1 bg-blue">admin</span>
        )}
        <Link
          className="link"
          to={`/profile/${classroomMembership.user.username}`}
        >
          {classroomMembership.user.username}
        </Link>
        {canEdit && (
          <Dropdown>
            <DropdownTrigger>
              <span className="ml2">
                <Button
                  size="small"
                  outline
                  phantomOutline
                  loading={isLoading}
                  data-testid="ClassroomMember-dropdownTrigger"
                >
                  <i className="fas fa-cog" />
                </Button>
              </span>
            </DropdownTrigger>
            <DropdownContent>
              <DropdownBackground width={200}>
                {this.renderDropdownContent()}
              </DropdownBackground>
            </DropdownContent>
          </Dropdown>
        )}
      </span>
    );
  }
}

export default injectIntl(
  createFragmentContainer(ClassroomMember, {
    classroomMembership: graphql`
      fragment ClassroomMember_classroomMembership on ClassroomMembership {
        id
        level
        user {
          username
        }
        classroom {
          id
        }
      }
    `,
  }),
);
