import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { DeleteClassroomMembershipMutation } from './__generated__/DeleteClassroomMembershipMutation.graphql';
const mutation = graphql`
  mutation DeleteClassroomMembershipMutation(
    $input: DeleteClassroomMembershipInput!
  ) {
    deleteClassroomMembership(input: $input) {
      id
      classroom {
        id
        numActiveMemberships
      }
      viewer {
        ...DashboardLayout_viewer
      }
    }
  }
`;

const commit = (environment: any, id: string, classroomId: string) =>
  commitMutationPromise<DeleteClassroomMembershipMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    },
    configs: [
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'id',
      },
      {
        type: 'RANGE_DELETE',
        parentID: classroomId,
        connectionKeys: [
          {
            key: 'ActiveMemberships_activeMemberships',
          },
        ],
        pathToConnection: ['classroom', 'activeClassroomMemberships'],
        deletedIDFieldName: 'id',
      },
    ],
  });

export default {
  commit,
};
