import React, { Component, FormEvent } from 'react';
import { Environment } from 'react-relay';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import ResetPasswordMutation from '../../mutations/ResetPasswordMutation';
import Button from '../common/Button';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
type State = {
  password: string;
  error: string | undefined | null;
  isDone: boolean;
  isSubmitting: boolean;
};
type Props = {
  intl: IntlShape;
  environment: Environment;
  token: string;
  background: 'white' | 'gray';
};
const messages = defineMessages({
  missingPasswordError: {
    id: 'ResetPasswordForm.missing_password_error',
    defaultMessage: 'Enter your password',
  },
});

class ResetPasswordForm extends Component<Props, State> {
  state: State = {
    password: '',
    error: null,
    isDone: false,
    isSubmitting: false,
  };
  static defaultProps: Partial<Props> = {
    background: 'white',
  };
  onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { password } = this.state;
    const { formatMessage } = this.props.intl;

    if (!password) {
      return this.setState({
        error: formatMessage(messages.missingPasswordError),
      });
    }

    this.setState({
      error: null,
    });

    try {
      this.setState({
        isSubmitting: true,
      });
      await ResetPasswordMutation.commit(
        this.props.environment,
        this.props.token,
        password,
      );
      this.setState({
        isDone: true,
        isSubmitting: false,
      });
    } catch (err) {
      this.setState({
        error: err.message,
        isSubmitting: false,
      });
    }
  };

  renderDone() {
    return (
      <div className="tc">
        <h3 className="f4">
          <FormattedMessage
            id="ResetPasswordForm.reset_successful_header"
            defaultMessage="Success!"
          />
        </h3>
        <p className="mv4 f6">
          <FormattedMessage
            id="ResetPasswordForm.reset_successful_details"
            defaultMessage="Your password has been reset"
          />
        </p>
        <Button fullWidth component={Link} to="/log-in">
          <FormattedMessage
            id="ResetPasswordForm.log_in_button"
            defaultMessage="Log in"
          />
        </Button>
      </div>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit} className="ResetPasswordForm">
        {!this.state.error ? null : (
          <div className="pa3 mb4 red ba b--light-red">{this.state.error}</div>
        )}
        <FormGroup
          label={
            <FormattedMessage
              id="ResetPasswordForm.new_password_label"
              defaultMessage="New password"
            />
          }
        >
          <Input
            required
            type="password"
            id="password"
            value={this.state.password}
            onChange={evt =>
              this.setState({
                password: evt.target.value,
              })
            }
          />
        </FormGroup>

        <Button type="submit" fullWidth disabled={this.state.isSubmitting}>
          {this.state.isSubmitting ? (
            <FormattedMessage
              id="ResetPasswordForm.submitting"
              defaultMessage="Submitting..."
            />
          ) : (
            <FormattedMessage
              id="ResetPasswordForm.reset_password_button"
              defaultMessage="Reset password"
            />
          )}
        </Button>
      </form>
    );
  }

  render() {
    return this.state.isDone ? this.renderDone() : this.renderForm();
  }
}

export default injectIntl(ResetPasswordForm);
