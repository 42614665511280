import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';

import { WordOrderWidget_exercise } from './__generated__/WordOrderWidget_exercise.graphql';
import { shuffleArray } from '../../lib/utils';
import { checkAnswer } from '../../lib/atomHelpers';
import WordPart from '../common/WordPart';
import Button from '../common/Button';
import CorrectAnswerModal from '../common/CorrectAnswerModal';
import WordOrderPositioner from '../common/WordOrderPositioner';
import './index.css';

type Props = {
  hasNext: boolean;
  allowedMistakes: number;
  exercise: WordOrderWidget_exercise;
  onCorrect?: (answer: string[]) => void;
  onMistake?: (answer: string[]) => void;
  onDone: () => void;
};

type State = {
  wordPartPositions: number[];
  scrambledParts: null | string[];
  buttonState: 'correct' | 'incorrect' | 'default';
  isComplete: boolean;
  numMistakes: number;
  lastResponse: null | string;
};

class WordOrderWidget extends Component<Props, State> {
  state: State = {
    wordPartPositions: [],
    scrambledParts: null,
    buttonState: 'default',
    isComplete: false,
    numMistakes: 0,
    lastResponse: null,
  };

  static defaultProps = {
    hasNext: false,
    allowedMistakes: 0,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      prevState.scrambledParts !== nextProps.exercise.content.scrambledParts
    ) {
      const wordPartPositions = shuffleArray(
        nextProps.exercise.content.scrambledParts.map((item, index) => index),
      );
      return {
        wordPartPositions,
        wordPartBounds: {},
        scrambledParts: nextProps.exercise.content.scrambledParts,
      };
    }
    return null;
  }

  onChangeWordPositions = (wordPartPositions: number[]) => {
    this.setState({
      wordPartPositions,
      buttonState: 'default',
      lastResponse: null,
    });
  };

  onCheckResponse = () => {
    const response = this.getResponse();
    const { onMistake, onCorrect } = this.props;
    if (response === '') return;
    const isCorrect = checkAnswer(
      response,
      this.props.exercise.content.correctAnswers.slice(),
    );
    if (isCorrect) {
      this.setState({ buttonState: 'correct', isComplete: true });
      if (onCorrect) onCorrect(this.getResponseParts());
    } else {
      this.setState({
        buttonState: 'incorrect',
        numMistakes: this.state.numMistakes + 1,
        lastResponse: response,
      });
      if (onMistake) onMistake(this.getResponseParts());
    }
  };

  getResponse() {
    return this.getResponseParts()
      .join('')
      .trim();
  }

  getResponseParts() {
    const scrambledParts = this.state.scrambledParts || [];
    return this.state.wordPartPositions.map(item => scrambledParts[item]);
  }

  getButtonType() {
    if (this.state.buttonState === 'default') return 'default';
    return this.state.buttonState === 'correct' ? 'success' : 'failure';
  }

  getButtonText() {
    if (this.state.buttonState === 'default') {
      return (
        <FormattedMessage
          id="WordOrderWidget.check_answer"
          defaultMessage="Check answer"
        />
      );
    }
    if (this.state.buttonState === 'correct') {
      if (this.props.hasNext) {
        return (
          <FormattedMessage
            id="WordOrderWidget.correct_next_exercise"
            defaultMessage=":D Correct! Next exercise →"
          />
        );
      } else {
        return (
          <FormattedMessage
            id="WordOrderWidget.correct_finish"
            defaultMessage=":D Correct! Finish →"
          />
        );
      }
    }
    return (
      <FormattedMessage
        id="WordOrderWidget.incorrect"
        defaultMessage=":( Incorrect"
      />
    );
  }

  render() {
    return (
      <div className="WordOrderWidget">
        <div className="WordOrderWidget-response">
          <div className="mb2 gray tl" style={{ marginLeft: '10px' }}>
            <FormattedMessage
              id="WordOrderWidget.instructions"
              defaultMessage="Drag the words below into a correct sentence"
            />
          </div>
          <WordOrderPositioner
            container={minHeight => (
              <div
                className="WordOrderWidget-wordPartPositions"
                style={{ height: minHeight }}
              >
                <div className="WordOrderWidget-line"></div>
                <div className="WordOrderWidget-line"></div>
              </div>
            )}
            wordPositions={this.state.wordPartPositions}
            words={this.props.exercise.content.scrambledParts.map(
              (text, itemPos) => (
                <WordPart text={text} />
              ),
            )}
            onChangeWordPositions={this.onChangeWordPositions}
          />
        </div>
        <div className="WordOrderWidget-checkResponse">
          <Button
            fullWidth
            size="large"
            buttonType={this.getButtonType()}
            disabled={
              !this.state.isComplete &&
              this.state.lastResponse === this.getResponse()
            }
            onClick={() =>
              this.state.isComplete
                ? this.props.onDone()
                : this.onCheckResponse()
            }
          >
            {this.getButtonText()}
          </Button>
        </div>
        <CorrectAnswerModal
          isOpen={this.state.numMistakes > this.props.allowedMistakes}
          correctAnswer={this.props.exercise.content.correctAnswers[0]}
          onDone={this.props.onDone}
        />
      </div>
    );
  }
}

export default createFragmentContainer(WordOrderWidget, {
  exercise: graphql`
    fragment WordOrderWidget_exercise on WordOrderExercise {
      content {
        correctAnswers
        scrambledParts
      }
    }
  `,
});
