import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../../environment';
import NavHeader from '../../components/NavHeader';
import AdminHeader from '../../components/AdminHeader';
import Loading from '../../components/common/Loading';
import AdminWorksheetsList from '../../components/admin/AdminWorksheetsList';
import { AdminWorksheetsPageQuery } from './__generated__/AdminWorksheetsPageQuery.graphql';
import Toggle from 'react-toggle';

const adminWorksheetsPageQuery = graphql`
  query AdminWorksheetsPageQuery($publishedOnly: Boolean) {
    viewer {
      ...NavHeader_viewer
      user {
        isAdmin
      }
    }
    admin {
      ...AdminWorksheetsList_admin @arguments(publishedOnly: $publishedOnly)
    }
  }
`;

const AdminWorksheetsPage = () => {
  const [publishedOnly, setPublishedOnly] = useState(false);

  const renderFilters = () => (
    <div className="flex items-center mt3 ba b--black-10 pa2">
      <div className="tl flex items-center">
        <Toggle
          icons={false}
          onChange={evt => setPublishedOnly(evt.target.checked)}
          checked={publishedOnly}
        />
        <span className="pl2" onClick={() => setPublishedOnly(!publishedOnly)}>
          Published only
        </span>
      </div>
    </div>
  );

  return (
    <QueryRenderer<AdminWorksheetsPageQuery>
      environment={environment}
      query={adminWorksheetsPageQuery}
      variables={{
        publishedOnly,
      }}
      render={({ error, props }) => {
        const renderWorksheetsListContents = () => {
          if (error) {
            return <div>{error.message}</div>;
          }

          if (!props) return <Loading />;

          if (
            !props.viewer ||
            !props.viewer.user ||
            !props.viewer.user.isAdmin
          ) {
            return <Redirect to="/" />;
          }
          return (
            <AdminWorksheetsList
              admin={
                props.admin as NonNullable<
                  AdminWorksheetsPageQuery['response']['admin']
                >
              }
              publishedOnly={publishedOnly}
            />
          );
        };

        return (
          <div className="AdminWorksheetsPage">
            <NavHeader viewer={props?.viewer} />
            <div className="mw8 center">
              <AdminHeader activeTab="worksheets" />
              {renderFilters()}
              {renderWorksheetsListContents()}
            </div>
          </div>
        );
      }}
    />
  );
};

export default AdminWorksheetsPage;
