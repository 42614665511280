/* tslint:disable */
/* eslint-disable */
/* @relayHash 3e765b638312dddd49f3c6ff7e7d4940 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteClassroomWorksheetInput = {
    id: string;
    clientMutationId?: string | null;
};
export type DeleteClassroomWorksheetMutationVariables = {
    input: DeleteClassroomWorksheetInput;
};
export type DeleteClassroomWorksheetMutationResponse = {
    readonly deleteClassroomWorksheet: {
        readonly id: string;
        readonly classroom: {
            readonly id: string;
            readonly numClassroomWorksheets: number;
        } | null;
    } | null;
};
export type DeleteClassroomWorksheetMutation = {
    readonly response: DeleteClassroomWorksheetMutationResponse;
    readonly variables: DeleteClassroomWorksheetMutationVariables;
};



/*
mutation DeleteClassroomWorksheetMutation(
  $input: DeleteClassroomWorksheetInput!
) {
  deleteClassroomWorksheet(input: $input) {
    id
    classroom {
      id
      numClassroomWorksheets
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteClassroomWorksheetInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteClassroomWorksheet",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteClassroomWorksheetPayload",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "classroom",
        "storageKey": null,
        "args": null,
        "concreteType": "Classroom",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numClassroomWorksheets",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteClassroomWorksheetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteClassroomWorksheetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteClassroomWorksheetMutation",
    "id": null,
    "text": "mutation DeleteClassroomWorksheetMutation(\n  $input: DeleteClassroomWorksheetInput!\n) {\n  deleteClassroomWorksheet(input: $input) {\n    id\n    classroom {\n      id\n      numClassroomWorksheets\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b3aba5d076e2e3d70be3277172306bcc';
export default node;
