import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { BanClassroomMembershipMutation } from './__generated__/BanClassroomMembershipMutation.graphql';
const mutation = graphql`
  mutation BanClassroomMembershipMutation(
    $input: BanClassroomMembershipInput!
  ) {
    banClassroomMembership(input: $input) {
      classroomBannedClassroomMembershipsEdge {
        node {
          id
          level
          classroom {
            id
            numActiveMemberships
          }
        }
      }
    }
  }
`;

const commit = (environment: any, id: string, classroomId: string) =>
  commitMutationPromise<BanClassroomMembershipMutation>(environment, {
    mutation,
    variables: {
      input: {
        classroomMembershipId: id,
      },
    },
    configs: [
      {
        type: 'RANGE_DELETE',
        parentID: classroomId,
        connectionKeys: [
          {
            key: 'ActiveMemberships_activeMemberships',
          },
        ],
        pathToConnection: ['classroom', 'activeClassroomMemberships'],
        deletedIDFieldName: ['classroomBannedClassroomMembershipsEdge', 'node'],
      },
      {
        type: 'RANGE_ADD',
        parentID: classroomId,
        connectionInfo: [
          {
            key: 'BannedMemberships_bannedMemberships',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'classroomBannedClassroomMembershipsEdge',
      },
    ],
  });

export default {
  commit,
};
