import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import DashboardFeed from '../components/DashboardFeed';
import PageLoading from '../components/common/PageLoading';
import { DashboardPage_viewerQuery } from './__generated__/DashboardPage_viewerQuery.graphql';
import DashboardLayout from '../components/DashboardLayout';

const dashboardPage_viewerQuery = graphql`
  query DashboardPage_viewerQuery {
    viewer {
      ...NavHeader_viewer
      ...PageLoading_viewer
      ...DashboardFeed_viewer
      ...DashboardLayout_viewer
      user {
        id
      }
    }
  }
`;

const DashboardPage = () => (
  <QueryRenderer<DashboardPage_viewerQuery>
    environment={environment}
    variables={{}}
    query={dashboardPage_viewerQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props || !props.viewer) {
        return <PageLoading viewer={props?.viewer} />;
      }

      if (props && (!props.viewer.user || !props.viewer.user.id)) {
        return <Redirect to="/" />;
      }

      return (
        <div>
          <NavHeader viewer={props.viewer} />
          <div className="mw8 center">
            <DashboardLayout activeTab="overview" viewer={props.viewer}>
              <DashboardFeed viewer={props.viewer} />
            </DashboardLayout>
          </div>
        </div>
      );
    }}
  />
);

export default DashboardPage;
