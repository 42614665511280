import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  UpdateClassroomMutation,
  UpdateClassroomInput,
} from './__generated__/UpdateClassroomMutation.graphql';
const mutation = graphql`
  mutation UpdateClassroomMutation($input: UpdateClassroomInput!) {
    updateClassroom(input: $input) {
      classroom {
        ...ClassroomViewer_classroom
      }
      # To reload the dashboard / mobile menu classes list
      viewer {
        ...DashboardLayout_viewer
        ...NavHeader_viewer
      }
    }
  }
`;

const commit = (environment: any, input: UpdateClassroomInput) =>
  commitMutationPromise<UpdateClassroomMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default {
  commit,
};
