/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Worksheet_worksheet = {
    readonly title: string | null;
    readonly description: string | null;
    readonly identifier: string | null;
    readonly " $refType": "Worksheet_worksheet";
};
export type Worksheet_worksheet$data = Worksheet_worksheet;
export type Worksheet_worksheet$key = {
    readonly " $data"?: Worksheet_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"Worksheet_worksheet">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "Worksheet_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '598176df2e99d131901b75bf5b038ebc';
export default node;
