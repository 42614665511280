/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WORKSHEET_VISIBILITY = "EVERYONE" | "LOGGED_IN" | "PASSWORD" | "%future added value";
export type WorksheetViewer_worksheet = {
    readonly id: string;
    readonly title: string | null;
    readonly description: string | null;
    readonly identifier: string | null;
    readonly password: string | null;
    readonly isPublished: boolean;
    readonly isVisibleToViewer: boolean;
    readonly isEditableByViewer: boolean;
    readonly visibility: WORKSHEET_VISIBILITY;
    readonly creator: {
        readonly id: string;
    } | null;
    readonly exercises: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ExerciseSummary_exercise">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetAnalysisTab_worksheet" | "WorksheetResultsTab_worksheet" | "WorksheetStudiersTab_worksheet">;
    readonly " $refType": "WorksheetViewer_worksheet";
};
export type WorksheetViewer_worksheet$data = WorksheetViewer_worksheet;
export type WorksheetViewer_worksheet$key = {
    readonly " $data"?: WorksheetViewer_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetViewer_worksheet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "WorksheetViewer_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "password",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isPublished",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isVisibleToViewer",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isEditableByViewer",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "visibility",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "creator",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercises",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "ExerciseSummary_exercise",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "WorksheetAnalysisTab_worksheet",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "WorksheetResultsTab_worksheet",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "WorksheetStudiersTab_worksheet",
      "args": null
    }
  ]
};
})();
(node as any).hash = '3508dac80562fd2de883dbfeb0026b0d';
export default node;
