/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AnswerDetails_answer = {
    readonly isCorrect: boolean | null;
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"VocabAnswerDetails_answer" | "WordOrderAnswerDetails_answer" | "TranslationAnswerDetails_answer" | "MultipleChoiceAnswerDetails_answer">;
    readonly " $refType": "AnswerDetails_answer";
};
export type AnswerDetails_answer$data = AnswerDetails_answer;
export type AnswerDetails_answer$key = {
    readonly " $data"?: AnswerDetails_answer$data;
    readonly " $fragmentRefs": FragmentRefs<"AnswerDetails_answer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "AnswerDetails_answer",
  "type": "Answer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCorrect",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "VocabAnswer",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "VocabAnswerDetails_answer",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "WordOrderAnswer",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "WordOrderAnswerDetails_answer",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "TranslationAnswer",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "TranslationAnswerDetails_answer",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "MultipleChoiceAnswer",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "MultipleChoiceAnswerDetails_answer",
          "args": null
        }
      ]
    }
  ]
};
(node as any).hash = '433c27a20197e7c5eab1b72bf250410f';
export default node;
