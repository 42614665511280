import React, { Component, ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';

import { removeArrayItem, arrayShallowEquals } from '../../lib/utils';
import MeasurableDiv from './MeasurableDiv';
import { bounds } from './MeasurableDiv';
import DraggableDiv from './DraggableDiv';
import { positionDelta } from './DraggableDiv';

import './TranslatorWordPositioner.css';

type position = {
  x: number;
  y: number;
};

type Props = {
  widgetPadding: number;
  wordPartSpacing: number;
  padWordBank: boolean;

  words: ReactNode[];
  chosenItems: number[];
  chosenItemsContainer: (container: number) => ReactNode;
  wordBankContainer: (container: number) => ReactNode;
  onChangeChosenItems: (items: number[]) => void;
  wordBankLabel?: ReactNode;
};

type State = {
  chosenItemsBounds: bounds | null;
  wordBankLabelBounds: bounds | null;
  draggingItem: number | null;
  draggingItemPosDelta: positionDelta | null;
  wordPartBounds: { [key: number]: bounds };
};

const updateWordPartBounds = (itemPos: number, bounds: bounds) => (
  prevState: State,
  props: Props,
) => ({
  wordPartBounds: { ...prevState.wordPartBounds, [itemPos]: bounds },
});

class TranslatorWordPositioner extends Component<Props, State> {
  static defaultProps = {
    widgetPadding: 10,
    wordPartSpacing: 10,
    padWordBank: true,
  };

  state: State = {
    wordPartBounds: {},
    chosenItemsBounds: null,
    wordBankLabelBounds: null,
    draggingItem: null,
    draggingItemPosDelta: null,
  };

  getLineHeight(): number {
    return this.state.wordPartBounds[0].height;
  }

  getMinRequiredHeight() {
    /* Determine how tall the container must be to fit all the words */
    // TODO: have this take into account bad ordering of items too
    if (!this.boundsHaveLoaded()) return 0;
    const { widgetPadding, wordPartSpacing } = this.props;
    const positions = this.props.words.map((word, index) => index);
    const lastPos = positions[positions.length - 1];
    const { row } = this.getItemRowAndOffset(lastPos, positions.slice(0, -1));
    return (
      2 * widgetPadding +
      (row + 1) * this.getLineHeight() +
      row * wordPartSpacing
    );
  }

  onClickWordPart = (itemPos: number) => {
    const chosenItemsCopy = this.props.chosenItems.slice(0);
    if (this.isChosen(itemPos)) {
      const chosenIndex = this.props.chosenItems.indexOf(itemPos);
      chosenItemsCopy.splice(chosenIndex, 1);
    } else {
      // add to the end of the list
      chosenItemsCopy.push(itemPos);
    }
    this.props.onChangeChosenItems(chosenItemsCopy);
  };

  onBeginDragWord = (itemPos: number, posDelta: positionDelta) => {
    this.setState({ draggingItem: itemPos, draggingItemPosDelta: posDelta });
  };

  onContinueDragWord = (posDelta: positionDelta) => {
    this.setState({ draggingItemPosDelta: posDelta });
  };

  onEndDragWord = () => {
    const { chosenItemsBounds, draggingItem } = this.state;
    if (draggingItem == null || !chosenItemsBounds) return;
    let isDroppedOnChosenArea = false;
    const newChosenItems = removeArrayItem(
      this.props.chosenItems,
      draggingItem,
    );
    for (let i = 0; i < this.props.chosenItems.length - 1; i++) {
      if (this.isDraggedItemOnChosenItemNum(i)) {
        newChosenItems.splice(i, 0, draggingItem);
        isDroppedOnChosenArea = true;
        break;
      }
    }
    if (!isDroppedOnChosenArea) {
      const draggedItemCenter = this.getDraggedItemCenter();
      if (
        draggedItemCenter.x > 0 &&
        draggedItemCenter.x < chosenItemsBounds.width &&
        draggedItemCenter.y > 0 &&
        draggedItemCenter.y < chosenItemsBounds.height
      ) {
        newChosenItems.push(draggingItem);
      }
    }
    this.setState({
      draggingItem: null,
      draggingItemPosDelta: null,
    });
    if (!arrayShallowEquals(newChosenItems, this.props.chosenItems)) {
      this.props.onChangeChosenItems(newChosenItems);
    }
  };

  isChosen(itemPos: number) {
    return this.props.chosenItems.indexOf(itemPos) >= 0;
  }

  getWordBankItems(): number[] {
    const wordBankItems = [];
    for (let i = 0; i < this.props.words.length; i++) {
      if (!this.isChosen(i)) wordBankItems.push(i);
    }
    return wordBankItems;
  }

  getPreviousItems(
    itemPos: number,
    ignorePhantomItem: boolean = false,
  ): number[] {
    let items;
    const isItemChosen = this.isChosen(itemPos);

    if (isItemChosen) {
      items = this.props.chosenItems.slice(
        0,
        this.props.chosenItems.indexOf(itemPos),
      );
    } else {
      const wordBankItems = this.getWordBankItems();
      items = wordBankItems.slice(0, wordBankItems.indexOf(itemPos));
    }
    const draggingItem = this.state.draggingItem;
    if (draggingItem != null) {
      items = removeArrayItem(items, draggingItem);

      if (isItemChosen && draggingItem !== itemPos && !ignorePhantomItem) {
        for (let i = 0; i < items.length + 1; i++) {
          if (this.isDraggedItemOnChosenItemNum(i)) {
            items.splice(i, 0, draggingItem);
            break;
          }
        }
      }
    }
    return items;
  }

  getItemRowAndOffset(
    itemPos: number,
    previousItems: number[],
    padRows: boolean = true,
  ) {
    const { wordPartSpacing, widgetPadding } = this.props;
    const padding = padRows ? widgetPadding : 0;
    let row = 0;
    let offset = padding;
    const chosenItemsWidth =
      (this.state.chosenItemsBounds && this.state.chosenItemsBounds.width) || 0;
    previousItems.forEach(prevItemPos => {
      const bounds = this.state.wordPartBounds[prevItemPos];
      offset += bounds.width;
      if (offset > chosenItemsWidth - padding) {
        // wrap next line
        row += 1;
        offset = padding + bounds.width + wordPartSpacing;
      } else {
        offset += wordPartSpacing;
      }
    });

    const curItemBounds = this.state.wordPartBounds[itemPos];
    if (offset + curItemBounds.width > chosenItemsWidth - padding) {
      row += 1;
      offset = padding;
    }
    return { row, offset };
  }

  getChosenItemsHeightOffset() {
    if (!this.state.chosenItemsBounds) return 0;
    let offset = this.state.chosenItemsBounds.height;
    if (this.props.wordBankLabel && this.state.wordBankLabelBounds) {
      offset += this.state.wordBankLabelBounds.height;
    }
    return offset;
  }

  getItemPos(itemPos: number, ignorePhantomItem: boolean = false) {
    if (!this.boundsHaveLoaded()) return { x: 0, y: 0 };

    const isChosen = this.isChosen(itemPos);
    const usePadding = isChosen || this.props.padWordBank;
    const previousItems = this.getPreviousItems(itemPos, ignorePhantomItem);
    const { row, offset } = this.getItemRowAndOffset(
      itemPos,
      previousItems,
      usePadding,
    );

    let dragDelta: positionDelta = { xDelta: 0, yDelta: 0 };
    if (
      this.state.draggingItem === itemPos &&
      this.state.draggingItemPosDelta
    ) {
      dragDelta = this.state.draggingItemPosDelta;
    }

    return {
      x: offset + dragDelta.xDelta,
      y:
        this.props.widgetPadding +
        row * (this.getLineHeight() + this.props.wordPartSpacing) +
        (isChosen ? 0 : this.getChosenItemsHeightOffset()) +
        dragDelta.yDelta,
    };
  }

  isDraggedItemOnChosenItemNum(chosenItemNum: number) {
    const { widgetPadding, wordPartSpacing } = this.props;
    const draggingItem = this.state.draggingItem;
    if (draggingItem == null) return false;
    const remainingChosenItemPositions = removeArrayItem(
      this.props.chosenItems,
      draggingItem,
    );
    const chosenItemAtPos = remainingChosenItemPositions[chosenItemNum];
    const chosenItemPos = this.getItemPos(chosenItemAtPos, true);
    const chosenItemBounds = this.state.wordPartBounds[chosenItemAtPos];

    const draggedItemCenter = this.getDraggedItemCenter();
    const isFirstOnLine = chosenItemPos.x === widgetPadding;
    // for the first word, give the user leeway if they drag off the page slightly
    // TODO: this looks like a logic error?
    const leftHorizLeniency = (isFirstOnLine as any) === 0 ? 70 : 0;
    const isDraggedOnItem =
      draggedItemCenter.x >=
        chosenItemPos.x - wordPartSpacing / 2 - leftHorizLeniency &&
      draggedItemCenter.x <
        chosenItemPos.x + chosenItemBounds.width + wordPartSpacing / 2 &&
      draggedItemCenter.y >= chosenItemPos.y - wordPartSpacing / 2 &&
      draggedItemCenter.y <
        chosenItemPos.y + chosenItemBounds.height + wordPartSpacing / 2;
    if (isDraggedOnItem) return true;

    // if this is the first word on a line, check if we're not dragging over the end of
    // the previous line. That's also a reasonable place for this item.
    if (isFirstOnLine && chosenItemNum > 0) {
      const itemAtPrevPos = remainingChosenItemPositions[chosenItemNum - 1];
      const prevItemPos = this.getItemPos(itemAtPrevPos, true);
      const prevItemBounds = this.state.wordPartBounds[itemAtPrevPos];
      const prevItemXBound =
        prevItemPos.x + prevItemBounds.width + wordPartSpacing / 2;
      return (
        draggedItemCenter.x >= prevItemXBound &&
        draggedItemCenter.x < prevItemXBound + 70 &&
        draggedItemCenter.y >= prevItemPos.y - wordPartSpacing / 2 &&
        draggedItemCenter.y <
          prevItemPos.y + prevItemBounds.height + wordPartSpacing / 2
      );
    }
    return false;
  }

  getDraggedItemCenter(): position {
    const draggingItem = this.state.draggingItem;
    if (draggingItem == null) return { x: 0, y: 0 };
    const draggedItemBounds = this.state.wordPartBounds[draggingItem];
    const draggedItemPos = this.getItemPos(draggingItem, true);
    return {
      x: draggedItemPos.x + draggedItemBounds.width / 2,
      y: draggedItemPos.y + draggedItemBounds.height / 2,
    };
  }

  boundsHaveLoaded() {
    if (!this.state.chosenItemsBounds) return false;
    for (let i = 0; i < this.props.words.length; i++) {
      if (!this.state.wordPartBounds[i]) return false;
    }
    return true;
  }

  getWordPartStyle(itemPos: number): CSSProperties {
    if (!this.boundsHaveLoaded()) {
      return { visibility: 'hidden' };
    }
    const { x, y } = this.getItemPos(itemPos);
    return { transform: `translate3d(${x}px, ${y}px, 0)` };
  }

  render() {
    const minRequiredHeight = this.getMinRequiredHeight();
    return (
      <div className="TranslatorWordPositioner">
        <MeasurableDiv
          className="TranslatorWordPositioner-chosenItems"
          onUpdateBounds={bounds =>
            this.setState({ chosenItemsBounds: bounds })
          }
        >
          {this.props.chosenItemsContainer(minRequiredHeight)}
        </MeasurableDiv>
        {!this.props.wordBankLabel ? null : (
          <MeasurableDiv
            onUpdateBounds={bounds =>
              this.setState({ wordBankLabelBounds: bounds })
            }
          >
            {this.props.wordBankLabel}
          </MeasurableDiv>
        )}
        <div className="TranslatorWordPositioner-wordBank">
          {this.props.wordBankContainer(minRequiredHeight)}
        </div>

        {this.props.words.map((word, itemPos) => (
          <DraggableDiv
            className={classNames('TranslatorWordPositioner-wordPart', {
              'is-dragging': this.state.draggingItem === itemPos,
            })}
            key={itemPos}
            style={this.getWordPartStyle(itemPos)}
            onClick={() => this.onClickWordPart(itemPos)}
            onBeginDrag={posDelta => this.onBeginDragWord(itemPos, posDelta)}
            onContinueDrag={posDelta => this.onContinueDragWord(posDelta)}
            onEndDrag={this.onEndDragWord}
          >
            <MeasurableDiv
              onUpdateBounds={bounds =>
                this.setState(updateWordPartBounds(itemPos, bounds))
              }
            >
              {word}
            </MeasurableDiv>
          </DraggableDiv>
        ))}
      </div>
    );
  }
}

export default TranslatorWordPositioner;
