import React, { Component } from 'react';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  defineMessages,
} from 'react-intl';
import { History } from 'history';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
  // @ts-ignore
} from 'react-simple-dropdown';
import * as Sentry from '@sentry/browser';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ClassroomActions_viewer } from './__generated__/ClassroomActions_viewer.graphql';
import { ClassroomActions_classroom } from './__generated__/ClassroomActions_classroom.graphql';
import Button from '../common/Button';
import DropdownLink from '../common/DropdownLink';
import DropdownBackground from '../common/DropdownBackground';
import Loading from '../common/Loading';
import DeleteClassroomMutation from '../../mutations/DeleteClassroomMutation';
import DeleteClassroomMembershipMutation from '../../mutations/DeleteClassroomMembershipMutation';

interface ClassroomActionsProps extends RouteComponentProps {
  viewer: ClassroomActions_viewer;
  classroom: ClassroomActions_classroom;
  intl: IntlShape;
  canEdit: boolean;
  relay: RelayProp;
  history: History;
  onClickEdit: () => void;
  onClickJoin: () => void;
  onClickAddWorksheet: () => void;
}

interface ClassroomActionsState {
  isLoading: boolean;
}

const messages = defineMessages({
  classroomDeletedSuccessfully: {
    id: 'ClassroomActions.class_deleted_successfully',
    defaultMessage: 'Class was successfully deleted',
  },
  confirmDeleteClassroom: {
    id: 'ClassroomActions.confirm_delete_clasroom',
    defaultMessage:
      'Are you sure you want to delete this class? There is no undo.',
  },
  confirmLeaveClassroom: {
    id: 'ClassroomActions.confirm_leave_clasroom',
    defaultMessage: 'Are you sure you want to leave this class?',
  },
  leftClassroomSuccessfully: {
    id: 'ClassroomActions.left_class_successfully',
    defaultMessage: 'Left class successfully',
  },
});

class ClassroomActions extends Component<
  ClassroomActionsProps,
  ClassroomActionsState
> {
  state: ClassroomActionsState = {
    isLoading: false,
  };

  getCurrentMembership() {
    const { classroom, viewer } = this.props;
    return (viewer.user?.classroomMemberships || []).find(
      membership => membership?.classroom.id === classroom.id,
    );
  }

  onLeaveClass = async () => {
    const { relay, classroom, history, intl } = this.props;
    const membership = this.getCurrentMembership();
    if (
      this.state.isLoading ||
      !membership ||
      !window.confirm(intl.formatMessage(messages.confirmLeaveClassroom))
    ) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await DeleteClassroomMembershipMutation.commit(
        relay.environment,
        membership?.id,
        classroom.id,
      );
      // redirect to homepage with flash
      history.push('/dashboard', {
        flash: intl.formatMessage(messages.leftClassroomSuccessfully),
      });
    } catch (err) {
      this.setState({ isLoading: false });
      Sentry.captureException(err);
      // window.Rollbar.error('Error deleting classroom membership', err);
      window.alert(err.message || 'Unable to leave class');
    }
  };

  onDeleteClass = async () => {
    const { relay, classroom, history, intl } = this.props;
    if (
      this.state.isLoading ||
      !window.confirm(intl.formatMessage(messages.confirmDeleteClassroom))
    ) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await DeleteClassroomMutation.commit(relay.environment, classroom.id);
      // redirect to homepage with flash
      history.push('/dashboard', {
        flash: intl.formatMessage(messages.classroomDeletedSuccessfully),
      });
    } catch (err) {
      this.setState({ isLoading: false });
      Sentry.captureException(err);
      // window.Rollbar.error('Error deleting classroom', err);
      window.alert(err.message || 'Unable to delete class');
    }
  };

  renderDropdownContents() {
    const { canEdit, onClickEdit } = this.props;
    if (this.state.isLoading) return <Loading />;
    return (
      <div>
        <DropdownLink color="red" onClick={this.onLeaveClass}>
          <FormattedMessage
            id="ClassroomActions.leave_class"
            defaultMessage="Leave class"
          />
        </DropdownLink>
        {canEdit && (
          <>
            <DropdownLink onClick={onClickEdit}>
              <FormattedMessage
                id="ClassroomActions.edit_class"
                defaultMessage="Edit class"
              />
            </DropdownLink>
            <DropdownLink color="red" onClick={this.onDeleteClass}>
              <FormattedMessage
                id="ClassroomActions.delete_class"
                defaultMessage="Delete class"
              />
            </DropdownLink>
          </>
        )}
      </div>
    );
  }

  render() {
    const { canEdit, onClickJoin, onClickAddWorksheet } = this.props;
    const { isLoading } = this.state;
    const membership = this.getCurrentMembership();
    return (
      <div className="ClassroomActions">
        {!membership && (
          <span className="dn dib-l ml2">
            <Button size="medium" onClick={onClickJoin}>
              <i className="fa fa-plus mr2" />
              <FormattedMessage
                id="ClassroomActions.join_button"
                defaultMessage="Join class"
              />
            </Button>
          </span>
        )}

        {canEdit && (
          <span className="dn dib-l ml2">
            <Button
              buttonType="default"
              id="addWorksheetsToClassroomButton"
              size="medium"
              onClick={onClickAddWorksheet}
            >
              <i className="fas fa-plus mr2" />
              <FormattedMessage
                id="ClassroomActions.add_wordsheet"
                defaultMessage="Add a wordsheet"
              />
            </Button>
          </span>
        )}
        {membership && membership.level !== 'BANNED' && (
          <span className="dib ml2">
            <Dropdown>
              <DropdownTrigger>
                <Button
                  buttonType="gray"
                  size="medium"
                  data-testid="ClassroomActions-dropdownTrigger"
                  outline
                  loading={isLoading}
                  disabled={isLoading}
                >
                  <i className="fas fa-cog" />
                </Button>
              </DropdownTrigger>
              <DropdownContent>
                <DropdownBackground>
                  {this.renderDropdownContents()}
                </DropdownBackground>
              </DropdownContent>
            </Dropdown>
          </span>
        )}
      </div>
    );
  }
}

export default withRouter(
  injectIntl(
    createFragmentContainer(ClassroomActions, {
      viewer: graphql`
        fragment ClassroomActions_viewer on Viewer {
          user {
            isAdmin
            classroomMemberships {
              id
              level
              classroom {
                id
              }
            }
          }
        }
      `,
      classroom: graphql`
        fragment ClassroomActions_classroom on Classroom {
          id
        }
      `,
    }),
  ),
);
