import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CompleteStudyRecordMutation } from './__generated__/CompleteStudyRecordMutation.graphql';
const mutation = graphql`
  mutation CompleteStudyRecordMutation($input: CompleteStudyRecordInput!) {
    completeStudyRecord(input: $input) {
      studyRecord {
        identifier
      }
    }
  }
`;

const commit = (environment: any, id: string) =>
  commitMutationPromise<CompleteStudyRecordMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    },
  });

export default {
  commit,
};
