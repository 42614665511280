import React, { FC, ReactNode } from 'react';

interface DropdownBackgroundProps {
  children: ReactNode;
  width?: number;
}

const DropdownBackground: FC<DropdownBackgroundProps> = ({
  children,
  width = 120,
}) => (
  <div
    className="DropdownBackground bg-white shadow-1 tc"
    style={{ width: `${width}px` }}
  >
    {children}
  </div>
);

export default DropdownBackground;
