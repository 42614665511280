import React, { Component, FormEvent } from 'react';
import FormGroup from '../common/FormGroup';
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';
import './BillingForm.css';
import Input from '../common/Input';
import Button from '../common/Button';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { BillingForm_subscriptionPlan } from './__generated__/BillingForm_subscriptionPlan.graphql';
import PurchaseUpgradeSubscriptionMutation from '../../mutations/PurchaseUpgradeSubscriptionMutation';

interface BillingFormBaseProps {
  subscriptionPlan: BillingForm_subscriptionPlan;
  relay: RelayProp;
}

type BillingFormProps = BillingFormBaseProps &
  ReactStripeElements.InjectedStripeProps;

interface BillingFormState {
  name: string;
  error: string | null;
  isSubmitting: boolean;
}

class BillingForm extends Component<BillingFormProps, BillingFormState> {
  state: BillingFormState = {
    name: '',
    error: null,
    isSubmitting: false,
  };

  handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (!this.state.name) {
      return this.setState({ error: 'Name is empty' });
    }
    const { stripe, relay, subscriptionPlan } = this.props;
    if (!stripe || this.state.isSubmitting) return;
    this.setState({ error: null, isSubmitting: true });
    try {
      const { token, error } = await stripe.createToken({
        type: 'card',
        name: this.state.name,
      });
      if (error) {
        this.setState({ error: error.message || null });
      } else if (!token) {
        this.setState({ error: 'Payment failed. Please try again' });
      } else {
        // optimistically track here
        window.fbq('track', 'Purchase', {
          currency: 'USD',
          value: subscriptionPlan.price,
        });
        await PurchaseUpgradeSubscriptionMutation.commit(
          relay.environment,
          subscriptionPlan.id,
          token.id,
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      // window.Rollbar.error('Payment error', error);
      this.setState({ error: error.message });
    }
    this.setState({ isSubmitting: false });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup label="Name">
          <Input
            id="billingName"
            required={true}
            value={this.state.name}
            onChange={evt => this.setState({ name: evt.target.value })}
          />
        </FormGroup>
        <FormGroup label="Card">
          <CardElement
            id="cardNumberInput"
            className="BillingForm-input"
            style={{ base: { lineHeight: '24px' } }}
          />
        </FormGroup>
        {this.state.error && <div className="pa2 red">{this.state.error}</div>}
        <div className="mt5">
          <FormGroup>
            <Button
              id="checkoutButton"
              fullWidth
              type="submit"
              loading={this.state.isSubmitting}
              disabled={this.state.isSubmitting}
            >
              <FormattedMessage
                id="BillingForm.checkout"
                defaultMessage="Checkout"
              />
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

export default createFragmentContainer(
  injectStripe<BillingFormBaseProps>(BillingForm),
  {
    subscriptionPlan: graphql`
      fragment BillingForm_subscriptionPlan on SubscriptionPlan {
        id
        price
      }
    `,
  },
);
