/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmbedHeader_viewer = {
    readonly user: {
        readonly id: string;
        readonly username: string;
        readonly createdAt: number;
    } | null;
    readonly " $refType": "EmbedHeader_viewer";
};
export type EmbedHeader_viewer$data = EmbedHeader_viewer;
export type EmbedHeader_viewer$key = {
    readonly " $data"?: EmbedHeader_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"EmbedHeader_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "EmbedHeader_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'bfcaed0a1f295ae13ee93d5f7159cd07';
export default node;
