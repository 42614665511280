import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Environment } from 'react-relay';

import Modal from '../common/Modal';
import SignUpForm, { CONCRETE_ACCOUNT_TYPE } from '../auth/SignUpForm';
import LogInForm from '../auth/LogInForm';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  closeButton?: boolean;
  environment: Environment;
  onSuccess?: () => void;
  closeOnTapBackdrop?: boolean;
  oauthRedirect?: string;
  modalHeader: string | ReactNode;
  signUpReason: string | ReactNode;
  logInReason: string | ReactNode;
  probableAccountType?: CONCRETE_ACCOUNT_TYPE;
};

type State = {
  mode: 'signup' | 'login';
};

class SignUpModal extends Component<Props, State> {
  state: State = {
    mode: 'signup',
  };

  render() {
    const {
      environment,
      onSuccess = () => window.location.reload(),
      isOpen,
      onClose,
      oauthRedirect = window.location.pathname,
      closeButton,
      modalHeader,
      logInReason,
      signUpReason,
      closeOnTapBackdrop,
      probableAccountType,
    } = this.props;
    return (
      <Modal
        header={modalHeader}
        onClose={onClose}
        isOpen={isOpen}
        closeButton={closeButton}
        closeOnTapBackdrop={closeOnTapBackdrop}
      >
        {this.state.mode === 'signup' && (
          <>
            <h4 className="lh-title mt0 f4 tc">{signUpReason}</h4>
            <SignUpForm
              environment={environment}
              oauthRedirect={oauthRedirect}
              onSignUp={onSuccess}
              probableAccountType={probableAccountType}
              background="gray"
            />
            <div className="center pa3 mt2 tc gray">
              <FormattedMessage
                id="SignUpModal.switch_to_login"
                defaultMessage="Already have an account?"
              />
              {/* eslint-disable-next-line */}
              <a
                className="ml1 pointer"
                data-testid="SignUpModal-logInLink"
                onClick={() => this.setState({ mode: 'login' })}
              >
                <FormattedMessage
                  id="SignUpModal.login_link"
                  defaultMessage="Log in"
                />
              </a>
            </div>
          </>
        )}

        {this.state.mode === 'login' && (
          <>
            <h4 className="lh-title mt0 f4 tc">{logInReason}</h4>
            <LogInForm
              environment={environment}
              oauthRedirect={oauthRedirect}
              onLogIn={onSuccess}
              background="gray"
            />
            <div className="center pa3 mt2 tc gray">
              <FormattedMessage
                id="SignUpModal.switch_to_sign_up"
                defaultMessage="Don't have an account yet?"
              />
              {/* eslint-disable-next-line */}
              <a
                className="ml1 pointer"
                data-testid="SignUpModal-signUpLink"
                onClick={() => this.setState({ mode: 'signup' })}
              >
                <FormattedMessage
                  id="SignUpModal.sign_up_link"
                  defaultMessage="Sign up"
                />
              </a>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default SignUpModal;
