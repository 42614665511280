import React from 'react';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import ProfileViewer from '../components/ProfileViewer';
import PageLoading from '../components/common/PageLoading';
import NotFound from '../components/common/NotFound';
import { ProfilePageQuery } from './__generated__/ProfilePageQuery.graphql';

const profilePageQuery = graphql`
  query ProfilePageQuery($username: String!) {
    viewer {
      ...NavHeader_viewer
    }
    userByUsername(username: $username) {
      ...ProfileViewer_user
    }
  }
`;
type Props = {
  match: match<{ username: string }>;
};

const ProfilePage = ({ match }: Props) => (
  <QueryRenderer<ProfilePageQuery>
    environment={environment}
    query={profilePageQuery}
    variables={{
      username: match.params.username,
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props) return <PageLoading />;
      const header = <NavHeader viewer={props.viewer} />;
      if (!props.userByUsername) return <NotFound header={header} />;
      return (
        <div className="ProfilePage">
          {header}
          <div className="mw8 center">
            <ProfileViewer user={props.userByUsername} />
          </div>
        </div>
      );
    }}
  />
);

ProfilePage.defaultProps = {
  isDemo: false,
};
export default ProfilePage;
