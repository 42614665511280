import React, { Component, RefObject, HTMLProps } from 'react';
import { ReactNode } from 'react';

export type positionDelta = {
  xDelta: number;
  yDelta: number;
};

type position = {
  x: number;
  y: number;
};

const dist = (pos1: position, pos2: position) => {
  return Math.sqrt(Math.pow(pos2.x - pos1.x, 2) + Math.pow(pos2.y - pos1.y, 2));
};

interface Props extends HTMLProps<HTMLDivElement> {
  onBeginDrag?: (delta: positionDelta) => void;
  onContinueDrag?: (delta: positionDelta) => void;
  onEndDrag?: () => void;
  onClick?: () => void;
  children?: ReactNode;
}

type State = {
  dragStartTime: null | number;
  dragStartPos: null | position;
  dragCurrentPos: null | position;
};

const DRAG_TIME_THRESH = 300;
const DRAG_DIST_THRESH = 20;

const extractMouseEvtPos = (
  handler: (clientX: number, clientY: number) => void,
  preventDefault = false,
) => {
  return (evt: MouseEvent) => {
    if (preventDefault) evt.preventDefault();
    handler(evt.clientX, evt.clientY);
  };
};

const extractTouchEvtPos = (
  handler: (clientX: number, clientY: number) => void,
  preventDefault = false,
) => {
  return (evt: TouchEvent) => {
    if (preventDefault) evt.preventDefault();
    handler(evt.touches[0].clientX, evt.touches[0].clientY);
  };
};

class DraggableDiv extends Component<Props, State> {
  elm: RefObject<HTMLDivElement> = React.createRef();

  state: State = {
    dragStartTime: null,
    dragStartPos: null,
    dragCurrentPos: null,
  };

  getPositionDelta = (curX: number, curY: number) => ({
    xDelta:
      curX - ((this.state.dragStartPos && this.state.dragStartPos.x) || 0),
    yDelta:
      curY - ((this.state.dragStartPos && this.state.dragStartPos.y) || 0),
  });

  getDragDist() {
    if (!this.state.dragCurrentPos || !this.state.dragStartPos) return 0;
    return dist(this.state.dragStartPos, this.state.dragCurrentPos);
  }

  onDragDown = (clientX: number, clientY: number) => {
    this.setState({
      dragStartTime: performance.now(),
      dragStartPos: {
        x: clientX,
        y: clientY,
      },
      dragCurrentPos: {
        x: clientX,
        y: clientY,
      },
    });
    this.removeGlobalListeners();
    document.body.addEventListener('mousemove', this.onMouseMove);
    document.body.addEventListener('touchmove', this.onTouchMove);
    document.body.addEventListener('mouseup', this.onDragEnd);
    document.body.addEventListener('touchend', this.onDragEnd);
    if (this.props.onBeginDrag)
      this.props.onBeginDrag.call(null, { xDelta: 0, yDelta: 0 });
  };

  onDragMove = (clientX: number, clientY: number) => {
    if (!this.isDragging()) return;
    this.setState({ dragCurrentPos: { x: clientX, y: clientY } });
    if (this.props.onContinueDrag) {
      this.props.onContinueDrag.call(
        null,
        this.getPositionDelta(clientX, clientY),
      );
    }
  };

  onDragEnd = (evt: MouseEvent | TouchEvent) => {
    evt.preventDefault();
    this.removeGlobalListeners();
    if (!this.isDragging()) return;
    if (this.props.onEndDrag) {
      this.props.onEndDrag.call(null);
    }
    const dragTimeDelta = performance.now() - (this.state.dragStartTime || 0);
    const dragDistDelta = this.getDragDist();
    if (dragTimeDelta < DRAG_TIME_THRESH && dragDistDelta < DRAG_DIST_THRESH) {
      this.props.onClick && this.props.onClick();
    }
    this.setState({
      dragStartTime: null,
      dragStartPos: null,
      dragCurrentPos: null,
    });
  };

  onMouseDown = extractMouseEvtPos(this.onDragDown, true);
  onTouchStart = extractTouchEvtPos(this.onDragDown, true);
  onMouseMove = extractMouseEvtPos(this.onDragMove);
  onTouchMove = extractTouchEvtPos(this.onDragMove);

  isDragging() {
    return this.state.dragStartTime !== null;
  }

  removeGlobalListeners() {
    document.body.removeEventListener('mousemove', this.onMouseMove);
    document.body.removeEventListener('touchmove', this.onTouchMove);
    document.body.removeEventListener('mouseup', this.onDragEnd);
    document.body.removeEventListener('touchend', this.onDragEnd);
  }

  componentDidMount() {
    // need to attach touchstart here because of https://github.com/facebook/react/issues/8968
    if (this.elm.current) {
      this.elm.current.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
    }
  }

  render() {
    const {
      children,
      onBeginDrag,
      onContinueDrag,
      onEndDrag,
      onClick,
      ...otherProps
    } = this.props;
    return (
      <div
        ref={this.elm}
        onMouseDown={(evt: any) => this.onMouseDown(evt)}
        onMouseUp={evt => (this.isDragging() ? evt.stopPropagation() : null)}
        onTouchEnd={evt => (this.isDragging() ? evt.stopPropagation() : null)}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}

export default DraggableDiv;
