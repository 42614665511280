/* tslint:disable */
/* eslint-disable */
/* @relayHash 22db23b862f52e6808e40c3adcea8fe4 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BannedMembershipsQueryVariables = {
    classroomId: string;
    count: number;
    cursor?: string | null;
};
export type BannedMembershipsQueryResponse = {
    readonly classroom: {
        readonly " $fragmentRefs": FragmentRefs<"BannedMemberships_classroom">;
    } | null;
};
export type BannedMembershipsQuery = {
    readonly response: BannedMembershipsQueryResponse;
    readonly variables: BannedMembershipsQueryVariables;
};



/*
query BannedMembershipsQuery(
  $classroomId: ID!
  $count: Int!
  $cursor: String
) {
  classroom: node(id: $classroomId) {
    __typename
    ... on Classroom {
      ...BannedMemberships_classroom_1G22uz
    }
    id
  }
}

fragment BannedMemberships_classroom_1G22uz on Classroom {
  id
  bannedMemberships(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...ClassroomMember_classroomMembership
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ClassroomMember_classroomMembership on ClassroomMembership {
  id
  level
  user {
    username
    id
  }
  classroom {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "classroomId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "classroomId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BannedMembershipsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "classroom",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Classroom",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "BannedMemberships_classroom",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "count",
                    "variableName": "count"
                  },
                  {
                    "kind": "Variable",
                    "name": "cursor",
                    "variableName": "cursor"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BannedMembershipsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "classroom",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Classroom",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bannedMemberships",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "ClassroomMembershipConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClassroomMembershipEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ClassroomMembership",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "level",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "username",
                                "args": null,
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "classroom",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Classroom",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "bannedMemberships",
                "args": (v4/*: any*/),
                "handle": "connection",
                "key": "BannedMemberships_bannedMemberships",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BannedMembershipsQuery",
    "id": null,
    "text": "query BannedMembershipsQuery(\n  $classroomId: ID!\n  $count: Int!\n  $cursor: String\n) {\n  classroom: node(id: $classroomId) {\n    __typename\n    ... on Classroom {\n      ...BannedMemberships_classroom_1G22uz\n    }\n    id\n  }\n}\n\nfragment BannedMemberships_classroom_1G22uz on Classroom {\n  id\n  bannedMemberships(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        ...ClassroomMember_classroomMembership\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ClassroomMember_classroomMembership on ClassroomMembership {\n  id\n  level\n  user {\n    username\n    id\n  }\n  classroom {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '263cbe19243c00616cc678d6db1ea710';
export default node;
