/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceWidget_exercise = {
    readonly content: {
        readonly prompt: string;
        readonly choices: ReadonlyArray<{
            readonly text: string;
            readonly isCorrect: boolean;
        }>;
    };
    readonly " $refType": "MultipleChoiceWidget_exercise";
};
export type MultipleChoiceWidget_exercise$data = MultipleChoiceWidget_exercise;
export type MultipleChoiceWidget_exercise$key = {
    readonly " $data"?: MultipleChoiceWidget_exercise$data;
    readonly " $fragmentRefs": FragmentRefs<"MultipleChoiceWidget_exercise">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "MultipleChoiceWidget_exercise",
  "type": "MultipleChoiceExercise",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "content",
      "storageKey": null,
      "args": null,
      "concreteType": "MultipleChoiceExerciseContent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "prompt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "choices",
          "storageKey": null,
          "args": null,
          "concreteType": "MultipleChoiceExerciseChoice",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "text",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isCorrect",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'd1475df2d1cce13379605b898fdaa1bc';
export default node;
