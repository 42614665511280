import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  VocabAnswerTermInput,
  CreateVocabAnswerMutation,
} from './__generated__/CreateVocabAnswerMutation.graphql';
const mutation = graphql`
  mutation CreateVocabAnswerMutation($input: CreateVocabAnswerInput!) {
    createVocabAnswer(input: $input) {
      answer {
        id
      }
    }
  }
`;

const commit = (
  environment: any,
  studyRecordId: string,
  exerciseId: string,
  isCorrect: boolean,
  responseTerm: VocabAnswerTermInput,
  correctTerm: VocabAnswerTermInput,
) =>
  commitMutationPromise<CreateVocabAnswerMutation>(environment, {
    mutation,
    variables: {
      input: {
        studyRecordId,
        exerciseId,
        isCorrect,
        responseTerm,
        correctTerm,
      },
    },
  });

export default {
  commit,
};
