import React from 'react';
import './WordPart.css';
type Props = {
  text: string;
};

const WordPart = ({ text }: Props) => (
  <div className="WordPart">{text.trim()}</div>
);

export default WordPart;
