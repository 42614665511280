import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { IntlShape } from 'react-intl';
import classNames from 'classnames';
import Flash from './components/Flash';
import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
import LogInPage from './pages/LogInPage';
import NewSheetPage from './pages/NewSheetPage';
import PostSignupPublishSheetPage from './pages/PostSignupPublishSheetPage';
import EditSheetPage from './pages/EditSheetPage';
import StudyPage from './pages/StudyPage';
import ViewWorksheetPage from './pages/ViewWorksheetPage';
import ScorePage from './pages/ScorePage';
import DashboardPage from './pages/DashboardPage';
import GoogleOauthPage from './pages/GoogleOauthPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ProfilePage from './pages/ProfilePage';
import AccountSettingsPage from './pages/AccountSettingsPage';
import AdminUsersPage from './pages/admin/AdminUsersPage';
import AdminStudyRecordsPage from './pages/admin/AdminStudyRecordsPage';
import AdminWorksheetsPage from './pages/admin/AdminWorksheetsPage';
import NotFoundPage from './pages/NotFoundPage';
import UpgradePage from './pages/UpgradePage';
import CheckoutPage from './pages/CheckoutPage';
import UpgradeSuccessPage from './pages/UpgradeSuccessPage';
import './App.css';
import ClassroomPage from './pages/ClassroomPage';
import AdminClassroomsPage from './pages/admin/AdminClassroomsPage';

type Props = {
  intl: IntlShape;
};

const App = ({ intl }: Props) => (
  <div className={classNames('App', 'sans-serif', `locale-${intl.locale}`)}>
    <Router>
      <React.Fragment>
        <Flash />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/sign-up" component={SignUpPage} />
          <Route exact path="/log-in" component={LogInPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPasswordPage}
          />
          <Route
            exact
            path="/account-settings"
            component={AccountSettingsPage}
          />
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/new-sheet" component={NewSheetPage} />
          <Route exact path="/upgrade" component={UpgradePage} />
          <Route exact path="/upgrade-success" component={UpgradeSuccessPage} />
          <Route exact path="/checkout/:planType" component={CheckoutPage} />
          <Route exact path="/privacy" component={PrivacyPolicyPage} />
          <Route
            exact
            path="/new-sheet/complete/:identifier"
            component={PostSignupPublishSheetPage}
          />
          <Route exact path="/edit/:identifier" component={EditSheetPage} />
          <Route
            exact
            path="/study/:identifier/embed"
            render={props => <StudyPage {...props} isEmbed />}
          />
          <Route exact path="/study/:identifier" component={StudyPage} />
          <Route exact path="/class/:identifier" component={ClassroomPage} />
          <Route
            exact
            path="/class/:identifier/join"
            render={props => <ClassroomPage {...props} showJoinModal />}
          />

          <Route
            exact
            path="/demo/:identifier"
            render={props => <StudyPage {...props} isDemo />}
          />
          <Route exact path="/view/:identifier" component={ViewWorksheetPage} />
          <Route
            exact
            path="/oauth/google/callback"
            component={GoogleOauthPage}
          />
          <Route
            exact
            path="/result/:identifier/embed"
            render={props => <ScorePage {...props} isEmbed />}
          />
          <Route exact path="/result/:identifier" component={ScorePage} />
          <Route
            exact
            path="/demo-result/:identifier"
            render={props => <ScorePage {...props} isDemo />}
          />
          <Route exact path="/profile/:username" component={ProfilePage} />
          <Route exact path="/admin/users" component={AdminUsersPage} />
          <Route
            exact
            path="/admin/study-records"
            component={AdminStudyRecordsPage}
          />
          <Route
            exact
            path="/admin/worksheets"
            component={AdminWorksheetsPage}
          />
          <Route exact path="/admin/classes" component={AdminClassroomsPage} />
          <Route
            exact
            path="/teacher-survey"
            component={() => {
              window.location.href = 'https://www.surveymonkey.com/r/5W8QSXN';
              return <h4>Loading the teacher survey...</h4>;
            }}
          />
          <Route exact path="*" component={NotFoundPage} />
        </Switch>
      </React.Fragment>
    </Router>
  </div>
);

export default injectIntl(App);
