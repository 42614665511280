import React, { Component, ReactNode } from 'react';
import { RelayPaginationProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import Button from '../common/Button';

type LoadMoreContainerProps = {
  relay: RelayPaginationProp;
  pageSize?: number;
  children: ReactNode;
};

type LoadMoreContainerState = {
  isLoadingMore: boolean;
};

class LoadMoreContainer extends Component<
  LoadMoreContainerProps,
  LoadMoreContainerState
> {
  state: LoadMoreContainerState = {
    isLoadingMore: false,
  };

  onLoadMore = () => {
    const { relay, pageSize = 50 } = this.props;
    if (relay.isLoading()) return;
    this.setState({ isLoadingMore: true });
    relay.loadMore(pageSize, () => {
      this.setState({ isLoadingMore: false });
    });
  };

  render() {
    const { relay, children } = this.props;
    return (
      <div className="LoadMoreContainer">
        {children}
        {relay.hasMore() && (
          <div className="pt3">
            <Button
              size="small"
              outline
              phantomOutline
              disabled={this.state.isLoadingMore}
              loading={this.state.isLoadingMore}
              onClick={this.onLoadMore}
            >
              <FormattedMessage
                id="LoadMoreContainer.load_more"
                defaultMessage="Load more"
              />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default LoadMoreContainer;
