/* tslint:disable */
/* eslint-disable */
/* @relayHash dfda059eff577e863e02852e3b785e9e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type JoinClassroomInput = {
    classroomId: string;
    clientMutationId?: string | null;
};
export type JoinClassroomMutationVariables = {
    input: JoinClassroomInput;
};
export type JoinClassroomMutationResponse = {
    readonly joinClassroom: {
        readonly classroomMembership: {
            readonly id: string;
            readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
        } | null;
        readonly viewer: {
            readonly " $fragmentRefs": FragmentRefs<"ClassroomViewer_viewer" | "DashboardLayout_viewer">;
        } | null;
    } | null;
};
export type JoinClassroomMutation = {
    readonly response: JoinClassroomMutationResponse;
    readonly variables: JoinClassroomMutationVariables;
};



/*
mutation JoinClassroomMutation(
  $input: JoinClassroomInput!
) {
  joinClassroom(input: $input) {
    classroomMembership {
      id
      level
    }
    viewer {
      ...ClassroomViewer_viewer
      ...DashboardLayout_viewer
    }
  }
}

fragment ClassroomActions_viewer on Viewer {
  user {
    isAdmin
    classroomMemberships {
      id
      level
      classroom {
        id
      }
    }
    id
  }
}

fragment ClassroomViewer_viewer on Viewer {
  ...ClassroomActions_viewer
  user {
    id
    isAdmin
    classroomMemberships {
      level
      classroom {
        id
      }
      id
    }
  }
}

fragment DashboardLayout_viewer on Viewer {
  user {
    id
    isAdmin
    accountType
    isUpgradedTeacher
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "JoinClassroomInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "level",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "classroomMembership",
  "storageKey": null,
  "args": null,
  "concreteType": "ClassroomMembership",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JoinClassroomMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "joinClassroom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JoinClassroomPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "ClassroomViewer_viewer",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "DashboardLayout_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JoinClassroomMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "joinClassroom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JoinClassroomPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isAdmin",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroomMemberships",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClassroomMembership",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "classroom",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Classroom",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "identifier",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  },
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accountType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isUpgradedTeacher",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "JoinClassroomMutation",
    "id": null,
    "text": "mutation JoinClassroomMutation(\n  $input: JoinClassroomInput!\n) {\n  joinClassroom(input: $input) {\n    classroomMembership {\n      id\n      level\n    }\n    viewer {\n      ...ClassroomViewer_viewer\n      ...DashboardLayout_viewer\n    }\n  }\n}\n\nfragment ClassroomActions_viewer on Viewer {\n  user {\n    isAdmin\n    classroomMemberships {\n      id\n      level\n      classroom {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment ClassroomViewer_viewer on Viewer {\n  ...ClassroomActions_viewer\n  user {\n    id\n    isAdmin\n    classroomMemberships {\n      level\n      classroom {\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment DashboardLayout_viewer on Viewer {\n  user {\n    id\n    isAdmin\n    accountType\n    isUpgradedTeacher\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'afc6b464f911104a25dc5d0d2996a41a';
export default node;
