/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedItem_activityFeedItem = {
    readonly __typename: "Worksheet";
    readonly exercises: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly creator: {
        readonly id: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Worksheet_worksheet">;
    readonly " $refType": "FeedItem_activityFeedItem";
} | {
    readonly __typename: "StudyRecord";
    readonly " $fragmentRefs": FragmentRefs<"StudyRecord_studyRecord">;
    readonly " $refType": "FeedItem_activityFeedItem";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "FeedItem_activityFeedItem";
};
export type FeedItem_activityFeedItem$data = FeedItem_activityFeedItem;
export type FeedItem_activityFeedItem$key = {
    readonly " $data"?: FeedItem_activityFeedItem$data;
    readonly " $fragmentRefs": FragmentRefs<"FeedItem_activityFeedItem">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "FeedItem_activityFeedItem",
  "type": "ActivityFeedItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "Worksheet",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "exercises",
          "storageKey": null,
          "args": null,
          "concreteType": null,
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "creator",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "FragmentSpread",
          "name": "Worksheet_worksheet",
          "args": null
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "StudyRecord",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "StudyRecord_studyRecord",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '0c1ef1a926f19fdbeab977444351a225';
export default node;
