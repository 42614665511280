import React, { ReactNode, Component } from 'react';
import classNames from 'classnames';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { DashboardLayout_viewer } from './__generated__/DashboardLayout_viewer.graphql';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '../common/Button';
import { exists } from '../../lib/utils';

import './index.css';
import Loading from '../common/Loading';
import EditClassroomModal from '../EditClassroomModal';

interface DashboardLayoutProps {
  children: ReactNode;
  activeTab: 'overview' | 'classes';
  activeIdentifier?: string;
  viewer?: DashboardLayout_viewer | null;
}

interface DashboardLayoutState {
  isNewClassroomModalOpen: boolean;
}

class DashboardLayout extends Component<
  DashboardLayoutProps,
  DashboardLayoutState
> {
  state: DashboardLayoutState = {
    isNewClassroomModalOpen: false,
  };

  renderCreateClassButton() {
    return (
      <Button
        buttonType="transparentDark"
        fullWidth={true}
        id="newClassButton"
        size="small"
        onClick={() => this.setState({ isNewClassroomModalOpen: true })}
      >
        <i className="fas fa-plus mr2" />
        <FormattedMessage
          id="DashboardLayout.new_class_button"
          defaultMessage="New class"
        />
      </Button>
    );
  }

  render() {
    const { children, activeTab, activeIdentifier, viewer } = this.props;
    const { isNewClassroomModalOpen } = this.state;
    const isLoading = !viewer;
    const classroomMemberships = (
      viewer?.user?.classroomMemberships || []
    ).filter(membership => membership && membership.level !== 'BANNED');
    const shouldShowNav =
      viewer?.user?.accountType === 'TEACHER' ||
      classroomMemberships.length > 0;
    return (
      <>
        <div className="DashboardLayout">
          {shouldShowNav && (
            <div className="DashboardLayout-nav pv3 dn db-ns flex-no-shrink">
              {viewer?.user?.accountType === 'TEACHER' && (
                <div className="DashboardLayout-button mb2">
                  <Button
                    buttonType="transparentDark"
                    component={Link}
                    fullWidth={true}
                    to="/new-sheet"
                    size="small"
                  >
                    <i className="fas fa-plus mr2" />
                    <FormattedMessage
                      id="DashboardLayout.create_class_button"
                      defaultMessage="New wordsheet"
                    />
                  </Button>
                </div>
              )}
              <div className="DashboardLayout-label">
                <FormattedMessage
                  id="DashboardLayout.activity_nav_header"
                  defaultMessage="Activity"
                />
              </div>
              <Link
                to="/dashboard"
                className={classNames('DashboardLayout-tab', {
                  'is-active': activeTab === 'overview',
                })}
              >
                <FormattedMessage
                  id="DashboardLayout.recent_activity_tab"
                  defaultMessage="Recent activity"
                />
              </Link>

              <div className="DashboardLayout-divider" />
              <div className="DashboardLayout-label">
                <FormattedMessage
                  id="DashboardLayout.classes_nav_header"
                  defaultMessage="Classes"
                />
              </div>
              {isLoading && <Loading />}
              {viewer && (
                <>
                  {classroomMemberships
                    .filter(exists)
                    .map(({ level, classroom }) => (
                      <Link
                        to={`/class/${classroom.identifier}`}
                        key={classroom.id}
                        className={classNames('DashboardLayout-tab', {
                          'is-active':
                            activeTab === 'classes' &&
                            activeIdentifier === classroom.identifier,
                        })}
                      >
                        {level === 'PENDING' && (
                          <i className="mr2 fas fa-clock" />
                        )}
                        {classroom.title}
                      </Link>
                    ))}
                  {viewer.user?.accountType === 'TEACHER' && (
                    <div className="DashboardLayout-button mt2">
                      {this.renderCreateClassButton()}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="DashboardLayout-main">{children}</div>
        </div>
        <EditClassroomModal
          isOpen={isNewClassroomModalOpen}
          onClose={() => this.setState({ isNewClassroomModalOpen: false })}
        />
      </>
    );
  }
}

export default createFragmentContainer(DashboardLayout, {
  viewer: graphql`
    fragment DashboardLayout_viewer on Viewer {
      user {
        id
        isAdmin
        accountType
        isUpgradedTeacher
        classroomMemberships {
          level
          classroom {
            id
            title
            identifier
          }
        }
      }
    }
  `,
});
