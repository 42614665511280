import queryString from 'query-string';

const UTM_FROM_CAMPAIGN = 'UTM_FROM_CAMPAIGN';

export const trackCampaign = () => {
  const parsedQuery = queryString.parse(window.location.search);
  if (parsedQuery.utm_source === 'facebook') {
    window.localStorage.setItem(UTM_FROM_CAMPAIGN, 'facebook');
  }
};

export const isFromCampaign = () =>
  !!window.localStorage.getItem(UTM_FROM_CAMPAIGN);

export const clearCampaign = () => {
  window.localStorage.removeItem(UTM_FROM_CAMPAIGN);
};
