import React, { FC } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import NavHeader from '../NavHeader';
import { PageLoading_viewer } from './__generated__/PageLoading_viewer.graphql';
import Loading from './Loading';

interface PageLoadingProps {
  viewer?: PageLoading_viewer | null;
}

const PageLoading: FC<PageLoadingProps> = ({ viewer }) => (
  <div className="PageLoading">
    <NavHeader viewer={viewer} />
    <Loading />
  </div>
);

export default createFragmentContainer(PageLoading, {
  viewer: graphql`
    fragment PageLoading_viewer on Viewer {
      ...NavHeader_viewer
    }
  `,
});
