import React, { FC, ReactNode } from 'react';
import Select from 'react-select';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { ClassroomsSelect_worksheet } from './__generated__/ClassroomsSelect_worksheet.graphql';

interface ClassroomsSelectProps {
  worksheet: ClassroomsSelect_worksheet;
  value: string | null;
  onChange: (value: string | null) => void;
}

const ClassroomsSelect: FC<ClassroomsSelectProps> = ({
  worksheet,
  value,
  onChange,
}) => {
  const selectOptions: {
    value: string | null;
    label: ReactNode;
  }[] = [
    {
      value: null,
      label: (
        <span data-testid="ClassroomsSelect-noneOption">
          <i className="fas fa-users mr2" />
          <FormattedMessage
            id="ClassroomsSelect.none_label"
            defaultMessage="All studiers (no filtering)"
          />
        </span>
      ),
    },
  ];
  worksheet.classroomWorksheetsVisibleToViewer.forEach(({ classroom }) => {
    selectOptions.push({
      value: classroom.id,
      label: (
        <span data-testid={`ClassroomsSelect-${classroom.id}Option`}>
          {classroom.title}
        </span>
      ),
    });
  });
  return (
    <div data-testid="ClassroomsSelect">
      <Select
        value={selectOptions.find(opt => opt.value === value)}
        options={selectOptions}
        onChange={val => val && 'value' in val && onChange(val.value)}
      />
    </div>
  );
};

export default createFragmentContainer(ClassroomsSelect, {
  worksheet: graphql`
    fragment ClassroomsSelect_worksheet on Worksheet {
      classroomWorksheetsVisibleToViewer {
        classroom {
          id
          title
        }
      }
    }
  `,
});
