import React from 'react';
import classNames from 'classnames';
import GoogleAuthButton from './GoogleAuthButton';
import './OauthHeader.css';
type Props = {
  redirect?: string;
  background: 'white' | 'gray';
  authType: 'login' | 'signup';
};

const OauthHeader = ({ redirect, background, authType }: Props) => (
  <div className="OauthHeader pb4 mb3 bb b--black-10 relative">
    <div className="fl w-100 w-50-ns pa2">
      <GoogleAuthButton redirect={redirect} authType={authType} />
    </div>
    <div className="cf" />
    <span className="OauthHeader-orContainer">
      <span
        className={classNames('OauthHeader-or', 'f6', `is-${background}Bg`)}
      >
        Or
      </span>
    </span>
  </div>
);

export default OauthHeader;
