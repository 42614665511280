import React, { Component } from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import './Button.css';
import Spinner from './Spinner';

export interface ButtonProps {
  children: ReactNode;
  buttonType?: 'success' | 'failure' | 'default' | 'gray' | 'transparentDark';
  size?: 'small' | 'medium' | 'large';
  outline?: boolean;
  phantomOutline?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  component?: any;
  [key: string]: any;
}

class Button extends Component<ButtonProps> {
  static defaultProps = {
    size: 'medium',
    fullWidth: false,
    outline: false,
    phantomOutline: false,
    buttonType: 'default',
  };

  render() {
    const {
      children,
      buttonType,
      size,
      fullWidth,
      outline,
      loading,
      phantomOutline,
      component,
      ...otherProps
    } = this.props;
    const ComponentClass = component || 'button';
    return (
      <ComponentClass
        className={classNames(
          'Button',
          `Button--${buttonType}`,
          `Button--${size}`,
          {
            'Button--fullWidth': fullWidth,
            'Button--outline': outline,
            'Button--phantomOutline': phantomOutline,
            'is-loading': loading,
          },
        )}
        {...otherProps}
      >
        <span className="Button-content">{children}</span>
        <span className="Button-spinner">
          <Spinner />
        </span>
      </ComponentClass>
    );
  }
}

export default Button;
