import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { UploadImageMutation } from './__generated__/UploadImageMutation.graphql';
const mutation = graphql`
  mutation UploadImageMutation($input: UploadImageInput!) {
    uploadImage(input: $input) {
      image {
        id
        url(size: LARGE_SQUARE)
      }
    }
  }
`;

const commit = (environment: any, file: any) =>
  commitMutationPromise<UploadImageMutation>(environment, {
    mutation,
    variables: {
      input: {
        file: null,
      },
    },
    uploadables: {
      file,
    },
  });

export default {
  commit,
};
