const LOCALE_KEY = 'LOCALE';

type localeCallback = (locale: string) => void;

class LocaleManager {
  onUpdateCallbacks: localeCallback[] = [];

  setLocale(locale: string) {
    localStorage.setItem(LOCALE_KEY, locale);
    this.onUpdateCallbacks.forEach(callback => callback(locale));
  }
  getLocale() {
    const savedLocale = localStorage.getItem(LOCALE_KEY);
    return savedLocale || navigator.language;
  }
  addListener(callback: localeCallback) {
    this.onUpdateCallbacks.push(callback);
  }
  removeListener(callback: localeCallback) {
    this.onUpdateCallbacks = this.onUpdateCallbacks.filter(
      cb => cb !== callback,
    );
  }
}

export default new LocaleManager();
