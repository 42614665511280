/* tslint:disable */
/* eslint-disable */
/* @relayHash 141699339681c5666e7d077cb895bebe */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminClassroomsPageQueryVariables = {};
export type AdminClassroomsPageQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly isAdmin: boolean;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"NavHeader_viewer">;
    } | null;
    readonly admin: {
        readonly " $fragmentRefs": FragmentRefs<"AdminClassroomsList_admin">;
    } | null;
};
export type AdminClassroomsPageQuery = {
    readonly response: AdminClassroomsPageQueryResponse;
    readonly variables: AdminClassroomsPageQueryVariables;
};



/*
query AdminClassroomsPageQuery {
  viewer {
    ...NavHeader_viewer
    user {
      isAdmin
      id
    }
  }
  admin {
    ...AdminClassroomsList_admin
  }
}

fragment AdminClassroomsList_admin on Admin {
  recentClassrooms(first: 100) {
    edges {
      node {
        id
        title
        identifier
        description
        requiresApproval
        createdAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment FeedbackModal_viewer on Viewer {
  user {
    id
  }
}

fragment NavHeader_viewer on Viewer {
  ...FeedbackModal_viewer
  user {
    id
    username
    isAdmin
    createdAt
    isUpgradedTeacher
    accountType
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
    worksheets(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdmin",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminClassroomsPageQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "NavHeader_viewer",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AdminClassroomsList_admin",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminClassroomsPageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isUpgradedTeacher",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accountType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomMemberships",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomMembership",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "level",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroom",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v1/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "worksheets",
                "storageKey": "worksheets(first:1)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  }
                ],
                "concreteType": "WorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Worksheet",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "recentClassrooms",
            "storageKey": "recentClassrooms(first:100)",
            "args": (v5/*: any*/),
            "concreteType": "ClassroomConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "requiresApproval",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "recentClassrooms",
            "args": (v5/*: any*/),
            "handle": "connection",
            "key": "AdminClassroomsList_recentClassrooms",
            "filters": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdminClassroomsPageQuery",
    "id": null,
    "text": "query AdminClassroomsPageQuery {\n  viewer {\n    ...NavHeader_viewer\n    user {\n      isAdmin\n      id\n    }\n  }\n  admin {\n    ...AdminClassroomsList_admin\n  }\n}\n\nfragment AdminClassroomsList_admin on Admin {\n  recentClassrooms(first: 100) {\n    edges {\n      node {\n        id\n        title\n        identifier\n        description\n        requiresApproval\n        createdAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FeedbackModal_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment NavHeader_viewer on Viewer {\n  ...FeedbackModal_viewer\n  user {\n    id\n    username\n    isAdmin\n    createdAt\n    isUpgradedTeacher\n    accountType\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n    worksheets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e4d12dfafe73490c51b2bcf417822e93';
export default node;
