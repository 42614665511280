import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { CSSTransitionGroup } from 'react-transition-group';

import { VocabWidget_exercise } from './__generated__/VocabWidget_exercise.graphql';
import { shuffleArray, removeArrayItem } from '../../lib/utils';
import Button from '../common/Button';
import './index.css';

export type vocabTerm = {
  word: string;
  definition: null | string;
  image: null | {
    id: string;
    url: string;
  };
};

type Props = {
  hasNext: boolean;
  exercise: VocabWidget_exercise;
  onProgress?: (num: number) => void;
  onCorrect?: (term: vocabTerm) => void;
  onMistake?: (answer: vocabTerm, correct: vocabTerm) => void;
  onDone: () => void;
};

type State = {
  terms: null | ReadonlyArray<vocabTerm>;
  scrambledTerms: vocabTerm[];
  currentResponses: vocabTerm[];
  currentTermNum: number;
  selectedAnswer: null | vocabTerm;
  buttonState: 'correct' | 'incorrect' | 'default';
  isComplete: boolean;
  numMistakes: number;
};

const createScrambledResponses = (
  termNum: number,
  scrambledTerms: vocabTerm[],
) => {
  const correctResponse = scrambledTerms[termNum];
  const otherTerms = shuffleArray(
    removeArrayItem(scrambledTerms, correctResponse),
  );
  return shuffleArray([correctResponse].concat(otherTerms.slice(0, 3)));
};

class VocabWidget extends Component<Props, State> {
  state: State = {
    terms: null,
    scrambledTerms: [],
    currentResponses: [],
    currentTermNum: 0,
    selectedAnswer: null,
    buttonState: 'default',
    isComplete: false,
    numMistakes: 0,
  };

  static defaultProps = {
    hasNext: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.terms !== nextProps.exercise.content.terms) {
      const scrambledTerms = shuffleArray(
        nextProps.exercise.content.terms.slice(),
      );
      return {
        scrambledTerms,
        terms: nextProps.exercise.content.terms,
        currentTermNum: 0,
        numMistakes: 0,
        currentResponses: createScrambledResponses(0, scrambledTerms),
        isComplete: false,
        selectedAnswer: null,
      };
    }
    return null;
  }

  onCheckResponse = () => {
    const { selectedAnswer, scrambledTerms, currentTermNum } = this.state;
    if (!selectedAnswer) return;
    const isComplete = currentTermNum === scrambledTerms.length - 1;
    const isCorrect = selectedAnswer === scrambledTerms[currentTermNum];
    if (isCorrect) {
      this.setState({ buttonState: 'correct', isComplete });
      if (this.props.onCorrect) this.props.onCorrect(selectedAnswer);
    } else {
      this.setState({ buttonState: 'incorrect', isComplete });
      if (this.props.onMistake)
        this.props.onMistake(selectedAnswer, scrambledTerms[currentTermNum]);
    }
  };

  onMainButtonClick = () => {
    if (this.state.isComplete) {
      this.props.onDone();
    } else if (this.state.buttonState !== 'default') {
      const currentTermNum = this.state.currentTermNum + 1;
      this.setState({
        currentTermNum,
        selectedAnswer: null,
        buttonState: 'default',
        currentResponses: createScrambledResponses(
          currentTermNum,
          this.state.scrambledTerms,
        ),
      });
      if (this.props.onProgress) this.props.onProgress(currentTermNum);
    } else {
      this.onCheckResponse();
    }
  };

  getButtonType() {
    if (this.state.buttonState === 'default') return 'default';
    return this.state.buttonState === 'correct' ? 'success' : 'failure';
  }

  getButtonText() {
    if (this.state.buttonState === 'default') {
      return (
        <FormattedMessage
          id="VocabWidget.check_answer"
          defaultMessage="Check answer"
        />
      );
    }
    if (this.state.buttonState === 'correct') {
      if (this.props.hasNext) {
        return (
          <FormattedMessage
            id="VocabWidget.correct_next_exercise"
            defaultMessage=":D Correct! Next →"
          />
        );
      } else {
        return (
          <FormattedMessage
            id="VocabWidget.correct_finish"
            defaultMessage=":D Correct! Finish →"
          />
        );
      }
    }
    if (this.props.hasNext) {
      return (
        <FormattedMessage
          id="VocabWidget.incorrect_next_exercise"
          defaultMessage=":( Incorrect. Next →"
        />
      );
    } else {
      return (
        <FormattedMessage
          id="VocabWidget.incorrect_finish"
          defaultMessage=":( Incorrect. Finish →"
        />
      );
    }
  }

  render() {
    const {
      selectedAnswer,
      currentTermNum,
      buttonState,
      scrambledTerms,
    } = this.state;
    const correctAnswer = scrambledTerms[currentTermNum];
    const prompt = correctAnswer.word;
    const isShowingAnswer = buttonState !== 'default';

    return (
      <div className="VocabWidget">
        <div className="VocabWidget-prompt center f3">{prompt}</div>
        <div className="VocabWidget-responsesContainer">
          <div className="VocabWidget-responses pv3">
            <CSSTransitionGroup
              transitionName="response"
              transitionAppear={true}
              transitionAppearTimeout={300}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              {this.state.currentResponses.map((response, i) => (
                // eslint-disable-next-line
                <a
                  className={classNames(
                    'VocabWidget-response',
                    `VocabWidget-response-num-${i}`,
                    {
                      'has-image': response.image,
                      'is-selected':
                        !isShowingAnswer && selectedAnswer === response,
                      'is-correct':
                        isShowingAnswer && response === correctAnswer,
                      'is-incorrect':
                        isShowingAnswer &&
                        response !== correctAnswer &&
                        selectedAnswer === response,
                    },
                  )}
                  onClick={() =>
                    !isShowingAnswer &&
                    this.setState({ selectedAnswer: response })
                  }
                  key={`${i}-${currentTermNum}`}
                >
                  {!response.image ? null : (
                    <img
                      alt="response"
                      className="VocabWidget-image"
                      src={response.image.url}
                    />
                  )}
                  {!response.definition ? null : (
                    <div
                      className={classNames('VocabWidget-definition', {
                        'is-long': response.definition.length > 50,
                        'is-short': response.definition.length < 10,
                      })}
                    >
                      <div className="VocabWidget-definitionScroll">
                        {response.definition}
                      </div>
                    </div>
                  )}
                </a>
              ))}
            </CSSTransitionGroup>
            <div className="cb" />
          </div>
        </div>
        <div className="VocabWidget-checkResponse">
          <Button
            fullWidth
            size="large"
            buttonType={this.getButtonType()}
            disabled={!this.state.isComplete && !this.state.selectedAnswer}
            onClick={this.onMainButtonClick}
          >
            {this.getButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(VocabWidget, {
  exercise: graphql`
    fragment VocabWidget_exercise on VocabExercise {
      content {
        terms {
          word
          definition
          image {
            id
            url(size: LARGE_SQUARE)
          }
        }
      }
    }
  `,
});
