/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetStudiersTab_worksheet = {
    readonly id: string;
    readonly " $refType": "WorksheetStudiersTab_worksheet";
};
export type WorksheetStudiersTab_worksheet$data = WorksheetStudiersTab_worksheet;
export type WorksheetStudiersTab_worksheet$key = {
    readonly " $data"?: WorksheetStudiersTab_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetStudiersTab_worksheet">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WorksheetStudiersTab_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '20385280e8e13f1b0ae5efa9b6739147';
export default node;
