import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';
import { ForgotPasswordPageQuery } from './__generated__/ForgotPasswordPageQuery.graphql';

const forgotPasswordPageQuery = graphql`
  query ForgotPasswordPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        id
      }
    }
  }
`;

const ForgotPasswordPage = () => (
  <QueryRenderer<ForgotPasswordPageQuery>
    environment={environment}
    variables={{}}
    query={forgotPasswordPageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      const viewer = props && props.viewer;
      if (viewer && viewer.user) {
        return <Redirect to="/dashboard" />;
      }

      return (
        <div className="ForgotPasswordPage">
          <NavHeader viewer={viewer} />
          <div className="pa3">
            <h1 className="f2">
              <FormattedMessage
                id="ForgotPasswordPage.forgot_password_header"
                defaultMessage="Forgot password"
              />
            </h1>
            <div className="ba b--black-10 measure center pa3 tl mb4 br2">
              <ForgotPasswordForm environment={environment} />
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default ForgotPasswordPage;
