/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ACCOUNT_TYPE = "STUDENT" | "TEACHER" | "%future added value";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type NavHeader_viewer = {
    readonly user: {
        readonly id: string;
        readonly username: string;
        readonly isAdmin: boolean;
        readonly createdAt: number;
        readonly isUpgradedTeacher: boolean;
        readonly accountType: ACCOUNT_TYPE | null;
        readonly classroomMemberships: ReadonlyArray<{
            readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
            readonly classroom: {
                readonly id: string;
                readonly title: string;
                readonly identifier: string;
            };
        } | null>;
        readonly worksheets: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                } | null;
            } | null> | null;
        };
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackModal_viewer">;
    readonly " $refType": "NavHeader_viewer";
};
export type NavHeader_viewer$data = NavHeader_viewer;
export type NavHeader_viewer$key = {
    readonly " $data"?: NavHeader_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"NavHeader_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "NavHeader_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAdmin",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isUpgradedTeacher",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accountType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "classroomMemberships",
          "storageKey": null,
          "args": null,
          "concreteType": "ClassroomMembership",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "level",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "classroom",
              "storageKey": null,
              "args": null,
              "concreteType": "Classroom",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "identifier",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "worksheets",
          "storageKey": "worksheets(first:1)",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "WorksheetConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "WorksheetEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Worksheet",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "FeedbackModal_viewer",
      "args": null
    }
  ]
};
})();
(node as any).hash = 'b6b91e703833e98a011e379e9d26cccc';
export default node;
