/* tslint:disable */
/* eslint-disable */
/* @relayHash 3bcc5d634e1733f7c3e8ff00ab8c3bc3 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ACCOUNT_TYPE = "STUDENT" | "TEACHER" | "%future added value";
export type AdminUsersListQueryVariables = {
    accountType?: ACCOUNT_TYPE | null;
    query?: string | null;
    upgradedOnly?: boolean | null;
    count: number;
    cursor?: string | null;
};
export type AdminUsersListQueryResponse = {
    readonly admin: {
        readonly " $fragmentRefs": FragmentRefs<"AdminUsersList_admin">;
    } | null;
};
export type AdminUsersListQuery = {
    readonly response: AdminUsersListQueryResponse;
    readonly variables: AdminUsersListQueryVariables;
};



/*
query AdminUsersListQuery(
  $accountType: ACCOUNT_TYPE
  $query: String
  $upgradedOnly: Boolean
  $count: Int!
  $cursor: String
) {
  admin {
    ...AdminUsersList_admin_250LWC
  }
}

fragment AdminUser_user on User {
  id
  createdAt
  username
  email
  isAdmin
  hasGoogle
  hasPassword
  isUpgradedTeacher
  accountType
  subscriptionStatus {
    subscriptionType
    subscriptionNote
  }
  worksheets {
    edges {
      node {
        id
        title
        identifier
      }
    }
  }
}

fragment AdminUsersList_admin_250LWC on Admin {
  recentUsers(accountType: $accountType, upgradedOnly: $upgradedOnly, query: $query, first: $count, after: $cursor) {
    edges {
      node {
        id
        ...AdminUser_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountType",
    "type": "ACCOUNT_TYPE",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "upgradedOnly",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountType",
  "variableName": "accountType"
},
v2 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v3 = {
  "kind": "Variable",
  "name": "upgradedOnly",
  "variableName": "upgradedOnly"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminUsersListQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AdminUsersList_admin",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminUsersListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "admin",
        "storageKey": null,
        "args": null,
        "concreteType": "Admin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "recentUsers",
            "storageKey": null,
            "args": (v4/*: any*/),
            "concreteType": "UserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isAdmin",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasGoogle",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasPassword",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isUpgradedTeacher",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "subscriptionStatus",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserSubscriptionStatus",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "subscriptionType",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "subscriptionNote",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "worksheets",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorksheetConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "WorksheetEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Worksheet",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "title",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "identifier",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "recentUsers",
            "args": (v4/*: any*/),
            "handle": "connection",
            "key": "AdminUsersList_recentUsers",
            "filters": [
              "accountType",
              "upgradedOnly",
              "query"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdminUsersListQuery",
    "id": null,
    "text": "query AdminUsersListQuery(\n  $accountType: ACCOUNT_TYPE\n  $query: String\n  $upgradedOnly: Boolean\n  $count: Int!\n  $cursor: String\n) {\n  admin {\n    ...AdminUsersList_admin_250LWC\n  }\n}\n\nfragment AdminUser_user on User {\n  id\n  createdAt\n  username\n  email\n  isAdmin\n  hasGoogle\n  hasPassword\n  isUpgradedTeacher\n  accountType\n  subscriptionStatus {\n    subscriptionType\n    subscriptionNote\n  }\n  worksheets {\n    edges {\n      node {\n        id\n        title\n        identifier\n      }\n    }\n  }\n}\n\nfragment AdminUsersList_admin_250LWC on Admin {\n  recentUsers(accountType: $accountType, upgradedOnly: $upgradedOnly, query: $query, first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        ...AdminUser_user\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9b8a4c108081394616d3b7d0c0d0cf8e';
export default node;
