import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Modal from '../common/Modal';
import Button from '../common/Button';
import AutofocusInput from '../common/AutofocusInput';
import { externalStudyLink } from '../../lib/utils';
type Props = {
  identifier: string | undefined | null;
  isOpen: boolean;
};

const CreationCompleteModal = ({ identifier, isOpen }: Props) => (
  <Modal
    header={
      <FormattedMessage
        id="CreationCompleteModal.modal_header"
        defaultMessage="Your wordsheet is live!"
      />
    }
    isOpen={isOpen}
    closeOnTapBackdrop={false}
  >
    {!identifier ? null : (
      <div className="tc ph2 ph4-ns pv2">
        <p className="gray">
          <FormattedMessage
            id="CreationCompleteModal.share_instructions"
            defaultMessage="Share the link below to study this wordsheet"
          />
        </p>
        <AutofocusInput
          value={externalStudyLink(identifier)}
          className="f6 f3-ns tc pa2 w-100 db border-box"
        />
        <div className="pt4">
          <div className="pa2 w-100 w-50-ns fl">
            <Button component={Link} to={`/study/${identifier}`} fullWidth>
              <FormattedMessage
                id="CreationCompleteModal.study_this_sheet_button"
                defaultMessage="Study this sheet"
              />
            </Button>
          </div>
          <div className="pa2 w-100 w-50-ns fl">
            <Button component={Link} to="/dashboard" fullWidth>
              <FormattedMessage
                id="CreationCompleteModal.back_home_button"
                defaultMessage="Back home"
              />
            </Button>
          </div>
          <div className="cf" />
        </div>
      </div>
    )}
  </Modal>
);

export default CreationCompleteModal;
