import { containsChineseChars, containsPunctuation } from './utils';

const prefixNonChineseWithSpace = (text: string) => {
  const prefix = containsChineseChars(text[0]) ? '' : ' ';
  return prefix + text;
};

const apostropheRegex = /['`’‘]/; // TODO: handle punctuation

export const getAtoms = (word: string): string[] => {
  const atoms = [];
  const letters = prefixNonChineseWithSpace(word)
    .replace(/\s+/giu, ' ')
    .split('');
  let currentAtom = '';

  for (const letter of letters) {
    const isHanzi = containsChineseChars(letter);
    const isPunct =
      containsPunctuation(letter) && !letter.match(apostropheRegex);
    const isCurAtomPunct =
      containsPunctuation(currentAtom) && !currentAtom.match(apostropheRegex);
    const isSpace = letter === ' ';

    if (currentAtom === ' ' && (isHanzi || isPunct)) {
      atoms.push(currentAtom + letter);
      currentAtom = '';
    } else if (
      currentAtom !== '' &&
      (isSpace || isHanzi || isPunct !== isCurAtomPunct)
    ) {
      if (isCurAtomPunct && isSpace) currentAtom += ' ';
      atoms.push(currentAtom);

      if (isHanzi) {
        atoms.push(letter);
        currentAtom = '';
      } else {
        currentAtom = letter;
      }
    } else if (isHanzi) {
      atoms.push(letter);
    } else {
      currentAtom += letter;
    }
  }

  if (currentAtom !== '' && currentAtom !== ' ') {
    if (containsPunctuation(currentAtom)) currentAtom += ' ';
    atoms.push(currentAtom);
  }

  return atoms;
};

const stripSpaces = (text: string) => text.replace(/\s+/giu, '');

const spansText = (text: string, textPieces: string[]) =>
  stripSpaces(textPieces.join('')) === stripSpaces(text);

const textStartsWith = (text: string, textPieces: string[]) =>
  stripSpaces(text).indexOf(stripSpaces(textPieces.join(''))) === 0;

export const findPiecesSpanningText = (
  text: string,
  textPieces: string[],
): number[] | undefined | null => {
  // use BFS to brute force find the first spanning for this text
  const prefixedText = prefixNonChineseWithSpace(text);
  const queue: { pieces: number[] }[] = [];
  queue.unshift({
    pieces: [],
  });

  while (queue.length > 0) {
    const current = queue.pop() as { pieces: number[] };
    const curPieces = current.pieces.map(index => textPieces[index]);
    if (spansText(prefixedText, curPieces)) return current.pieces;

    for (let index = 0; index < textPieces.length; index++) {
      if (current.pieces.indexOf(index) === -1) {
        const nextPieces = current.pieces.slice();
        nextPieces.push(index);

        if (
          textStartsWith(
            prefixedText,
            nextPieces.map(index => textPieces[index]),
          )
        ) {
          queue.unshift({
            pieces: nextPieces,
          });
        }
      }
    }
  }

  return null;
};
export const checkAnswer = (response: string, correctAnswers: string[]) => {
  const strippedResponse = stripSpaces(response);

  for (const correctAnswer of correctAnswers) {
    const strippedAnswer = stripSpaces(correctAnswer);
    if (strippedResponse === strippedAnswer) return true;
  }

  return false;
};
