import React from 'react';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import EmbedHeader from '../components/EmbedHeader';
import StudyRecordViewer from '../components/StudyRecordViewer';
import PageLoading from '../components/common/PageLoading';
import NotFound from '../components/common/NotFound';
import { ScorePage_studyRecordQuery } from './__generated__/ScorePage_studyRecordQuery.graphql';

const scorePage_studyRecordQuery = graphql`
  query ScorePage_studyRecordQuery($identifier: String!) {
    viewer {
      ...NavHeader_viewer
      ...EmbedHeader_viewer
    }
    studyRecordByIdentifier(identifier: $identifier) {
      ...StudyRecordViewer_studyRecord
    }
  }
`;
type Props = {
  match: match<{ identifier: string }>;
  isDemo?: boolean;
  isEmbed?: boolean;
};

const ScorePage = ({ match, isDemo = false, isEmbed = false }: Props) => (
  <QueryRenderer<ScorePage_studyRecordQuery>
    environment={environment}
    query={scorePage_studyRecordQuery}
    variables={{
      identifier: match.params.identifier,
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props) return <PageLoading />;
      const header = isEmbed ? (
        <EmbedHeader viewer={props.viewer} />
      ) : (
        <NavHeader viewer={props.viewer} />
      );
      if (!props.studyRecordByIdentifier) return <NotFound header={header} />;
      return (
        <div className="ScorePage">
          {header}
          <div className="mw8 center">
            <StudyRecordViewer
              studyRecord={props.studyRecordByIdentifier}
              isDemo={isDemo}
              isEmbed={isEmbed}
            />
          </div>
        </div>
      );
    }}
  />
);

ScorePage.defaultProps = {
  isDemo: false,
};
export default ScorePage;
