import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';

import { TranslatorWidget_exercise } from './__generated__/TranslatorWidget_exercise.graphql';
import { shuffleArray } from '../../lib/utils';
import { checkAnswer } from '../../lib/atomHelpers';
import WordPart from '../common/WordPart';
import Button from '../common/Button';
import CorrectAnswerModal from '../common/CorrectAnswerModal';
import TranslatorWordPositioner from '../common/TranslatorWordPositioner';
import './index.css';

type Props = {
  hasNext: boolean;
  allowedMistakes: number;
  exercise: TranslatorWidget_exercise;
  onCorrect?: (answer: string[]) => void;
  onMistake?: (answer: string[]) => void;
  onDone: () => void;
};

type State = {
  wordBankPositions: number[];
  chosenItems: number[];
  buttonState: 'correct' | 'incorrect' | 'default';
  isComplete: boolean;
  numMistakes: number;
  lastResponse: null | string;
};

class TranslatorWidget extends Component<Props, State> {
  state: State = {
    chosenItems: [],
    wordBankPositions: [],
    buttonState: 'default',
    isComplete: false,
    numMistakes: 0,
    lastResponse: null,
  };

  static defaultProps = {
    hasNext: false,
    allowedMistakes: 0,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      prevState.wordBankPositions.length !==
      nextProps.exercise.content.scrambledParts.length
    ) {
      const wordBankPositions = shuffleArray(
        nextProps.exercise.content.scrambledParts.map((item, index) => index),
      );
      return {
        chosenItems: [],
        wordBankPositions,
      };
    }
    return null;
  }

  onChangeChosenItems = (chosenItems: number[]) => {
    this.setState({
      chosenItems: this.encodeChosenItems(chosenItems),
      buttonState: 'default',
      lastResponse: null,
    });
  };

  onCheckResponse = () => {
    const response = this.getChosenText();
    const { onCorrect, onMistake } = this.props;
    if (response === '') return;
    const isCorrect = checkAnswer(
      response,
      this.props.exercise.content.correctAnswers.slice(),
    );
    if (isCorrect) {
      this.setState({ buttonState: 'correct', isComplete: true });
      if (onCorrect) onCorrect(this.getChosenParts());
    } else {
      this.setState({
        buttonState: 'incorrect',
        numMistakes: this.state.numMistakes + 1,
        lastResponse: response,
      });
      if (onMistake) onMistake(this.getChosenParts());
    }
  };

  getChosenText() {
    return this.getChosenParts()
      .join('')
      .trim();
  }

  getChosenParts() {
    return this.state.chosenItems.map(
      item => this.props.exercise.content.scrambledParts[item],
    );
  }

  encodeChosenItems(chosenItems: number[]): number[] {
    return chosenItems.map(item => this.state.wordBankPositions[item]);
  }

  decodeChosenItems(): number[] {
    return this.state.chosenItems.map(item =>
      this.state.wordBankPositions.indexOf(item),
    );
  }

  getButtonType() {
    if (this.state.buttonState === 'default') return 'default';
    return this.state.buttonState === 'correct' ? 'success' : 'failure';
  }

  getButtonText() {
    if (this.state.buttonState === 'default') {
      return (
        <FormattedMessage
          id="TranslatorWidget.check_answer"
          defaultMessage="Check answer"
        />
      );
    }
    if (this.state.buttonState === 'correct') {
      if (this.props.hasNext) {
        return (
          <FormattedMessage
            id="TranslatorWidget.correct_next_exercise"
            defaultMessage=":D Correct! Next exercise →"
          />
        );
      } else {
        return (
          <FormattedMessage
            id="TranslatorWidget.correct_finish"
            defaultMessage=":D Correct! Finish →"
          />
        );
      }
    }
    return (
      <FormattedMessage
        id="TranslatorWidget.incorrect"
        defaultMessage=":( Incorrect"
      />
    );
  }

  render() {
    return (
      <div className="TranslatorWidget">
        <div className="TranslatorWidget-prompt">
          <span className="TranslatorWidget-instructions">
            <FormattedMessage
              id="TranslatorWidget.prompt_instructions"
              defaultMessage="Translate:"
            />
          </span>
          {this.props.exercise.content.prompt}
        </div>
        <div className="TranslatorWidget-response">
          <TranslatorWordPositioner
            chosenItems={this.decodeChosenItems()}
            onChangeChosenItems={this.onChangeChosenItems}
            padWordBank={false}
            chosenItemsContainer={minHeight => (
              <div
                className="TranslatorWidget-chosenItems"
                style={{ height: minHeight }}
              ></div>
            )}
            wordBankLabel={
              <div className="TranslatorWidget-wordBankLabel">
                <FormattedMessage
                  id="TranslatorWidget.instructions"
                  defaultMessage="Tap the words below to assemble the correct translation"
                />
              </div>
            }
            wordBankContainer={minHeight => (
              <div
                className="TranslatorWidget-wordBank"
                style={{ height: Math.max(minHeight, 98) - 10 }}
              />
            )}
            words={this.state.wordBankPositions.map(itemPos => (
              <WordPart
                text={this.props.exercise.content.scrambledParts[itemPos]}
              />
            ))}
          />
        </div>
        <div className="TranslatorWidget-checkResponse">
          <Button
            fullWidth
            size="large"
            buttonType={this.getButtonType()}
            disabled={
              !this.state.isComplete &&
              (this.state.chosenItems.length === 0 ||
                this.state.lastResponse === this.getChosenText())
            }
            onClick={() =>
              this.state.isComplete
                ? this.props.onDone()
                : this.onCheckResponse()
            }
          >
            {this.getButtonText()}
          </Button>
        </div>
        <CorrectAnswerModal
          isOpen={this.state.numMistakes > this.props.allowedMistakes}
          correctAnswer={this.props.exercise.content.correctAnswers[0]}
          onDone={this.props.onDone}
        />
      </div>
    );
  }
}

export default createFragmentContainer(TranslatorWidget, {
  exercise: graphql`
    fragment TranslatorWidget_exercise on TranslationExercise {
      content {
        prompt
        correctAnswers
        scrambledParts
      }
    }
  `,
});
