import React from 'react';
import { withRouter } from 'react-router-dom';
import { Location } from 'history';
import { CSSTransitionGroup } from 'react-transition-group';
import './Flash.css';

type Props = {
  location: Location;
};

const Flash = ({ location }: Props) => (
  <div className="Flash">
    <CSSTransitionGroup
      transitionName="message"
      transitionAppear={true}
      transitionAppearTimeout={5000}
      transitionEnterTimeout={5000}
      transitionLeaveTimeout={1000}
    >
      {!location.state || !location.state.flash
        ? []
        : [
            <div
              className="Flash-message"
              key={`${location.key || ''}-${location.state.flash}`}
            >
              {location.state.flash}
            </div>,
          ]}
    </CSSTransitionGroup>
  </div>
);

export default withRouter(Flash);
