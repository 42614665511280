/* tslint:disable */
/* eslint-disable */
/* @relayHash 921d90258a92ea648cdae49cf9326ab6 */

import { ConcreteRequest } from "relay-runtime";
export type PublishWorksheetInput = {
    id: string;
    clientMutationId?: string | null;
};
export type PublishWorksheetMutationVariables = {
    input: PublishWorksheetInput;
};
export type PublishWorksheetMutationResponse = {
    readonly publishWorksheet: {
        readonly worksheet: {
            readonly id: string;
            readonly title: string | null;
            readonly description: string | null;
            readonly identifier: string | null;
            readonly isPublished: boolean;
        } | null;
    } | null;
};
export type PublishWorksheetMutation = {
    readonly response: PublishWorksheetMutationResponse;
    readonly variables: PublishWorksheetMutationVariables;
};



/*
mutation PublishWorksheetMutation(
  $input: PublishWorksheetInput!
) {
  publishWorksheet(input: $input) {
    worksheet {
      id
      title
      description
      identifier
      isPublished
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PublishWorksheetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "publishWorksheet",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PublishWorksheetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "worksheet",
        "storageKey": null,
        "args": null,
        "concreteType": "Worksheet",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "identifier",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isPublished",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PublishWorksheetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PublishWorksheetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PublishWorksheetMutation",
    "id": null,
    "text": "mutation PublishWorksheetMutation(\n  $input: PublishWorksheetInput!\n) {\n  publishWorksheet(input: $input) {\n    worksheet {\n      id\n      title\n      description\n      identifier\n      isPublished\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '21b0568bf1b026d327f619c7829b34e0';
export default node;
