/* tslint:disable */
/* eslint-disable */
/* @relayHash 9a6c5d91648cc21fa4b4f701448ef328 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardFeedQueryVariables = {
    count: number;
    cursor?: string | null;
};
export type DashboardFeedQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"FeedItem_viewer" | "DashboardFeed_viewer">;
    } | null;
};
export type DashboardFeedQuery = {
    readonly response: DashboardFeedQueryResponse;
    readonly variables: DashboardFeedQueryVariables;
};



/*
query DashboardFeedQuery(
  $count: Int!
  $cursor: String
) {
  viewer {
    ...FeedItem_viewer
    ...DashboardFeed_viewer_1G22uz
  }
}

fragment DashboardFeed_viewer_1G22uz on Viewer {
  ...FeedItem_viewer
  activityFeed(first: $count, after: $cursor) {
    edges {
      node {
        id
        __typename
        ... on StudyRecord {
          completedAt
        }
        ...FeedItem_activityFeedItem
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment FeedItem_activityFeedItem on ActivityFeedItem {
  __typename
  ... on Worksheet {
    ...Worksheet_worksheet
    exercises {
      __typename
      id
    }
    creator {
      id
    }
  }
  ... on StudyRecord {
    ...StudyRecord_studyRecord
  }
}

fragment FeedItem_viewer on Viewer {
  user {
    id
  }
}

fragment StudyRecord_studyRecord on StudyRecord {
  createdAt
  completedAt
  identifier
  score
  duration
  user {
    username
    id
  }
  worksheet {
    identifier
    title
    id
  }
}

fragment Worksheet_worksheet on Worksheet {
  title
  description
  identifier
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardFeedQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "FeedItem_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "DashboardFeed_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardFeedQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activityFeed",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "ActivityFeedItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ActivityFeedItemEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "type": "StudyRecord",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completedAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "score",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "duration",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "username",
                                "args": null,
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "worksheet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Worksheet",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v1/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "type": "Worksheet",
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "description",
                            "args": null,
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "exercises",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v1/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "creator",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": (v2/*: any*/)
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "activityFeed",
            "args": (v3/*: any*/),
            "handle": "connection",
            "key": "DashboardFeed_activityFeed",
            "filters": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardFeedQuery",
    "id": null,
    "text": "query DashboardFeedQuery(\n  $count: Int!\n  $cursor: String\n) {\n  viewer {\n    ...FeedItem_viewer\n    ...DashboardFeed_viewer_1G22uz\n  }\n}\n\nfragment DashboardFeed_viewer_1G22uz on Viewer {\n  ...FeedItem_viewer\n  activityFeed(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        __typename\n        ... on StudyRecord {\n          completedAt\n        }\n        ...FeedItem_activityFeedItem\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FeedItem_activityFeedItem on ActivityFeedItem {\n  __typename\n  ... on Worksheet {\n    ...Worksheet_worksheet\n    exercises {\n      __typename\n      id\n    }\n    creator {\n      id\n    }\n  }\n  ... on StudyRecord {\n    ...StudyRecord_studyRecord\n  }\n}\n\nfragment FeedItem_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment StudyRecord_studyRecord on StudyRecord {\n  createdAt\n  completedAt\n  identifier\n  score\n  duration\n  user {\n    username\n    id\n  }\n  worksheet {\n    identifier\n    title\n    id\n  }\n}\n\nfragment Worksheet_worksheet on Worksheet {\n  title\n  description\n  identifier\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2ce3ea3a42d2bdb939fed9a8583467be';
export default node;
