/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AddWorksheetModalWorksheet_worksheet = {
    readonly id: string;
    readonly title: string | null;
    readonly publishedAt: number;
    readonly exercises: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $refType": "AddWorksheetModalWorksheet_worksheet";
};
export type AddWorksheetModalWorksheet_worksheet$data = AddWorksheetModalWorksheet_worksheet;
export type AddWorksheetModalWorksheet_worksheet$key = {
    readonly " $data"?: AddWorksheetModalWorksheet_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"AddWorksheetModalWorksheet_worksheet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AddWorksheetModalWorksheet_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publishedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercises",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = 'bc99b5cf2e1c911e93bda7a7fdaabd66';
export default node;
