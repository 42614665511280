import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import WorksheetEditor from '../components/WorksheetEditor/index';
import CreateWorksheetMutation from '../mutations/CreateWorksheetMutation';
import PageLoading from '../components/common/PageLoading';

const newSheetPage_noSheetQuery = graphql`
  query NewSheetPage_noSheetQuery {
    viewer {
      ...NavHeader_viewer
      ...PageLoading_viewer
      user {
        id
      }
    }
  }
`;
const newSheetPage_withSheetQuery = graphql`
  query NewSheetPage_withSheetQuery($newSheetId: ID!) {
    viewer {
      ...NavHeader_viewer
      ...WorksheetEditor_viewer
      user {
        id
      }
    }
    node(id: $newSheetId) {
      ... on Worksheet {
        ...WorksheetEditor_worksheet
      }
    }
  }
`;
type State = {
  newSheetId: string | undefined | null;
  isAutosaved: boolean;
};

class NewSheetPage extends Component<{}, State> {
  state: State = {
    newSheetId: null,
    isAutosaved: false,
  };

  componentDidMount() {
    const newSheetId = window.localStorage.getItem('newSheetId');

    if (newSheetId) {
      this.setState({
        newSheetId,
        isAutosaved: true,
      });
    } else {
      this.startNewWorksheet();
    }
  }

  async startNewWorksheet() {
    window.localStorage.removeItem('newSheetId');
    this.setState({
      newSheetId: null,
    });
    const res = await CreateWorksheetMutation.commit(environment);
    const newSheetId =
      res.createWorksheet &&
      res.createWorksheet.worksheet &&
      res.createWorksheet.worksheet.id; // TODO: better error handling and i18n

    if (!newSheetId) throw new Error('Unable to start a new worksheet');
    window.localStorage.setItem('newSheetId', newSheetId);
    this.setState({
      newSheetId,
    });
  }

  render() {
    return (
      <QueryRenderer<any>
        environment={environment}
        query={
          this.state.newSheetId
            ? newSheetPage_withSheetQuery
            : newSheetPage_noSheetQuery
        }
        variables={{
          newSheetId: this.state.newSheetId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          }

          if (props && this.state.newSheetId && !props.node) {
            // can't load existing sheet for some reason, start a new one
            this.startNewWorksheet();
          }

          if (!props || !props.node) {
            return <PageLoading viewer={props?.viewer} />;
          }
          return (
            <div>
              <NavHeader viewer={props.viewer} />
              <div className="mw8 center">
                <WorksheetEditor
                  editMode={false}
                  worksheet={props && props.node}
                  viewer={props && props.viewer}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default NewSheetPage;
