import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import classNames from 'classnames';

import { DraggableExercise_exercise } from './__generated__/DraggableExercise_exercise.graphql';
import ExerciseSummary from '../ExerciseSummary';
import Button from '../common/Button';
import './DraggableExercise.css';

type Props = {
  exercise: DraggableExercise_exercise;
  index: number;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
};

const DraggableExercise = ({ exercise, index, onEdit, onDelete }: Props) => {
  return (
    <Draggable draggableId={exercise.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames('DraggableExercise mb3', {
            'is-dragging': snapshot.isDragging,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="DraggableExercise-buttons">
            <div className="fr ml2 DraggableExercise-deleteButton">
              <Button
                outline
                buttonType="gray"
                onClick={() => onDelete(exercise.id)}
              >
                <i className="DraggableExercise-buttonIcon fas fa-times" />
              </Button>
            </div>
            <div className="fr ml2 DraggableExercise-editButton">
              <Button
                outline
                buttonType="gray"
                onClick={() => onEdit(exercise.id)}
              >
                <i className="DraggableExercise-buttonIcon fas fa-pen" />
              </Button>
            </div>
          </div>
          <ExerciseSummary
            key={exercise.id}
            exercise={exercise}
            alwaysShowAnswers
            scrambleWords={false}
          />
        </div>
      )}
    </Draggable>
  );
};

export default createFragmentContainer(DraggableExercise, {
  exercise: graphql`
    fragment DraggableExercise_exercise on Exercise {
      id
      ...ExerciseSummary_exercise
    }
  `,
});
