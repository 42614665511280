import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { TranslationExerciseAnalysis_exercise } from './__generated__/TranslationExerciseAnalysis_exercise.graphql';
import WordPieceSharedAnalysis from './WordPieceSharedAnalysis';
type answer = {
  isCorrect: boolean;
  response: string[];
};
type Props = {
  exercise: TranslationExerciseAnalysis_exercise;
  answers: answer[];
};

const TranslationExerciseAnalysis = ({ exercise, answers }: Props) => {
  return (
    <div className="TranslationExerciseAnalysis">
      <div className="mb3 i f6">{exercise.content.prompt}</div>
      <WordPieceSharedAnalysis
        answers={answers}
        scrambledParts={exercise.content.scrambledParts}
        correctAnswers={exercise.content.correctAnswers}
      />
    </div>
  );
};

export default createFragmentContainer(TranslationExerciseAnalysis, {
  exercise: graphql`
    fragment TranslationExerciseAnalysis_exercise on TranslationExercise {
      content {
        prompt
        correctAnswers
        scrambledParts
      }
    }
  `,
});
