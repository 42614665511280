import React from 'react';
import ReactDOM from 'react-dom';
import 'autotrack'; // for Google Analytics SPA tracking
import LocaleDataProvider from './components/LocaleDataProvider';
import App from './App';
import { isWechat } from './lib/utils'; // import registerServiceWorker from './registerServiceWorker';
import { trackCampaign } from './lib/campaignTracker';
import * as Sentry from '@sentry/browser';

import './index.css';

Sentry.init({
  dsn:
    'https://d29f87cf1cfb43409cfc0ac22b5548da@o394678.ingest.sentry.io/5245114',
  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  ignoreErrors: [/ResizeObserver loop/gi],
});

if (window.location.host.indexOf('www.') === 0) {
  window.location.host = window.location.host.replace('www.', '');
} // redirect to SSL on prod website, but not on wechat because the GFW doesn't like security :'(

if (
  window.location.protocol === 'http:' &&
  window.location.host === 'wordsheet.io' &&
  !isWechat()
) {
  // TODO: on wechat, use SSL if it seems like it's not blocked
  window.location.protocol = 'https:';
}

trackCampaign();

const rootElm = document.getElementById('root');

if (rootElm) {
  ReactDOM.render(
    <LocaleDataProvider>
      <App />
    </LocaleDataProvider>,
    rootElm,
  );
} // registerServiceWorker();
