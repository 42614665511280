import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-toggle/style.css';
import { graphql } from 'babel-plugin-relay/macro';
import Toggle from 'react-toggle';
import Button from '../common/Button';
import { Link } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import { UpgradePlans_root } from './__generated__/UpgradePlans_root.graphql';

interface UpgradePlansProps {
  root: UpgradePlans_root;
  isYearlySelected: boolean;
  onChangeYearlySelected: (isYearlySelected: boolean) => void;
}

class UpgradePlans extends Component<UpgradePlansProps> {
  renderPeriodToggle() {
    const { isYearlySelected } = this.props;
    return (
      <>
        <div className="flex items-center">
          <span className="mr2">
            {isYearlySelected ? (
              <FormattedMessage
                id="UpgradePlans.bill_yearly"
                defaultMessage="Bill yearly"
              />
            ) : (
              <FormattedMessage
                id="UpgradePlans.bill_monthly"
                defaultMessage="Bill monthly"
              />
            )}
          </span>
          <Toggle
            icons={false}
            onChange={evt =>
              this.props.onChangeYearlySelected(evt.target.checked)
            }
            checked={isYearlySelected}
          />
        </div>
        <div className="f7 gray mt2 tr-ns pb2">
          {isYearlySelected && (
            <FormattedMessage
              id="UpgradePlans.yearly_savings"
              defaultMessage="40% savings with yearly"
            />
          )}
          &nbsp;
        </div>
      </>
    );
  }

  render() {
    const { isYearlySelected } = this.props;

    const plan = this.props.root.subscriptionPlans.find(
      ({ period }) => isYearlySelected === (period === 'YEARLY'),
    ) as UpgradePlans_root['subscriptionPlans'][0];

    return (
      <div className="UpgradePlans ph3">
        <div className="fr dn db-ns">{this.renderPeriodToggle()}</div>
        <h1 className="f2">
          <FormattedMessage
            id="UpgradePlans.upgrade_header"
            defaultMessage="Choose the right plan for you"
          />
        </h1>
        <div className="db dn-ns pb3">{this.renderPeriodToggle()}</div>
        <div className="fl w-50 pa0 pa1-ns">
          <div className="ba b--black-10 measure center tl mb4 br2 pa3">
            <h2 className="mt0">Free Forever</h2>
            <p className="gray">
              <FormattedMessage
                id="UpgradePlans.free_forever_tagline"
                defaultMessage="Create and share content"
              />
            </p>
            <div className="mt4">
              <b>$0</b> / month
            </div>
            <div className="mt2 gray f6">&nbsp;</div>

            <div className="mt4">
              <div className="f6 mt2 gray">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.create_wordsheets"
                  defaultMessage="Create and share Wordsheets"
                />
              </div>
              <div className="f6 mt2 gray">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.study_wordsheets"
                  defaultMessage="Study Wordsheets"
                />
              </div>
              <div className="f6 mt2 gray">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.view_studiers"
                  defaultMessage="See which students studied"
                />
              </div>
              <div className="f6 mt2">&nbsp;</div>
              <div className="f6 mt2">&nbsp;</div>
              <div className="mt4 tc">
                <Button
                  fullWidth
                  component={Link}
                  to="/dashboard"
                  buttonType="gray"
                  outline
                >
                  <FormattedMessage
                    id="UpgradePlans.continue_with_free"
                    defaultMessage="Continue with free"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="fl w-50 pl1 pl3-ns pa1-ns">
          <div className="ba b--black-10 measure center tl mb4 br2 pa3">
            <h2 className="mt0">Teacher Plus</h2>
            <p className="gray">
              <FormattedMessage
                id="UpgradePlans.teacher_plus_tagline"
                defaultMessage="View and analyze student results"
              />
            </p>
            <div className="mt4">
              <b>${isYearlySelected ? plan.price / 12 : plan.price}</b> / month
            </div>
            <div className="mt2 gray f6">
              {isYearlySelected && (
                <FormattedMessage
                  id="UpgradePlans.billed_yearly"
                  defaultMessage="Billed yearly ({price})"
                  values={{ price: `$${plan.price}` }}
                />
              )}
              &nbsp;
            </div>

            <div className="mt4">
              <div className="f6 mt2 gray">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.create_wordsheets"
                  defaultMessage="Create and share Wordsheets"
                />
              </div>
              <div className="f6 mt2 gray">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.study_wordsheets"
                  defaultMessage="Study Wordsheets"
                />
              </div>
              <div className="f6 mt2 gray">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.view_studiers"
                  defaultMessage="See which students studied"
                />
              </div>
              <div className="f6 mt2 b">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.view_student_results"
                  defaultMessage="Detailed student scores and results"
                />
              </div>
              <div className="f6 mt2 b">
                <i className="fas fa-check mr2 green" />
                <FormattedMessage
                  id="UpgradePlans.analyze_mistakes"
                  defaultMessage="Analyze common student mistakes"
                />
              </div>
              <div className="mt4 tc">
                <Button
                  fullWidth
                  buttonType="success"
                  component={Link}
                  to={`/checkout/${isYearlySelected ? 'yearly' : 'monthly'}`}
                  onClick={() => {
                    window.fbq('track', 'AddToCart');
                  }}
                >
                  <FormattedMessage
                    id="UpgradePlans.upgrade_to_teacher"
                    defaultMessage="Upgrade to Teacher"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(UpgradePlans, {
  root: graphql`
    fragment UpgradePlans_root on Root {
      subscriptionPlans {
        period
        price
      }
    }
  `,
});
