import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { DeleteClassroomWorksheetMutation } from './__generated__/DeleteClassroomWorksheetMutation.graphql';
const mutation = graphql`
  mutation DeleteClassroomWorksheetMutation(
    $input: DeleteClassroomWorksheetInput!
  ) {
    deleteClassroomWorksheet(input: $input) {
      id
      classroom {
        id
        numClassroomWorksheets
      }
    }
  }
`;

const commit = (environment: any, id: string, classroomId: string) =>
  commitMutationPromise<DeleteClassroomWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    },
    configs: [
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'id',
      },
      {
        type: 'RANGE_DELETE',
        parentID: classroomId,
        connectionKeys: [
          {
            key: 'ClassroomWorksheetsInfiniteScroll_classroomWorksheets',
          },
        ],
        pathToConnection: ['classroom', 'classroomWorksheets'],
        deletedIDFieldName: 'id',
      },
    ],
  });

export default {
  commit,
};
