import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../../environment';
import NavHeader from '../../components/NavHeader';
import AdminHeader from '../../components/AdminHeader';
import AdminUsersList from '../../components/admin/AdminUsersList';
import { AdminUsersPageQuery } from './__generated__/AdminUsersPageQuery.graphql';
import Toggle from 'react-toggle';
import Input from '../../components/common/Input';
import AccountTypeSelect, {
  ACCOUNT_TYPE,
} from '../../components/common/AccountTypeSelect';
import Loading from '../../components/common/Loading';

const adminUsersPageQuery = graphql`
  query AdminUsersPageQuery(
    $accountType: ACCOUNT_TYPE
    $query: String
    $upgradedOnly: Boolean
  ) {
    viewer {
      ...NavHeader_viewer
      user {
        isAdmin
      }
    }
    admin {
      ...AdminUsersList_admin
        @arguments(
          accountType: $accountType
          query: $query
          upgradedOnly: $upgradedOnly
        )
    }
  }
`;

const AdminUsersPage = () => {
  const [upgradedOnly, setUpgradedOnly] = useState(false);
  const [query, setQuery] = useState('');
  const [accountType, setAccountType] = useState<ACCOUNT_TYPE | null>(null);

  const renderFilters = () => (
    <div className="flex items-center mt3 ba b--black-10 pa2">
      <div className="tl flex items-center">
        <Toggle
          icons={false}
          onChange={evt => setUpgradedOnly(evt.target.checked)}
          checked={upgradedOnly}
        />
        <span className="pl2" onClick={() => setUpgradedOnly(!upgradedOnly)}>
          Upgraded only
        </span>
      </div>
      <div className="flex items-center ml3">
        <span className="mr2">Account type</span>
        <div style={{ width: '200px' }}>
          <AccountTypeSelect
            value={accountType}
            onChange={setAccountType}
            includeEmpty
          />
        </div>
      </div>
      <div className="flex items-center ml3">
        <span className="mr2">Query</span>
        <Input value={query} onChange={evt => setQuery(evt.target.value)} />
      </div>
    </div>
  );

  return (
    <QueryRenderer<AdminUsersPageQuery>
      environment={environment}
      variables={{
        upgradedOnly,
        query,
        accountType,
      }}
      query={adminUsersPageQuery}
      render={({ error, props }) => {
        const renderUsersListContents = () => {
          if (error) {
            return <div>{error.message}</div>;
          }

          if (!props) return <Loading />;

          if (
            !props.viewer ||
            !props.viewer.user ||
            !props.viewer.user.isAdmin
          ) {
            return <Redirect to="/" />;
          }
          return (
            <AdminUsersList
              admin={
                props.admin as NonNullable<
                  AdminUsersPageQuery['response']['admin']
                >
              }
              upgradedOnly={upgradedOnly}
              accountType={accountType}
              query={query}
            />
          );
        };

        return (
          <div className="AdminUsersPage">
            <NavHeader viewer={props?.viewer} />
            <div className="mw8 center">
              <AdminHeader activeTab="users" />
              {renderFilters()}
              {renderUsersListContents()}
            </div>
          </div>
        );
      }}
    />
  );
};

export default AdminUsersPage;
