import React, { HTMLProps } from 'react';
import { ReactNode } from 'react';
import Measure from 'react-measure';

export type bounds = {
  width: number;
  height: number;
};

export type onUpdateBounds = (bounds: bounds) => void;

interface Props extends HTMLProps<HTMLDivElement> {
  onUpdateBounds?: onUpdateBounds;
  children?: ReactNode;
}

export default (props: Props) => {
  const { children, onUpdateBounds, ...otherProps } = props;
  return (
    <Measure
      bounds
      onResize={contentRect =>
        onUpdateBounds &&
        contentRect.bounds &&
        onUpdateBounds(contentRect.bounds)
      }
    >
      {({ measureRef }) => (
        <div ref={measureRef} {...otherProps}>
          {children}
        </div>
      )}
    </Measure>
  );
};
