import React from 'react';
import { Redirect } from 'react-router-dom';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import ResetPasswordForm from '../components/auth/ResetPasswordForm';
import { ResetPasswordPageQuery } from './__generated__/ResetPasswordPageQuery.graphql';

const resetPasswordPageQuery = graphql`
  query ResetPasswordPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        id
      }
    }
  }
`;
type Props = {
  match: match<{ token: string }>;
};

const ResetPasswordPage = ({ match }: Props) => (
  <QueryRenderer<ResetPasswordPageQuery>
    environment={environment}
    variables={{}}
    query={resetPasswordPageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      const viewer = props && props.viewer;
      if ((viewer && viewer.user) || !match.params.token) {
        return <Redirect to="/dashboard" />;
      }

      return (
        <div className="ResetPasswordPage">
          <NavHeader viewer={viewer} />
          <div className="pa3">
            <h1 className="f2">
              <FormattedMessage
                id="ResetPasswordPage.reset_password_header"
                defaultMessage="Reset your password"
              />
            </h1>
            <div className="ba b--black-10 measure center pa3 tl mb4 br2">
              <ResetPasswordForm
                background="white"
                environment={environment}
                token={match.params.token}
              />
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default ResetPasswordPage;
