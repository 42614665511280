import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import './AdminHeader.css';
type Props = {
  activeTab: 'users' | 'worksheets' | 'studyRecords' | 'classrooms';
};

const AdminHeader = ({ activeTab }: Props) => (
  <div className="AdminHeader tl">
    <h2 className="lh-title f4">
      <FormattedMessage id="AdminHeader.title" defaultMessage="Admin" />
    </h2>
    <div className="flex AdminHeader-tabs">
      <Link
        className={classNames('db pa3 link', {
          'bg-black-10': activeTab === 'users',
        })}
        to="/admin/users"
      >
        <FormattedMessage id="AdminHeader.users_link" defaultMessage="Users" />
      </Link>
      <Link
        className={classNames('db pa3 link', {
          'bg-black-10': activeTab === 'worksheets',
        })}
        to="/admin/worksheets"
      >
        <FormattedMessage
          id="AdminHeader.wordsheets_link"
          defaultMessage="Wordsheets"
        />
      </Link>
      <Link
        className={classNames('db pa3 link', {
          'bg-black-10': activeTab === 'classrooms',
        })}
        to="/admin/classes"
      >
        <FormattedMessage
          id="AdminHeader.classrooms_link"
          defaultMessage="Classes"
        />
      </Link>
      <Link
        className={classNames('db pa3 link', {
          'bg-black-10': activeTab === 'studyRecords',
        })}
        to="/admin/study-records"
      >
        <FormattedMessage
          id="AdminHeader.study_records_link"
          defaultMessage="Study records"
        />
      </Link>
    </div>
  </div>
);

export default AdminHeader;
