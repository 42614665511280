import React, { ReactNode, FC } from 'react';

interface ErrorBoxProps {
  children: ReactNode;
}

const ErrorBox: FC<ErrorBoxProps> = ({ children }) => (
  <div className="pa3 mb4 red ba b--light-red">{children}</div>
);

export default ErrorBox;
