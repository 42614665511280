/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionPlanPeriod = "MONTHLY" | "YEARLY" | "%future added value";
export type UpgradePlans_root = {
    readonly subscriptionPlans: ReadonlyArray<{
        readonly period: SubscriptionPlanPeriod;
        readonly price: number;
    }>;
    readonly " $refType": "UpgradePlans_root";
};
export type UpgradePlans_root$data = UpgradePlans_root;
export type UpgradePlans_root$key = {
    readonly " $data"?: UpgradePlans_root$data;
    readonly " $fragmentRefs": FragmentRefs<"UpgradePlans_root">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "UpgradePlans_root",
  "type": "Root",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscriptionPlans",
      "storageKey": null,
      "args": null,
      "concreteType": "SubscriptionPlan",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "period",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '888b8dbd7b44d41f0677a0fb6f0a5a20';
export default node;
