/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WORKSHEET_VISIBILITY = "EVERYONE" | "LOGGED_IN" | "PASSWORD" | "%future added value";
export type WorksheetAuth_worksheet = {
    readonly id: string;
    readonly title: string | null;
    readonly visibility: WORKSHEET_VISIBILITY;
    readonly isVisibleToViewer: boolean;
    readonly " $refType": "WorksheetAuth_worksheet";
};
export type WorksheetAuth_worksheet$data = WorksheetAuth_worksheet;
export type WorksheetAuth_worksheet$key = {
    readonly " $data"?: WorksheetAuth_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetAuth_worksheet">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WorksheetAuth_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "visibility",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isVisibleToViewer",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '0748d42d6b55329687440205b2de80b9';
export default node;
