import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { deleteExerciseFromStore } from './helpers/exerciseMutationHelpers';
import { DeleteExerciseMutation } from './__generated__/DeleteExerciseMutation.graphql';
const mutation = graphql`
  mutation DeleteExerciseMutation($input: DeleteExerciseInput!) {
    deleteExercise(input: $input) {
      id
    }
  }
`;

const commit = (environment: any, id: string, worksheetId: string) =>
  commitMutationPromise<DeleteExerciseMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    },
    optimisticUpdater: store => deleteExerciseFromStore(store, id, worksheetId),
    updater: store => deleteExerciseFromStore(store, id, worksheetId),
  });

export default {
  commit,
};
