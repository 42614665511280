import React from 'react';
import { FormattedMessage } from 'react-intl';
import { findPiecesSpanningText, checkAnswer } from '../../lib/atomHelpers';
import './WordPieceSharedAnalysis.css';
type answer = {
  isCorrect: boolean;
  response: string[];
};
type Props = {
  correctAnswers: ReadonlyArray<string>;
  scrambledParts: ReadonlyArray<string>;
  answers: answer[];
};

const getAnalysisGroups = (
  correctAnswers: readonly string[],
  scrambledParts: readonly string[],
  answers: answer[],
) => {
  const counts: {
    correctResponses: { [answer: string]: number };
    incorrectResponses: { [answer: string]: number };
    totalCorrect: number;
    total: number;
  } = {
    correctResponses: {},
    incorrectResponses: {},
    totalCorrect: 0,
    total: 0,
  };
  correctAnswers.forEach(answer => {
    counts.correctResponses[answer] = 0;
  });
  answers.forEach(answer => {
    const response = answer.response.join('');

    if (answer.isCorrect) {
      correctAnswers.forEach(correctAnswer => {
        if (checkAnswer(response, [correctAnswer])) {
          counts.correctResponses[correctAnswer] += 1;
          counts.totalCorrect += 1;
          counts.total += 1;
        }
      });
    } else {
      if (!counts.incorrectResponses[response]) {
        counts.incorrectResponses[response] = 0;
      }

      counts.incorrectResponses[response] += 1;
      counts.total += 1;
    }
  });
  const sortedIncorrectResponses = Object.keys(counts.incorrectResponses).map(
    response => ({
      response,
      count: counts.incorrectResponses[response],
    }),
  );
  sortedIncorrectResponses.sort((resA, resB) => resB.count - resA.count);
  return {
    correctResponses: correctAnswers.map(response => ({
      response,
      count: counts.correctResponses[response],
    })),
    incorrectResponses: sortedIncorrectResponses,
    totalCorrect: counts.totalCorrect,
    total: counts.total,
  };
};

const renderResponse = (
  response: string,
  scrambledParts: readonly string[],
) => {
  const spanningPieces = findPiecesSpanningText(
    response,
    scrambledParts.slice(),
  );
  if (!spanningPieces) return <div className="mb2 fl pv2 gray">{response}</div>;
  return spanningPieces.map((piece, i) => (
    <div className="mb2 mr2 bg-white fl pv2 ph3 br1" key={i}>
      {scrambledParts[piece]}
    </div>
  ));
};

const WordPieceSharedAnalysis = ({
  correctAnswers,
  scrambledParts,
  answers,
}: Props) => {
  const {
    correctResponses,
    incorrectResponses,
    totalCorrect,
    total,
  } = getAnalysisGroups(correctAnswers, scrambledParts, answers);
  const hasAnswers = answers.length > 0;
  return (
    <div className="WordPieceSharedAnalysis">
      {hasAnswers ? (
        <div>
          <FormattedMessage
            id="WordPieceSharedAnalysis.percent_correct"
            defaultMessage="{percent}% correct"
            values={{
              percent: (
                <span className="f3">
                  {Math.round((100 * totalCorrect) / total)}
                </span>
              ),
            }}
          />
          <span className="dib gray ml3 f6">
            {totalCorrect} / {total}
          </span>
          <div className="f6 gray mt3">
            <FormattedMessage
              id="WordPieceSharedAnalysis.correct_answers_label"
              defaultMessage="Correct answers"
            />
          </div>
          <div className="WordPieceSharedAnalysis-correctResponses">
            {correctResponses.map(({ response, count }, i) => (
              <div className="mt2" key={`correct-${i}`}>
                <span className="WordPieceSharedAnalysis-count dib f3 mr3 fl is-correct">
                  {count}
                </span>
                {renderResponse(response, scrambledParts)}
                <div className="cb" />
              </div>
            ))}
          </div>
          {incorrectResponses.length === 0 ? null : (
            <React.Fragment>
              <div className="f6 gray mt3">
                <FormattedMessage
                  id="WordPieceSharedAnalysis.incorrect_responses_label"
                  defaultMessage="Top incorrect responses"
                />
              </div>
              <div className="WordPieceSharedAnalysis-incorrectResponses">
                {incorrectResponses.map(({ response, count }, i) => (
                  <div className="mt2" key={`incorrect-${i}`}>
                    <span className="WordPieceSharedAnalysis-count dib f3 mr3 fl is-incorrect">
                      {count}
                    </span>
                    {renderResponse(response, scrambledParts)}
                    <div className="cb" />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      ) : (
        <div className="gray pv3" key="no-data">
          <FormattedMessage
            id="WordPieceSharedAnalysis.no_results"
            defaultMessage="No data yet"
          />
        </div>
      )}
    </div>
  );
};

export default WordPieceSharedAnalysis;
