import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { AdminUpgradeUserMutation } from './__generated__/AdminUpgradeUserMutation.graphql';
const mutation = graphql`
  mutation AdminUpgradeUserMutation($input: AdminUpgradeUserInput!) {
    adminUpgradeUser(input: $input) {
      user {
        id
        isUpgradedTeacher
        subscriptionStatus {
          subscriptionType
        }
      }
    }
  }
`;

const commit = (environment: any, userId: string, subscriptionNote?: string) =>
  commitMutationPromise<AdminUpgradeUserMutation>(environment, {
    mutation,
    variables: {
      input: {
        userId,
        subscriptionNote,
      },
    },
  });

export default {
  commit,
};
