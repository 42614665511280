/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudyRecord_studyRecord = {
    readonly createdAt: number;
    readonly completedAt: number | null;
    readonly identifier: string | null;
    readonly score: number | null;
    readonly duration: number | null;
    readonly user: {
        readonly username: string;
    } | null;
    readonly worksheet: {
        readonly identifier: string | null;
        readonly title: string | null;
    };
    readonly " $refType": "StudyRecord_studyRecord";
};
export type StudyRecord_studyRecord$data = StudyRecord_studyRecord;
export type StudyRecord_studyRecord$key = {
    readonly " $data"?: StudyRecord_studyRecord$data;
    readonly " $fragmentRefs": FragmentRefs<"StudyRecord_studyRecord">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StudyRecord_studyRecord",
  "type": "StudyRecord",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "completedAt",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "score",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "duration",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "worksheet",
      "storageKey": null,
      "args": null,
      "concreteType": "Worksheet",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '701c0d87990e54faac0b12372fd8f0a0';
export default node;
