import React from 'react';
import Spinner from './Spinner';

const Loading = () => (
  <div className="tc pa3 pt4 center" key={0}>
    <Spinner size="large" />
  </div>
);

export default Loading;
