import React, { Component } from 'react';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import localeManager from '../lib/localeManager';
import enMessages from '../i18n/en.json';
const messagesMap = {
  en: enMessages,
};
type locale = 'en';

const determineLocale = (_localeString: string): locale => {
  return 'en';
};

type Props = {
  children: ReactNode;
};
type State = {
  locale: locale;
};

class LocaleDataProvider extends Component<Props, State> {
  state: State = {
    locale: 'en',
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    return {
      locale: determineLocale(localeManager.getLocale()),
    };
  }

  onUpdateLocale = (localeString: string) => {
    this.setState({
      locale: determineLocale(localeString),
    });
  };

  componentDidMount() {
    localeManager.addListener(this.onUpdateLocale);
  }

  componentWillUnmount() {
    localeManager.removeListener(this.onUpdateLocale);
  }

  render() {
    const locale = this.state.locale;
    return (
      <IntlProvider locale={locale} messages={messagesMap[locale]} key={locale}>
        {this.props.children}
      </IntlProvider>
    );
  }
}

export default LocaleDataProvider;
