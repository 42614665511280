/* tslint:disable */
/* eslint-disable */
/* @relayHash 1227fceeee6a76dfb29e578a74ce0fe9 */

import { ConcreteRequest } from "relay-runtime";
export type CreateMultipleChoiceAnswerInput = {
    studyRecordId: string;
    exerciseId: string;
    choices: Array<MultipleChoiceAnswerChoiceInput>;
    isCorrect: boolean;
    clientMutationId?: string | null;
};
export type MultipleChoiceAnswerChoiceInput = {
    isChosen: boolean;
    text: string;
};
export type CreateMultipleChoiceAnswerMutationVariables = {
    input: CreateMultipleChoiceAnswerInput;
};
export type CreateMultipleChoiceAnswerMutationResponse = {
    readonly createMultipleChoiceAnswer: {
        readonly answer: {
            readonly id: string;
        } | null;
    } | null;
};
export type CreateMultipleChoiceAnswerMutation = {
    readonly response: CreateMultipleChoiceAnswerMutationResponse;
    readonly variables: CreateMultipleChoiceAnswerMutationVariables;
};



/*
mutation CreateMultipleChoiceAnswerMutation(
  $input: CreateMultipleChoiceAnswerInput!
) {
  createMultipleChoiceAnswer(input: $input) {
    answer {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateMultipleChoiceAnswerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createMultipleChoiceAnswer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMultipleChoiceAnswerPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "answer",
        "storageKey": null,
        "args": null,
        "concreteType": "MultipleChoiceAnswer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateMultipleChoiceAnswerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateMultipleChoiceAnswerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateMultipleChoiceAnswerMutation",
    "id": null,
    "text": "mutation CreateMultipleChoiceAnswerMutation(\n  $input: CreateMultipleChoiceAnswerInput!\n) {\n  createMultipleChoiceAnswer(input: $input) {\n    answer {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0704b7a0711229821a840a28e02a9ff4';
export default node;
