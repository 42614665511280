/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassroomViewer_classroom = {
    readonly id: string;
    readonly identifier: string;
    readonly title: string;
    readonly description: string | null;
    readonly numActiveMemberships: number;
    readonly numClassroomWorksheets: number;
    readonly requiresApproval: boolean;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomActions_classroom" | "PendingMemberships_classroom" | "WorksheetsTab_classroom" | "MembersTab_classroom" | "JoinClassroomModal_classroom" | "AddWorksheetModal_classroom" | "EditClassroomModal_classroom">;
    readonly " $refType": "ClassroomViewer_classroom";
};
export type ClassroomViewer_classroom$data = ClassroomViewer_classroom;
export type ClassroomViewer_classroom$key = {
    readonly " $data"?: ClassroomViewer_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassroomViewer_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ClassroomViewer_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numActiveMemberships",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numClassroomWorksheets",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requiresApproval",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ClassroomActions_classroom",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PendingMemberships_classroom",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "WorksheetsTab_classroom",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MembersTab_classroom",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "JoinClassroomModal_classroom",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AddWorksheetModal_classroom",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EditClassroomModal_classroom",
      "args": null
    }
  ]
};
(node as any).hash = '33eb2c6a9579781b04cea9def50818de';
export default node;
