import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import classNames from 'classnames';

import environment from '../../environment';
import UploadImageMutation from '../../mutations/UploadImageMutation';
import { ImageChooserSearchQuery } from './__generated__/ImageChooserSearchQuery.graphql';
import {
  ImageChooserImageQueryResponse,
  ImageChooserImageQuery,
} from './__generated__/ImageChooserImageQuery.graphql';
import Input from '../common/Input';
import Button from '../common/Button';

import './ImageChooser.css';
import { exists } from '../../lib/utils';

type Props = {
  defaultQuery: null | string;
  defaultSelectedImageId: null | string;
  onSelectImageId: (id: null | string) => void;
  intl: IntlShape;
};

type State = {
  query: undefined | string;
  selectedImageId: null | string;
  hoveringImage: null | ImageChooserImageQueryResponse['node'];
  isUploading: boolean;
  uploadError: null | string;
};

const messages = defineMessages({
  searchPlaceholder: {
    id: 'ImageChooser.search_placeholder',
    defaultMessage: 'Search for images',
  },
});

const imageChooserSearchQuery = graphql`
  query ImageChooserSearchQuery($query: String!) {
    imageSearch(query: $query) {
      images(first: 20) {
        edges {
          node {
            id
            url(size: LARGE_SQUARE)
          }
        }
      }
    }
  }
`;

const imageChooserImageQuery = graphql`
  query ImageChooserImageQuery($id: ID!) {
    node(id: $id) {
      ... on Image {
        id
        url(size: LARGE_SQUARE)
      }
    }
  }
`;

class ImageChooser extends Component<Props, State> {
  state: State = {
    query: undefined,
    selectedImageId: null,
    hoveringImage: null,
    isUploading: false,
    uploadError: null,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      (prevState.query === null && nextProps.defaultQuery) ||
      (!prevState.selectedImageId && nextProps.defaultSelectedImageId)
    ) {
      return {
        query: nextProps.defaultQuery || '',
        selectedImageId: nextProps.defaultSelectedImageId,
      };
    }
    return null;
  }

  onUploadImage = async (files: FileList) => {
    const file = files[0];
    if (!file) return;
    this.setState({ isUploading: true, uploadError: null });
    try {
      const res = await UploadImageMutation.commit(environment, file);
      if (res.uploadImage && res.uploadImage.image) {
        this.setState({ selectedImageId: res.uploadImage.image.id });
      }
    } catch (err) {
      this.setState({ uploadError: err.message });
    }
    this.setState({ isUploading: false });
  };

  renderResults() {
    return (
      <div className="ImageChooser-searchResults">
        <QueryRenderer<ImageChooserSearchQuery>
          environment={environment}
          query={imageChooserSearchQuery}
          render={({ error, props }) => {
            if (!props) return <span className="gray f7">Loading...</span>;
            if (
              props.imageSearch &&
              props.imageSearch.images &&
              props.imageSearch.images.edges &&
              props.imageSearch.images.edges.length > 0
            ) {
              return props.imageSearch.images.edges
                .filter(exists)
                .map((edge, i) => (
                  // eslint-disable-next-line
                  <a
                    key={i}
                    className="ImageChooser-searchResultSpacer fl"
                    onMouseEnter={() =>
                      this.setState({ hoveringImage: edge.node })
                    }
                    onMouseLeave={() =>
                      this.setState(state => {
                        if (state.hoveringImage === edge.node) {
                          return { ...state, hoveringImage: null };
                        }
                        return {};
                      })
                    }
                    onClick={() =>
                      edge.node &&
                      this.setState({ selectedImageId: edge.node.id })
                    }
                  >
                    <img
                      alt="search result"
                      className={classNames('ImageChooser-searchResult', {
                        'is-selected':
                          edge.node &&
                          this.state.selectedImageId === edge.node.id,
                      })}
                      src={(edge.node && edge.node.url) || ''}
                    />
                  </a>
                ));
            }
            return (
              <span className="gray f7">
                <FormattedMessage
                  id="ImageChooser.no_results"
                  defaultMessage="No results :("
                />
              </span>
            );
          }}
          variables={{ query: this.state.query || '' }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="ImageChooser">
        <div className="ImageChooser-imageTarget">
          {!this.state.selectedImageId ? null : (
            <React.Fragment>
              <QueryRenderer<ImageChooserImageQuery>
                environment={environment}
                query={imageChooserImageQuery}
                variables={{ id: this.state.selectedImageId }}
                render={({ error, props }) => {
                  if (!props)
                    return <span className="gray f7">Loading...</span>;
                  return (
                    <img
                      alt="selected"
                      className="ImageChooser-selectedImage"
                      src={(props.node && props.node.url) || ''}
                    />
                  );
                }}
              />
              <button
                className="ImageChooser-deleteImageButton"
                onClick={() => this.setState({ selectedImageId: null })}
              >
                <i className="fas fa-trash" />
              </button>
            </React.Fragment>
          )}
          {!this.state.hoveringImage ? null : (
            <img
              alt="selected"
              src={this.state.hoveringImage.url}
              className={classNames('ImageChooser-hoveringImage', {
                'is-selected':
                  this.state.selectedImageId === this.state.hoveringImage.id,
              })}
            />
          )}
        </div>
        <div className="tc ImageChooser-uploadButton">
          <Button component="label" htmlFor="image-uploader" fullWidth>
            <i
              className={classNames('fas dib mr2', {
                'fa-upload': !this.state.isUploading,
                'fa-spinner': this.state.isUploading,
              })}
            />
            <FormattedMessage
              id="ImageChooser.upload_image"
              defaultMessage="Upload image"
            />
          </Button>
          <input
            className="dn"
            type="file"
            id="image-uploader"
            data-testid="ImageChooser-fileUpload"
            disabled={this.state.isUploading}
            onChange={evt =>
              evt.target.files && this.onUploadImage(evt.target.files)
            }
          />
        </div>
        {!this.state.uploadError ? null : (
          <div className="mt1 tc f7 red">{this.state.uploadError}</div>
        )}
        <div className="gray tc i mv2">
          <FormattedMessage id="ImageChooser.or" defaultMessage="or" />
        </div>
        <Input
          value={this.state.query}
          onChange={evt => this.setState({ query: evt.target.value })}
          placeholder={this.props.intl.formatMessage(
            messages.searchPlaceholder,
          )}
        />
        <div className="ImageChooser-hint">
          <FormattedMessage
            id="ImageChooser.hint_search_using_english"
            defaultMessage="note: searching in English may give better results"
          />
        </div>
        {!this.state.query ? null : this.renderResults()}
        <div className="cb" />
        <div className="mt2 ImageChooser-doneButton">
          <Button
            fullWidth
            onClick={() =>
              this.props.onSelectImageId(this.state.selectedImageId)
            }
          >
            <FormattedMessage id="ImageChooser.done" defaultMessage="Done" />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(ImageChooser);
