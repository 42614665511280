import React, { ReactNode, FC } from 'react';
import './Detail.css';

interface DetailProps {
  label: string | ReactNode;
  children: ReactNode;
}

const Detail: FC<DetailProps> = ({ label, children }) => (
  <div className="pv3 Detail">
    <span className="mr2 f6 Detail-label">{label}</span>
    {children}
  </div>
);

export default Detail;
