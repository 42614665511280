import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import MeasurableDiv from './MeasurableDiv';
import { bounds } from './MeasurableDiv';

import './WordSplitter.css';

type position = {
  x: number;
  y: number;
};

type Props = {
  // these are the positions where atoms are fused together
  // there are n - 1 fusion points, since they are between atoms
  fusedPositions: Set<number>;
  // atoms are the smallest pieces of words that can be joined or split apart
  // for Chinese this will be individual characters, for English it will be words
  atoms: string[];
  onFuseAtoms: (atom: number) => void;
  onSplitAtoms: (atom: number) => void;
  wordJoinerWidth: number;
  wordSplitterWidth: number;
  rowHeight: number;
  rowMargin: number;
};

type State = {
  // these are just for keeping track of when props change
  atoms: string[];
  fusedPositions: Set<number>;

  // internal state
  hoveringConnector: null | number;
  containerWidth: null | number;
  atomBounds: { [atom: number]: bounds };
};

const updateAtomBounds = (atomIndex: number, bounds: bounds) => (
  prevState: State,
  props: Props,
) => ({
  atomBounds: { ...prevState.atomBounds, [atomIndex]: bounds },
});

const CONNECTOR_OVERLAP = 3;

class WordSplitter extends Component<Props, State> {
  static defaultProps = {
    wordJoinerWidth: 24,
    wordSplitterWidth: 16,
    rowHeight: 50,
    rowMargin: 5,
  };

  state: State = {
    atoms: [],
    fusedPositions: new Set(),
    hoveringConnector: null,
    containerWidth: null,
    atomBounds: {},
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      prevState.atoms !== nextProps.atoms ||
      prevState.fusedPositions !== nextProps.fusedPositions
    ) {
      return {
        atoms: nextProps.atoms,
        fusedPositions: nextProps.fusedPositions,
        hoveringConnector: null,
      };
    }
    return null;
  }

  hasMeasuredPositions() {
    const { containerWidth, atomBounds } = this.state;
    if (!containerWidth) return false;
    for (let i = 0; i < this.props.atoms.length; i++) {
      if (!atomBounds[i]) return false;
    }
    return true;
  }

  shouldSlideConnector(connectorIndex: number) {
    const { hoveringConnector } = this.state;
    const { fusedPositions } = this.props;
    if (connectorIndex === hoveringConnector || hoveringConnector == null) {
      return false;
    }
    // check that all indices between connector and hover are joined
    const maxConnectorIndex = Math.max(connectorIndex, hoveringConnector - 1);
    const minConnectorIndex = Math.min(connectorIndex, hoveringConnector + 1);
    for (let i = minConnectorIndex; i <= maxConnectorIndex; i++) {
      if (!fusedPositions.has(i)) return false;
    }
    return true;
  }

  shouldSlideAtom(atomIndex: number) {
    const { hoveringConnector } = this.state;
    if (hoveringConnector == null) {
      return false;
    }
    if (
      hoveringConnector === atomIndex ||
      hoveringConnector === atomIndex - 1
    ) {
      return true;
    }
    return (
      this.shouldSlideConnector(atomIndex) ||
      this.shouldSlideConnector(atomIndex - 1)
    );
  }

  // how wide is the word starting at this atom?
  // need to make sure we don't line-break inside of words
  getWordWidth(atomIndex: number) {
    const { atomBounds } = this.state;
    const { fusedPositions, wordSplitterWidth } = this.props;
    let wordWidth = atomBounds[atomIndex].width;
    let curConnectorIndex = atomIndex;
    while (fusedPositions.has(curConnectorIndex)) {
      wordWidth += wordSplitterWidth + atomBounds[curConnectorIndex + 1].width;
      curConnectorIndex += 1;
    }
    return wordWidth;
  }

  calculateBaseAtomPositions() {
    if (!this.hasMeasuredPositions()) return [];
    const { atomBounds, containerWidth } = this.state;
    const {
      rowHeight,
      rowMargin,
      wordJoinerWidth,
      wordSplitterWidth,
      fusedPositions,
      atoms,
    } = this.props;
    let xOffset = 0;
    let yOffset = 0;
    return atoms.map((atom, atomIndex) => {
      if (atomIndex > 0) {
        const isWordJoiner = !fusedPositions.has(atomIndex - 1);
        const joinerOffset = isWordJoiner ? wordJoinerWidth : wordSplitterWidth;
        xOffset += joinerOffset + atomBounds[atomIndex - 1].width;
      }

      if (xOffset + this.getWordWidth(atomIndex) > (containerWidth || 0)) {
        xOffset = wordJoinerWidth;
        yOffset += rowHeight + rowMargin;
      }
      return { x: xOffset, y: yOffset };
    });
  }

  renderAtoms(basePositions: position[]) {
    const parts: ReactNode[] = [];
    this.props.atoms.forEach((atom, atomIndex) => {
      const atomPosition = basePositions[atomIndex];
      const { wordJoinerWidth, wordSplitterWidth, fusedPositions } = this.props;
      if (atomIndex > 0 && atomPosition) {
        const isWordJoiner = !fusedPositions.has(atomIndex - 1);
        const connecterWidth = isWordJoiner
          ? wordJoinerWidth
          : wordSplitterWidth;
        const connectorPosition = {
          x: atomPosition.x - connecterWidth,
          y: atomPosition.y,
        };
        parts.push(this.renderConnector(atomIndex - 1, connectorPosition));
      }
      parts.push(this.renderAtom(atomIndex, atomPosition));
    });
    return parts;
  }

  renderConnector(connectorIndex: number, position: position) {
    const { hoveringConnector } = this.state;
    const isJoiner = !this.props.fusedPositions.has(connectorIndex);
    let slideAdjustment = 0;
    if (
      hoveringConnector != null &&
      this.shouldSlideConnector(connectorIndex)
    ) {
      const isHoveringSplitter = this.props.fusedPositions.has(
        hoveringConnector,
      );
      slideAdjustment = connectorIndex > hoveringConnector ? -4 : 4;
      if (isHoveringSplitter) slideAdjustment *= -1;
    }
    const {
      rowHeight,
      wordJoinerWidth,
      wordSplitterWidth,
      onFuseAtoms,
      onSplitAtoms,
    } = this.props;
    return (
      <div
        key={`${connectorIndex}-connector`}
        className={classNames({
          'WordSplitter-joiner': isJoiner,
          'WordSplitter-splitter': !isJoiner,
          'is-hovering': this.state.hoveringConnector === connectorIndex,
        })}
        style={{
          left: `${position.x + slideAdjustment - CONNECTOR_OVERLAP}px`,
          top: `${position.y}px`,
          height: `${rowHeight}px`,
          lineHeight: `${rowHeight}px`,
          width: `${(isJoiner ? wordJoinerWidth : wordSplitterWidth) +
            2 * CONNECTOR_OVERLAP}px`,
        }}
        onMouseEnter={() =>
          this.setState({ hoveringConnector: connectorIndex })
        }
        onMouseLeave={() => this.setState({ hoveringConnector: null })}
        onClick={() =>
          isJoiner ? onFuseAtoms(connectorIndex) : onSplitAtoms(connectorIndex)
        }
      >
        <i
          className={classNames('fas', {
            'fa-angle-left': !isJoiner,
            'fa-angle-right': isJoiner,
          })}
        ></i>
        <i
          className={classNames('fas', {
            'fa-angle-left': isJoiner,
            'fa-angle-right': !isJoiner,
          })}
        ></i>
      </div>
    );
  }

  renderAtom(atomIndex: number, position: null | position) {
    const { hoveringConnector } = this.state;
    const { rowHeight, atoms } = this.props;
    const text = atoms[atomIndex];
    let slideAdjustment = 0;
    if (hoveringConnector != null && this.shouldSlideAtom(atomIndex)) {
      const isHoveringSplitter = this.props.fusedPositions.has(
        hoveringConnector,
      );
      slideAdjustment = atomIndex > hoveringConnector ? -4 : 4;
      if (isHoveringSplitter) slideAdjustment *= -1;
    }
    return (
      <MeasurableDiv
        className="WordSplitter-atom"
        key={`${atomIndex}-atom`}
        style={{
          left: `${position ? position.x + slideAdjustment : 0}px`,
          top: `${position ? position.y : 0}px`,
          height: `${rowHeight}px`,
          lineHeight: `${rowHeight}px`,
          visibility: position ? 'visible' : 'hidden',
        }}
        onUpdateBounds={bounds =>
          this.setState(updateAtomBounds(atomIndex, bounds))
        }
      >
        {text}
      </MeasurableDiv>
    );
  }

  render() {
    const basePositions = this.calculateBaseAtomPositions();
    let height = 0;
    if (basePositions.length > 0) {
      height = basePositions[basePositions.length - 1].y + this.props.rowHeight;
    }
    height += this.props.rowMargin;
    return (
      <MeasurableDiv
        className="WordSplitter"
        onUpdateBounds={bounds =>
          this.setState({ containerWidth: bounds.width })
        }
        style={{ height: `${height}px` }}
      >
        {this.renderAtoms(basePositions)}
      </MeasurableDiv>
    );
  }
}

export default WordSplitter;
