/* tslint:disable */
/* eslint-disable */
/* @relayHash 8f7b1855a4615a92aec3664065d0f29e */

import { ConcreteRequest } from "relay-runtime";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type ChangeClassroomMembershipLevelInput = {
    classroomMembershipId: string;
    level: CLASSROOM_MEMBERSHIP_LEVEL;
    clientMutationId?: string | null;
};
export type ChangeClassroomMembershipLevelMutationVariables = {
    input: ChangeClassroomMembershipLevelInput;
};
export type ChangeClassroomMembershipLevelMutationResponse = {
    readonly changeClassroomMembershipLevel: {
        readonly classroomMembership: {
            readonly id: string;
            readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
            readonly classroom: {
                readonly id: string;
                readonly numActiveMemberships: number;
            };
        };
    } | null;
};
export type ChangeClassroomMembershipLevelMutation = {
    readonly response: ChangeClassroomMembershipLevelMutationResponse;
    readonly variables: ChangeClassroomMembershipLevelMutationVariables;
};



/*
mutation ChangeClassroomMembershipLevelMutation(
  $input: ChangeClassroomMembershipLevelInput!
) {
  changeClassroomMembershipLevel(input: $input) {
    classroomMembership {
      id
      level
      classroom {
        id
        numActiveMemberships
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ChangeClassroomMembershipLevelInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "changeClassroomMembershipLevel",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeClassroomMembershipLevelPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "classroomMembership",
        "storageKey": null,
        "args": null,
        "concreteType": "ClassroomMembership",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "level",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "classroom",
            "storageKey": null,
            "args": null,
            "concreteType": "Classroom",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numActiveMemberships",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ChangeClassroomMembershipLevelMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ChangeClassroomMembershipLevelMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ChangeClassroomMembershipLevelMutation",
    "id": null,
    "text": "mutation ChangeClassroomMembershipLevelMutation(\n  $input: ChangeClassroomMembershipLevelInput!\n) {\n  changeClassroomMembershipLevel(input: $input) {\n    classroomMembership {\n      id\n      level\n      classroom {\n        id\n        numActiveMemberships\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '7c30b8a0a11abc6235360032b811c9b3';
export default node;
