/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ACCOUNT_TYPE = "STUDENT" | "TEACHER" | "%future added value";
export type SubscriptionType = "TEACHER_FOREVER" | "TEACHER_MONTHLY" | "TEACHER_YEARLY" | "%future added value";
export type AdminUser_user = {
    readonly id: string;
    readonly createdAt: number;
    readonly username: string;
    readonly email: string | null;
    readonly isAdmin: boolean;
    readonly hasGoogle: boolean;
    readonly hasPassword: boolean;
    readonly isUpgradedTeacher: boolean;
    readonly accountType: ACCOUNT_TYPE | null;
    readonly subscriptionStatus: {
        readonly subscriptionType: SubscriptionType | null;
        readonly subscriptionNote: string | null;
    } | null;
    readonly worksheets: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string | null;
                readonly identifier: string | null;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "AdminUser_user";
};
export type AdminUser_user$data = AdminUser_user;
export type AdminUser_user$key = {
    readonly " $data"?: AdminUser_user$data;
    readonly " $fragmentRefs": FragmentRefs<"AdminUser_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AdminUser_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasGoogle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasPassword",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isUpgradedTeacher",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "accountType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscriptionStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "UserSubscriptionStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionNote",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "worksheets",
      "storageKey": null,
      "args": null,
      "concreteType": "WorksheetConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "WorksheetEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Worksheet",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "identifier",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'ac6f714c44e2e2403d1eab36d675f353';
export default node;
