import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { FeedItem_activityFeedItem } from './__generated__/FeedItem_activityFeedItem.graphql';
import { FeedItem_viewer } from './__generated__/FeedItem_viewer.graphql';
import Worksheet from '../common/Worksheet';
import StudyRecord from '../common/StudyRecord';
type Props = {
  activityFeedItem: FeedItem_activityFeedItem;
  viewer: FeedItem_viewer;
};

// sigh, why can't typescript infer this???
interface WorksheetForTidbits {
  readonly creator: null | {
    readonly id: string;
  };
  readonly exercises: readonly {
    readonly id: string;
  }[];
}

const getWorksheetTidbits = (
  worksheet: WorksheetForTidbits,
  viewer: FeedItem_viewer,
) => {
  const tidbits = [];
  tidbits.push(
    <FormattedMessage
      id="FeedItem.num_exercises"
      defaultMessage="{numExercises, number} {numExercises, plural,
        one {exercise}
        other {exercises}
      }"
      values={{
        numExercises: worksheet.exercises.length,
      }}
    />,
  );

  if (
    viewer.user &&
    worksheet.creator &&
    worksheet.creator.id === viewer.user.id
  ) {
    tidbits.push(
      <FormattedMessage
        id="FeedItem.created_by_you"
        defaultMessage="created by you"
      />,
    );
  }
  return tidbits;
};

const FeedItem = ({ activityFeedItem, viewer }: Props) => {
  if (activityFeedItem.__typename === 'Worksheet') {
    return (
      <div className="FeedItem">
        <Worksheet
          worksheet={activityFeedItem}
          extraInfo={getWorksheetTidbits(activityFeedItem, viewer)}
        />
      </div>
    );
  }

  if (activityFeedItem.__typename === 'StudyRecord') {
    return (
      <div className="FeedItem">
        <StudyRecord
          showUser={false}
          studyRecord={activityFeedItem}
          showWorksheet
          showStudyButton
        />
      </div>
    );
  }

  return null;
}; // for testing raw component

export { FeedItem };
export default createFragmentContainer(FeedItem, {
  activityFeedItem: graphql`
    fragment FeedItem_activityFeedItem on ActivityFeedItem {
      __typename
      ... on Worksheet {
        ...Worksheet_worksheet
        exercises {
          id
        }
        creator {
          id
        }
      }
      ... on StudyRecord {
        ...StudyRecord_studyRecord
      }
    }
  `,
  viewer: graphql`
    fragment FeedItem_viewer on Viewer {
      user {
        id
      }
    }
  `,
});
