/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WORKSHEET_VISIBILITY = "EVERYONE" | "LOGGED_IN" | "PASSWORD" | "%future added value";
export type AdminWorksheetsList_admin = {
    readonly recentWorksheets: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly createdAt: number;
                readonly identifier: string | null;
                readonly title: string | null;
                readonly isPublished: boolean;
                readonly visibility: WORKSHEET_VISIBILITY;
                readonly creator: {
                    readonly username: string;
                } | null;
                readonly exercises: ReadonlyArray<{
                    readonly __typename: "VocabExercise";
                    readonly content: {
                        readonly terms: ReadonlyArray<{
                            readonly word: string;
                            readonly definition: string | null;
                            readonly image: {
                                readonly id: string;
                            } | null;
                        }>;
                    };
                } | {
                    readonly __typename: "WordOrderExercise";
                    readonly content: {
                        readonly correctAnswers: ReadonlyArray<string>;
                        readonly scrambledParts: ReadonlyArray<string>;
                    };
                } | {
                    readonly __typename: "TranslationExercise";
                    readonly content: {
                        readonly prompt: string;
                        readonly correctAnswers: ReadonlyArray<string>;
                        readonly scrambledParts: ReadonlyArray<string>;
                    };
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                }>;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "AdminWorksheetsList_admin";
};
export type AdminWorksheetsList_admin$data = AdminWorksheetsList_admin;
export type AdminWorksheetsList_admin$key = {
    readonly " $data"?: AdminWorksheetsList_admin$data;
    readonly " $fragmentRefs": FragmentRefs<"AdminWorksheetsList_admin">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "correctAnswers",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scrambledParts",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AdminWorksheetsList_admin",
  "type": "Admin",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "recentWorksheets"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 50
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "publishedOnly",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "recentWorksheets",
      "name": "__AdminWorksheetsList_recentWorksheets_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "publishedOnly",
          "variableName": "publishedOnly"
        }
      ],
      "concreteType": "WorksheetConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "WorksheetEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Worksheet",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "identifier",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isPublished",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "visibility",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "creator",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "username",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "exercises",
                  "storageKey": null,
                  "args": null,
                  "concreteType": null,
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "type": "VocabExercise",
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "content",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "VocabExerciseContent",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "terms",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "VocabExerciseTerm",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "word",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "definition",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "image",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "Image",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "WordOrderExercise",
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "content",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "WordOrderExerciseContent",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/)
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "InlineFragment",
                      "type": "TranslationExercise",
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "content",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "TranslationExerciseContent",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "prompt",
                              "args": null,
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            (v3/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                },
                (v1/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'c4dca1a6047a8a105b6b402b7d9cc111';
export default node;
