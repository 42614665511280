/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetAnalysisTab_worksheet = {
    readonly id: string;
    readonly exercises: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly " $fragmentRefs": FragmentRefs<"TranslationExerciseAnalysis_exercise" | "WordOrderExerciseAnalysis_exercise" | "VocabExerciseAnalysis_exercise" | "MultipleChoiceExerciseAnalysis_exercise">;
    }>;
    readonly " $refType": "WorksheetAnalysisTab_worksheet";
};
export type WorksheetAnalysisTab_worksheet$data = WorksheetAnalysisTab_worksheet;
export type WorksheetAnalysisTab_worksheet$key = {
    readonly " $data"?: WorksheetAnalysisTab_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetAnalysisTab_worksheet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "WorksheetAnalysisTab_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercises",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "type": "TranslationExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "TranslationExerciseAnalysis_exercise",
              "args": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "WordOrderExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "WordOrderExerciseAnalysis_exercise",
              "args": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "VocabExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "VocabExerciseAnalysis_exercise",
              "args": null
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "MultipleChoiceExercise",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "MultipleChoiceExerciseAnalysis_exercise",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '91b7dac7e86da088fabe3ecbd2073ffc';
export default node;
