import React, { FC } from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import './MobileMenuDivider.css';

type MobileMenuDividerProps = {
  label?: string | ReactNode;
};

const MobileMenuDivider: FC<MobileMenuDividerProps> = ({ label }) => {
  return (
    <div className={classNames('MobileMenuDivider bt b--black-10 mv3')}>
      {label && (
        <div className="MobileMenuDivider-label black-40 bg-white f7">
          {label}
        </div>
      )}
    </div>
  );
};

export default MobileMenuDivider;
