/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ACCOUNT_TYPE = "STUDENT" | "TEACHER" | "%future added value";
export type WorksheetViewer_viewer = {
    readonly user: {
        readonly isUpgradedTeacher: boolean;
        readonly accountType: ACCOUNT_TYPE | null;
    } | null;
    readonly " $refType": "WorksheetViewer_viewer";
};
export type WorksheetViewer_viewer$data = WorksheetViewer_viewer;
export type WorksheetViewer_viewer$key = {
    readonly " $data"?: WorksheetViewer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetViewer_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WorksheetViewer_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isUpgradedTeacher",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accountType",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'a0faabc711ee0e16f5f101f002ebadf8';
export default node;
