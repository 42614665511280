import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '../common/Button';
import DemoExerciseButton from './DemoExerciseButton';
import './index.css';

const Home = () => (
  <div className="Home">
    <div className="center mw8 pb5 Home-hero">
      <div className="tc-l mt4 ph3 fl w-50-ns w-60-m w-100 ">
        <h1 className="f2 fw2 mb0 lh-title pv4 pv5-ns">
          <FormattedMessage
            id="Home.main_tagline"
            defaultMessage="Create free online language learning exercises in minutes"
            description="The main tagline on the homepage"
          />
        </h1>
      </div>
      <div className="Home-laptopImg dn db-l" />
      <div className="Home-phoneImg dn db-ns" />
      <div className="cf" />
    </div>
    <div className="Home-underHero bg-black-05 pv4">
      <div className="mw8 center">
        <div className="fl pa2 pt4 w-100 w-third-ns">
          <h3 className="lh-title f3 ma0">
            <FormattedMessage
              id="Home.marketing_point_1_title"
              defaultMessage="Fun and Engaging"
              description="First of the 3 marketing points on the homepage"
            />
          </h3>
          <p className="lh-copy">
            <FormattedMessage
              id="Home.marketing_point_1_body"
              defaultMessage="Wordsheet exercises are interactive and engaging. All exercises can be studied on a phone or computer. Students love it!"
              description="First of the 3 marketing points on the homepage"
            />
          </p>
        </div>
        <div className="fl pa2 pt4 w-100 w-third-ns">
          <h3 className="lh-title f3 ma0">
            <FormattedMessage
              id="Home.marketing_point_2_title"
              defaultMessage="Fast and Easy"
              description="Second of the 3 marketing points on the homepage"
            />
          </h3>
          <p className="lh-copy">
            <FormattedMessage
              id="Home.marketing_point_2_body"
              defaultMessage="Creating and sharing wordsheets is a breeze. Each wordsheet only takes a few minutes to make. Then send the link out to Students to study!"
              description="Second of the 3 marketing points on the homepage"
            />
          </p>
        </div>
        <div className="fl pa2 pt4 w-100 w-third-ns">
          <h3 className="lh-title f3 ma0">
            <FormattedMessage
              id="Home.marketing_point_3_title"
              defaultMessage="Your own content"
              description="Third of the 3 marketing points on the homepage"
            />
          </h3>
          <p className="lh-copy">
            <FormattedMessage
              id="Home.marketing_point_3_body"
              defaultMessage="You have full control of all the content and exercises. Create your own sheets tailored to what you our your students are studying!"
              description="Third of the 3 marketing points on the homepage"
            />
          </p>
        </div>
      </div>
      <div className="cf" />
    </div>
    <div className="pv4 mw8 pb5 center">
      <h2 className="f3 fw2 lh-title">
        <FormattedMessage id="Home.demos_title" defaultMessage="Try it out" />
      </h2>

      <div className="fl w-100 w-third-ns pa2">
        <DemoExerciseButton
          identifier="S6H"
          flag="china"
          name="Basic Chinese"
          numExercises={6}
        />
      </div>

      <div className="fl w-100 w-third-ns pa2">
        <DemoExerciseButton
          identifier="V3Y"
          flag="france"
          name="Telling time in French"
          numExercises={5}
        />
      </div>

      <div className="fl w-100 w-third-ns pa2">
        <DemoExerciseButton
          identifier="HJ5"
          flag="spain"
          name="Basic Spanish"
          numExercises={6}
        />
      </div>

      <div className="cf" />

      <div className="pt5 pb4">
        <Button
          component={Link}
          to="/new-sheet"
          buttonType="success"
          size="large"
        >
          <FormattedMessage
            id="Home.main_cta"
            defaultMessage="Create your own Wordsheet"
            description="Button to create a new wordsheet on the homepage"
          />
        </Button>
      </div>
    </div>
  </div>
);

export default Home;
