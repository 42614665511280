import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { ResetPasswordMutation } from './__generated__/ResetPasswordMutation.graphql';
const mutation = graphql`
  mutation ResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

const commit = (environment: any, token: string, password: string) =>
  commitMutationPromise<ResetPasswordMutation>(environment, {
    mutation,
    variables: {
      input: {
        token,
        password,
      },
    },
  });

export default {
  commit,
};
