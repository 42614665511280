/* tslint:disable */
/* eslint-disable */
/* @relayHash 0e7eef0a2e958674f509201842e0d8b6 */

import { ConcreteRequest } from "relay-runtime";
export type UpdateMultipleChoiceExerciseInput = {
    id: string;
    position?: number | null;
    content?: MultipleChoiceExerciseContentInput | null;
    clientMutationId?: string | null;
};
export type MultipleChoiceExerciseContentInput = {
    prompt: string;
    choices: Array<MultipleChoiceExerciseChoiceInput>;
};
export type MultipleChoiceExerciseChoiceInput = {
    isCorrect: boolean;
    text: string;
};
export type UpdateMultipleChoiceExerciseMutationVariables = {
    input: UpdateMultipleChoiceExerciseInput;
};
export type UpdateMultipleChoiceExerciseMutationResponse = {
    readonly updateMultipleChoiceExercise: {
        readonly exercise: {
            readonly id: string;
            readonly content: {
                readonly prompt: string;
                readonly choices: ReadonlyArray<{
                    readonly isCorrect: boolean;
                    readonly text: string;
                }>;
            };
        } | null;
    } | null;
};
export type UpdateMultipleChoiceExerciseMutation = {
    readonly response: UpdateMultipleChoiceExerciseMutationResponse;
    readonly variables: UpdateMultipleChoiceExerciseMutationVariables;
};



/*
mutation UpdateMultipleChoiceExerciseMutation(
  $input: UpdateMultipleChoiceExerciseInput!
) {
  updateMultipleChoiceExercise(input: $input) {
    exercise {
      id
      content {
        prompt
        choices {
          isCorrect
          text
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMultipleChoiceExerciseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMultipleChoiceExercise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMultipleChoiceExercisePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "exercise",
        "storageKey": null,
        "args": null,
        "concreteType": "MultipleChoiceExercise",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "content",
            "storageKey": null,
            "args": null,
            "concreteType": "MultipleChoiceExerciseContent",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "prompt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "choices",
                "storageKey": null,
                "args": null,
                "concreteType": "MultipleChoiceExerciseChoice",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isCorrect",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateMultipleChoiceExerciseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateMultipleChoiceExerciseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateMultipleChoiceExerciseMutation",
    "id": null,
    "text": "mutation UpdateMultipleChoiceExerciseMutation(\n  $input: UpdateMultipleChoiceExerciseInput!\n) {\n  updateMultipleChoiceExercise(input: $input) {\n    exercise {\n      id\n      content {\n        prompt\n        choices {\n          isCorrect\n          text\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b0579d35ba5c194071bbbbdfeb5e3a62';
export default node;
