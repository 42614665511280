import React, { FC } from 'react';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import { ClassroomPage_pageQuery } from './__generated__/ClassroomPage_pageQuery.graphql';
import DashboardLayout from '../components/DashboardLayout';
import ClassroomViewer from '../components/ClassroomViewer';
import Loading from '../components/common/Loading';
import NotFound from '../components/common/NotFound';

const dashboardPage_pageQuery = graphql`
  query ClassroomPage_pageQuery($identifier: String!) {
    viewer {
      ...NavHeader_viewer
      ...PageLoading_viewer
      ...ClassroomViewer_viewer
      ...DashboardLayout_viewer
      user {
        id
      }
    }
    classroomByIdentifier(identifier: $identifier) {
      id
      ...ClassroomViewer_classroom
    }
  }
`;

interface ClassroomPageProps {
  match: match<{ identifier: string }>;
  showJoinModal?: boolean;
}

const ClassroomPage: FC<ClassroomPageProps> = ({ match, showJoinModal }) => (
  <QueryRenderer<ClassroomPage_pageQuery>
    environment={environment}
    variables={{
      identifier: match.params.identifier,
    }}
    query={dashboardPage_pageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      const header = <NavHeader viewer={props?.viewer} />;
      if (props?.viewer && !props?.classroomByIdentifier) {
        return <NotFound header={header} />;
      }

      return (
        <div>
          {header}
          <div className="mw8 center">
            <DashboardLayout
              activeTab="classes"
              activeIdentifier={match.params.identifier}
              viewer={props?.viewer}
            >
              {props?.classroomByIdentifier && props?.viewer ? (
                <ClassroomViewer
                  viewer={props.viewer}
                  classroom={props.classroomByIdentifier}
                  showJoinModal={!!showJoinModal}
                />
              ) : (
                <Loading />
              )}
            </DashboardLayout>
          </div>
        </div>
      );
    }}
  />
);

export default ClassroomPage;
