/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VocabExerciseSummary_exercise = {
    readonly content: {
        readonly terms: ReadonlyArray<{
            readonly word: string;
            readonly definition: string | null;
            readonly image: {
                readonly id: string;
                readonly url: string;
            } | null;
        }>;
    };
    readonly " $refType": "VocabExerciseSummary_exercise";
};
export type VocabExerciseSummary_exercise$data = VocabExerciseSummary_exercise;
export type VocabExerciseSummary_exercise$key = {
    readonly " $data"?: VocabExerciseSummary_exercise$data;
    readonly " $fragmentRefs": FragmentRefs<"VocabExerciseSummary_exercise">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "VocabExerciseSummary_exercise",
  "type": "VocabExercise",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "content",
      "storageKey": null,
      "args": null,
      "concreteType": "VocabExerciseContent",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "terms",
          "storageKey": null,
          "args": null,
          "concreteType": "VocabExerciseTerm",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "word",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "definition",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "image",
              "storageKey": null,
              "args": null,
              "concreteType": "Image",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "url",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "size",
                      "value": "SMALL_SQUARE"
                    }
                  ],
                  "storageKey": "url(size:\"SMALL_SQUARE\")"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '7e3aa40571f08e333640a60c1902bb0a';
export default node;
