import React, { Component } from 'react';
import { QueryRenderer, createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import Modal from '../common/Modal';
import { AddWorksheetModal_classroom } from './__generated__/AddWorksheetModal_classroom.graphql';
import { FormattedMessage } from 'react-intl';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import { AddWorksheetModalQuery } from './__generated__/AddWorksheetModalQuery.graphql';
import Loading from '../common/Loading';
import AddWorksheetModalWorksheet from './AddWorksheetModalWorksheet';

interface AddWorksheetModalProps {
  isOpen: boolean;
  onClose: () => void;
  classroom: AddWorksheetModal_classroom;
  relay: RelayProp;
}

interface AddWorksheetModalState {
  query: string;
}

const addWorksheetModalQuery = graphql`
  query AddWorksheetModalQuery($filter: String) {
    viewer {
      user {
        worksheets(first: 100, filter: $filter) {
          edges {
            node {
              id
              ...AddWorksheetModalWorksheet_worksheet
            }
          }
        }
      }
    }
  }
`;

class AddWorksheetModal extends Component<
  AddWorksheetModalProps,
  AddWorksheetModalState
> {
  state: AddWorksheetModalState = {
    query: '',
  };

  render() {
    const { isOpen, onClose, classroom } = this.props;
    const { query } = this.state;
    const classroomWorksheetIds = new Set<string>();
    for (const edge of classroom.classroomWorksheets?.edges || []) {
      if (edge?.node?.worksheet.id) {
        classroomWorksheetIds.add(edge?.node?.worksheet.id);
      }
    }
    return (
      <div className="AddWorksheetModal">
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeButton
          header={
            <FormattedMessage
              id="AddWorksheetModal.header"
              defaultMessage="Add a wordsheet to this class"
            />
          }
        >
          <FormGroup
            label={
              <FormattedMessage
                id="AddWorksheetModal.search"
                defaultMessage="Search your wordsheets"
              />
            }
          >
            <Input
              value={query}
              id="addWorksheetFilterInput"
              onChange={evt => this.setState({ query: evt.target.value })}
            />
          </FormGroup>

          <QueryRenderer<AddWorksheetModalQuery>
            environment={this.props.relay.environment}
            query={addWorksheetModalQuery}
            variables={{
              filter: query || undefined,
            }}
            render={({ error, props }) => {
              if (error) {
                return <div>{error.message}</div>;
              }

              if (!props?.viewer?.user?.worksheets?.edges) {
                return <Loading />;
              }

              if (props.viewer.user.worksheets.edges.length === 0) {
                return (
                  <div className="pa5 tc gray ba b--dashed b--gray">
                    <FormattedMessage
                      id="AddWorksheetModal.no_wordsheets_found_message"
                      defaultMessage="No wordsheets found"
                    />
                  </div>
                );
              }
              return props.viewer.user.worksheets.edges.map(
                edge =>
                  edge?.node && (
                    <div key={edge.node.id}>
                      <AddWorksheetModalWorksheet
                        classroomId={classroom.id}
                        worksheet={edge.node}
                        isAddedToClass={classroomWorksheetIds.has(edge.node.id)}
                      />
                    </div>
                  ),
              );
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default createFragmentContainer(AddWorksheetModal, {
  classroom: graphql`
    fragment AddWorksheetModal_classroom on Classroom {
      id
      # TODO: either deal with pagination, or find a less hacky way to filter out already added worksheets
      classroomWorksheets(first: 500)
        @connection(key: "AddWorksheetModal_classroomWorksheets") {
        edges {
          node {
            worksheet {
              id
            }
          }
        }
      }
    }
  `,
});
