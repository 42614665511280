/* tslint:disable */
/* eslint-disable */
/* @relayHash 671cb9c2020a0da4635104dd2130875c */

import { ConcreteRequest } from "relay-runtime";
export type UpdateWordOrderExerciseInput = {
    id: string;
    position?: number | null;
    content?: WordOrderExerciseContentInput | null;
    clientMutationId?: string | null;
};
export type WordOrderExerciseContentInput = {
    correctAnswers: Array<string>;
    scrambledParts: Array<string>;
};
export type UpdateWordOrderExerciseMutationVariables = {
    input: UpdateWordOrderExerciseInput;
};
export type UpdateWordOrderExerciseMutationResponse = {
    readonly updateWordOrderExercise: {
        readonly exercise: {
            readonly id: string;
            readonly content: {
                readonly correctAnswers: ReadonlyArray<string>;
                readonly scrambledParts: ReadonlyArray<string>;
            };
        } | null;
    } | null;
};
export type UpdateWordOrderExerciseMutation = {
    readonly response: UpdateWordOrderExerciseMutationResponse;
    readonly variables: UpdateWordOrderExerciseMutationVariables;
};



/*
mutation UpdateWordOrderExerciseMutation(
  $input: UpdateWordOrderExerciseInput!
) {
  updateWordOrderExercise(input: $input) {
    exercise {
      id
      content {
        correctAnswers
        scrambledParts
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateWordOrderExerciseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateWordOrderExercise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWordOrderExercisePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "exercise",
        "storageKey": null,
        "args": null,
        "concreteType": "WordOrderExercise",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "content",
            "storageKey": null,
            "args": null,
            "concreteType": "WordOrderExerciseContent",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "correctAnswers",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "scrambledParts",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateWordOrderExerciseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateWordOrderExerciseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateWordOrderExerciseMutation",
    "id": null,
    "text": "mutation UpdateWordOrderExerciseMutation(\n  $input: UpdateWordOrderExerciseInput!\n) {\n  updateWordOrderExercise(input: $input) {\n    exercise {\n      id\n      content {\n        correctAnswers\n        scrambledParts\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0466cf8ce74890afbeb6343fc0c8cb98';
export default node;
