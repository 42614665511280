/* tslint:disable */
/* eslint-disable */
/* @relayHash 2381e928c151032ad6843edeb8590391 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetAnalysisTabQueryVariables = {
    id: string;
    classroomId?: string | null;
};
export type WorksheetAnalysisTabQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly studyRecords?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly answers: ReadonlyArray<{
                        readonly isCorrect: boolean | null;
                        readonly exercise: {
                            readonly id: string;
                        } | null;
                        readonly response?: ReadonlyArray<string> | null;
                        readonly correctTerm?: {
                            readonly word: string;
                            readonly definition: string | null;
                            readonly image: {
                                readonly id: string;
                            } | null;
                        } | null;
                        readonly choices?: ReadonlyArray<{
                            readonly text: string;
                            readonly isChosen: boolean;
                        }> | null;
                    }>;
                } | null;
            } | null> | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"ClassroomsSelect_worksheet">;
    } | null;
};
export type WorksheetAnalysisTabQuery = {
    readonly response: WorksheetAnalysisTabQueryResponse;
    readonly variables: WorksheetAnalysisTabQueryVariables;
};



/*
query WorksheetAnalysisTabQuery(
  $id: ID!
  $classroomId: ID
) {
  node(id: $id) {
    __typename
    ... on Worksheet {
      id
      ...ClassroomsSelect_worksheet
      studyRecords(first: 500, classroomId: $classroomId) {
        edges {
          node {
            answers {
              __typename
              isCorrect
              exercise {
                __typename
                id
              }
              ... on WordOrderAnswer {
                response
              }
              ... on TranslationAnswer {
                response
              }
              ... on VocabAnswer {
                correctTerm {
                  word
                  definition
                  image {
                    id
                  }
                }
              }
              ... on MultipleChoiceAnswer {
                choices {
                  text
                  isChosen
                }
              }
              id
            }
            id
          }
        }
      }
    }
    id
  }
}

fragment ClassroomsSelect_worksheet on Worksheet {
  classroomWorksheetsVisibleToViewer {
    classroom {
      id
      title
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "classroomId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "classroomId",
    "variableName": "classroomId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCorrect",
  "args": null,
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "response",
    "args": null,
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineFragment",
  "type": "WordOrderAnswer",
  "selections": (v6/*: any*/)
},
v8 = {
  "kind": "InlineFragment",
  "type": "TranslationAnswer",
  "selections": (v6/*: any*/)
},
v9 = {
  "kind": "InlineFragment",
  "type": "VocabAnswer",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "correctTerm",
      "storageKey": null,
      "args": null,
      "concreteType": "VocabAnswerTerm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "word",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "definition",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "image",
          "storageKey": null,
          "args": null,
          "concreteType": "Image",
          "plural": false,
          "selections": (v5/*: any*/)
        }
      ]
    }
  ]
},
v10 = {
  "kind": "InlineFragment",
  "type": "MultipleChoiceAnswer",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "choices",
      "storageKey": null,
      "args": null,
      "concreteType": "MultipleChoiceAnswerChoice",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "text",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isChosen",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WorksheetAnalysisTabQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Worksheet",
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "studyRecords",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyRecordConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StudyRecordEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyRecord",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "answers",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "exercise",
                                "storageKey": null,
                                "args": null,
                                "concreteType": null,
                                "plural": false,
                                "selections": (v5/*: any*/)
                              },
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "ClassroomsSelect_worksheet",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WorksheetAnalysisTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Worksheet",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomWorksheetsVisibleToViewer",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomWorksheet",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroom",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "studyRecords",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyRecordConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StudyRecordEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyRecord",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "answers",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                              (v11/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "exercise",
                                "storageKey": null,
                                "args": null,
                                "concreteType": null,
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v2/*: any*/)
                                ]
                              },
                              (v2/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WorksheetAnalysisTabQuery",
    "id": null,
    "text": "query WorksheetAnalysisTabQuery(\n  $id: ID!\n  $classroomId: ID\n) {\n  node(id: $id) {\n    __typename\n    ... on Worksheet {\n      id\n      ...ClassroomsSelect_worksheet\n      studyRecords(first: 500, classroomId: $classroomId) {\n        edges {\n          node {\n            answers {\n              __typename\n              isCorrect\n              exercise {\n                __typename\n                id\n              }\n              ... on WordOrderAnswer {\n                response\n              }\n              ... on TranslationAnswer {\n                response\n              }\n              ... on VocabAnswer {\n                correctTerm {\n                  word\n                  definition\n                  image {\n                    id\n                  }\n                }\n              }\n              ... on MultipleChoiceAnswer {\n                choices {\n                  text\n                  isChosen\n                }\n              }\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ClassroomsSelect_worksheet on Worksheet {\n  classroomWorksheetsVisibleToViewer {\n    classroom {\n      id\n      title\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd78bead42901a34c955fa5a27875dca0';
export default node;
