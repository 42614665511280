import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

import Modal from './Modal';
import Button from './Button';

import './CorrectAnswerModal.css';

type Props = {
  isOpen: boolean;
  correctAnswer: string | ReactNode;
  onDone: () => void;
};

const CorrectAnswerModal = ({ isOpen, correctAnswer, onDone }: Props) => (
  <Modal width="narrow" isOpen={isOpen}>
    <div className="tc">
      <h4 className="f5 gray ma0 pt3">
        <FormattedMessage
          id="CorrectAnswerModal.correct_answer_header"
          defaultMessage="Good try! The correct answer is:"
          description="This comes up when the users gets an exercise wrong"
        />
      </h4>
      <div className="f4 pv3 CorrectAnswerModal-answerContent">
        {correctAnswer}
      </div>
      <Button fullWidth onClick={onDone}>
        <FormattedMessage
          id="CorrectAnswerModal.continue_button"
          defaultMessage="Got it!"
        />
      </Button>
    </div>
  </Modal>
);

export default CorrectAnswerModal;
