/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditClassroomModal_classroom = {
    readonly id: string;
    readonly title: string;
    readonly description: string | null;
    readonly requiresApproval: boolean;
    readonly " $refType": "EditClassroomModal_classroom";
};
export type EditClassroomModal_classroom$data = EditClassroomModal_classroom;
export type EditClassroomModal_classroom$key = {
    readonly " $data"?: EditClassroomModal_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"EditClassroomModal_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "EditClassroomModal_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requiresApproval",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '218d88a433c01a550f053adc885b196c';
export default node;
