import React, { Component, RefObject, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLInputElement> {}

class AutofocusInput extends Component<Props> {
  inputRef: RefObject<HTMLInputElement> = React.createRef();

  render() {
    return (
      <input
        {...this.props}
        readOnly
        ref={this.inputRef}
        onClick={() => this.inputRef.current && this.inputRef.current.select()}
      />
    );
  }
}

export default AutofocusInput;
