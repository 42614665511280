/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TranslationAnswerDetails_answer = {
    readonly isCorrect: boolean | null;
    readonly response: ReadonlyArray<string> | null;
    readonly exercise: {
        readonly content?: {
            readonly prompt: string;
            readonly correctAnswers: ReadonlyArray<string>;
            readonly scrambledParts: ReadonlyArray<string>;
        };
    } | null;
    readonly " $refType": "TranslationAnswerDetails_answer";
};
export type TranslationAnswerDetails_answer$data = TranslationAnswerDetails_answer;
export type TranslationAnswerDetails_answer$key = {
    readonly " $data"?: TranslationAnswerDetails_answer$data;
    readonly " $fragmentRefs": FragmentRefs<"TranslationAnswerDetails_answer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "TranslationAnswerDetails_answer",
  "type": "TranslationAnswer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCorrect",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "response",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercise",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "type": "TranslationExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "TranslationExerciseContent",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "prompt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "correctAnswers",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "scrambledParts",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'dd8c8d873cbb3a5ef01de48950073692';
export default node;
