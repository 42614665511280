import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { AnswerDetails_answer } from './__generated__/AnswerDetails_answer.graphql';
import VocabAnswerDetails from './VocabAnswerDetails';
import WordOrderAnswerDetails from './WordOrderAnswerDetails';
import TranslationAnswerDetails from './TranslationAnswerDetails';
import MultipleChoiceAnswerDetails from './MultipleChoiceAnswerDetails';
import './AnswerDetails.css';
type Props = {
  answer: AnswerDetails_answer;
};

const AnswerDetails = ({ answer }: Props) => (
  <div className="AnswerDetails pa3 br1 flex">
    <div
      className={classNames('AnswerDetails-result f2', {
        'is-correct': answer.isCorrect,
      })}
    >
      {answer.isCorrect ? (
        <i className="fas fa-check" />
      ) : (
        <i className="fas fa-times" />
      )}
    </div>
    <div className="ph3 w-100">
      {answer.__typename !== 'VocabAnswer' ? null : (
        <VocabAnswerDetails answer={answer} />
      )}
      {answer.__typename !== 'WordOrderAnswer' ? null : (
        <WordOrderAnswerDetails answer={answer} />
      )}
      {answer.__typename !== 'TranslationAnswer' ? null : (
        <TranslationAnswerDetails answer={answer} />
      )}
      {answer.__typename !== 'MultipleChoiceAnswer' ? null : (
        <MultipleChoiceAnswerDetails answer={answer} />
      )}
    </div>
  </div>
);

export default createFragmentContainer(AnswerDetails, {
  answer: graphql`
    fragment AnswerDetails_answer on Answer {
      isCorrect
      __typename
      ... on VocabAnswer {
        ...VocabAnswerDetails_answer
      }
      ... on WordOrderAnswer {
        ...WordOrderAnswerDetails_answer
      }
      ... on TranslationAnswer {
        ...TranslationAnswerDetails_answer
      }
      ... on MultipleChoiceAnswer {
        ...MultipleChoiceAnswerDetails_answer
      }
    }
  `,
});
