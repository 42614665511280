import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { VocabExerciseAnalysis_exercise } from './__generated__/VocabExerciseAnalysis_exercise.graphql';
import './VocabExerciseAnalysis.css';
type term = {
  word: string;
  definition: string | undefined | null;
  image:
    | {
        id: string;
      }
    | undefined
    | null;
};
type answer = {
  isCorrect: boolean;
  correctTerm: term;
};
type Props = {
  exercise: VocabExerciseAnalysis_exercise;
  answers: answer[];
};

const hashTerm = (term: term) => {
  return `${term.word}-${term.definition || ''}-${(term.image &&
    term.image.id) ||
    ''}`;
};

const getAnalysisCounts = (terms: readonly term[], answers: answer[]) => {
  const counts: {
    terms: { [hash: string]: { totalCorrect: number; total: number } };
    total: number;
  } = {
    terms: {},
    total: 0,
  };
  terms.forEach(term => {
    counts.terms[hashTerm(term)] = {
      totalCorrect: 0,
      total: 0,
    };
  });
  answers.forEach(answer => {
    const answerHash = hashTerm(answer.correctTerm);

    if (counts.terms[answerHash]) {
      counts.terms[answerHash].total += 1;
      counts.total += 1;

      if (answer.isCorrect) {
        counts.terms[answerHash].totalCorrect += 1;
      }
    }
  });
  return counts;
};

const VocabExerciseAnalysis = ({ exercise, answers }: Props) => {
  const counts = getAnalysisCounts(exercise.content.terms, answers);
  return (
    <div className="VocabExerciseAnalysis">
      {counts.total === 0 ? (
        <div className="gray pv3" key="no-data">
          <FormattedMessage
            id="VocabExerciseAnalysis.no_results"
            defaultMessage="No data yet"
          />
        </div>
      ) : (
        exercise.content.terms.map((term, i) => {
          const { totalCorrect, total } = counts.terms[hashTerm(term)];
          return (
            <div key={i} className="mb3">
              <div
                className={classNames(
                  'VocabExerciseAnalysis-term fl bg-white pa2 br1 mr2 mt2',
                  {
                    'has-image': term.image && term.image.id,
                  },
                )}
              >
                <div>{term.word}</div>
                {!term.definition ? null : (
                  <div className="gray f6 pt1">{term.definition}</div>
                )}
                {!term.image ? null : (
                  <img
                    className="VocabExerciseAnalysis-termImage br1 br--right"
                    src={term.image.url}
                    alt={`term ${i}`}
                  />
                )}
              </div>
              <div className="cb" />
              {total === 0 ? (
                <div className="gray mt2 f6">
                  <FormattedMessage
                    id="VocabExerciseAnalysis.no_results"
                    defaultMessage="No data yet"
                  />
                </div>
              ) : (
                <div className="mt2">
                  <FormattedMessage
                    id="VocabExerciseAnalysis.percent_correct"
                    defaultMessage="{percent}% correct"
                    values={{
                      percent: (
                        <span className="f3">
                          {Math.round((100 * totalCorrect) / total)}
                        </span>
                      ),
                    }}
                  />
                  <span className="dib gray ml3 f6">
                    {totalCorrect} / {total}
                  </span>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default createFragmentContainer(VocabExerciseAnalysis, {
  exercise: graphql`
    fragment VocabExerciseAnalysis_exercise on VocabExercise {
      content {
        terms {
          word
          definition
          image {
            id
            url(size: SMALL_SQUARE)
          }
        }
      }
    }
  `,
});
