import isEmpty from 'lodash.isempty';
import clone from 'lodash.clone';
import {
  Environment,
  Network,
  RecordSource,
  Store,
  FetchFunction,
  Uploadable,
} from 'relay-runtime';
import auth from './lib/auth';

const fetchQuery: FetchFunction = (
  operation,
  variables,
  _cacheConfig,
  uploadables,
) => {
  const isUpload = !isEmpty(uploadables);
  const headers: { [key: string]: string } = {
    Accept: 'application/json',
    ...auth.getAuthHeaders(),
  };
  let body;

  if (isUpload) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    let adjustedVariables = clone(variables);
    if (!adjustedVariables.input) adjustedVariables.input = {};
    const filesMap: { [fileIndex: number]: string[] } = {};
    const filesList: Uploadable[] = [];
    Object.keys(uploadables || {}).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        adjustedVariables.input[key] = null;
        filesMap[filesList.length] = [`variables.input.${key}`];
        filesList.push((uploadables || {})[key]);
      }
    });
    formData.append(
      'operations',
      JSON.stringify({
        query: operation.text,
        variables: adjustedVariables,
      }),
    );
    formData.append('map', JSON.stringify(filesMap));
    filesList.forEach((file, i) => {
      formData.append(`${i}`, file);
    });
    body = formData;
  } else {
    body = JSON.stringify({
      query: operation.text,
      variables,
    });
    headers['Content-Type'] = 'application/json';
  }

  return fetch(`//${process.env.REACT_APP_API_HOST || ''}/api/graphql`, {
    headers,
    body,
    method: 'POST',
  }).then(response => response.json());
};

const network = Network.create(fetchQuery);
const source = new RecordSource();
const store = new Store(source);
export default new Environment({
  network,
  store,
});
