import React, { Component } from 'react';
import { QueryRenderer, createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { WorksheetStudiersTab_worksheet } from './__generated__/WorksheetStudiersTab_worksheet.graphql';
import Loading from '../common/Loading';
import './WorksheetStudiersTab.css';
import { exists } from '../../lib/utils';
import { WorksheetStudiersTabQuery } from './__generated__/WorksheetStudiersTabQuery.graphql';
import { Link } from 'react-router-dom';
import Username from '../common/Username';

type Props = {
  worksheet: WorksheetStudiersTab_worksheet;
  relay: RelayProp;
};
// TODO: infinite scroll this!
const worksheetResultsTabQuery = graphql`
  query WorksheetStudiersTabQuery($id: ID!) {
    node(id: $id) {
      ... on Worksheet {
        id
        numStudiers
        studiers(first: 500) {
          edges {
            node {
              username
              isUpgradedTeacher
            }
          }
        }
      }
    }
  }
`;

class WorksheetStudiersTab extends Component<Props> {
  render() {
    return (
      <div className="WorksheetStudiersTab">
        <QueryRenderer<WorksheetStudiersTabQuery>
          environment={this.props.relay.environment}
          query={worksheetResultsTabQuery}
          variables={{
            id: this.props.worksheet.id,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            }

            if (!props?.node?.studiers) {
              return <Loading />;
            }

            const studierEdges = props?.node?.studiers?.edges || [];

            if (studierEdges.length === 0) {
              return (
                <div className="pa5 tc gray ba b--dashed b--gray">
                  <FormattedMessage
                    id="WorksheetStudiersTab.not_studied_message"
                    defaultMessage="No logged-in users have studied this wordsheet yet"
                  />
                </div>
              );
            }

            return (
              <div>
                <h2>
                  <FormattedMessage
                    id="WorksheetStudiersTab.studied_by_count"
                    defaultMessage="Studied by {num, plural, one {# user} other {# users}}"
                    values={{ num: props.node.numStudiers }}
                  />
                </h2>
                <div className="flex flex-wrap">
                  {studierEdges.filter(exists).map(
                    ({ node }) =>
                      node && (
                        <Link
                          to={`/profile/${node.username}`}
                          className="WorksheetStudiersTab-studier flex items-center link ph4 pv3 mr2 mb2 br2"
                          key={node.username}
                        >
                          <Username
                            username={node.username}
                            isTeacher={node.isUpgradedTeacher}
                          />
                        </Link>
                      ),
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default createFragmentContainer(WorksheetStudiersTab, {
  worksheet: graphql`
    fragment WorksheetStudiersTab_worksheet on Worksheet {
      id
    }
  `,
});
