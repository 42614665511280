import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  UpdateWorksheetMutation,
  UpdateWorksheetInput,
} from './__generated__/UpdateWorksheetMutation.graphql';
const mutation = graphql`
  mutation UpdateWorksheetMutation($input: UpdateWorksheetInput!) {
    updateWorksheet(input: $input) {
      worksheet {
        id
        title
        description
        identifier
        isPublished
      }
    }
  }
`;

const commit = (
  environment: any,
  id: string,
  fields: Omit<UpdateWorksheetInput, 'id'>,
) => {
  // just to get typescript to be quiet...
  const optimisticResponse: any = {
    updateWorksheet: {
      worksheet: {
        id,
        ...fields,
      },
    },
  };

  return commitMutationPromise<UpdateWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
        ...fields,
      },
    },
    optimisticResponse,
  });
};

export default {
  commit,
};
