import React, { FC } from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import './MobileMenuLink.css';

type MobileMenuLinkProps = {
  children: ReactNode;
  component?: any;
  [key: string]: any;
};

const MobileMenuLink: FC<MobileMenuLinkProps> = ({
  children,
  component,
  ...otherProps
}) => {
  const ComponentClass = component || 'a';
  return (
    <ComponentClass
      className={classNames('MobileMenuLink f6 fw4 link db pa3')}
      {...otherProps}
    >
      {children}
    </ComponentClass>
  );
};

export default MobileMenuLink;
