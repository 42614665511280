import React, { HTMLProps, ChangeEvent } from 'react';
import classNames from 'classnames';
import './Input.css';

interface Props extends HTMLProps<HTMLInputElement> {
  className?: string;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({ className, ...otherProps }: Props) => (
  <input className={classNames('Input', className)} {...otherProps} />
);

export default Input;
