/* tslint:disable */
/* eslint-disable */
/* @relayHash d5c17d72ff80540cfff813d91b96cd6e */

import { ConcreteRequest } from "relay-runtime";
export type SubmitFeedbackInput = {
    email?: string | null;
    messageType: string;
    content: string;
    clientMutationId?: string | null;
};
export type SubmitFeedbackMutationVariables = {
    input: SubmitFeedbackInput;
};
export type SubmitFeedbackMutationResponse = {
    readonly submitFeedback: {
        readonly feedbackMessage: {
            readonly id: string;
        } | null;
    } | null;
};
export type SubmitFeedbackMutation = {
    readonly response: SubmitFeedbackMutationResponse;
    readonly variables: SubmitFeedbackMutationVariables;
};



/*
mutation SubmitFeedbackMutation(
  $input: SubmitFeedbackInput!
) {
  submitFeedback(input: $input) {
    feedbackMessage {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SubmitFeedbackInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "submitFeedback",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitFeedbackPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feedbackMessage",
        "storageKey": null,
        "args": null,
        "concreteType": "FeedbackMessage",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubmitFeedbackMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SubmitFeedbackMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SubmitFeedbackMutation",
    "id": null,
    "text": "mutation SubmitFeedbackMutation(\n  $input: SubmitFeedbackInput!\n) {\n  submitFeedback(input: $input) {\n    feedbackMessage {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2e7697782b7b9088c4dca609542a6b2f';
export default node;
