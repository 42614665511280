import React, { Component } from 'react';
import Button from '../common/Button';
import './ExerciseTypeButton.css';

type Props = {
  onClick: () => void;
  title: string;
  subtitle: string;
};

class ExerciseTypeButton extends Component<Props> {
  render() {
    return (
      <div className="ExerciseTypeButton">
        <Button fullWidth onClick={this.props.onClick}>
          <div className="pa1">
            <span className="f3">{this.props.title}</span>
            <p className="f7 light-gray">{this.props.subtitle}</p>
          </div>
        </Button>
      </div>
    );
  }
}

export default ExerciseTypeButton;
