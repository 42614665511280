/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudyRecordViewer_studyRecord = {
    readonly id: string;
    readonly score: number | null;
    readonly duration: number | null;
    readonly user: {
        readonly id: string;
    } | null;
    readonly worksheet: {
        readonly identifier: string | null;
        readonly title: string | null;
        readonly description: string | null;
    };
    readonly answers: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"AnswerDetails_answer">;
    }>;
    readonly " $refType": "StudyRecordViewer_studyRecord";
};
export type StudyRecordViewer_studyRecord$data = StudyRecordViewer_studyRecord;
export type StudyRecordViewer_studyRecord$key = {
    readonly " $data"?: StudyRecordViewer_studyRecord$data;
    readonly " $fragmentRefs": FragmentRefs<"StudyRecordViewer_studyRecord">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StudyRecordViewer_studyRecord",
  "type": "StudyRecord",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "score",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "duration",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "worksheet",
      "storageKey": null,
      "args": null,
      "concreteType": "Worksheet",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "identifier",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "answers",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "AnswerDetails_answer",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'd9a48731f7fa032823e97f800666b4f5';
export default node;
