import React, { Component, FormEvent } from 'react';
import { Environment } from 'react-relay';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';

import EmailSignupMutation from '../../mutations/EmailSignupMutation';
import Button from '../common/Button';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import OauthHeader from './OauthHeader';
import { isWechat } from '../../lib/utils';
import { ACCOUNT_TYPE } from '../../mutations/__generated__/EmailSignupMutation.graphql';
import AccountTypeSelect from '../common/AccountTypeSelect';
import { isFromCampaign } from '../../lib/campaignTracker';

export type CONCRETE_ACCOUNT_TYPE = Exclude<
  ACCOUNT_TYPE,
  '%future added value'
>;

type State = {
  username: string;
  email: string;
  password: string;
  accountType: CONCRETE_ACCOUNT_TYPE | null;
  error: null | string;
};

type Props = {
  intl: IntlShape;
  environment: Environment;
  onSignUp: () => void;
  oauthRedirect?: string;
  background: 'white' | 'gray';
  probableAccountType?: CONCRETE_ACCOUNT_TYPE;
};

const messages = defineMessages({
  missingUsernameError: {
    id: 'SignUpForm.missing_username_error',
    defaultMessage: 'Enter a username',
  },
  usernameLengthError: {
    id: 'SignUpForm.username_length_error',
    defaultMessage: 'Username must be between 3 and 20 characters long',
  },
  usernameCharsError: {
    id: 'SignUpForm.username_chars_error',
    defaultMessage: 'Username can contain only letters, numbers, and _',
  },
  missingEmailError: {
    id: 'SignUpForm.missing_email_error',
    defaultMessage: 'Enter your email address',
  },
  missingAccountTypeError: {
    id: 'SignUpForm.missing_account_type_error',
    defaultMessage: 'Select an account type',
  },
  passwordLengthError: {
    id: 'SignUpForm.password_length_error',
    defaultMessage: 'Password must be at least 8 characters',
  },
});

class SignUpForm extends Component<Props, State> {
  state: State = {
    username: '',
    email: '',
    password: '',
    error: null,
    accountType: isFromCampaign() ? 'TEACHER' : null,
  };

  static defaultProps: Partial<Props> = {
    background: 'white',
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.probableAccountType && state.accountType === null) {
      return { accountType: props.probableAccountType };
    }
    return null;
  }

  onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { username, email, password, accountType } = this.state;
    const { formatMessage } = this.props.intl;
    if (!username) {
      return this.setState({
        error: formatMessage(messages.missingUsernameError),
      });
    }
    if (username.length < 3 || username.length > 20) {
      return this.setState({
        error: formatMessage(messages.usernameLengthError),
      });
    }
    if (!username.match(/^[a-zA-Z0-9_]+$/)) {
      return this.setState({
        error: formatMessage(messages.usernameCharsError),
      });
    }
    if (!email) {
      return this.setState({
        error: formatMessage(messages.missingEmailError),
      });
    }
    if (password.length < 6) {
      return this.setState({
        error: formatMessage(messages.passwordLengthError),
      });
    }
    if (!accountType && !isFromCampaign()) {
      return this.setState({
        error: formatMessage(messages.missingAccountTypeError),
      });
    }
    this.setState({ error: null });
    try {
      await EmailSignupMutation.commit(this.props.environment, {
        username,
        email,
        password,
        accountType,
      });
      window.fbq('track', 'CompleteRegistration');
      this.props.onSignUp();
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  render() {
    return (
      <form onSubmit={this.onSubmit} className="SignUpForm">
        {isWechat() ? null : (
          <OauthHeader
            redirect={this.props.oauthRedirect}
            authType="signup"
            background={this.props.background}
          />
        )}
        {!this.state.error ? null : (
          <div className="pa3 mb4 red ba b--light-red">{this.state.error}</div>
        )}
        <FormGroup
          label={
            <FormattedMessage
              id="SignUpForm.username_label"
              defaultMessage="Username"
            />
          }
        >
          <Input
            required
            type="text"
            id="username"
            value={this.state.username}
            onChange={evt => this.setState({ username: evt.target.value })}
          />
        </FormGroup>

        <FormGroup
          label={
            <FormattedMessage
              id="SignUpForm.email_label"
              defaultMessage="Email"
            />
          }
        >
          <Input
            required
            type="text"
            id="email"
            value={this.state.email}
            onChange={evt => this.setState({ email: evt.target.value })}
          />
        </FormGroup>

        <FormGroup
          label={
            <FormattedMessage
              id="SignUpForm.password_label"
              defaultMessage="Password"
            />
          }
        >
          <Input
            required
            type="password"
            id="password"
            value={this.state.password}
            onChange={evt => this.setState({ password: evt.target.value })}
            className="input-reset ba b--black-20 pa2 mb4 db w-100"
          />
        </FormGroup>

        {!isFromCampaign() && (
          <FormGroup
            label={
              <FormattedMessage
                id="SignUpForm.account_type"
                defaultMessage="Account type"
              />
            }
          >
            <div className="w-50-ns">
              <AccountTypeSelect
                value={this.state.accountType}
                onChange={accountType => this.setState({ accountType })}
              />
            </div>
          </FormGroup>
        )}

        <Button type="submit" fullWidth>
          <FormattedMessage
            id="SignUpForm.sign_up_button"
            defaultMessage="Sign up"
          />
        </Button>
      </form>
    );
  }
}

export default injectIntl(SignUpForm);
