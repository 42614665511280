/* tslint:disable */
/* eslint-disable */
/* @relayHash 61940738ef17b246491da95c7c70ff13 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MembersTabQueryVariables = {
    id: string;
};
export type MembersTabQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly " $fragmentRefs": FragmentRefs<"ActiveMemberships_classroom" | "BannedMemberships_classroom">;
    } | null;
};
export type MembersTabQuery = {
    readonly response: MembersTabQueryResponse;
    readonly variables: MembersTabQueryVariables;
};



/*
query MembersTabQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Classroom {
      id
      ...ActiveMemberships_classroom
      ...BannedMemberships_classroom
    }
    id
  }
}

fragment ActiveMemberships_classroom on Classroom {
  id
  activeMemberships(first: 100) {
    edges {
      node {
        id
        ...ClassroomMember_classroomMembership
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment BannedMemberships_classroom on Classroom {
  id
  bannedMemberships(first: 100) {
    edges {
      node {
        id
        ...ClassroomMember_classroomMembership
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ClassroomMember_classroomMembership on ClassroomMembership {
  id
  level
  user {
    username
    id
  }
  classroom {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "ClassroomMembershipEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "ClassroomMembership",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "level",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "classroom",
            "storageKey": null,
            "args": null,
            "concreteType": "Classroom",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MembersTabQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Classroom",
            "selections": [
              (v2/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "ActiveMemberships_classroom",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "BannedMemberships_classroom",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MembersTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Classroom",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "activeMemberships",
                "storageKey": "activeMemberships(first:100)",
                "args": (v4/*: any*/),
                "concreteType": "ClassroomMembershipConnection",
                "plural": false,
                "selections": (v5/*: any*/)
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "activeMemberships",
                "args": (v4/*: any*/),
                "handle": "connection",
                "key": "ActiveMemberships_activeMemberships",
                "filters": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bannedMemberships",
                "storageKey": "bannedMemberships(first:100)",
                "args": (v4/*: any*/),
                "concreteType": "ClassroomMembershipConnection",
                "plural": false,
                "selections": (v5/*: any*/)
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "bannedMemberships",
                "args": (v4/*: any*/),
                "handle": "connection",
                "key": "BannedMemberships_bannedMemberships",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MembersTabQuery",
    "id": null,
    "text": "query MembersTabQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Classroom {\n      id\n      ...ActiveMemberships_classroom\n      ...BannedMemberships_classroom\n    }\n    id\n  }\n}\n\nfragment ActiveMemberships_classroom on Classroom {\n  id\n  activeMemberships(first: 100) {\n    edges {\n      node {\n        id\n        ...ClassroomMember_classroomMembership\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment BannedMemberships_classroom on Classroom {\n  id\n  bannedMemberships(first: 100) {\n    edges {\n      node {\n        id\n        ...ClassroomMember_classroomMembership\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ClassroomMember_classroomMembership on ClassroomMembership {\n  id\n  level\n  user {\n    username\n    id\n  }\n  classroom {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'cc25889e06a018f4f97ee6d6920e2b99';
export default node;
