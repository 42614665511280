import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import classNames from 'classnames';
import { VocabExerciseSummary_exercise } from './__generated__/VocabExerciseSummary_exercise.graphql';
import ExerciseSummaryContainer from './ExerciseSummaryContainer';
import './VocabExerciseSummary.css';
type Props = {
  exercise: VocabExerciseSummary_exercise;
  intl: IntlShape;
};
const messages = defineMessages({
  typeLabel: {
    id: 'VocabExerciseSummary.type_label',
    defaultMessage: 'Vocab exercise',
  },
});

const VocabExerciseSummary = ({ exercise, intl }: Props) => (
  <div className="VocabExerciseSummary">
    <ExerciseSummaryContainer
      typeLabel={intl.formatMessage(messages.typeLabel)}
    >
      {exercise.content.terms.map((term, i) => (
        <div
          key={i}
          className={classNames(
            'VocabExerciseSummary-term fl bg-white pa2 br1 mr2 mt2',
            {
              'has-image': term.image && term.image.id,
            },
          )}
        >
          <div>{term.word}</div>
          {!term.definition ? null : (
            <div className="gray f6 pt1">{term.definition}</div>
          )}
          {!term.image ? null : (
            <img
              className="VocabExerciseSummary-termImage br1 br--right"
              src={term.image.url}
              alt={`term ${i}`}
            />
          )}
        </div>
      ))}
      <div className="cf" />
    </ExerciseSummaryContainer>
  </div>
); // for testing raw component

export { VocabExerciseSummary };
export default injectIntl(
  createFragmentContainer(VocabExerciseSummary, {
    exercise: graphql`
      fragment VocabExerciseSummary_exercise on VocabExercise {
        content {
          terms {
            word
            definition
            image {
              id
              url(size: SMALL_SQUARE)
            }
          }
        }
      }
    `,
  }),
);
