/* tslint:disable */
/* eslint-disable */
/* @relayHash c4074542133263ea96435a247dceee30 */

import { ConcreteRequest } from "relay-runtime";
export type UnlockPasswordedWorksheetInput = {
    worksheetId: string;
    password: string;
    clientMutationId?: string | null;
};
export type UnlockPasswordedWorksheetMutationVariables = {
    input: UnlockPasswordedWorksheetInput;
};
export type UnlockPasswordedWorksheetMutationResponse = {
    readonly unlockPasswordedWorksheet: {
        readonly worksheet: {
            readonly id: string;
        };
    } | null;
};
export type UnlockPasswordedWorksheetMutation = {
    readonly response: UnlockPasswordedWorksheetMutationResponse;
    readonly variables: UnlockPasswordedWorksheetMutationVariables;
};



/*
mutation UnlockPasswordedWorksheetMutation(
  $input: UnlockPasswordedWorksheetInput!
) {
  unlockPasswordedWorksheet(input: $input) {
    worksheet {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnlockPasswordedWorksheetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "unlockPasswordedWorksheet",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnlockPasswordedWorksheetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "worksheet",
        "storageKey": null,
        "args": null,
        "concreteType": "Worksheet",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UnlockPasswordedWorksheetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UnlockPasswordedWorksheetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UnlockPasswordedWorksheetMutation",
    "id": null,
    "text": "mutation UnlockPasswordedWorksheetMutation(\n  $input: UnlockPasswordedWorksheetInput!\n) {\n  unlockPasswordedWorksheet(input: $input) {\n    worksheet {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '35f055866471f896b8f263ecb65860a5';
export default node;
