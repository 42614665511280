/* tslint:disable */
/* eslint-disable */
/* @relayHash afb090d620339a110a09f6a31903a769 */

import { ConcreteRequest } from "relay-runtime";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type BanClassroomMembershipInput = {
    classroomMembershipId: string;
    clientMutationId?: string | null;
};
export type BanClassroomMembershipMutationVariables = {
    input: BanClassroomMembershipInput;
};
export type BanClassroomMembershipMutationResponse = {
    readonly banClassroomMembership: {
        readonly classroomBannedClassroomMembershipsEdge: {
            readonly node: {
                readonly id: string;
                readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
                readonly classroom: {
                    readonly id: string;
                    readonly numActiveMemberships: number;
                };
            } | null;
        };
    } | null;
};
export type BanClassroomMembershipMutation = {
    readonly response: BanClassroomMembershipMutationResponse;
    readonly variables: BanClassroomMembershipMutationVariables;
};



/*
mutation BanClassroomMembershipMutation(
  $input: BanClassroomMembershipInput!
) {
  banClassroomMembership(input: $input) {
    classroomBannedClassroomMembershipsEdge {
      node {
        id
        level
        classroom {
          id
          numActiveMemberships
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "BanClassroomMembershipInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "banClassroomMembership",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BanClassroomMembershipPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "classroomBannedClassroomMembershipsEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "ClassroomMembershipEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ClassroomMembership",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "level",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroom",
                "storageKey": null,
                "args": null,
                "concreteType": "Classroom",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "numActiveMemberships",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BanClassroomMembershipMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BanClassroomMembershipMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BanClassroomMembershipMutation",
    "id": null,
    "text": "mutation BanClassroomMembershipMutation(\n  $input: BanClassroomMembershipInput!\n) {\n  banClassroomMembership(input: $input) {\n    classroomBannedClassroomMembershipsEdge {\n      node {\n        id\n        level\n        classroom {\n          id\n          numActiveMemberships\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8b1c70dda42534974831040877fc1cdf';
export default node;
