/* tslint:disable */
/* eslint-disable */
/* @relayHash 95b5e6ccae79832f079bacb4a188c01c */

import { ConcreteRequest } from "relay-runtime";
export type CreateMultipleChoiceExerciseInput = {
    worksheetId: string;
    content: MultipleChoiceExerciseContentInput;
    position: number;
    clientMutationId?: string | null;
};
export type MultipleChoiceExerciseContentInput = {
    prompt: string;
    choices: Array<MultipleChoiceExerciseChoiceInput>;
};
export type MultipleChoiceExerciseChoiceInput = {
    isCorrect: boolean;
    text: string;
};
export type CreateMultipleChoiceExerciseMutationVariables = {
    input: CreateMultipleChoiceExerciseInput;
};
export type CreateMultipleChoiceExerciseMutationResponse = {
    readonly createMultipleChoiceExercise: {
        readonly exercise: {
            readonly id: string;
            readonly content: {
                readonly prompt: string;
                readonly choices: ReadonlyArray<{
                    readonly text: string;
                    readonly isCorrect: boolean;
                }>;
            };
            readonly worksheet: {
                readonly id: string;
            };
        } | null;
    } | null;
};
export type CreateMultipleChoiceExerciseMutation = {
    readonly response: CreateMultipleChoiceExerciseMutationResponse;
    readonly variables: CreateMultipleChoiceExerciseMutationVariables;
};



/*
mutation CreateMultipleChoiceExerciseMutation(
  $input: CreateMultipleChoiceExerciseInput!
) {
  createMultipleChoiceExercise(input: $input) {
    exercise {
      id
      content {
        prompt
        choices {
          text
          isCorrect
        }
      }
      worksheet {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateMultipleChoiceExerciseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createMultipleChoiceExercise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMultipleChoiceExercisePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "exercise",
        "storageKey": null,
        "args": null,
        "concreteType": "MultipleChoiceExercise",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "content",
            "storageKey": null,
            "args": null,
            "concreteType": "MultipleChoiceExerciseContent",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "prompt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "choices",
                "storageKey": null,
                "args": null,
                "concreteType": "MultipleChoiceExerciseChoice",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isCorrect",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "worksheet",
            "storageKey": null,
            "args": null,
            "concreteType": "Worksheet",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateMultipleChoiceExerciseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateMultipleChoiceExerciseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateMultipleChoiceExerciseMutation",
    "id": null,
    "text": "mutation CreateMultipleChoiceExerciseMutation(\n  $input: CreateMultipleChoiceExerciseInput!\n) {\n  createMultipleChoiceExercise(input: $input) {\n    exercise {\n      id\n      content {\n        prompt\n        choices {\n          text\n          isCorrect\n        }\n      }\n      worksheet {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c97fe7d0f3ebc304e6941727b1944b7b';
export default node;
