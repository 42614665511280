import { tempId } from '../../lib/graphqlUtils';
import { RecordSourceSelectorProxy } from 'relay-runtime';
export const addExerciseToStore = (
  store: RecordSourceSelectorProxy,
  newExercise: any,
  worksheetId: string,
  position: number,
) => {
  const worksheet = store.get(worksheetId);
  if (worksheet) {
    const exercises = (worksheet.getLinkedRecords('exercises') || []).slice();
    exercises.splice(position, 0, newExercise);
    worksheet.setLinkedRecords(exercises, 'exercises');
  }
};
export const deleteExerciseFromStore = (
  store: RecordSourceSelectorProxy,
  id: string,
  worksheetId: string,
) => {
  const worksheet = store.get(worksheetId);
  if (worksheet) {
    let exercises = worksheet.getLinkedRecords('exercises') || [];
    exercises = exercises.filter(
      exercise => exercise && exercise.getValue('id') !== id,
    );
    worksheet.setLinkedRecords(exercises, 'exercises');
    store.delete(id);
  }
};

const linkTerms = (
  store: RecordSourceSelectorProxy,
  id: string,
  exerciseContent: any,
  termsContent: any[],
) => {
  const existingRecords = exerciseContent.getLinkedRecords('terms') || [];
  const termRecords = termsContent.map((term, i) => {
    const termRecord =
      existingRecords[i] ||
      store.create(`${id}:content:terms:${i}`, 'VocabExerciseTerm');
    Object.keys(term).forEach(termKey => {
      termRecord.setValue(term[termKey], termKey);
    });
    return termRecord;
  });
  exerciseContent.setLinkedRecords(termRecords, 'terms');
};

const linkChoices = (
  store: RecordSourceSelectorProxy,
  id: string,
  exerciseContent: any,
  choicesContent: any[],
) => {
  const existingRecords = exerciseContent.getLinkedRecords('choices') || [];
  const choiceRecords = choicesContent.map((choice, i) => {
    const choiceRecord =
      existingRecords[i] ||
      store.create(
        `${id}:content:choices:${i}`,
        'MultipleChoiceExerciseChoice',
      );
    Object.keys(choice).forEach(choiceKey => {
      choiceRecord.setValue(choice[choiceKey], choiceKey);
    });
    return choiceRecord;
  });
  exerciseContent.setLinkedRecords(choiceRecords, 'choices');
};

export const optimisticCreateExerciseUpdater = (
  exerciseType: string,
  worksheetId: string,
  content: any,
  position: number,
) => {
  return (store: RecordSourceSelectorProxy) => {
    const id = tempId('newExercise');
    const newExerciseContent = store.create(
      `${id}:content`,
      `${exerciseType}Content`,
    );
    Object.keys(content).forEach(key => {
      // TODO: exceptions for terms/choices is hacky. Could this be done automatically somehow?
      if (key === 'terms' && exerciseType === 'VocabExercise') {
        linkTerms(store, id, newExerciseContent, content[key]);
      } else if (
        key === 'choices' &&
        exerciseType === 'MultipleChoiceExercise'
      ) {
        linkChoices(store, id, newExerciseContent, content[key]);
      } else {
        newExerciseContent.setValue(content[key], key);
      }
    });
    const newExercise = store.create(id, exerciseType);
    newExercise.setValue(id, 'id');
    newExercise.setLinkedRecord(newExerciseContent, 'content');
    const worksheet = store.get(worksheetId);
    if (worksheet) {
      newExercise.setLinkedRecord(worksheet, 'worksheet');
      addExerciseToStore(store, newExercise, worksheetId, position);
    }
  };
};
export const updateExerciseUpdater = (
  exerciseType: string,
  id: string,
  worksheetId: string,
  fields: {
    position?: number | null;
    content?: any | null;
  },
) => {
  return (store: RecordSourceSelectorProxy) => {
    const exercise = store.get(id);

    if (exercise && fields.content != null) {
      const content = fields.content;
      const contentKeys = Object.keys(fields.content);
      const exerciseContent = exercise.getLinkedRecord('content');
      contentKeys.forEach(key => {
        if (key === 'terms' && exerciseType === 'VocabExercise') {
          linkTerms(store, id, exerciseContent, content[key]);
        } else if (
          key === 'choices' &&
          exerciseType === 'MultipleChoiceExercise'
        ) {
          linkChoices(store, id, exerciseContent, content[key]);
        } else if (exerciseContent) {
          exerciseContent.setValue(content[key], key);
        }
      });
    }

    if (fields.position != null) {
      const worksheet = store.get(worksheetId);
      if (worksheet) {
        let exercises = worksheet.getLinkedRecords('exercises') || [];
        exercises = exercises.filter(
          exercise => exercise && exercise.getValue('id') !== id,
        );
        if (exercise) exercises.splice(fields.position, 0, exercise);
        worksheet.setLinkedRecords(exercises, 'exercises');
      }
    }
  };
};
