import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import Button from '../common/Button';
import { AdminStudyRecordsList_admin } from './__generated__/AdminStudyRecordsList_admin.graphql';
import Loading from '../common/Loading';
import { exists } from '../../lib/utils';
type Props = {
  admin: AdminStudyRecordsList_admin;
  relay: RelayPaginationProp;
};

const AdminStudyRecordsList = ({ admin, relay }: Props) => (
  <div className="AdminStudyRecordsList pt3 tl">
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading />}
    >
      {admin &&
      admin.recentStudyRecords.edges &&
      admin.recentStudyRecords.edges.length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="AdminStudyRecordsList.no_content_message"
            defaultMessage="No study records found"
          />
        </div>
      )}
      {admin &&
        admin.recentStudyRecords.edges &&
        admin.recentStudyRecords.edges.filter(exists).map(
          ({ node }) =>
            node && (
              <div
                className="AdminStudyRecordsList-studyRecord bg-black-10 pa2 mt2 br1"
                key={node.id}
              >
                {!node.identifier ? null : (
                  <div className="fr">
                    <Button
                      component={Link}
                      to={`/result/${node.identifier || ''}`}
                    >
                      View study record
                    </Button>
                  </div>
                )}
                <div>
                  <span className="gray f6 dib mr2">worksheet:</span>
                  <Link
                    className="link"
                    to={`/view/${node.worksheet.identifier || ''}`}
                  >
                    {node.worksheet.title}
                  </Link>
                </div>
                <div>
                  <span className="gray f6 dib mr2">user:</span>
                  {node.user ? (
                    <Link to={`/profile/${node.user.username}`}>
                      {node.user.username}
                    </Link>
                  ) : (
                    <span className="gray">Anonymous</span>
                  )}
                </div>
                <div>
                  <span className="gray f6 dib mr2">score:</span>
                  {node.score && Math.round(node.score)}
                </div>
                <div>
                  <span className="gray f6 dib mr2">duration:</span>
                  {node.duration && Math.round(node.duration)}
                </div>
                <div>
                  <span className="gray f6 dib mr2">Created at:</span>
                  {new Date(node.createdAt * 1000).toLocaleString()}
                </div>
                <div className="mt1 f6">
                  {node.completedAt ? (
                    <div>
                      <i className="fas fa-check" /> completed
                    </div>
                  ) : (
                    <div className="gray">
                      <i className="fas fa-times" /> abandoned
                    </div>
                  )}
                </div>
              </div>
            ),
        )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  AdminStudyRecordsList,
  {
    admin: graphql`
      fragment AdminStudyRecordsList_admin on Admin
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "String" }
        ) {
        recentStudyRecords(first: $count, after: $cursor)
          @connection(key: "AdminStudyRecordsList_recentStudyRecords") {
          edges {
            node {
              id
              createdAt
              completedAt
              identifier
              score
              duration
              user {
                username
              }
              worksheet {
                identifier
                title
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props =>
      props.admin && props.admin.recentStudyRecords,
    getVariables: (props, { count, cursor }) => ({
      count,
      cursor,
    }),
    query: graphql`
      query AdminStudyRecordsListQuery($count: Int!, $cursor: String) {
        admin: admin {
          ...AdminStudyRecordsList_admin
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
