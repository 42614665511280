/* tslint:disable */
/* eslint-disable */
/* @relayHash f5c68c33f1594b3a03e3a69e3c5fadfb */

import { ConcreteRequest } from "relay-runtime";
export type ImageChooserImageQueryVariables = {
    id: string;
};
export type ImageChooserImageQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly url?: string;
    } | null;
};
export type ImageChooserImageQuery = {
    readonly response: ImageChooserImageQueryResponse;
    readonly variables: ImageChooserImageQueryVariables;
};



/*
query ImageChooserImageQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Image {
      id
      url(size: LARGE_SQUARE)
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": "LARGE_SQUARE"
    }
  ],
  "storageKey": "url(size:\"LARGE_SQUARE\")"
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImageChooserImageQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Image",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ImageChooserImageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Image",
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ImageChooserImageQuery",
    "id": null,
    "text": "query ImageChooserImageQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Image {\n      id\n      url(size: LARGE_SQUARE)\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ef24cc5efa54ebb681b6d7b921f00892';
export default node;
