import React, { ReactNode, FC } from 'react';
import classNames from 'classnames';
import './Alert.css';

interface AlertProps {
  children: ReactNode;
  alertType?: 'success' | 'default';
}

const Alert: FC<AlertProps> = ({ children, alertType }) => (
  <div
    className={classNames('Alert pa3 lh-copy', {
      'Alert--success': alertType === 'success',
    })}
  >
    {children}
  </div>
);

export default Alert;
