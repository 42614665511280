import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { Environment } from 'react-relay';
import { UnlockPasswordedWorksheetMutation } from './__generated__/UnlockPasswordedWorksheetMutation.graphql';

const mutation = graphql`
  mutation UnlockPasswordedWorksheetMutation(
    $input: UnlockPasswordedWorksheetInput!
  ) {
    unlockPasswordedWorksheet(input: $input) {
      worksheet {
        id
      }
    }
  }
`;

const commit = (
  environment: Environment,
  worksheetId: string,
  password: string,
) =>
  commitMutationPromise<UnlockPasswordedWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        worksheetId,
        password,
      },
    },
  });

export default {
  commit,
};
