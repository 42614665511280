/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedbackModal_viewer = {
    readonly user: {
        readonly id: string;
    } | null;
    readonly " $refType": "FeedbackModal_viewer";
};
export type FeedbackModal_viewer$data = FeedbackModal_viewer;
export type FeedbackModal_viewer$key = {
    readonly " $data"?: FeedbackModal_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackModal_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "FeedbackModal_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'b5ea863c5c29241b177239b5d999e8b8';
export default node;
