import React, { FunctionComponent } from 'react';
import { FormattedRelativeTime } from 'react-intl';

interface Props {
  since: Date;
}

// from https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
const monthDiff = (d1: Date, d2: Date) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

const TimeAgo: FunctionComponent<Props> = ({ since }) => {
  const numMonthsAgo = monthDiff(since, new Date());
  if (numMonthsAgo > 12) {
    return (
      <FormattedRelativeTime
        value={-1 * Math.floor(numMonthsAgo / 12)}
        // eslint-disable-next-line
        style="long"
        unit="year"
        numeric="auto"
      />
    );
  }
  if (numMonthsAgo > 0) {
    return (
      <FormattedRelativeTime
        value={-1 * numMonthsAgo}
        // eslint-disable-next-line
        style="long"
        unit="month"
        numeric="auto"
      />
    );
  }
  const timeAgoSec = (since.getTime() - Date.now()) / 1000;
  return (
    <FormattedRelativeTime
      value={timeAgoSec}
      // eslint-disable-next-line
      style="long"
      updateIntervalInSeconds={1}
      numeric="auto"
    />
  );
};

export default TimeAgo;
