import React, { FunctionComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import SignUpForm from '../components/auth/SignUpForm';
import { SignUpPageQuery } from './__generated__/SignUpPageQuery.graphql';

const signUpViewerQuery = graphql`
  query SignUpPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        id
      }
    }
  }
`;

const SignUpPage: FunctionComponent = () => (
  <QueryRenderer<SignUpPageQuery>
    environment={environment}
    variables={{}}
    query={signUpViewerQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      const viewer = props && props.viewer;
      if (viewer && viewer.user) {
        return <Redirect to="/" />;
      }

      return (
        <div>
          <NavHeader viewer={viewer} />
          <div className="pa3">
            <h1 className="f2">
              <FormattedMessage
                id="SignUpPage.sign_up_header"
                defaultMessage="Sign up"
              />
            </h1>
            <div className="ba b--black-10 measure center pa3 tl mb4 br2">
              <SignUpForm
                background="white"
                environment={environment}
                onSignUp={() => {
                  window.location.href = '/dashboard';
                }}
              />
            </div>
            <FormattedMessage
              id="SignUpPage.switch_to_login"
              defaultMessage="Already have an account?"
            />
            <Link to="/log-in" className="ml1">
              <FormattedMessage
                id="SignUpPage.login_link"
                defaultMessage="Log in"
              />
            </Link>
          </div>
        </div>
      );
    }}
  />
);

export default SignUpPage;
