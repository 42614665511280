import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { ExerciseSummary_exercise } from './__generated__/ExerciseSummary_exercise.graphql';
import TranslationExerciseSummary from './TranslationExerciseSummary';
import WordOrderExerciseSummary from './WordOrderExerciseSummary';
import VocabExerciseSummary from './VocabExerciseSummary';
import MultipleChoiceExerciseSummary from './MultipleChoiceExerciseSummary';
import './index.css';
type Props = {
  exercise: ExerciseSummary_exercise;
  alwaysShowAnswers: boolean;
  scrambleWords: boolean;
};

const ExerciseSummary = (props: Props) => {
  const { exercise } = props;
  const type = exercise.__typename;
  return (
    <div className="ExerciseSummary pa3">
      {type === 'WordOrderExercise' && <WordOrderExerciseSummary {...props} />}
      {type === 'TranslationExercise' && (
        <TranslationExerciseSummary {...props} />
      )}
      {type === 'VocabExercise' && <VocabExerciseSummary {...props} />}
      {type === 'MultipleChoiceExercise' && (
        <MultipleChoiceExerciseSummary {...props} />
      )}
    </div>
  );
}; // for testing raw component

export { ExerciseSummary };
export default createFragmentContainer(ExerciseSummary, {
  exercise: graphql`
    fragment ExerciseSummary_exercise on Exercise {
      id
      worksheet {
        id
      }
      __typename
      ... on TranslationExercise {
        ...TranslationExerciseSummary_exercise
      }
      ... on WordOrderExercise {
        ...WordOrderExerciseSummary_exercise
      }
      ... on VocabExercise {
        ...VocabExerciseSummary_exercise
      }
      ... on MultipleChoiceExercise {
        ...MultipleChoiceExerciseSummary_exercise
      }
    }
  `,
});
