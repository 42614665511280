import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import { UpgradePageQuery } from './__generated__/UpgradePageQuery.graphql';
import PageLoading from '../components/common/PageLoading';
import UpgradePlans from '../components/upgrade/UpgradePlans';
import './UpgradePage.css';
import Button from '../components/common/Button';
import { FormattedMessage } from 'react-intl';

const upgradeQuery = graphql`
  query UpgradePageQuery {
    viewer {
      ...NavHeader_viewer
      ...PageLoading_viewer
      user {
        id
        isUpgradedTeacher
      }
    }
    ...UpgradePlans_root
  }
`;

interface IUpgradePageState {
  isYearlySelected: boolean;
}

class UpgradePage extends Component<any, IUpgradePageState> {
  state: IUpgradePageState = {
    isYearlySelected: true,
  };

  render() {
    return (
      <QueryRenderer<UpgradePageQuery>
        environment={environment}
        variables={{}}
        query={upgradeQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          }

          if (!props || !props.viewer) {
            return <PageLoading viewer={props?.viewer} />;
          }

          const viewer = props && props.viewer;
          if (props && (!props.viewer.user || !props.viewer.user.id)) {
            return <Redirect to="/" />;
          }

          if (viewer?.user?.isUpgradedTeacher) {
            return <Redirect to="/upgrade-success" />;
          }

          const checkoutUrl = this.state.isYearlySelected
            ? '/checkout/yearly'
            : '/checkout/monthly';

          return (
            <div>
              <NavHeader viewer={viewer} />
              <div className="mw8 center tl cf">
                <UpgradePlans
                  root={props}
                  isYearlySelected={this.state.isYearlySelected}
                  onChangeYearlySelected={isYearlySelected =>
                    this.setState({ isYearlySelected })
                  }
                />
              </div>
              <div className="UpgradePage-hero cf pv5 ph2 mt4 dn db-ns">
                <div className="mw8 center tl flex items-center">
                  <div className="w-third w-50-m pa2">
                    <h2 className="lh-copy">
                      <FormattedMessage
                        id="UpgradePage.results_headline"
                        defaultMessage="Detailed student results"
                      />
                    </h2>
                    <p className="lh-copy gray">
                      <FormattedMessage
                        id="UpgradePage.results_description"
                        defaultMessage="
                          Understand how your students are studying and where they
                          have trouble. view scores and results for logged-in and
                          logged-out student that study your wordsheets.
                        "
                      />
                    </p>
                  </div>
                  <div className="w-two-thirds w-50-m pa2">
                    <img
                      alt="techer plus student results page"
                      src="/results_screenshot.png"
                      className="UpgradePage-upsellImg"
                    />
                  </div>
                  <div className="cf" />
                </div>

                <div className="mw8 center tl flex items-center pv5">
                  <div className="w-two-thirds w-50-m pa2">
                    <img
                      alt="techer plus student results page"
                      src="/analysis_screenshot.png"
                      className="UpgradePage-upsellImg"
                    />
                  </div>
                  <div className="w-third w-50-m pa2">
                    <h2 className="lh-copy">
                      <FormattedMessage
                        id="UpgradePage.analysis_headline"
                        defaultMessage="Understand where students are struggling"
                      />
                    </h2>
                    <p className="lh-copy gray">
                      <FormattedMessage
                        id="UpgradePage.analysis_description"
                        defaultMessage="
                          Teacher Plus offers powerful analysis tools so you can see
                          which questions students are stuggling with, and which
                          questions they've mastered. See at a glance what
                          the most common mistakes are, and how many students have
                          made them.
                        "
                      />
                    </p>
                  </div>
                  <div className="cf" />
                </div>
                <Button
                  component={Link}
                  to={checkoutUrl}
                  buttonType="success"
                  size="large"
                >
                  <FormattedMessage
                    id="UpgradePage.upgrade_button"
                    defaultMessage="Upgrade to Teacher Plus"
                  />
                </Button>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default UpgradePage;
