import React, { Component, ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';

import FormGroup from '../common/FormGroup';
import WordSplitter from '../common/WordSplitter';
import Button from '../common/Button';
import AsianLanguagesGroupingNote from '../common/AsianLanguagesGroupingNote';
import RtlLanguagesGroupingNote from '../common/RtlLanguagesGroupingNote';
import EditExtraCorrectAnswerModal from './EditExtraCorrectAnswerModal';
import { getAtoms, findPiecesSpanningText } from '../../lib/atomHelpers';
import {
  removeArrayItem,
  containsChineseChars,
  containsRtlChars,
} from '../../lib/utils';
import { numPossibleOrders } from './helpers';
import './index.css';

export type wordOrderConfig = {
  correctAnswers: ReadonlyArray<string>;
  scrambledParts: ReadonlyArray<string>;
};

type Props = {
  defaultConfig?: null | wordOrderConfig;
  onUpdateConfig: (config: wordOrderConfig) => void;
  intl: IntlShape;
};

type State = {
  defaultConfig: null | wordOrderConfig;
  correctAnswer: string;
  answerPieces: string[];
  otherCorrectAnswers: string[];
  fusedPieceLocations: Set<number>;
  isAddingCorrectAnswer: boolean;
};

const messages = defineMessages({
  correctAnswerLabel: {
    id: 'WordOrderEditor.correct_answer_label',
    defaultMessage: 'Correct answer sentence',
  },
  correctAnswerHint: {
    id: 'WordOrderEditor.correct_answer_hint',
    defaultMessage:
      "This is the correct answer for the exercise. This sentence will be scrambled and your students will need to put it back in order. Don't worry if there are other correct answers too - you'll be able to add those below.",
  },
  correctAnswerPlaceholder: {
    id: 'WordOrderEditor.correct_answer_placeholder',
    defaultMessage: 'Sentence',
  },
  groupWordsLabel: {
    id: 'WordOrderEditor.group_words_label',
    defaultMessage: 'Adjust word grouping',
  },
  groupWordsHint: {
    id: 'WordOrderEditor.group_words_hint',
    defaultMessage:
      'Group letters and words together into chunks that your students will need to assemble into the sentence above. The larger the word chunks the easier it will be. Click between words to join them together, and click inside words to split them apart.',
  },
  otherCorrectAnswersLabel: {
    id: 'WordOrderEditor.other_correct_answers_label',
    defaultMessage: 'Other correct answers',
  },
  otherCorrectAnswersHint: {
    id: 'WordOrderEditor.other_correct_answers_hint',
    defaultMessage:
      'If there are other ways the words above can be assembled into a correct answer you can list them here.',
  },
});

class WordOrderEditor extends Component<Props, State> {
  state: State = {
    correctAnswer: '',
    defaultConfig: null,
    answerPieces: [],
    otherCorrectAnswers: [],
    fusedPieceLocations: new Set(),
    isAddingCorrectAnswer: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const defaultConfig = nextProps.defaultConfig;
    if (prevState.defaultConfig !== defaultConfig) {
      if (!defaultConfig) {
        return {
          correctAnswer: '',
          defaultConfig: null,
          answerPieces: [],
          otherCorrectAnswers: [],
        };
      }
      const otherCorrectAnswers: string[] = defaultConfig.correctAnswers.slice(
        1,
      );
      return {
        correctAnswer: defaultConfig.correctAnswers[0],
        defaultConfig,
        answerPieces: defaultConfig.scrambledParts,
        otherCorrectAnswers,
      };
    }
    return null;
  }

  onChangeCorrectAnswer = (evt: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      correctAnswer: evt.target.value,
      answerPieces: getAtoms(evt.target.value),
      fusedPieceLocations: new Set(),
    });
  };

  onFuseAnswerPieces = (connectorIndex: number) => {
    const fusedPieceLocations = new Set(this.state.fusedPieceLocations);
    fusedPieceLocations.add(connectorIndex);
    this.setState({ fusedPieceLocations });
  };

  onSplitAnswerPieces = (connectorIndex: number) => {
    const fusedPieceLocations = new Set(this.state.fusedPieceLocations);
    fusedPieceLocations.delete(connectorIndex);
    this.setState({ fusedPieceLocations });
  };

  onAddCorrectAnswer = (correctAnswer: string) => {
    const otherCorrectAnswers = this.state.otherCorrectAnswers.slice();
    otherCorrectAnswers.push(correctAnswer);
    this.setState({ otherCorrectAnswers, isAddingCorrectAnswer: false });
  };

  getFusedAnswerPieces(): string[] {
    const fusedAnswerPieces = [];
    let curPiece = '';
    this.state.answerPieces.forEach((piece, index) => {
      if (this.state.fusedPieceLocations.has(index - 1)) {
        curPiece += piece;
      } else {
        if (curPiece !== '') {
          fusedAnswerPieces.push(curPiece);
        }
        curPiece = piece;
      }
    });
    if (curPiece !== '') {
      fusedAnswerPieces.push(curPiece);
    }
    return fusedAnswerPieces;
  }

  isAnswerValid(extraAnswer: string) {
    return !!findPiecesSpanningText(extraAnswer, this.getFusedAnswerPieces());
  }

  hasMoreValidExtraAnswers() {
    const answerPieces = this.getFusedAnswerPieces();
    const totalValidAnswers = numPossibleOrders(answerPieces);
    const validExtraAnswers = this.state.otherCorrectAnswers.filter(answer =>
      this.isAnswerValid(answer),
    );
    return validExtraAnswers.length < totalValidAnswers - 1;
  }

  onDone = () => {
    const updatedConfig = {
      correctAnswers: [this.state.correctAnswer.trim()],
      scrambledParts: this.getFusedAnswerPieces(),
    };
    this.state.otherCorrectAnswers.forEach(answer => {
      if (this.isAnswerValid(answer))
        updatedConfig.correctAnswers.push(answer.trim());
    });
    this.props.onUpdateConfig(updatedConfig);
  };

  renderGroupingHint() {
    const baseMessage = this.props.intl.formatMessage(messages.groupWordsHint);
    if (!containsChineseChars(this.state.correctAnswer)) return baseMessage;
    return (
      <React.Fragment>
        {baseMessage}
        <AsianLanguagesGroupingNote />
      </React.Fragment>
    );
  }

  renderRtlHint() {
    if (!containsRtlChars(this.state.correctAnswer)) return null;
    return (
      <div className="mb3">
        <RtlLanguagesGroupingNote />
      </div>
    );
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="WordOrderEditor">
        <div className="WordOrderEditor-body">
          <FormGroup
            label={formatMessage(messages.correctAnswerLabel)}
            hint={formatMessage(messages.correctAnswerHint)}
          >
            <input
              className="WordOrderEditor-input"
              type="text"
              data-testid="WordOrderEditor-correctAnswer"
              placeholder={formatMessage(messages.correctAnswerPlaceholder)}
              value={this.state.correctAnswer}
              onChange={this.onChangeCorrectAnswer}
            />
          </FormGroup>

          {this.renderRtlHint()}

          <FormGroup
            isHidden={!this.state.correctAnswer}
            label={formatMessage(messages.groupWordsLabel)}
            hint={this.renderGroupingHint()}
          >
            <div className="WordOrderEditor-answerParts">
              <WordSplitter
                atoms={this.state.answerPieces}
                fusedPositions={this.state.fusedPieceLocations}
                onFuseAtoms={this.onFuseAnswerPieces}
                onSplitAtoms={this.onSplitAnswerPieces}
              />
            </div>
          </FormGroup>
          <FormGroup
            isHidden={!this.state.correctAnswer}
            label={formatMessage(messages.otherCorrectAnswersLabel)}
            hint={formatMessage(messages.otherCorrectAnswersHint)}
          >
            {this.state.otherCorrectAnswers.map(answer => (
              <div
                className={classNames('WordOrderEditor-otherCorrectAnswer', {
                  'is-invalid': !this.isAnswerValid(answer),
                })}
                key={answer}
              >
                {answer}
                <button
                  className="WordOrderEditor-deleteOtherCorrectAnswer"
                  onClick={() =>
                    this.setState({
                      otherCorrectAnswers: removeArrayItem(
                        this.state.otherCorrectAnswers,
                        answer,
                      ),
                    })
                  }
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            ))}
            <button
              disabled={!this.hasMoreValidExtraAnswers()}
              className="WordOrderEditor-addButton WordOrderEditor-addCorrectAnswer"
              onClick={() => this.setState({ isAddingCorrectAnswer: true })}
            >
              <i className="fas fa-plus" />
            </button>
          </FormGroup>
        </div>
        <EditExtraCorrectAnswerModal
          isOpen={this.state.isAddingCorrectAnswer}
          onCancel={() => this.setState({ isAddingCorrectAnswer: false })}
          onChange={this.onAddCorrectAnswer}
          correctAnswerText={this.state.correctAnswer}
          otherCorrectAnswers={[this.state.correctAnswer.trim()].concat(
            this.state.otherCorrectAnswers,
          )}
          answerPieces={this.getFusedAnswerPieces()}
        />
        <div className="WordOrderEditor-doneButton">
          <Button
            type="success"
            disabled={!this.state.correctAnswer}
            onClick={this.onDone}
            fullWidth
          >
            <FormattedMessage
              id="WordOrderEditor.done_button"
              defaultMessage="Done"
            />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(WordOrderEditor);
