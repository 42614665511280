/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WORKSHEET_VISIBILITY = "EVERYONE" | "LOGGED_IN" | "PASSWORD" | "%future added value";
export type WorksheetEditor_worksheet = {
    readonly id: string;
    readonly title: string | null;
    readonly description: string | null;
    readonly identifier: string | null;
    readonly isPublished: boolean;
    readonly visibility: WORKSHEET_VISIBILITY;
    readonly password: string | null;
    readonly exercises: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly content?: {
            readonly prompt: string;
            readonly choices: ReadonlyArray<{
                readonly text: string;
                readonly isCorrect: boolean;
            }>;
            readonly correctAnswers: ReadonlyArray<string>;
            readonly scrambledParts: ReadonlyArray<string>;
            readonly terms?: ReadonlyArray<{
                readonly word: string;
                readonly definition: string | null;
                readonly image: {
                    readonly id: string;
                } | null;
            }>;
        };
        readonly " $fragmentRefs": FragmentRefs<"DraggableExercise_exercise">;
    }>;
    readonly " $refType": "WorksheetEditor_worksheet";
};
export type WorksheetEditor_worksheet$data = WorksheetEditor_worksheet;
export type WorksheetEditor_worksheet$key = {
    readonly " $data"?: WorksheetEditor_worksheet$data;
    readonly " $fragmentRefs": FragmentRefs<"WorksheetEditor_worksheet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "prompt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "correctAnswers",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scrambledParts",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "WorksheetEditor_worksheet",
  "type": "Worksheet",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isPublished",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "visibility",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "password",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercises",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "type": "MultipleChoiceExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "MultipleChoiceExerciseContent",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "choices",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MultipleChoiceExerciseChoice",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "text",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "isCorrect",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "TranslationExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "TranslationExerciseContent",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "WordOrderExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "WordOrderExerciseContent",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "type": "VocabExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "VocabExerciseContent",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "terms",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "VocabExerciseTerm",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "word",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "definition",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "image",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Image",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "DraggableExercise_exercise",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '639c836c459a75e95828aaedd9234215';
export default node;
