import React, { FC } from 'react';
import classNames from 'classnames';
import './TeacherBadge.css';

interface TeacherBadgeProps {
  size?: 'default' | 'small';
  shrinkTextOnMobile?: boolean;
}

const TeacherBadge: FC<TeacherBadgeProps> = ({ size, shrinkTextOnMobile }) => (
  <span
    className={classNames('TeacherBadge f7', {
      'TeacherBadge--small': size === 'small',
    })}
  >
    <span className={classNames({ 'dn di-ns': shrinkTextOnMobile })}>
      Teacher+
    </span>
    <span
      className={classNames({
        'di dn-ns': shrinkTextOnMobile,
        dn: !shrinkTextOnMobile,
      })}
    >
      T+
    </span>
  </span>
);

export default TeacherBadge;
