import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { StudyRecordViewer_studyRecord } from './__generated__/StudyRecordViewer_studyRecord.graphql';
import Button from '../common/Button';
import AnswerDetails from './AnswerDetails';
import './index.css';
type Props = {
  studyRecord: StudyRecordViewer_studyRecord;
  relay: RelayProp;
  isDemo: boolean;
  isEmbed: boolean;
};

class StudyRecordViewer extends Component<Props> {
  renderDemoCta() {
    return (
      <div className="pt3">
        <h1 className="StudyRecordViewer-demoScore">
          <FormattedMessage
            id="StudyRecordViewer.score_percent"
            defaultMessage="{score, number}%"
            values={{
              score: Math.round(this.props.studyRecord.score || 0),
            }}
          />
        </h1>
        <p className="pt4">
          <FormattedMessage
            id="StudyRecordViewer.finished_demo_text"
            defaultMessage="You can create wordsheets like this using your own content."
          />
        </p>
        <div className="pv4 center">
          <Button
            size="large"
            component={Link}
            to="/new-sheet"
            buttonType="success"
          >
            <FormattedMessage
              id="StudyRecordViewer.create_wordsheet_button"
              defaultMessage="Create your own Wordsheet"
            />
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { studyRecord, isDemo, isEmbed } = this.props;
    const { worksheet } = studyRecord;
    let studyUrl = `/study/${worksheet.identifier || ''}`;

    if (isEmbed) {
      studyUrl += '/embed';
    }

    return (
      <div className="StudyRecordViewer pa3 tl">
        {isDemo ? null : (
          <React.Fragment>
            <div className="fr">
              <Button component={Link} size="large" to={studyUrl}>
                <FormattedMessage
                  id="StudyRecordViewer.study"
                  defaultMessage="Study"
                />
              </Button>
            </div>
            <span className="gray f6">
              <FormattedMessage
                id="StudyRecordViewer.score_title"
                defaultMessage="Study result"
              />
            </span>
            <h2 className="mt2">{studyRecord.worksheet.title}</h2>
          </React.Fragment>
        )}

        <div className="tc pv3">
          {this.props.isDemo ? (
            this.renderDemoCta()
          ) : (
            <React.Fragment>
              <div className="StudyRecordViewer-score f1 mv2">
                <FormattedMessage
                  id="StudyRecordViewer.score_percent"
                  defaultMessage="{score, number}%"
                  values={{
                    score: Math.round(studyRecord.score || 0),
                  }}
                />
              </div>
              {!studyRecord.duration ? null : (
                <div className="gray f7">
                  <FormattedMessage
                    id="StudyRecordViewer.duration_text"
                    defaultMessage="In {duration, number} seconds"
                    values={{
                      duration: Math.round(studyRecord.duration),
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {studyRecord.answers.length === 0 ? null : (
          <div className="tl">
            <h4>
              <FormattedMessage
                id="StudyRecordViewer.responses_label"
                defaultMessage="Responses"
              />
            </h4>
            {studyRecord.answers.map(answer => (
              <div className="mv2" key={answer.id}>
                <AnswerDetails answer={answer} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(StudyRecordViewer, {
  studyRecord: graphql`
    fragment StudyRecordViewer_studyRecord on StudyRecord {
      id
      score
      duration
      user {
        id
      }
      worksheet {
        identifier
        title
        description
      }
      answers {
        id
        ...AnswerDetails_answer
      }
    }
  `,
});
