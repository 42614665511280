import React from 'react';
import { ReactNode } from 'react';
import './LabelText.css';
type Props = {
  children: ReactNode;
};

const LabelText = ({ children }: Props) => (
  <div className="LabelText">{children}</div>
);

export default LabelText;
