import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import Button from '../common/Button';
import Input from '../common/Input';
import Modal from '../common/Modal';
import ImageChooser from './ImageChooser';
import ImagePreview from './ImagePreview';

import './index.css';

export type vocabTerm = {
  word: string;
  definition: null | string;
  imageId: null | string;
};

type partialVocabTerm = {
  word: string;
  definition: string;
  imageId: null | string;
};

export type vocabConfig = {
  terms: ReadonlyArray<vocabTerm>;
};

type partialVocabConfig = {
  terms: ReadonlyArray<partialVocabTerm>;
};

type Props = {
  defaultConfig?: null | vocabConfig;
  onUpdateConfig: (config: vocabConfig) => void;
  intl: IntlShape;
};

type State = {
  defaultConfig: null | partialVocabConfig;
  terms: partialVocabTerm[];
  showingImageModal: null | number;
};

const isTermValid = (term: partialVocabTerm) =>
  term.word && (term.definition || term.imageId);

const messages = defineMessages({
  wordFieldLabel: {
    id: 'VocabEditor.word_field_label',
    defaultMessage: 'Word',
  },
  definitionFieldLabel: {
    id: 'VocabEditor.definition_field_label',
    defaultMessage: 'Definition',
  },
  imageChooserModalHeader: {
    id: 'VocabEditor.image_chooser_modal_header',
    defaultMessage: 'Select an image',
  },
  noTermsError: {
    id: 'VocabEditor.no_terms_error',
    defaultMessage: 'Must have at least 3 complete terms',
  },
  invalidTermsError: {
    id: 'VocabEditor.invalid_terms_error',
    defaultMessage: 'Terms must all have a definition or an image or both',
  },
});

class VocabEditor extends Component<Props, State> {
  state: State = {
    defaultConfig: null,
    terms: [
      { word: '', definition: '', imageId: null },
      { word: '', definition: '', imageId: null },
      { word: '', definition: '', imageId: null },
    ],
    showingImageModal: null,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const defaultConfig = nextProps.defaultConfig;
    if (prevState.defaultConfig !== defaultConfig) {
      if (!defaultConfig) {
        return {
          terms: [],
          defaultConfig: null,
        };
      }
      return {
        terms: defaultConfig.terms,
        defaultConfig,
      };
    }
    return null;
  }

  getNonEmptyTerms = () =>
    this.state.terms.filter(
      term => term.word || term.definition || term.imageId,
    );

  isValid = () => {
    const nonEmptyTerms = this.getNonEmptyTerms();
    if (nonEmptyTerms.length < 3) return false;
    const validTerms = nonEmptyTerms.filter(term => isTermValid(term));
    return validTerms.length === nonEmptyTerms.length;
  };

  renderInvalidReason() {
    const { formatMessage } = this.props.intl;
    const nonEmptyTerms = this.getNonEmptyTerms();
    if (nonEmptyTerms.length === 0) return formatMessage(messages.noTermsError);
    return formatMessage(messages.invalidTermsError);
  }

  onUpdateTerm = (termNum: number, termFields: Partial<partialVocabTerm>) => {
    const terms = this.state.terms.slice();
    terms[termNum] = { ...terms[termNum], ...termFields };
    this.setState({ terms });
  };

  onDone = () => {
    // sigh... flow... :/
    const terms: ReadonlyArray<vocabTerm> = this.state.terms.filter(term =>
      isTermValid(term),
    );
    this.props.onUpdateConfig({ terms });
  };

  onAddTerm = () => {
    const terms = this.state.terms.slice();
    terms.push({ word: '', definition: '', imageId: null });
    this.setState({ terms });
  };

  onDeleteTerm = (termNum: number) => {
    const terms = this.state.terms.slice();
    terms.splice(termNum, 1);
    this.setState({ terms });
  };

  onSelectImage = (imageId: string | null) => {
    if (this.state.showingImageModal == null) return;
    this.onUpdateTerm(this.state.showingImageModal, { imageId });
    this.setState({ showingImageModal: null });
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { showingImageModal, terms } = this.state;
    const isShowingImageChooserModal = showingImageModal != null;
    return (
      <div className="VocabEditor">
        <div className="VocabEditor-body">
          <div className="mb3">
            {this.state.terms.map((term, i) => (
              <div
                key={i}
                className={classNames('VocabEditor-term', {
                  'mt4 pt4 bt b--moon-gray': i > 0,
                })}
              >
                <div
                  className={classNames('VocabEditor-termNum gray f3', {
                    pt4: i > 0,
                  })}
                >
                  {i + 1}
                </div>
                <div className="mb2">
                  <Input
                    type="text"
                    className="VocabEditor-wordInput"
                    placeholder={formatMessage(messages.wordFieldLabel)}
                    onChange={evt =>
                      this.onUpdateTerm(i, { word: evt.target.value })
                    }
                    value={this.state.terms[i].word}
                  />
                </div>
                <Input
                  type="text"
                  className="VocabEditor-definitionInput"
                  placeholder={formatMessage(messages.definitionFieldLabel)}
                  onChange={evt =>
                    this.onUpdateTerm(i, { definition: evt.target.value })
                  }
                  value={this.state.terms[i].definition}
                />
                <div
                  className={classNames('VocabEditor-ImagePreview', {
                    pt4: i > 0,
                  })}
                >
                  <ImagePreview
                    word={term.word}
                    selectedImageId={term.imageId}
                    onClick={() => this.setState({ showingImageModal: i })}
                  />
                </div>
                <div
                  className={classNames('VocabEditor-deleteTerm', {
                    pt4: i > 0,
                  })}
                >
                  <Button
                    buttonType="gray"
                    size="small"
                    outline
                    phantomOutline
                    onClick={() => this.onDeleteTerm(i)}
                  >
                    <i className="fas fa-trash VocabEditor-deleteTermIcon" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button outline onClick={this.onAddTerm}>
            <i className="fa fa-plus pr1" />
            <FormattedMessage
              id="WorksheetEditor.add_term_button"
              defaultMessage="Add vocab term"
            />
          </Button>
        </div>
        <div
          className="VocabEditor-doneButton"
          data-tip={this.isValid() ? null : this.renderInvalidReason()}
          data-type="error"
        >
          <Button
            type="success"
            disabled={!this.isValid()}
            onClick={this.onDone}
            fullWidth
          >
            <FormattedMessage
              id="VocabEditor.done_button"
              defaultMessage="Done"
            />
          </Button>
        </div>
        <ReactTooltip effect="solid" place="top" html={true} />
        <Modal
          isOpen={isShowingImageChooserModal}
          onClose={() => this.setState({ showingImageModal: null })}
          header={formatMessage(messages.imageChooserModalHeader)}
          closeButton={true}
        >
          {showingImageModal == null ? null : (
            <ImageChooser
              key={showingImageModal}
              defaultQuery={terms[showingImageModal].word}
              defaultSelectedImageId={terms[showingImageModal].imageId}
              onSelectImageId={this.onSelectImage}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default injectIntl(VocabEditor);
