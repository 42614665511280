/* tslint:disable */
/* eslint-disable */
/* @relayHash e5cae0d01e9d5933271c11abbfa0aec7 */

import { ConcreteRequest } from "relay-runtime";
export type EmailLoginInput = {
    emailOrUsername: string;
    password: string;
    clientMutationId?: string | null;
};
export type EmailLoginMutationVariables = {
    input: EmailLoginInput;
};
export type EmailLoginMutationResponse = {
    readonly emailLogin: {
        readonly user: {
            readonly id: string;
            readonly email: string | null;
            readonly username: string;
        } | null;
        readonly token: string | null;
    } | null;
};
export type EmailLoginMutation = {
    readonly response: EmailLoginMutationResponse;
    readonly variables: EmailLoginMutationVariables;
};



/*
mutation EmailLoginMutation(
  $input: EmailLoginInput!
) {
  emailLogin(input: $input) {
    user {
      id
      email
      username
    }
    token
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EmailLoginInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "emailLogin",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmailLoginPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EmailLoginMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EmailLoginMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "EmailLoginMutation",
    "id": null,
    "text": "mutation EmailLoginMutation(\n  $input: EmailLoginInput!\n) {\n  emailLogin(input: $input) {\n    user {\n      id\n      email\n      username\n    }\n    token\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd9bb67954f798d0e03d9c2b95cf341ba';
export default node;
