/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BillingForm_subscriptionPlan = {
    readonly id: string;
    readonly price: number;
    readonly " $refType": "BillingForm_subscriptionPlan";
};
export type BillingForm_subscriptionPlan$data = BillingForm_subscriptionPlan;
export type BillingForm_subscriptionPlan$key = {
    readonly " $data"?: BillingForm_subscriptionPlan$data;
    readonly " $fragmentRefs": FragmentRefs<"BillingForm_subscriptionPlan">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "BillingForm_subscriptionPlan",
  "type": "SubscriptionPlan",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "price",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '7fbc9c67d23227a30667cea1b88b1143';
export default node;
