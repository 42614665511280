import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  CreateClassroomMutation,
  CreateClassroomInput,
} from './__generated__/CreateClassroomMutation.graphql';
const mutation = graphql`
  mutation CreateClassroomMutation($input: CreateClassroomInput!) {
    createClassroom(input: $input) {
      classroom {
        id
        identifier
      }
      # To reload the dashboard / mobile menu classes list
      viewer {
        ...DashboardLayout_viewer
        ...NavHeader_viewer
      }
    }
  }
`;

const commit = (environment: any, input: CreateClassroomInput) =>
  commitMutationPromise<CreateClassroomMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default {
  commit,
};
