import React from 'react';
import { ReactNode } from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { DashboardFeed_viewer } from './__generated__/DashboardFeed_viewer.graphql';
import FeedItem from './FeedItem';
import Button from '../common/Button';
import Loading from '../common/Loading';
import LabelText from '../common/LabelText';
import './index.css';
import { exists } from '../../lib/utils';
type Props = {
  viewer: DashboardFeed_viewer;
  relay: RelayPaginationProp;
};

const getGroupLabel = (typename: string) => {
  if (typename === 'StudyRecord') {
    return (
      <FormattedMessage
        id="DashboardFeed.you_studied"
        defaultMessage="You studied"
      />
    );
  }

  if (typename === 'Worksheet') {
    return (
      <FormattedMessage
        id="DashboardFeed.you_created"
        defaultMessage="You created"
      />
    );
  }
};

const groupFeedItems = (
  feedItemEdges: DashboardFeed_viewer['activityFeed']['edges'],
) => {
  const groups = [];
  let currentGroup:
    | {
        label: ReactNode | undefined | null;
        items: any[];
      }
    | undefined
    | null;
  let lastItemType: string; // skip incomplete study records they're already listed in this group

  // TODO: fix this type
  const incompleteSheetExistsInCurrentGroup = (record: any) => {
    if (lastItemType !== 'StudyRecord') return false;
    if (record.completedAt) return false;
    if (!currentGroup) return false;

    for (const item of currentGroup.items) {
      if (item.worksheetId === record.worksheetId) return true;
    }

    return false;
  };

  (feedItemEdges || []).filter(exists).forEach(({ node }) => {
    if (!node) return;

    if (node.__typename !== lastItemType) {
      if (currentGroup) groups.push(currentGroup);
      lastItemType = node.__typename;
      currentGroup = {
        label: getGroupLabel(node.__typename),
        items: [node],
      };
    } else if (currentGroup && !incompleteSheetExistsInCurrentGroup(node)) {
      currentGroup.items.push(node);
    }
  });
  if (currentGroup) groups.push(currentGroup);
  return groups;
};

const DashboardFeed = ({ viewer, relay }: Props) => (
  <div className="DashboardFeed pa3">
    <div className="fr mt2 dn db-ns">
      <Button component={Link} size="large" to="/new-sheet">
        <i className="fa fa-plus mr2" />
        <FormattedMessage
          id="DashboardFeed.new_wordsheet_button"
          defaultMessage="New wordsheet"
        />
      </Button>
    </div>
    <h2 className="f2 mt3 mb4">
      <FormattedMessage
        id="DashboardFeed.recent_activity_label"
        defaultMessage="Recent activity"
      />
    </h2>
    <div className="db dn-ns tc pb3">
      <Button component={Link} fullWidth to="/new-sheet">
        <i className="fa fa-plus mr1" />
        <FormattedMessage
          id="DashboardFeed.new_wordsheet_button"
          defaultMessage="New wordsheet"
        />
      </Button>
    </div>
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading key="loader" />}
    >
      {viewer.activityFeed.edges &&
      viewer.activityFeed.edges.length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray">
          <FormattedMessage
            id="DashboardFeed.no_content_message"
            defaultMessage="You haven't created or studied any wordsheets yet"
          />
        </div>
      )}
      {!viewer.activityFeed.edges
        ? null
        : groupFeedItems(viewer.activityFeed.edges).map(
            ({ label, items }, i) => (
              <div className="mt4" key={i}>
                <LabelText>{label}</LabelText>
                {items.map(item => (
                  <div className="DashboardFeed-feedItem mt1" key={item.id}>
                    <FeedItem activityFeedItem={item} viewer={viewer} />
                  </div>
                ))}
              </div>
            ),
          )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  DashboardFeed,
  {
    viewer: graphql`
      fragment DashboardFeed_viewer on Viewer
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
        ) {
        ...FeedItem_viewer
        activityFeed(first: $count, after: $cursor)
          @connection(key: "DashboardFeed_activityFeed") {
          edges {
            node {
              id
              __typename
              ... on StudyRecord {
                completedAt
              }
              ...FeedItem_activityFeedItem
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props => props.viewer && props.viewer.activityFeed,
    getVariables: (props, { count, cursor }) => ({
      count,
      cursor,
    }),
    query: graphql`
      query DashboardFeedQuery($count: Int!, $cursor: String) {
        viewer: viewer {
          ...FeedItem_viewer
          ...DashboardFeed_viewer @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
