/* tslint:disable */
/* eslint-disable */
/* @relayHash e990748b2ef4cc655f4b072112fafe3e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountSettingsPageQueryVariables = {};
export type AccountSettingsPageQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly " $fragmentRefs": FragmentRefs<"AccountSettings_user">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"NavHeader_viewer">;
    } | null;
};
export type AccountSettingsPageQuery = {
    readonly response: AccountSettingsPageQueryResponse;
    readonly variables: AccountSettingsPageQueryVariables;
};



/*
query AccountSettingsPageQuery {
  viewer {
    ...NavHeader_viewer
    user {
      ...AccountSettings_user
      id
    }
  }
}

fragment AccountSettings_user on User {
  username
  email
  hasPassword
  isUpgradedTeacher
  accountType
  subscriptionStatus {
    subscriptionCancelAtPeriodEnd
    subscriptionCurrentPeriodEnd
    subscriptionCardEndDigits
    plan {
      price
      id
    }
    subscriptionType
    subscriptionNote
  }
}

fragment FeedbackModal_viewer on Viewer {
  user {
    id
  }
}

fragment NavHeader_viewer on Viewer {
  ...FeedbackModal_viewer
  user {
    id
    username
    isAdmin
    createdAt
    isUpgradedTeacher
    accountType
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
    worksheets(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountSettingsPageQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "AccountSettings_user",
                "args": null
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "NavHeader_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountSettingsPageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isAdmin",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isUpgradedTeacher",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accountType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomMemberships",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomMembership",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "level",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroom",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "identifier",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  (v0/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "worksheets",
                "storageKey": "worksheets(first:1)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  }
                ],
                "concreteType": "WorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Worksheet",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPassword",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subscriptionStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "UserSubscriptionStatus",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subscriptionCancelAtPeriodEnd",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subscriptionCurrentPeriodEnd",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subscriptionCardEndDigits",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "plan",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SubscriptionPlan",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "price",
                        "args": null,
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subscriptionType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subscriptionNote",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountSettingsPageQuery",
    "id": null,
    "text": "query AccountSettingsPageQuery {\n  viewer {\n    ...NavHeader_viewer\n    user {\n      ...AccountSettings_user\n      id\n    }\n  }\n}\n\nfragment AccountSettings_user on User {\n  username\n  email\n  hasPassword\n  isUpgradedTeacher\n  accountType\n  subscriptionStatus {\n    subscriptionCancelAtPeriodEnd\n    subscriptionCurrentPeriodEnd\n    subscriptionCardEndDigits\n    plan {\n      price\n      id\n    }\n    subscriptionType\n    subscriptionNote\n  }\n}\n\nfragment FeedbackModal_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment NavHeader_viewer on Viewer {\n  ...FeedbackModal_viewer\n  user {\n    id\n    username\n    isAdmin\n    createdAt\n    isUpgradedTeacher\n    accountType\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n    worksheets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4355a75274a6056676dec5d02fdb8fdc';
export default node;
