import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import LogInForm from '../components/auth/LogInForm';
import { LogInPageQuery } from './__generated__/LogInPageQuery.graphql';

const logInPageQuery = graphql`
  query LogInPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        id
      }
    }
  }
`;

const LogInPage = () => (
  <QueryRenderer<LogInPageQuery>
    environment={environment}
    variables={{}}
    query={logInPageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      const viewer = props && props.viewer;
      if (viewer && viewer.user) {
        return <Redirect to="/dashboard" />;
      }

      return (
        <div>
          <NavHeader
            viewer={viewer as NonNullable<LogInPageQuery['response']['viewer']>}
          />
          <div className="pa3">
            <h1 className="f2">
              <FormattedMessage
                id="LogInPage.log_in_header"
                defaultMessage="Log in"
              />
            </h1>
            <div className="ba b--black-10 measure center pa3 tl mb4 br2">
              <LogInForm
                background="white"
                environment={environment}
                onLogIn={() => {
                  window.location.href = '/dashboard';
                }}
              />
            </div>
            <FormattedMessage
              id="LogInPage.switch_to_sign_up"
              defaultMessage="Don't have an account yet?"
            />
            <Link to="/sign-up" className="ml1">
              <FormattedMessage
                id="LogInPage.sign_up_link"
                defaultMessage="Sign up"
              />
            </Link>
          </div>
        </div>
      );
    }}
  />
);

export default LogInPage;
