import React, { Component, FormEvent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';

import { FeedbackModal_viewer } from './__generated__/FeedbackModal_viewer.graphql';
import Modal from './Modal';
import Button from './Button';
import FormGroup from './FormGroup';
import SubmitFeedbackMutation from '../../mutations/SubmitFeedbackMutation';
import Textarea from './Textarea';

export type messageType = 'contact' | 'feedback' | 'help';

type Props = {
  isOpen: boolean;
  messageType: messageType;
  viewer?: FeedbackModal_viewer | null;
  onDone: () => void;
  relay: RelayProp;
};

type State = {
  content: string;
  email: string;
  hasSubmitted: boolean;
  isSubmitting: boolean;
};

class FeedbackModal extends Component<Props, State> {
  state: State = {
    content: '',
    email: '',
    hasSubmitted: false,
    isSubmitting: false,
  };

  getHeader() {
    if (this.props.messageType === 'feedback') {
      return (
        <FormattedMessage
          id="FeedbackModal.feedback_header"
          defaultMessage="Feedback"
        />
      );
    }
    if (this.props.messageType === 'help') {
      return (
        <FormattedMessage
          id="FeedbackModal.help_header"
          defaultMessage="Help"
        />
      );
    }
    return (
      <FormattedMessage
        id="FeedbackModal.contact_header"
        defaultMessage="Contact us"
      />
    );
  }

  onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (this.isValid()) {
      this.setState({ isSubmitting: true });
      await SubmitFeedbackMutation.commit(this.props.relay.environment, {
        email: this.state.email || null,
        content: this.state.content,
        messageType: this.props.messageType,
      });
      this.setState({ hasSubmitted: true, isSubmitting: false });
    }
  };

  isLoggedIn() {
    return !!this.props.viewer?.user;
  }

  isValid() {
    if (!this.isLoggedIn() && !this.state.email.trim()) return false;
    return !!this.state.content.trim();
  }

  onDone = () => {
    this.setState({
      email: '',
      content: '',
      hasSubmitted: false,
      isSubmitting: false,
    });
    this.props.onDone();
  };

  renderFinished() {
    return (
      <div className="tc">
        <h1 className="f2 lh-title">
          <FormattedMessage
            id="FeedbackModal.submitted_header"
            defaultMessage="Thanks for writing in!"
          />
        </h1>
        <p className="mb4 pv4 gray">
          <FormattedMessage
            id="FeedbackModal.submitted_body"
            defaultMessage="We'll get back to you as soon as possible."
          />
        </p>
        <Button fullWidth buttonType="success" onClick={this.onDone}>
          <FormattedMessage
            id="FeedbackModal.done_button"
            defaultMessage="Done"
          />
        </Button>
      </div>
    );
  }

  renderForm() {
    return (
      <form className="tc" onSubmit={this.onSubmit}>
        {this.isLoggedIn() ? null : (
          <FormGroup
            label={
              <FormattedMessage
                id="FeedbackModal.email_label"
                defaultMessage="Email"
              />
            }
          >
            <input
              className="FeedbackModal-input"
              type="email"
              required
              value={this.state.email}
              onChange={evt => this.setState({ email: evt.target.value })}
            />
          </FormGroup>
        )}
        <FormGroup
          label={
            <FormattedMessage
              id="FeedbackModal.message_label"
              defaultMessage="Message"
            />
          }
        >
          <Textarea
            rows={3}
            value={this.state.content}
            onChange={evt => this.setState({ content: evt.target.value })}
          />
        </FormGroup>
        <Button
          fullWidth
          buttonType="success"
          type="submit"
          loading={this.state.isSubmitting}
          disabled={!this.isValid() || this.state.isSubmitting}
        >
          <FormattedMessage id="FeedbackModal.send" defaultMessage="Send" />
        </Button>
      </form>
    );
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        header={this.getHeader()}
        onClose={this.onDone}
        closeButton
      >
        {this.state.hasSubmitted ? this.renderFinished() : this.renderForm()}
      </Modal>
    );
  }
}

export default createFragmentContainer(FeedbackModal, {
  viewer: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment FeedbackModal_viewer on Viewer {
      user {
        id
      }
    }
  `,
});
