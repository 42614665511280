import React, { FC } from 'react';
import classNames from 'classnames';
import TeacherBadge from './TeacherBadge';

interface UsernameProps {
  isTeacher: boolean;
  username: string;
}

const Username: FC<UsernameProps> = ({ username, isTeacher }) => (
  <span className="flex items-center">
    <span className={classNames({ mr2: isTeacher })}>{username}</span>
    {isTeacher && <TeacherBadge size="small" />}
  </span>
);

export default Username;
