import { graphql } from 'babel-plugin-relay/macro';
import {
  VocabExerciseContentInput,
  CreateVocabExerciseMutation,
} from './__generated__/CreateVocabExerciseMutation.graphql';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  addExerciseToStore,
  optimisticCreateExerciseUpdater,
} from './helpers/exerciseMutationHelpers';
const mutation = graphql`
  mutation CreateVocabExerciseMutation($input: CreateVocabExerciseInput!) {
    createVocabExercise(input: $input) {
      exercise {
        id
        content {
          terms {
            word
            definition
            image {
              id
              url(size: SMALL_SQUARE)
            }
          }
        }
        worksheet {
          id
        }
      }
    }
  }
`;

const commit = (
  environment: any,
  worksheetId: string,
  content: VocabExerciseContentInput,
  position: number,
) =>
  commitMutationPromise<CreateVocabExerciseMutation>(environment, {
    mutation,
    variables: {
      input: {
        worksheetId,
        content,
        position,
      },
    },
    optimisticUpdater: optimisticCreateExerciseUpdater(
      'VocabExercise',
      worksheetId,
      content,
      position,
    ),
    updater: store => {
      const payload = store.getRootField('createVocabExercise');
      if (payload) {
        const newExercise = payload.getLinkedRecord('exercise');
        addExerciseToStore(store, newExercise, worksheetId, position);
      }
    },
  });

export default {
  commit,
};
