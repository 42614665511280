/* tslint:disable */
/* eslint-disable */
/* @relayHash 0b8971b033e561e5310dbad2b79e431e */

import { ConcreteRequest } from "relay-runtime";
export type PurchaseUpgradeSubscriptionInput = {
    stripeToken: string;
    subscriptionPlanId: string;
    clientMutationId?: string | null;
};
export type PurchaseUpgradeSubscriptionMutationVariables = {
    input: PurchaseUpgradeSubscriptionInput;
};
export type PurchaseUpgradeSubscriptionMutationResponse = {
    readonly purchaseUpgradeSubscription: {
        readonly viewer: {
            readonly user: {
                readonly id: string;
                readonly isUpgradedTeacher: boolean;
            } | null;
        } | null;
    } | null;
};
export type PurchaseUpgradeSubscriptionMutation = {
    readonly response: PurchaseUpgradeSubscriptionMutationResponse;
    readonly variables: PurchaseUpgradeSubscriptionMutationVariables;
};



/*
mutation PurchaseUpgradeSubscriptionMutation(
  $input: PurchaseUpgradeSubscriptionInput!
) {
  purchaseUpgradeSubscription(input: $input) {
    viewer {
      user {
        id
        isUpgradedTeacher
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PurchaseUpgradeSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "purchaseUpgradeSubscription",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PurchaseUpgradeSubscriptionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isUpgradedTeacher",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PurchaseUpgradeSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PurchaseUpgradeSubscriptionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PurchaseUpgradeSubscriptionMutation",
    "id": null,
    "text": "mutation PurchaseUpgradeSubscriptionMutation(\n  $input: PurchaseUpgradeSubscriptionInput!\n) {\n  purchaseUpgradeSubscription(input: $input) {\n    viewer {\n      user {\n        id\n        isUpgradedTeacher\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b4c3bd8fecd89a8113443ba4752474ef';
export default node;
