import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { SubmitFeedbackMutation } from './__generated__/SubmitFeedbackMutation.graphql';
const mutation = graphql`
  mutation SubmitFeedbackMutation($input: SubmitFeedbackInput!) {
    submitFeedback(input: $input) {
      feedbackMessage {
        id
      }
    }
  }
`;
type fields = {
  email: string | undefined | null;
  content: string;
  messageType: string;
};

const commit = (environment: any, fields: fields) =>
  commitMutationPromise<SubmitFeedbackMutation>(environment, {
    mutation,
    variables: {
      input: { ...fields },
    },
  });

export default {
  commit,
};
