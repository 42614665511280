import { graphql } from 'babel-plugin-relay/macro';
import auth from '../lib/auth';
import { commitMutationPromise } from '../lib/graphqlUtils';
import * as Sentry from '@sentry/browser';
import {
  EmailSignupMutation,
  EmailSignupInput,
} from './__generated__/EmailSignupMutation.graphql';
const mutation = graphql`
  mutation EmailSignupMutation($input: EmailSignupInput!) {
    emailSignup(input: $input) {
      user {
        id
      }
      token
    }
  }
`;

const commit = (environment: any, input: EmailSignupInput) =>
  commitMutationPromise<EmailSignupMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    onCompleted: ({ emailSignup }) => {
      if (emailSignup && emailSignup.token) {
        const token = emailSignup.token;
        auth.setToken(token);
        Sentry.configureScope(scope => {
          scope.setUser({
            username: input.username,
            email: input.email,
            id: emailSignup.user?.id,
          });
        });
        window.heap.track('login', {
          username: input.username,
          email: input.email,
          accountType: input.accountType,
          source: 'email',
        });
        window.heap.identify(emailSignup.user && emailSignup.user.id, 'ID');
      }
    },
  });

export default {
  commit,
};
