import React from 'react';
import classNames from 'classnames';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { StudyRecord_studyRecord } from './__generated__/StudyRecord_studyRecord.graphql';
import Button from '../common/Button';
import './StudyRecord.css';
type Props = {
  studyRecord: StudyRecord_studyRecord;
  showUser: boolean;
  showWorksheet: boolean;
  showStudyButton: boolean;
};

const renderUser = (user: StudyRecord_studyRecord['user']) => {
  if (!user)
    return (
      <div className="pa3 gray f6 StudyRecord-anon">
        <FormattedMessage
          id="StudyRecord.anonymous_user"
          defaultMessage="Anonymous user"
        />
      </div>
    );
  return (
    <Link
      to={`/profile/${user.username}`}
      className="pa3 link StudyRecord-userLink"
    >
      {user.username}
    </Link>
  );
};

const StudyRecord = ({
  studyRecord,
  showUser,
  showWorksheet,
  showStudyButton,
}: Props) => {
  const identifier = studyRecord.identifier || '';
  const worksheetIdentifier = studyRecord.worksheet.identifier || '';
  return (
    <div
      className={classNames('StudyRecord', {
        'has-worksheet': showWorksheet,
      })}
    >
      {!showWorksheet ? null : (
        <Link
          to={`/view/${worksheetIdentifier}`}
          className="StudyRecord-viewLink no-underline dark-gray"
        >
          <h1 className="f4 pa3 mv0 lh-title no-underline StudyRecord-title">
            {studyRecord.worksheet.title}
          </h1>
        </Link>
      )}
      <div className="StudyRecord-info">
        {studyRecord.completedAt ? (
          <React.Fragment>
            <div className="StudyRecord-stats">
              {showUser ? renderUser(studyRecord.user) : null}
              <div className="gray mr2 ml3 pv3 f6  db-ns dn relative StudyRecord-dateCompleted">
                {new Date(studyRecord.completedAt * 1000).toLocaleDateString(
                  'en-US', // TODO: localize this better if more langs are added
                  {
                    year: 'numeric',
                    day: 'numeric',
                    month: 'short',
                  },
                )}
                <Link
                  to={`/result/${identifier}`}
                  className="db dn-ns StudyRecord-hiddenScoreLink"
                />
              </div>
              <div className="gray mr2 ml3 pv3 f6 relative StudyRecord-duration">
                <FormattedMessage
                  id="StudyRecord.duration"
                  defaultMessage="{duration, number, integer}s"
                  values={{
                    duration: Math.round(studyRecord.duration || 0),
                  }}
                />
                <Link
                  to={`/result/${identifier}`}
                  className="db dn-ns StudyRecord-hiddenScoreLink"
                />
              </div>
              <div className="mr2 f4 b pv3 f5 relative StudyRecord-score">
                <FormattedMessage
                  id="StudyRecord.score"
                  defaultMessage="{score, number, percent}"
                  values={{
                    score: Math.round(studyRecord.score || 0) / 100,
                  }}
                />
                <Link
                  to={`/result/${identifier}`}
                  className="db dn-ns StudyRecord-hiddenScoreLink"
                />
              </div>
            </div>
            <div className="mh2 dn db-ns">
              <Button component={Link} to={`/result/${identifier}`}>
                <FormattedMessage
                  id="StudyRecord.results"
                  defaultMessage="Results"
                />
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div className="StudyRecord-stats">
            {showUser ? renderUser(studyRecord.user) : null}
            <div className="mh3 f6 ml3 gray">
              <FormattedMessage
                id="StudyRecord.not_completed"
                defaultMessage="Not completed"
              />
            </div>
          </div>
        )}
        {!showStudyButton ? null : (
          <div className="StudyRecord-studyButton">
            <Button component={Link} to={`/study/${worksheetIdentifier}`}>
              <FormattedMessage id="StudyRecord.study" defaultMessage="Study" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

StudyRecord.defaultProps = {
  showUser: false,
  showStudy: false,
  showWorksheet: false,
}; // for testing raw component

export { StudyRecord };
export default createFragmentContainer(StudyRecord, {
  studyRecord: graphql`
    fragment StudyRecord_studyRecord on StudyRecord {
      createdAt
      completedAt
      identifier
      score
      duration
      user {
        username
      }
      worksheet {
        identifier
        title
      }
    }
  `,
});
