import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import CheckBox from '../common/CheckBox';
import { MultipleChoiceAnswerDetails_answer } from './__generated__/MultipleChoiceAnswerDetails_answer.graphql';
import './MultipleChoiceAnswerDetails.css';
type Props = {
  answer: MultipleChoiceAnswerDetails_answer;
};

const CHOICE_LABELS = ['A', 'B', 'C', 'D', 'E'];

type choices = readonly {
  readonly text: string;
  readonly isChosen: boolean;
}[];

const renderChoices = (choices: choices, isCorrect: boolean) => (
  <div
    className={classNames('MultipleChoiceAnswerDetails-choices br1 ph2 pt2', {
      'is-correct': isCorrect,
      'is-incorrect': !isCorrect,
    })}
  >
    {choices.map((choice, index) => (
      <div className="MultipleChoiceAnswerDetails-answer f5 lh-copy">
        <div className="mr2 b MultipleChoiceAnswerDetails-label">
          {CHOICE_LABELS[index]}
        </div>
        <div className="mr2">
          <CheckBox isChecked={choice.isChosen} />
        </div>
        <div className="truncate">{choice.text}</div>
      </div>
    ))}
  </div>
);

const MultipleChoiceAnswerDetails = ({ answer }: Props) => {
  let choices: choices = [];
  let prompt; // need to do it this way to keep flow happy :/

  if (answer.exercise && answer.exercise.content) {
    prompt = answer.exercise.content.prompt; // remap exercise choices to match the format of answer choices

    const exerciseChoices = answer.exercise.content.choices || [];
    choices = exerciseChoices.map(({ isCorrect, text }) => ({
      text,
      isChosen: isCorrect,
    }));
  }

  return (
    <div className="MultipleChoiceAnswerDetails">
      <div className="MultipleChoiceAnswerDetails-prompt">{prompt}</div>
      <div className="MultipleChoiceAnswerDetails-responses">
        <div className="MultipleChoiceAnswerDetails-response fl pr2 mt3 mw-100">
          <div className="gray f7 mb1">
            <FormattedMessage
              id="MultipleChoiceAnswerDetails.user_response"
              defaultMessage="Your response"
            />
          </div>
          {renderChoices(answer.choices || [], answer.isCorrect || false)}
        </div>
        {answer.isCorrect ? null : (
          <div className="MultipleChoiceAnswerDetails-response fl pr2 mt3 mw-100">
            <div className="gray f7 mb1">
              <FormattedMessage
                id="MultipleChoiceAnswerDetails.correct_answer"
                defaultMessage="Correct answer"
              />
            </div>
            {renderChoices(choices, true)}
          </div>
        )}
        <div className="cb" />
      </div>
    </div>
  );
};

export default createFragmentContainer(MultipleChoiceAnswerDetails, {
  answer: graphql`
    fragment MultipleChoiceAnswerDetails_answer on MultipleChoiceAnswer {
      isCorrect
      choices {
        isChosen
        text
      }
      exercise {
        ... on MultipleChoiceExercise {
          content {
            prompt
            choices {
              isCorrect
              text
            }
          }
        }
      }
    }
  `,
});
