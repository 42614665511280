import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import LabelText from './LabelText';
import './FormGroup.css';
type Props = {
  isHidden: boolean;
  noMargin: boolean;
  label?: string | ReactNode;
  hint?: string | ReactNode;
  children?: ReactNode;
};

class FormGroup extends Component<Props> {
  static defaultProps = {
    isHidden: false,
    noMargin: false,
  };

  renderLabelText() {
    return this.props.label && <LabelText>{this.props.label}</LabelText>;
  }

  renderHint() {
    return <p className="FormGroup-hint lh-copy mt2">{this.props.hint}</p>;
  }

  render() {
    return (
      <div
        className={classNames('FormGroup', {
          'is-hidden': this.props.isHidden,
          'no-margin': this.props.noMargin,
        })}
      >
        {this.props.label ? this.renderLabelText() : null}
        {this.props.children}
        {this.props.hint ? this.renderHint() : null}
      </div>
    );
  }
}

export default FormGroup;
