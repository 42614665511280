/* tslint:disable */
/* eslint-disable */
/* @relayHash 36650922852bed9a1d003a8b2aa38102 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetResultsTabQueryVariables = {
    id: string;
    classroomId?: string | null;
};
export type WorksheetResultsTabQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly studyRecords?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly completedAt: number | null;
                    readonly " $fragmentRefs": FragmentRefs<"StudyRecord_studyRecord">;
                } | null;
            } | null> | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"ClassroomsSelect_worksheet">;
    } | null;
};
export type WorksheetResultsTabQuery = {
    readonly response: WorksheetResultsTabQueryResponse;
    readonly variables: WorksheetResultsTabQueryVariables;
};



/*
query WorksheetResultsTabQuery(
  $id: ID!
  $classroomId: ID
) {
  node(id: $id) {
    __typename
    ... on Worksheet {
      id
      ...ClassroomsSelect_worksheet
      studyRecords(classroomId: $classroomId, first: 500) {
        edges {
          node {
            ...StudyRecord_studyRecord
            completedAt
            id
          }
        }
      }
    }
    id
  }
}

fragment ClassroomsSelect_worksheet on Worksheet {
  classroomWorksheetsVisibleToViewer {
    classroom {
      id
      title
    }
    id
  }
}

fragment StudyRecord_studyRecord on StudyRecord {
  createdAt
  completedAt
  identifier
  score
  duration
  user {
    username
    id
  }
  worksheet {
    identifier
    title
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "classroomId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "classroomId",
    "variableName": "classroomId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WorksheetResultsTabQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Worksheet",
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "studyRecords",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyRecordConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StudyRecordEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyRecord",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "StudyRecord_studyRecord",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "ClassroomsSelect_worksheet",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WorksheetResultsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Worksheet",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomWorksheetsVisibleToViewer",
                "storageKey": null,
                "args": null,
                "concreteType": "ClassroomWorksheet",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroom",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Classroom",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "studyRecords",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "StudyRecordConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StudyRecordEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StudyRecord",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "score",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "duration",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "username",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "worksheet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Worksheet",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WorksheetResultsTabQuery",
    "id": null,
    "text": "query WorksheetResultsTabQuery(\n  $id: ID!\n  $classroomId: ID\n) {\n  node(id: $id) {\n    __typename\n    ... on Worksheet {\n      id\n      ...ClassroomsSelect_worksheet\n      studyRecords(classroomId: $classroomId, first: 500) {\n        edges {\n          node {\n            ...StudyRecord_studyRecord\n            completedAt\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ClassroomsSelect_worksheet on Worksheet {\n  classroomWorksheetsVisibleToViewer {\n    classroom {\n      id\n      title\n    }\n    id\n  }\n}\n\nfragment StudyRecord_studyRecord on StudyRecord {\n  createdAt\n  completedAt\n  identifier\n  score\n  duration\n  user {\n    username\n    id\n  }\n  worksheet {\n    identifier\n    title\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'eeba434bb7ccfd19225b42964865ae63';
export default node;
