/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VocabAnswerDetails_answer = {
    readonly isCorrect: boolean | null;
    readonly correctTerm: {
        readonly word: string;
        readonly definition: string | null;
        readonly image: {
            readonly url: string;
        } | null;
    } | null;
    readonly responseTerm: {
        readonly word: string;
        readonly definition: string | null;
        readonly image: {
            readonly url: string;
        } | null;
    } | null;
    readonly " $refType": "VocabAnswerDetails_answer";
};
export type VocabAnswerDetails_answer$data = VocabAnswerDetails_answer;
export type VocabAnswerDetails_answer$key = {
    readonly " $data"?: VocabAnswerDetails_answer$data;
    readonly " $fragmentRefs": FragmentRefs<"VocabAnswerDetails_answer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "word",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "definition",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "image",
    "storageKey": null,
    "args": null,
    "concreteType": "Image",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": [
          {
            "kind": "Literal",
            "name": "size",
            "value": "SMALL_SQUARE"
          }
        ],
        "storageKey": "url(size:\"SMALL_SQUARE\")"
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "VocabAnswerDetails_answer",
  "type": "VocabAnswer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCorrect",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "correctTerm",
      "storageKey": null,
      "args": null,
      "concreteType": "VocabAnswerTerm",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "responseTerm",
      "storageKey": null,
      "args": null,
      "concreteType": "VocabAnswerTerm",
      "plural": false,
      "selections": (v0/*: any*/)
    }
  ]
};
})();
(node as any).hash = 'f3d045b872cfb11ee6feee4d1466dc8d';
export default node;
