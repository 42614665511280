/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WordOrderAnswerDetails_answer = {
    readonly isCorrect: boolean | null;
    readonly response: ReadonlyArray<string> | null;
    readonly exercise: {
        readonly content?: {
            readonly correctAnswers: ReadonlyArray<string>;
            readonly scrambledParts: ReadonlyArray<string>;
        };
    } | null;
    readonly " $refType": "WordOrderAnswerDetails_answer";
};
export type WordOrderAnswerDetails_answer$data = WordOrderAnswerDetails_answer;
export type WordOrderAnswerDetails_answer$key = {
    readonly " $data"?: WordOrderAnswerDetails_answer$data;
    readonly " $fragmentRefs": FragmentRefs<"WordOrderAnswerDetails_answer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WordOrderAnswerDetails_answer",
  "type": "WordOrderAnswer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCorrect",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "response",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercise",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "type": "WordOrderExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "WordOrderExerciseContent",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "correctAnswers",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "scrambledParts",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'b127b04eb85ae3e6c68b6e4e17ca7a0b';
export default node;
