import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { ClassroomWorksheetsInfiniteScroll_classroom } from './__generated__/ClassroomWorksheetsInfiniteScroll_classroom.graphql';
import Loading from '../common/Loading';
import { exists } from '../../lib/utils';
import ClassroomWorksheet from './ClassroomWorksheet';

type ClassroomWorksheetsInfiniteScrollProps = {
  classroom: ClassroomWorksheetsInfiniteScroll_classroom;
  relay: RelayPaginationProp;
  canEdit: boolean;
};

const ClassroomWorksheetsInfiniteScroll = ({
  classroom,
  relay,
  canEdit,
}: ClassroomWorksheetsInfiniteScrollProps) => (
  <div className="ClassroomWorksheetsInfiniteScroll pt3 tl">
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading />}
    >
      {(classroom?.classroomWorksheets?.edges || []).length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="ClassroomWorksheetsInfiniteScroll.no_content_message"
            defaultMessage="No wordsheets have been added to this class yet"
          />
        </div>
      )}
      {classroom?.classroomWorksheets?.edges?.filter(exists)?.map(
        ({ node }) =>
          node && (
            <div key={node.id} className="mb1">
              <ClassroomWorksheet classroomWorksheet={node} canEdit={canEdit} />
            </div>
          ),
      )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  ClassroomWorksheetsInfiniteScroll,
  {
    classroom: graphql`
      fragment ClassroomWorksheetsInfiniteScroll_classroom on Classroom
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 50 }
          cursor: { type: "String" }
        ) {
        id
        classroomWorksheets(first: $count, after: $cursor)
          @connection(
            key: "ClassroomWorksheetsInfiniteScroll_classroomWorksheets"
          ) {
          edges {
            node {
              id
              ...ClassroomWorksheet_classroomWorksheet
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props => props.classroom?.classroomWorksheets,
    getVariables: (props, { count, cursor }) => ({
      classroomId: props.classroom.id,
      count,
      cursor,
    }),
    query: graphql`
      query ClassroomWorksheetsInfiniteScrollQuery(
        $classroomId: ID!
        $count: Int!
        $cursor: String
      ) {
        classroom: node(id: $classroomId) {
          ... on Classroom {
            ...ClassroomWorksheetsInfiniteScroll_classroom
              @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `,
  },
);
