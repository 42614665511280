import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { hashCode, objVals } from '../../lib/utils';
import CheckBox from '../common/CheckBox';
import { MultipleChoiceExerciseAnalysis_exercise } from './__generated__/MultipleChoiceExerciseAnalysis_exercise.graphql';
import './MultipleChoiceExerciseAnalysis.css';
type choice = {
  text: string;
  isChosen: boolean;
};
type answer = {
  isCorrect: boolean;
  choices: choice[];
};
type Props = {
  exercise: MultipleChoiceExerciseAnalysis_exercise;
  answers: answer[];
};

type incorrectResponseWithCount = {
  count: number;
  choices: choice[];
};

const hashAnswer = (answer: answer) => hashCode(JSON.stringify(answer.choices));

const getAnalysisCounts = (answers: answer[]) => {
  const incorrectResponsesMap: {
    [x: number]: incorrectResponseWithCount;
  } = {};
  const counts: {
    incorrectResponses: incorrectResponseWithCount[];
    totalIncorrect: number;
    totalCorrect: number;
  } = {
    incorrectResponses: [],
    totalIncorrect: 0,
    totalCorrect: 0,
  };
  answers.forEach(answer => {
    if (answer.isCorrect) {
      counts.totalCorrect += 1;
    } else {
      counts.totalIncorrect += 1;
      const hash = hashAnswer(answer);

      if (!incorrectResponsesMap[hash]) {
        incorrectResponsesMap[hash] = {
          count: 0,
          choices: answer.choices,
        };
      }

      incorrectResponsesMap[hash].count += 1;
    }
  });
  counts.incorrectResponses = objVals(incorrectResponsesMap).sort(
    (a, b) => b.count - a.count,
  );
  return counts;
};

const CHOICE_LABELS = ['A', 'B', 'C', 'D', 'E'];

const renderChoices = (choices: choice[]) => (
  <div className="MultipleChoiceExerciseAnalysis-choices fl">
    {choices.map((choice, index) => (
      <div
        className="MultipleChoiceExerciseAnalysis-answer f5 lh-copy"
        key={index}
      >
        <div className="mr2 b MultipleChoiceExerciseAnalysis-label">
          {CHOICE_LABELS[index]}
        </div>
        <div className="mr2">
          <CheckBox isChecked={choice.isChosen} />
        </div>
        <div className="truncate">{choice.text}</div>
      </div>
    ))}
  </div>
);

const MultipleChoiceExerciseAnalysis = ({ exercise, answers }: Props) => {
  const {
    totalCorrect,
    totalIncorrect,
    incorrectResponses,
  } = getAnalysisCounts(answers);
  const total = totalCorrect + totalIncorrect; // remap exercise choices to match the format of answer choices

  const exerciseChoices = exercise.content.choices || [];
  const correctChoices = exerciseChoices.map(({ isCorrect, text }) => ({
    text,
    isChosen: isCorrect,
  }));
  return (
    <div className="MultipleChoiceExerciseAnalysis">
      <div className="mb3 i f6">{exercise.content.prompt}</div>
      {total === 0 ? (
        <div className="gray pv3" key="no-data">
          <FormattedMessage
            id="MultipleChoiceExerciseAnalysis.no_results"
            defaultMessage="No data yet"
          />
        </div>
      ) : (
        <div>
          <FormattedMessage
            id="MultipleChoiceExerciseAnalysis.percent_correct"
            defaultMessage="{percent}% correct"
            values={{
              percent: (
                <span className="f3">
                  {Math.round((100 * totalCorrect) / total)}
                </span>
              ),
            }}
          />
          <span className="dib gray ml3 f6">
            {totalCorrect} / {total}
          </span>
          <div className="f6 gray mt3">
            <FormattedMessage
              id="MultipleChoiceExerciseAnalysis.correct_answer_label"
              defaultMessage="Correct answer"
            />
          </div>
          <div className="MultipleChoiceExerciseAnalysis-correctResponses">
            <div className="mt2">
              <span className="MultipleChoiceExerciseAnalysis-count dib f3 mr3 pt1 fl is-correct">
                {totalCorrect}
              </span>
              {renderChoices(correctChoices)}
              <div className="cb" />
            </div>
          </div>
          {incorrectResponses.length === 0 ? null : (
            <React.Fragment>
              <div className="f6 gray mt3">
                <FormattedMessage
                  id="MultipleChoiceExerciseAnalysis.incorrect_responses_label"
                  defaultMessage="Top incorrect responses"
                />
              </div>
              <div className="MultipleChoiceExerciseAnalysis-incorrectResponses">
                {incorrectResponses.map(({ choices, count }, i) => (
                  <div className="mt2" key={`incorrect-${i}`}>
                    <span className="MultipleChoiceExerciseAnalysis-count dib f3 mr3 pt1 fl is-incorrect">
                      {count}
                    </span>
                    {renderChoices(choices)}
                    <div className="cb" />
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default createFragmentContainer(MultipleChoiceExerciseAnalysis, {
  exercise: graphql`
    fragment MultipleChoiceExerciseAnalysis_exercise on MultipleChoiceExercise {
      content {
        prompt
        choices {
          isCorrect
          text
        }
      }
    }
  `,
});
