import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { ForgotPasswordMutation } from './__generated__/ForgotPasswordMutation.graphql';
const mutation = graphql`
  mutation ForgotPasswordMutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
    }
  }
`;

const commit = (environment: any, emailOrUsername: string) =>
  commitMutationPromise<ForgotPasswordMutation>(environment, {
    mutation,
    variables: {
      input: {
        emailOrUsername,
      },
    },
  });

export default {
  commit,
};
