/* tslint:disable */
/* eslint-disable */
/* @relayHash 2542b33194e7d5906a716f64101499c7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AddWorksheetModalQueryVariables = {
    filter?: string | null;
};
export type AddWorksheetModalQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly worksheets: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly " $fragmentRefs": FragmentRefs<"AddWorksheetModalWorksheet_worksheet">;
                    } | null;
                } | null> | null;
            };
        } | null;
    } | null;
};
export type AddWorksheetModalQuery = {
    readonly response: AddWorksheetModalQueryResponse;
    readonly variables: AddWorksheetModalQueryVariables;
};



/*
query AddWorksheetModalQuery(
  $filter: String
) {
  viewer {
    user {
      worksheets(first: 100, filter: $filter) {
        edges {
          node {
            id
            ...AddWorksheetModalWorksheet_worksheet
          }
        }
      }
      id
    }
  }
}

fragment AddWorksheetModalWorksheet_worksheet on Worksheet {
  id
  title
  publishedAt
  exercises {
    __typename
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddWorksheetModalQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "worksheets",
                "storageKey": null,
                "args": (v1/*: any*/),
                "concreteType": "WorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Worksheet",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "AddWorksheetModalWorksheet_worksheet",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddWorksheetModalQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "worksheets",
                "storageKey": null,
                "args": (v1/*: any*/),
                "concreteType": "WorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Worksheet",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "publishedAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "exercises",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "__typename",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AddWorksheetModalQuery",
    "id": null,
    "text": "query AddWorksheetModalQuery(\n  $filter: String\n) {\n  viewer {\n    user {\n      worksheets(first: 100, filter: $filter) {\n        edges {\n          node {\n            id\n            ...AddWorksheetModalWorksheet_worksheet\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AddWorksheetModalWorksheet_worksheet on Worksheet {\n  id\n  title\n  publishedAt\n  exercises {\n    __typename\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '29bc856bf279ddb972634eff05a7a771';
export default node;
