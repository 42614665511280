import React, { Component } from 'react';
import classNames from 'classnames';
import './CheckBox.css';
type Props = {
  boxBorderColor: string;
  isChecked: boolean;
};

class CheckBox extends Component<Props> {
  static defaultProps = {
    boxBorderColor: '#CCC',
  };

  render() {
    const { boxBorderColor, isChecked, ...otherProps } = this.props;
    return (
      <div
        className={classNames('CheckBox dib', {
          'is-checked': isChecked,
        })}
        style={{
          borderColor: boxBorderColor,
        }}
        {...otherProps}
      >
        <i className="fa fa-check CheckBox-checkmark" />
      </div>
    );
  }
}

export default CheckBox;
