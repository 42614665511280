import React, { Component } from 'react';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import EmbedHeader from '../components/EmbedHeader';
import WorksheetStudier from '../components/WorksheetStudier';
import Loading from '../components/common/Loading';
import NotFound from '../components/common/NotFound';
import FullScreenContainer from '../components/common/FullScreenContainer';
import { StudyPage_worksheetQuery } from './__generated__/StudyPage_worksheetQuery.graphql';
import WorksheetAuth from '../components/auth/WorksheetAuth';
import './StudyPage.css';

const studyPage_worksheetQuery = graphql`
  query StudyPage_worksheetQuery($identifier: String!) {
    viewer {
      ...NavHeader_viewer
      ...EmbedHeader_viewer
      ...WorksheetStudier_viewer
      ...WorksheetAuth_viewer
    }
    worksheetByIdentifier(identifier: $identifier) {
      isVisibleToViewer
      ...WorksheetStudier_worksheet
      ...WorksheetAuth_worksheet
    }
  }
`;
type Props = {
  match: match<{ identifier: string }>;
  isDemo?: boolean;
  isEmbed?: boolean;
};

class StudyPage extends Component<Props> {
  render() {
    const { match, isDemo = false, isEmbed = false } = this.props;
    return (
      <QueryRenderer<StudyPage_worksheetQuery>
        environment={environment}
        query={studyPage_worksheetQuery}
        variables={{
          identifier: match.params.identifier,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          }

          const header = isEmbed ? (
            <EmbedHeader viewer={props?.viewer} />
          ) : (
            <NavHeader viewer={props?.viewer} />
          );
          if (!props?.viewer) {
            return (
              <>
                {header}
                <Loading />
              </>
            );
          }

          if (!props.worksheetByIdentifier) return <NotFound header={header} />;
          if (!props.worksheetByIdentifier.isVisibleToViewer) {
            return (
              <div className="StudyPage">
                {header}
                <div className="pt5">
                  <WorksheetAuth
                    worksheet={props.worksheetByIdentifier}
                    viewer={props.viewer}
                  />
                </div>
              </div>
            );
          }

          return (
            <FullScreenContainer>
              <div className="StudyPage">
                {header}
                <div className="mw8 center w-100 StudyPage-container">
                  <div className="StudyPage-containerInner">
                    <WorksheetStudier
                      worksheet={props.worksheetByIdentifier}
                      isDemo={isDemo}
                      isEmbed={isEmbed}
                      viewer={props.viewer}
                    />
                  </div>
                </div>
              </div>
            </FullScreenContainer>
          );
        }}
      />
    );
  }
}

export default StudyPage;
