/* tslint:disable */
/* eslint-disable */
/* @relayHash 3271b4754f05d04c129d7ffafca517ae */

import { ConcreteRequest } from "relay-runtime";
export type ImageChooserSearchQueryVariables = {
    query: string;
};
export type ImageChooserSearchQueryResponse = {
    readonly imageSearch: {
        readonly images: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly url: string;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type ImageChooserSearchQuery = {
    readonly response: ImageChooserSearchQueryResponse;
    readonly variables: ImageChooserSearchQueryVariables;
};



/*
query ImageChooserSearchQuery(
  $query: String!
) {
  imageSearch(query: $query) {
    images(first: 20) {
      edges {
        node {
          id
          url(size: LARGE_SQUARE)
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "imageSearch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "concreteType": "ImageSearch",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "images",
        "storageKey": "images(first:20)",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          }
        ],
        "concreteType": "ImageConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ImageEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Image",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "size",
                        "value": "LARGE_SQUARE"
                      }
                    ],
                    "storageKey": "url(size:\"LARGE_SQUARE\")"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImageChooserSearchQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ImageChooserSearchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ImageChooserSearchQuery",
    "id": null,
    "text": "query ImageChooserSearchQuery(\n  $query: String!\n) {\n  imageSearch(query: $query) {\n    images(first: 20) {\n      edges {\n        node {\n          id\n          url(size: LARGE_SQUARE)\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '28e379d759fee2ce3938acc516071093';
export default node;
