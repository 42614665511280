import React, { Component } from 'react';
import { ReactNode } from 'react';
type Props = {
  children: ReactNode;
};
type State = {
  innerHeight: number | undefined | null;
};

class FullScreenContainer extends Component<Props, State> {
  state: State = {
    innerHeight: null,
  };

  componentDidMount() {
    this.setState({
      innerHeight: window.innerHeight,
    });
  }

  render() {
    const style: {
      maxHeight: string;
      height?: string;
    } = {
      maxHeight: '100vh',
    };
    if (this.state.innerHeight) style.height = `${this.state.innerHeight}px`;
    return (
      <div className="FullScreenContainer" style={style}>
        {this.props.children}
      </div>
    );
  }
}

export default FullScreenContainer;
