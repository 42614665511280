import React from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import './Tabs.css';

type Props = {
  children: ReactNode;
};

const Tabs = ({ children }: Props) => (
  <div className="Tabs ph2-ns">{children}</div>
);

type TabProbs = {
  id?: string;
  children: ReactNode;
  isActive: boolean;
  onClick?: () => any;
};

const Tab = ({ children, isActive, onClick, id, ...extraProps }: TabProbs) => (
  // eslint-disable-next-line
  <a
    id={id}
    {...extraProps}
    className={classNames('Tab pv2 ph2 ph3-ns', { 'is-active': isActive })}
    onClick={() => onClick && onClick()}
  >
    {children}
  </a>
);

Tabs.Tab = Tab;

export default Tabs;
export { Tab };
