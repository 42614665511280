import React, { Component, Fragment, ReactNode, MouseEvent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import Select from 'react-select';
import { Link, withRouter, match } from 'react-router-dom';
import { Location, History } from 'history';
import debounce from 'lodash.debounce';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DropResult } from 'react-beautiful-dnd';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
// @ts-ignore
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import FormGroup from '../common/FormGroup';
import Button from '../common/Button';
import Modal from '../common/Modal';
import {
  WorksheetEditor_worksheet,
  WORKSHEET_VISIBILITY,
} from './__generated__/WorksheetEditor_worksheet.graphql';
import ExerciseTypeButton from './ExerciseTypeButton';
import DraggableExercise from './DraggableExercise';
import CreationCompleteModal from './CreationCompleteModal';
import MultipleChoiceEditor from '../MultipleChoiceEditor/index';
import TranslatorEditor from '../TranslatorEditor/index';
import WordOrderEditor from '../WordOrderEditor/index';
import VocabEditor from '../VocabEditor/index';
import { multipleChoiceConfig } from '../MultipleChoiceEditor/index';
import { translatorConfig } from '../TranslatorEditor/index';
import { wordOrderConfig } from '../WordOrderEditor/index';
import { vocabConfig } from '../VocabEditor/index';
import UpdateWorksheetMutation from '../../mutations/UpdateWorksheetMutation';
import PublishWorksheetMutation from '../../mutations/PublishWorksheetMutation';
import CreateMultipleChoiceExerciseMutation from '../../mutations/CreateMultipleChoiceExerciseMutation';
import CreateTranslationExerciseMutation from '../../mutations/CreateTranslationExerciseMutation';
import CreateWordOrderExerciseMutation from '../../mutations/CreateWordOrderExerciseMutation';
import CreateVocabExerciseMutation from '../../mutations/CreateVocabExerciseMutation';
import UpdateMultipleChoiceExerciseMutation from '../../mutations/UpdateMultipleChoiceExerciseMutation';
import UpdateTranslationExerciseMutation from '../../mutations/UpdateTranslationExerciseMutation';
import UpdateWordOrderExerciseMutation from '../../mutations/UpdateWordOrderExerciseMutation';
import UpdateVocabExerciseMutation from '../../mutations/UpdateVocabExerciseMutation';
import DeleteExerciseMutation from '../../mutations/DeleteExerciseMutation';
import DeleteWorksheetMutation from '../../mutations/DeleteWorksheetMutation';
import './index.css';
import { WorksheetEditor_viewer } from './__generated__/WorksheetEditor_viewer.graphql';
import EditPasswordModal from './EditPasswordModal';
import Textarea from '../common/Textarea';
import Input from '../common/Input';
import SignUpModal from '../auth/SignUpModal';

type exerciseType = 'translation' | 'wordOrder' | 'vocab' | 'multipleChoice';
type Props = {
  worksheet: WorksheetEditor_worksheet;
  viewer: WorksheetEditor_viewer;
  relay: RelayProp;
  editMode: boolean;
  intl: IntlShape;
  location: Location;
  history: History;
  match: match;
};

type CONCRETE_WORKSHEET_VISIBILITY = Exclude<
  WORKSHEET_VISIBILITY,
  '%future added value'
>;

type State = {
  isSelectingExerciseType: boolean;
  editingExerciseType: exerciseType | undefined | null;
  title: string;
  password: string;
  description: string;
  isPasswordModalOpen: boolean;
  isPasswordRevealed: boolean;
  visibility: CONCRETE_WORKSHEET_VISIBILITY;
  editingExerciseId: string | undefined | null;
  isShowingSignUpPrompt: boolean;
};
const messages = defineMessages({
  confirmDeleteExercise: {
    id: 'WorksheetEditor.confirm_delete_exercise',
    defaultMessage:
      'Are you sure you want to delete this exercise? There is NO undo.',
  },
  confirmDeleteWorksheet: {
    id: 'WorksheetEditor.confirm_delete_wordsheet',
    defaultMessage:
      'Are you sure you want to delete this wordsheet? There is NO undo.',
  },
  newMultipleChoiceExercise: {
    id: 'WorksheetEditor.new_multiple_choice_exercise_header',
    defaultMessage: 'New multiple choice exercise',
  },
  editMultipleChoiceExercise: {
    id: 'WorksheetEditor.edit_multiple_choice_exercise_header',
    defaultMessage: 'Edit multiple choice exercise',
  },
  newTranslationExercise: {
    id: 'WorksheetEditor.new_translation_exercise_header',
    defaultMessage: 'New translation exercise',
  },
  editTranslationExercise: {
    id: 'WorksheetEditor.edit_translation_exercise_header',
    defaultMessage: 'Edit translation exercise',
  },
  newWordOrderExercise: {
    id: 'WorksheetEditor.new_word_order_exercise_header',
    defaultMessage: 'New word order exercise',
  },
  editWordOrderExercise: {
    id: 'WorksheetEditor.edit_word_order_exercise_header',
    defaultMessage: 'Edit word order exercise',
  },
  newVocabExercise: {
    id: 'WorksheetEditor.new_vocab_exercise_header',
    defaultMessage: 'New vocab exercise',
  },
  editVocabExercise: {
    id: 'WorksheetEditor.edit_vocab_exercise_header',
    defaultMessage: 'Edit vocab exercise',
  },
  doneButton: {
    id: 'WorksheetEditor.done_button',
    defaultMessage: 'Done',
  },
  titleFieldLabel: {
    id: 'WorksheetEditor.title_field_label',
    defaultMessage: 'Title',
  },
  descriptionFieldLabel: {
    id: 'WorksheetEditor.description_field_label',
    defaultMessage: 'Description',
  },
  visibilityFieldLabel: {
    id: 'WorksheetEditor.visibility_field_label',
    defaultMessage: 'Visible to',
  },
  exercisesFieldLabel: {
    id: 'WorksheetEditor.exercises_field_label',
    defaultMessage: 'Exercises',
  },
  visibleToEveryone: {
    id: 'WorksheetEditor.visible_to_everyone',
    defaultMessage: 'Visible to everyone',
  },
  visibleToLoggedIn: {
    id: 'WorksheetEditor.visible_to_everyone',
    defaultMessage: 'Visible to logged-in users',
  },
  visibleWithPassword: {
    id: 'WorksheetEditor.visible_with_password',
    defaultMessage: 'Visible with a password',
  },
  chooseExerciseTypeHeader: {
    id: 'WorksheetEditor.choose_exercise_type_header',
    defaultMessage: 'Choose exercise type',
  },
  translationExerciseTitle: {
    id: 'WorksheetEditor.translation_exercise_title',
    defaultMessage: 'Translation exercise',
  },
  translationExerciseDescription: {
    id: 'WorksheetEditor.translation_exercise_description',
    defaultMessage: 'Provide a sentence for your students to translate.',
  },
  wordOrderExerciseTitle: {
    id: 'WorksheetEditor.word_order_exercise_title',
    defaultMessage: 'Word order exercise',
  },
  wordOrderExerciseDescription: {
    id: 'WorksheetEditor.word_order_exercise_description',
    defaultMessage:
      'Students rearrange scrambled words into the correct order.',
  },
  vocabExerciseTitle: {
    id: 'WorksheetEditor.vocab_exercise_title',
    defaultMessage: 'Vocab exercise',
  },
  vocabExerciseDescription: {
    id: 'WorksheetEditor.vocab_exercise_description',
    defaultMessage: 'Quiz students on vocabularly with text and images',
  },
  multipleChoiceExerciseTitle: {
    id: 'WorksheetEditor.multiple_choice_exercise_title',
    defaultMessage: 'Multiple choice exercise',
  },
  multipleChoiceExerciseDescription: {
    id: 'WorksheetEditor.multiple_choice_exercise_description',
    defaultMessage: 'A simple text prompt with multiple choice responses',
  },
  missingTitleError: {
    id: 'WorksheetEditor.must_add_a_title',
    defaultMessage: 'You must add a title',
  },
  missingExerciseError: {
    id: 'WorksheetEditor.must_add_an_exercise_title',
    defaultMessage: 'You must add at least 1 exercise',
  },
});

const exerciseTypeMapping: {
  [x: string]: exerciseType;
} = {
  TranslationExercise: 'translation',
  WordOrderExercise: 'wordOrder',
  VocabExercise: 'vocab',
  MultipleChoiceExercise: 'multipleChoice',
};

class WorksheetEditor extends Component<Props, State> {
  state: State = {
    title: '',
    description: '',
    password: '',
    visibility: 'EVERYONE',
    isPasswordModalOpen: false,
    isSelectingExerciseType: false,
    isPasswordRevealed: false,
    isShowingSignUpPrompt: false,
    editingExerciseType: null,
    editingExerciseId: null,
  };

  componentDidMount() {
    this.setState(
      {
        title: this.props.worksheet.title || '',
        description: this.props.worksheet.description || '',
        visibility: this.props.worksheet
          .visibility as CONCRETE_WORKSHEET_VISIBILITY,
        password: this.props.worksheet.password || '',
      },
      () => {
        const queryParams = queryString.parse(this.props.location.search);

        if (
          queryParams.publish === this.props.worksheet.id &&
          this.isValid() &&
          this.isLoggedIn()
        ) {
          this.onSignUpPublish();
        }
      },
    );
  }

  isValid() {
    if (!this.props.worksheet.exercises) return false;
    return this.state.title.trim() && this.props.worksheet.exercises.length > 0;
  }

  isLoggedIn() {
    return this.props.viewer.user && this.props.viewer.user.id;
  }

  onPublish = async () => {
    if (this.isLoggedIn()) {
      await PublishWorksheetMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
      );
      window.localStorage.removeItem('newSheetId');
      window.heap.track('edit_publish', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        numExercises: this.props.worksheet.exercises.length,
      });
    } else {
      this.setState({
        isShowingSignUpPrompt: true,
      });
      window.heap.track('edit_promptSignup', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        numExercises: this.props.worksheet.exercises.length,
      });
    }
  };
  onSignUpPublish = async () => {
    await PublishWorksheetMutation.commit(
      this.props.relay.environment,
      this.props.worksheet.id,
    );
    window.localStorage.removeItem('newSheetId');
    window.heap.track('edit_signupPublishSuccess', {
      identifier: this.props.worksheet.identifier,
      id: this.props.worksheet.id,
      numExercises: this.props.worksheet.exercises.length,
    });
    window.location.href = `/new-sheet/complete/${this.props.worksheet
      .identifier || ''}`;
  };
  onAddExercise = () => {
    this.setState({
      isSelectingExerciseType: true,
    });
  };
  onEditPassword = (evt: MouseEvent) => {
    evt.preventDefault();
    this.setState({ isPasswordModalOpen: true });
  };
  onToggleRevealPassword = (evt: MouseEvent) => {
    evt.preventDefault();
    this.setState({ isPasswordRevealed: !this.state.isPasswordRevealed });
  };
  onSelectExerciseType = (type: exerciseType) => {
    this.setState({
      isSelectingExerciseType: false,
      editingExerciseType: type,
      editingExerciseId: null,
    });
    window.heap.track('edit_selectExerciseType', {
      identifier: this.props.worksheet.identifier,
      id: this.props.worksheet.id,
      numExercises: this.props.worksheet.exercises.length,
      exerciseType: type,
    });
  };
  onDeleteExercise = (exerciseId: string) => {
    if (
      // eslint-disable-next-line
      confirm(this.props.intl.formatMessage(messages.confirmDeleteExercise))
    ) {
      const exercise = this.props.worksheet.exercises.find(
        ex => ex.id === exerciseId,
      );
      DeleteExerciseMutation.commit(
        this.props.relay.environment,
        exerciseId,
        this.props.worksheet.id,
      );
      window.heap.track('edit_deleteExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        numExercises: this.props.worksheet.exercises.length,
        exerciseId,
        exerciseType: exercise && exercise.__typename,
      });
    }
  };
  onDeleteWorksheet = async () => {
    if (
      // eslint-disable-next-line
      confirm(this.props.intl.formatMessage(messages.confirmDeleteWorksheet))
    ) {
      await DeleteWorksheetMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
      );
      window.location.href = '/dashboard';
    }
  };
  onBeginEditingExercise = (exerciseId: string) => {
    const exercise = this.props.worksheet.exercises.find(
      ex => ex.id === exerciseId,
    );
    if (!exercise) return;
    this.setState({
      editingExerciseType: exerciseTypeMapping[exercise.__typename],
      editingExerciseId: exerciseId,
    });
  };
  debouncedUpdateMutation = debounce(() => {
    if (this.state.title.trim()) {
      UpdateWorksheetMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
        {
          title: this.state.title.trim(),
          description: this.state.description.trim(),
          visibility: this.state.visibility,
          password: this.state.password,
        },
      );
      window.heap.track('edit_update', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        title: this.state.title.trim(),
        description: this.state.description.trim(),
      });
    }
  }, 2000);
  onUpdateTitle = (title: string) => {
    this.setState({ title });
    this.debouncedUpdateMutation();
  };
  onUpdateDescription = (description: string) => {
    this.setState({ description });
    this.debouncedUpdateMutation();
  };
  onSelectVisibility = (visibility: CONCRETE_WORKSHEET_VISIBILITY) => {
    if (visibility === 'PASSWORD' && this.state.visibility !== 'PASSWORD') {
      this.setState({ isPasswordModalOpen: true });
    } else {
      this.setState({ isPasswordModalOpen: false, visibility });
      this.debouncedUpdateMutation();
    }
  };
  onDoneEditingPassword = (password: string) => {
    this.setState({
      isPasswordModalOpen: false,
      visibility: 'PASSWORD',
      password,
    });
    this.debouncedUpdateMutation();
  };
  onCreateOrUpdateMultipleChoiceExercise = (content: multipleChoiceConfig) => {
    const clonedChoices = content.choices.map(choice => ({ ...choice }));
    const clonedContent = {
      prompt: content.prompt,
      choices: clonedChoices,
    };

    if (this.state.editingExerciseId) {
      UpdateMultipleChoiceExerciseMutation.commit(
        this.props.relay.environment,
        this.state.editingExerciseId,
        this.props.worksheet.id,
        {
          content: clonedContent,
        },
      );
      window.heap.track('edit_updateMultipleChoiceExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        exerciseId: this.state.editingExerciseId,
        content: JSON.stringify(content, null, 2),
      });
    } else {
      CreateMultipleChoiceExerciseMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
        clonedContent,
        (this.props.worksheet.exercises || []).length,
      );
      window.heap.track('edit_createMultipleChoiceExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        content: JSON.stringify(content, null, 2),
      });
    }

    this.setState({
      editingExerciseType: null,
      editingExerciseId: null,
    });
  };
  onCreateOrUpdateTranslationExercise = (content: translatorConfig) => {
    if (this.state.editingExerciseId) {
      UpdateTranslationExerciseMutation.commit(
        this.props.relay.environment,
        this.state.editingExerciseId,
        this.props.worksheet.id,
        {
          content: {
            prompt: content.prompt,
            correctAnswers: [...content.correctAnswers],
            scrambledParts: [...content.scrambledParts],
          },
        },
      );
      window.heap.track('edit_updateTranslationExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        exerciseId: this.state.editingExerciseId,
        content: JSON.stringify(content, null, 2),
      });
    } else {
      CreateTranslationExerciseMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
        {
          prompt: content.prompt,
          correctAnswers: [...content.correctAnswers],
          scrambledParts: [...content.scrambledParts],
        },
        (this.props.worksheet.exercises || []).length,
      );
      window.heap.track('edit_createTranslationExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        content: JSON.stringify(content, null, 2),
      });
    }

    this.setState({
      editingExerciseType: null,
      editingExerciseId: null,
    });
  };
  onCreateOrUpdateWordOrderExercise = (content: wordOrderConfig) => {
    if (this.state.editingExerciseId) {
      UpdateWordOrderExerciseMutation.commit(
        this.props.relay.environment,
        this.state.editingExerciseId,
        this.props.worksheet.id,
        {
          content: {
            correctAnswers: [...content.correctAnswers],
            scrambledParts: [...content.scrambledParts],
          },
        },
      );
      window.heap.track('edit_updateWordOrderExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        exerciseId: this.state.editingExerciseId,
        content: JSON.stringify(content, null, 2),
      });
    } else {
      CreateWordOrderExerciseMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
        {
          correctAnswers: [...content.correctAnswers],
          scrambledParts: [...content.scrambledParts],
        },
        (this.props.worksheet.exercises || []).length,
      );
      window.heap.track('edit_createWordOrderExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        content: JSON.stringify(content, null, 2),
      });
    }

    this.setState({
      editingExerciseType: null,
      editingExerciseId: null,
    });
  };
  onCreateOrUpdateVocabExercise = (content: vocabConfig) => {
    if (this.state.editingExerciseId) {
      UpdateVocabExerciseMutation.commit(
        this.props.relay.environment,
        this.state.editingExerciseId,
        this.props.worksheet.id,
        {
          content: {
            terms: [...content.terms],
          },
        },
      );
      window.heap.track('edit_updateVocabExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        exerciseId: this.state.editingExerciseId,
        content: JSON.stringify(content, null, 2),
      });
    } else {
      CreateVocabExerciseMutation.commit(
        this.props.relay.environment,
        this.props.worksheet.id,
        {
          terms: [...content.terms],
        },
        (this.props.worksheet.exercises || []).length,
      );
      window.heap.track('edit_createVocabExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        content: JSON.stringify(content, null, 2),
      });
    }

    this.setState({
      editingExerciseType: null,
      editingExerciseId: null,
    });
  };
  onDragEnd = (result: DropResult) => {
    if (!this.props.worksheet.exercises || !result.destination) return;

    if (result.destination.index !== result.source.index) {
      const destIndex = result.destination.index;
      const exercise = this.props.worksheet.exercises.filter(
        ex => ex.id === result.draggableId,
      )[0];
      let mutation: any = UpdateTranslationExerciseMutation;
      if (exercise.__typename === 'WordOrderExercise') {
        mutation = UpdateWordOrderExerciseMutation;
      }
      mutation.commit(
        this.props.relay.environment,
        exercise.id,
        this.props.worksheet.id,
        {
          position: destIndex,
        },
      );
      window.heap.track('edit_reorderExercise', {
        identifier: this.props.worksheet.identifier,
        id: this.props.worksheet.id,
        position: destIndex,
      });
    }
  };

  getExerciseHeader() {
    const isEdit = !!this.state.editingExerciseId;
    const formatMessage = this.props.intl.formatMessage;

    if (this.state.editingExerciseType === 'translation') {
      return formatMessage(
        isEdit
          ? messages.editTranslationExercise
          : messages.newTranslationExercise,
      );
    }

    if (this.state.editingExerciseType === 'wordOrder') {
      return formatMessage(
        isEdit ? messages.editWordOrderExercise : messages.newWordOrderExercise,
      );
    }

    if (this.state.editingExerciseType === 'vocab') {
      return formatMessage(
        isEdit ? messages.editVocabExercise : messages.newVocabExercise,
      );
    }

    if (this.state.editingExerciseType === 'multipleChoice') {
      return formatMessage(
        isEdit
          ? messages.editMultipleChoiceExercise
          : messages.newMultipleChoiceExercise,
      );
    }
  }

  getExercise(exerciseId?: string | null) {
    if (!exerciseId) return null;
    return this.props.worksheet.exercises.find(ex => ex.id === exerciseId);
  }

  renderExerciseCreator() {
    let creator;
    const editingExercise = this.getExercise(this.state.editingExerciseId);

    if (this.state.editingExerciseType === 'multipleChoice') {
      creator = (
        <MultipleChoiceEditor
          defaultConfig={editingExercise && editingExercise.content}
          onUpdateConfig={config =>
            this.onCreateOrUpdateMultipleChoiceExercise(config)
          }
        />
      );
    }

    if (this.state.editingExerciseType === 'translation') {
      creator = (
        <TranslatorEditor
          defaultConfig={editingExercise && editingExercise.content}
          onUpdateConfig={config =>
            this.onCreateOrUpdateTranslationExercise(config)
          }
        />
      );
    }

    if (this.state.editingExerciseType === 'wordOrder') {
      creator = (
        <WordOrderEditor
          defaultConfig={editingExercise && editingExercise.content}
          onUpdateConfig={config =>
            this.onCreateOrUpdateWordOrderExercise(config)
          }
        />
      );
    }

    if (this.state.editingExerciseType === 'vocab') {
      let defaultConfig = null;

      if (
        editingExercise &&
        editingExercise.content &&
        editingExercise.content.terms
      ) {
        defaultConfig = {
          terms: editingExercise.content.terms.map(term => ({
            word: term.word,
            definition: term.definition,
            imageId: term.image && term.image.id,
          })),
        };
      }

      creator = (
        <VocabEditor
          defaultConfig={defaultConfig}
          onUpdateConfig={config => this.onCreateOrUpdateVocabExercise(config)}
        />
      );
    }

    return (
      <>
        <div className="WorksheetEditor-exerciseHeader">
          <h2 className="WorksheetEditor-title cf pv3">
            <div className="fl w-10 w-20-ns ph2 tl f4">
              {/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  this.setState({
                    editingExerciseType: null,
                  })
                }
                className="WorksheetEditor-backArrow"
              >
                <i className="fa fa-arrow-left pr1" />
                <span className="dn dib-ns">
                  <FormattedMessage
                    id="WorksheetEditor.cancel_button"
                    defaultMessage="Cancel"
                  />
                </span>
              </a>
            </div>
            <div className="fl w-80 w-60-ns ph2 f4">
              {this.getExerciseHeader()}
            </div>
          </h2>
        </div>
        {creator}
      </>
    );
  }

  renderExerciseTypeSelection() {
    const { formatMessage } = this.props.intl;
    return (
      <Fragment>
        <div className="WorksheetEditor-exerciseButton">
          <ExerciseTypeButton
            title={formatMessage(messages.translationExerciseTitle)}
            subtitle={formatMessage(messages.translationExerciseDescription)}
            onClick={() => this.onSelectExerciseType('translation')}
          />
        </div>
        <div className="WorksheetEditor-exerciseButton">
          <ExerciseTypeButton
            title={formatMessage(messages.wordOrderExerciseTitle)}
            subtitle={formatMessage(messages.wordOrderExerciseDescription)}
            onClick={() => this.onSelectExerciseType('wordOrder')}
          />
        </div>
        <div className="WorksheetEditor-exerciseButton">
          <ExerciseTypeButton
            title={formatMessage(messages.vocabExerciseTitle)}
            subtitle={formatMessage(messages.vocabExerciseDescription)}
            onClick={() => this.onSelectExerciseType('vocab')}
          />
        </div>
        <div className="WorksheetEditor-exerciseButton">
          <ExerciseTypeButton
            title={formatMessage(messages.multipleChoiceExerciseTitle)}
            subtitle={formatMessage(messages.multipleChoiceExerciseDescription)}
            onClick={() => this.onSelectExerciseType('multipleChoice')}
          />
        </div>
      </Fragment>
    );
  }

  renderInvalidReason() {
    if (!this.state.title.trim()) {
      return this.props.intl.formatMessage(messages.missingTitleError);
    }

    if (this.props.worksheet.exercises.length === 0) {
      return this.props.intl.formatMessage(messages.missingExerciseError);
    }
  }

  renderDoneButton() {
    if (this.props.editMode) {
      return (
        <Button
          type="success"
          disabled={!this.isValid()}
          fullWidth
          component={Link}
          to={`/view/${this.props.worksheet.identifier || ''}`}
        >
          {this.props.intl.formatMessage(messages.doneButton)}
        </Button>
      );
    }

    return (
      <div
        data-tip={this.isValid() ? null : this.renderInvalidReason()}
        data-type="error"
      >
        <Button
          type="success"
          disabled={!this.isValid()}
          onClick={this.onPublish}
          fullWidth
        >
          {this.props.intl.formatMessage(messages.doneButton)}
        </Button>
      </div>
    );
  }

  renderMainEditorHeader() {
    return (
      <div className="WorksheetEditor-title">
        {this.props.editMode ? (
          <>
            <div className="fr w-20 ph2 tr pt2">
              {!this.props.editMode ? null : (
                <Button
                  onClick={this.onDeleteWorksheet}
                  outline
                  buttonType="gray"
                >
                  <i className="fas fa-trash" />
                </Button>
              )}
            </div>
            <h2 className="fr w-60 ph2 pa3 ma0">
              <FormattedMessage
                id="WorksheetEditor.edit_wordsheet_header"
                defaultMessage="Edit wordsheet"
              />
            </h2>
          </>
        ) : (
          <h2 className="pa3 ma0">
            <FormattedMessage
              id="WorksheetEditor.new_wordsheet_header"
              defaultMessage="New wordsheet"
            />
          </h2>
        )}
      </div>
    );
  }

  renderMainEditor() {
    const { formatMessage } = this.props.intl;

    const selectOptions: {
      [key in CONCRETE_WORKSHEET_VISIBILITY]: {
        value: CONCRETE_WORKSHEET_VISIBILITY;
        label: ReactNode;
      };
    } = {
      EVERYONE: {
        value: 'EVERYONE',
        label: (
          <span>
            <i className="fas fa-users mr2" />
            {formatMessage(messages.visibleToEveryone)}
          </span>
        ),
      },
      LOGGED_IN: {
        value: 'LOGGED_IN',
        label: (
          <span>
            <i className="fas fa-user-check mr2" />
            {formatMessage(messages.visibleToLoggedIn)}
          </span>
        ),
      },
      PASSWORD: {
        value: 'PASSWORD',
        label: (
          <span>
            <i className="fas fa-key mr2" />
            {formatMessage(messages.visibleWithPassword)}
          </span>
        ),
      },
    };

    return (
      <>
        {this.renderMainEditorHeader()}
        <div className="pa3">
          <FormGroup label={formatMessage(messages.titleFieldLabel)}>
            <Input
              type="text"
              data-testid="WorksheetEditor-titleInput"
              value={this.state.title || ''}
              onChange={evt => this.onUpdateTitle(evt.target.value)}
            />
          </FormGroup>

          <FormGroup label={formatMessage(messages.descriptionFieldLabel)}>
            <Textarea
              data-testid="WorksheetEditor-descriptionInput"
              rows={3}
              value={this.state.description || ''}
              onChange={evt => this.onUpdateDescription(evt.target.value)}
            />
          </FormGroup>
          {this.isLoggedIn() && (
            <FormGroup label={formatMessage(messages.visibilityFieldLabel)}>
              <div className="w-50-ns">
                <Select
                  value={selectOptions[this.state.visibility]}
                  options={Object.values(selectOptions)}
                  onChange={val =>
                    val && 'value' in val && this.onSelectVisibility(val.value)
                  }
                />
                {this.state.isPasswordRevealed && (
                  <div className="pt3">{this.state.password}</div>
                )}
                {this.state.visibility === 'PASSWORD' && (
                  <div className="pt3">
                    <a
                      className="link f6 mr3"
                      href="#editPassword"
                      onClick={this.onEditPassword}
                    >
                      <FormattedMessage
                        id="WorksheetEditor.edit_password"
                        defaultMessage="Edit password"
                      />
                    </a>
                    <a
                      className="link f6 mr3"
                      href="#revealPassword"
                      onClick={this.onToggleRevealPassword}
                    >
                      {this.state.isPasswordRevealed ? (
                        <FormattedMessage
                          id="WorksheetEditor.hide_password"
                          defaultMessage="Hide password"
                        />
                      ) : (
                        <FormattedMessage
                          id="WorksheetEditor.reveal_password"
                          defaultMessage="Reveal password"
                        />
                      )}
                    </a>
                  </div>
                )}
              </div>
            </FormGroup>
          )}
          <FormGroup label={formatMessage(messages.exercisesFieldLabel)}>
            <Droppable droppableId="exercises">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {(this.props.worksheet.exercises || []).map(
                    (exercise, index) => (
                      <DraggableExercise
                        exercise={exercise}
                        index={index}
                        key={exercise.id}
                        onDelete={this.onDeleteExercise}
                        onEdit={this.onBeginEditingExercise}
                      />
                    ),
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Button outline onClick={this.onAddExercise}>
              <i className="fa fa-plus pr1" />
              <FormattedMessage
                id="WorksheetEditor.add_exercise_button"
                defaultMessage="Add exercise"
              />
            </Button>
          </FormGroup>
        </div>
        <div className="pa3">{this.renderDoneButton()}</div>
        <ReactTooltip effect="solid" place="top" html={true} />
      </>
    );
  }

  render() {
    return (
      <div className="WorksheetEditor">
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.state.editingExerciseType
            ? this.renderExerciseCreator()
            : this.renderMainEditor()}
        </DragDropContext>
        <Modal
          header={this.props.intl.formatMessage(
            messages.chooseExerciseTypeHeader,
          )}
          closeButton
          isOpen={this.state.isSelectingExerciseType}
          onClose={() =>
            this.setState({
              isSelectingExerciseType: false,
            })
          }
        >
          {this.renderExerciseTypeSelection()}
        </Modal>
        <EditPasswordModal
          onClose={() => this.setState({ isPasswordModalOpen: false })}
          onDone={this.onDoneEditingPassword}
          password={this.state.password}
          isOpen={this.state.isPasswordModalOpen}
        />
        {this.isLoggedIn() ? null : (
          <SignUpModal
            isOpen={this.state.isShowingSignUpPrompt}
            onClose={() =>
              this.setState({
                isShowingSignUpPrompt: false,
              })
            }
            environment={this.props.relay.environment}
            onSuccess={this.onSignUpPublish}
            oauthRedirect={`/new-sheet?publish=${this.props.worksheet.id}`}
            closeButton={true}
            probableAccountType="TEACHER"
            modalHeader={
              <FormattedMessage
                id="WorksheetEditor.sign_up_modal_header"
                defaultMessage="You're almost done!"
              />
            }
            signUpReason={
              <FormattedMessage
                id="WorksheetEditor.sign_up_prompt"
                defaultMessage="Sign up for free to create this wordsheet"
              />
            }
            logInReason={
              <FormattedMessage
                id="WorksheetEditor.log_in_prompt"
                defaultMessage="Log in to create this wordsheet"
              />
            }
          />
        )}
        {this.props.editMode || !this.isLoggedIn() ? null : (
          <CreationCompleteModal
            identifier={this.props.worksheet.identifier}
            isOpen={this.props.worksheet.isPublished}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  injectIntl(
    createFragmentContainer(WorksheetEditor, {
      viewer: graphql`
        fragment WorksheetEditor_viewer on Viewer {
          user {
            id
          }
        }
      `,
      worksheet: graphql`
        # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
        fragment WorksheetEditor_worksheet on Worksheet {
          id
          title
          description
          identifier
          isPublished
          visibility
          password
          exercises {
            id
            __typename
            ... on MultipleChoiceExercise {
              content {
                prompt
                choices {
                  text
                  isCorrect
                }
              }
            }
            ... on TranslationExercise {
              content {
                prompt
                correctAnswers
                scrambledParts
              }
            }
            ... on WordOrderExercise {
              content {
                correctAnswers
                scrambledParts
              }
            }
            ... on VocabExercise {
              content {
                terms {
                  word
                  definition
                  image {
                    id
                  }
                }
              }
            }
            ...DraggableExercise_exercise
          }
        }
      `,
    }),
  ),
);
