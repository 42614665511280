/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CLASSROOM_MEMBERSHIP_LEVEL = "ADMIN" | "BANNED" | "CREATOR" | "MEMBER" | "PENDING" | "%future added value";
export type PendingMemberships_classroom = {
    readonly pendingMemberships: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly level: CLASSROOM_MEMBERSHIP_LEVEL;
                readonly " $fragmentRefs": FragmentRefs<"PendingMembership_classroomMembership">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "PendingMemberships_classroom";
};
export type PendingMemberships_classroom$data = PendingMemberships_classroom;
export type PendingMemberships_classroom$key = {
    readonly " $data"?: PendingMemberships_classroom$data;
    readonly " $fragmentRefs": FragmentRefs<"PendingMemberships_classroom">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "PendingMemberships_classroom",
  "type": "Classroom",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pendingMemberships",
      "storageKey": "pendingMemberships(first:100)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "ClassroomMembershipConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClassroomMembershipEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClassroomMembership",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "level",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "PendingMembership_classroomMembership",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '5a07ee26c353ff6f73f914725f9bca29';
export default node;
