import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { DeleteWorksheetMutation } from './__generated__/DeleteWorksheetMutation.graphql';
const mutation = graphql`
  mutation DeleteWorksheetMutation($input: DeleteWorksheetInput!) {
    deleteWorksheet(input: $input) {
      id
    }
  }
`;

const commit = (environment: any, id: string) =>
  commitMutationPromise<DeleteWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    }, // intentionally not optimistically updating here to avoid showing "Not found" on edit page
  });

export default {
  commit,
};
