import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import AutofocusTextarea from '../common/AutofocusTextarea';
import Modal from '../common/Modal';

import './EmbedModal.css';

type Props = {
  isOpen: boolean;
  worksheetIdentifier: string;
  onClose: () => void;
};

type State = {};

class EmbedModal extends Component<Props, State> {
  state: State = {};

  render() {
    const { isOpen, onClose } = this.props;
    // eslint-disable-next-line
    const embedUrl = `${location.origin}/study/${this.props.worksheetIdentifier}/embed`;
    return (
      <Modal
        isOpen={isOpen}
        header={
          <FormattedMessage
            id="EmbedModal.modal_header"
            defaultMessage="Embed This Wordsheet"
          />
        }
        onClose={onClose}
        closeButton
      >
        <div>
          <p>
            <FormattedMessage
              id="EmbedModal.instructions"
              defaultMessage="Copy and paste the HTML snippet below to embed this wordsheet on your website or blog"
            />
          </p>
          <AutofocusTextarea
            className="EmbedModal-textarea pa3"
            value={`<iframe src="${embedUrl}" style="border:0" height="500" width="100%"></iframe>`}
          />
        </div>
      </Modal>
    );
  }
}

export default EmbedModal;
