import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlShape } from 'react-intl';
import { EmbedHeader_viewer } from './__generated__/EmbedHeader_viewer.graphql';
import auth from '../lib/auth';
import './EmbedHeader.css';
type Props = {
  viewer?: EmbedHeader_viewer | null;
  intl: IntlShape;
};

class EmbedHeader extends Component<Props> {
  signOut = () => {
    auth.clearToken();
    window.heap.resetIdentity();
    window.heap.track('signout');
    window.location.reload();
  };
  isLoggedIn = () => !!this.props.viewer?.user?.id;

  render() {
    // eslint-disable-next-line
    const fullSiteUrl = location.pathname.replace('/embed', '');
    return (
      <div className="EmbedHeader bg-blue">
        <nav className="dt w-100 mw8 center">
          <div className="dtc v-mid f5 tl ph2 pr0">
            <a
              href={fullSiteUrl}
              // eslint-disable-next-line
              target="_blank"
              className="dib white no-underline EmbedHeader-logo"
            >
              Wordsheet
            </a>
          </div>
          <div className="dtc v-mid tr pl0">
            {this.isLoggedIn() ? (
              <a
                className="f6 fw4 hover-white no-underline white-70 dib pv2 ph2 ph3-ns"
                href={fullSiteUrl}
                // eslint-disable-next-line
                target="_blank"
              >
                {this.props.viewer?.user?.username}{' '}
                <i className="fas fa-external-link-alt" />
              </a>
            ) : (
              <a
                href={fullSiteUrl}
                // eslint-disable-next-line
                target="_blank"
                className="f6 fw4 hover-white no-underline white-70 dib pv2 ph2 ph3-ns"
              >
                <FormattedMessage
                  id="EmbedHeader.full_site"
                  defaultMessage="Full site"
                />{' '}
                <i className="fas fa-external-link-alt" />
              </a>
            )}
          </div>
        </nav>
      </div>
    );
  }
}

export default injectIntl(
  createFragmentContainer(EmbedHeader, {
    viewer: graphql`
      fragment EmbedHeader_viewer on Viewer {
        user {
          id
          username
          createdAt
        }
      }
    `,
  }),
);
