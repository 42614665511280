import React, { FC } from 'react';
import { Redirect, match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import { CheckoutPageQuery } from './__generated__/CheckoutPageQuery.graphql';
import PageLoading from '../components/common/PageLoading';
import BillingForm from '../components/upgrade/BillingForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { FormattedMessage } from 'react-intl';

const upgradeQuery = graphql`
  query CheckoutPageQuery {
    viewer {
      ...NavHeader_viewer
      ...PageLoading_viewer
      user {
        id
        isUpgradedTeacher
      }
    }
    subscriptionPlans {
      ...BillingForm_subscriptionPlan
      period
      price
    }
  }
`;

interface CheckoutPageProps {
  match: match<{ planType: string }>;
}

const CheckoutPage: FC<CheckoutPageProps> = ({ match }) => (
  <QueryRenderer<CheckoutPageQuery>
    environment={environment}
    variables={{}}
    query={upgradeQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props || !props.viewer || !props.subscriptionPlans) {
        return <PageLoading viewer={props?.viewer} />;
      }

      const viewer = props && props.viewer;
      if (props && (!props.viewer.user || !props.viewer.user.id)) {
        return <Redirect to="/" />;
      }

      if (props.viewer.user?.isUpgradedTeacher) {
        return <Redirect to="/upgrade-success" />;
      }

      const isYearly = match.params.planType !== 'monthly';

      const subscriptionPlan = props.subscriptionPlans.find(
        ({ period }) => isYearly === (period === 'YEARLY'),
      ) as CheckoutPageQuery['response']['subscriptionPlans'][0];

      return (
        <div className="CheckoutPage">
          <NavHeader viewer={viewer} />
          <div className="pa3">
            <h1 className="f2">
              <FormattedMessage
                id="CheckoutPage.header"
                defaultMessage="Checkout"
              />
            </h1>
            <div className="mw8 center tl">
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY || ''}>
                <div className="ba b--black-10 measure center pa3 pb0 tl mb4 br2">
                  <h2 className="mt0">
                    <FormattedMessage
                      id="CheckoutPage.teacher_plus"
                      defaultMessage="Teacher Plus"
                    />
                  </h2>
                  <p>
                    <b>${subscriptionPlan.price}</b> /{' '}
                    {isYearly ? (
                      <FormattedMessage
                        id="CheckoutPage.year"
                        defaultMessage="year"
                      />
                    ) : (
                      <FormattedMessage
                        id="CheckoutPage.month"
                        defaultMessage="month"
                      />
                    )}
                  </p>
                  <div className="mt4">
                    <Elements>
                      <BillingForm subscriptionPlan={subscriptionPlan} />
                    </Elements>
                  </div>
                </div>
              </StripeProvider>
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default CheckoutPage;
