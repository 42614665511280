import React from 'react';
import { FormattedMessage } from 'react-intl';

const NotAllowed = () => (
  <div className="tc pa3 pt4" key={0}>
    <FormattedMessage id="NotAllowed.text" defaultMessage="Not allowed :(" />
  </div>
);

export default NotAllowed;
