import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { ProfileViewer_user } from './__generated__/ProfileViewer_user.graphql';
import Loading from '../common/Loading';
import Worksheet from '../common/Worksheet';
import TimeAgo from '../common/TimeAgo';
import './index.css';
import { exists } from '../../lib/utils';
import TeacherBadge from '../common/TeacherBadge';
type Props = {
  user: ProfileViewer_user;
  relay: RelayPaginationProp;
};

const ProfileViewer = ({ user, relay }: Props) => (
  <div className="ProfileViewer pa3 tl">
    <h1 className="mb3">{user.username}</h1>
    {user.isUpgradedTeacher && (
      <div className="pb3">
        <TeacherBadge />
      </div>
    )}
    <p className="gray f6 mb4">
      <FormattedMessage
        id="ProfileViewer.sign_up_time"
        defaultMessage="Signed up {ago}"
        values={{
          ago: <TimeAgo since={new Date(user.createdAt * 1000)} />,
        }}
      />
    </p>
    <h4 className="mb3">
      <FormattedMessage
        id="ProfileViewer.wordsheets_created"
        defaultMessage="Created wordsheets"
      />
    </h4>
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading />}
    >
      {user &&
      user.worksheets.edges &&
      user.worksheets.edges.length > 0 ? null : (
        <div className="pa3 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="ProfileViewer.no_wordsheets_message"
            defaultMessage="No wordsheets created"
          />
        </div>
      )}
      {user &&
        user.worksheets.edges &&
        user.worksheets.edges.filter(exists).map(
          ({ node }) =>
            node && (
              <div className="mt3" key={node.id}>
                <Worksheet
                  worksheet={node}
                  extraInfo={[
                    <FormattedMessage
                      id="ProfileViewer.wordsheet_created_time"
                      defaultMessage="Created {ago}"
                      values={{
                        ago: (
                          <TimeAgo since={new Date(node.createdAt * 1000)} />
                        ),
                      }}
                    />,
                  ]}
                />
              </div>
            ),
        )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  ProfileViewer,
  {
    user: graphql`
      fragment ProfileViewer_user on User
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 50 }
          cursor: { type: "String" }
        ) {
        username
        createdAt
        isUpgradedTeacher
        worksheets(first: $count, after: $cursor)
          @connection(key: "ProfileViewer_worksheets") {
          edges {
            node {
              id
              description
              createdAt
              ...Worksheet_worksheet
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props => props.user && props.user.worksheets,
    getVariables: (props, { count, cursor }) => ({
      count,
      cursor,
      username: props.user.username,
    }),
    query: graphql`
      query ProfileViewerQuery(
        $count: Int!
        $cursor: String
        $username: String!
      ) {
        user: userByUsername(username: $username) {
          ...ProfileViewer_user @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
