import React, { FC } from 'react';
import classNames from 'classnames';
import './Spinner.css';

interface SpinnerProps {
  size?: 'large' | 'default';
  icon?: string;
}

const Spinner: FC<SpinnerProps> = ({ size, icon = 'fa-spinner' }) => (
  <i
    className={classNames('Spinner fas', icon, {
      'Spinner--large': size === 'large',
    })}
  />
);

export default Spinner;
