import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CreateMultipleChoiceAnswerMutation } from './__generated__/CreateMultipleChoiceAnswerMutation.graphql';
const mutation = graphql`
  mutation CreateMultipleChoiceAnswerMutation(
    $input: CreateMultipleChoiceAnswerInput!
  ) {
    createMultipleChoiceAnswer(input: $input) {
      answer {
        id
      }
    }
  }
`;

const commit = (
  environment: any,
  studyRecordId: string,
  exerciseId: string,
  isCorrect: boolean,
  choices: {
    isChosen: boolean;
    text: string;
  }[],
) =>
  commitMutationPromise<CreateMultipleChoiceAnswerMutation>(environment, {
    mutation,
    variables: {
      input: {
        studyRecordId,
        exerciseId,
        isCorrect,
        choices,
      },
    },
  });

export default {
  commit,
};
