import { graphql } from 'babel-plugin-relay/macro';
import {
  MultipleChoiceExerciseContentInput,
  CreateMultipleChoiceExerciseMutation,
} from './__generated__/CreateMultipleChoiceExerciseMutation.graphql';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  addExerciseToStore,
  optimisticCreateExerciseUpdater,
} from './helpers/exerciseMutationHelpers';
const mutation = graphql`
  mutation CreateMultipleChoiceExerciseMutation(
    $input: CreateMultipleChoiceExerciseInput!
  ) {
    createMultipleChoiceExercise(input: $input) {
      exercise {
        id
        content {
          prompt
          choices {
            text
            isCorrect
          }
        }
        worksheet {
          id
        }
      }
    }
  }
`;

const commit = (
  environment: any,
  worksheetId: string,
  content: MultipleChoiceExerciseContentInput,
  position: number,
) =>
  commitMutationPromise<CreateMultipleChoiceExerciseMutation>(environment, {
    mutation,
    variables: {
      input: {
        worksheetId,
        content,
        position,
      },
    },
    optimisticUpdater: optimisticCreateExerciseUpdater(
      'MultipleChoiceExercise',
      worksheetId,
      content,
      position,
    ),
    updater: store => {
      const payload = store.getRootField('createMultipleChoiceExercise');
      if (payload) {
        const newExercise = payload.getLinkedRecord('exercise');
        addExerciseToStore(store, newExercise, worksheetId, position);
      }
    },
  });

export default {
  commit,
};
