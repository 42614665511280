/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ACCOUNT_TYPE = "STUDENT" | "TEACHER" | "%future added value";
export type SubscriptionType = "TEACHER_FOREVER" | "TEACHER_MONTHLY" | "TEACHER_YEARLY" | "%future added value";
export type AccountSettings_user = {
    readonly username: string;
    readonly email: string | null;
    readonly hasPassword: boolean;
    readonly isUpgradedTeacher: boolean;
    readonly accountType: ACCOUNT_TYPE | null;
    readonly subscriptionStatus: {
        readonly subscriptionCancelAtPeriodEnd: boolean;
        readonly subscriptionCurrentPeriodEnd: number | null;
        readonly subscriptionCardEndDigits: string | null;
        readonly plan: {
            readonly price: number;
        } | null;
        readonly subscriptionType: SubscriptionType | null;
        readonly subscriptionNote: string | null;
    } | null;
    readonly " $refType": "AccountSettings_user";
};
export type AccountSettings_user$data = AccountSettings_user;
export type AccountSettings_user$key = {
    readonly " $data"?: AccountSettings_user$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountSettings_user">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "AccountSettings_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasPassword",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isUpgradedTeacher",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "accountType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscriptionStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "UserSubscriptionStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionCancelAtPeriodEnd",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionCurrentPeriodEnd",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionCardEndDigits",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "plan",
          "storageKey": null,
          "args": null,
          "concreteType": "SubscriptionPlan",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "price",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subscriptionNote",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '9d29d2ae021ef2475bfead3c7d83632c';
export default node;
