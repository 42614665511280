import React, { Component } from 'react';
import { QueryRenderer, createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { MembersTab_classroom } from './__generated__/MembersTab_classroom.graphql';
import Loading from '../common/Loading';
import { MembersTabQuery } from './__generated__/MembersTabQuery.graphql';
import ActiveMemberships from './ActiveMemberships';
import BannedMemberships from './BannedMemberships';

type MembersTabProps = {
  classroom: MembersTab_classroom;
  relay: RelayProp;
  canEdit: boolean;
};
const worksheetsTabQuery = graphql`
  query MembersTabQuery($id: ID!) {
    node(id: $id) {
      ... on Classroom {
        id
        ...ActiveMemberships_classroom
        ...BannedMemberships_classroom
      }
    }
  }
`;

class MembersTab extends Component<MembersTabProps> {
  render() {
    return (
      <div className="MembersTab">
        <QueryRenderer<MembersTabQuery>
          environment={this.props.relay.environment}
          query={worksheetsTabQuery}
          variables={{
            id: this.props.classroom.id,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            }

            if (!props?.node) {
              return <Loading />;
            }
            return (
              <div>
                <ActiveMemberships
                  classroom={props.node}
                  canEdit={this.props.canEdit}
                />
                {this.props.canEdit && (
                  <BannedMemberships
                    classroom={props.node}
                    canEdit={this.props.canEdit}
                  />
                )}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default createFragmentContainer(MembersTab, {
  classroom: graphql`
    fragment MembersTab_classroom on Classroom {
      id
    }
  `,
});
