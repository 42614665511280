// based on https://stackoverflow.com/a/12646864
// retuns a new array that's shuffled
export function shuffleArray<T>(array: Array<T>): Array<T> {
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = shuffledArray[i];
    shuffledArray[i] = shuffledArray[j];
    shuffledArray[j] = temp;
  }
  return shuffledArray;
}

export const exists = <T>(obj: T | undefined | null): obj is T => {
  return obj !== undefined && obj !== null;
};

// returns a new array with the item removed
export function removeArrayItem<T>(array: Array<T>, item: T): Array<T> {
  const itemIndex = array.indexOf(item);
  const arrCopy = array.slice();
  if (itemIndex >= 0) {
    arrCopy.splice(itemIndex, 1);
  }
  return arrCopy;
}

export function preloadImage(src: string) {
  if (document.images) {
    new Image().src = src;
  }
}

// Object.values but comaptible with flow's nonsense
export function objVals<T>(obj: { [key: string]: T }): T[] {
  return Object.keys(obj).map(key => obj[key]);
}

export function containsChineseChars(str: null | string): boolean {
  if (!str) return false;
  return !!str.match(/[\u3400-\u9FBF]/);
}

export function containsRtlChars(str: null | string): boolean {
  if (!str) return false;
  return !!str.match(
    /[\u0590-\u05FF\uFB2A-\uFB4E\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd92-\ufdc7\ufe70-\ufefc\uFDF0-\uFDFD]/,
  );
}

export function containsNumbers(str: null | string): boolean {
  if (!str) return false;
  return !!str.match(/[0-9]/);
}

export function isWechat() {
  return /micromessenger/i.test(navigator.userAgent);
}

export function externalStudyLink(identifier: string) {
  return `https://${window.location.host}/study/${identifier}`;
}

export function externalJoinLink(identifier: string) {
  return `https://${window.location.host}/class/${identifier}/join`;
}

// from https://stackoverflow.com/a/21396529
const PUNCT_REGEX = /[$\uFFE5^+=`~<>{}[\]|\u3000-\u303F!-#%-\x2A,-/:;\x3F@\x5B-\x5D_\x7B}\u00A1\u00A7\u00AB\u00B6\u00B7\u00BB\u00BF\u037E\u0387\u055A-\u055F\u0589\u058A\u05BE\u05C0\u05C3\u05C6\u05F3\u05F4\u0609\u060A\u060C\u060D\u061B\u061E\u061F\u066A-\u066D\u06D4\u0700-\u070D\u07F7-\u07F9\u0830-\u083E\u085E\u0964\u0965\u0970\u0AF0\u0DF4\u0E4F\u0E5A\u0E5B\u0F04-\u0F12\u0F14\u0F3A-\u0F3D\u0F85\u0FD0-\u0FD4\u0FD9\u0FDA\u104A-\u104F\u10FB\u1360-\u1368\u1400\u166D\u166E\u169B\u169C\u16EB-\u16ED\u1735\u1736\u17D4-\u17D6\u17D8-\u17DA\u1800-\u180A\u1944\u1945\u1A1E\u1A1F\u1AA0-\u1AA6\u1AA8-\u1AAD\u1B5A-\u1B60\u1BFC-\u1BFF\u1C3B-\u1C3F\u1C7E\u1C7F\u1CC0-\u1CC7\u1CD3\u2010-\u2027\u2030-\u2043\u2045-\u2051\u2053-\u205E\u207D\u207E\u208D\u208E\u2329\u232A\u2768-\u2775\u27C5\u27C6\u27E6-\u27EF\u2983-\u2998\u29D8-\u29DB\u29FC\u29FD\u2CF9-\u2CFC\u2CFE\u2CFF\u2D70\u2E00-\u2E2E\u2E30-\u2E3B\u3001-\u3003\u3008-\u3011\u3014-\u301F\u3030\u303D\u30A0\u30FB\uA4FE\uA4FF\uA60D-\uA60F\uA673\uA67E\uA6F2-\uA6F7\uA874-\uA877\uA8CE\uA8CF\uA8F8-\uA8FA\uA92E\uA92F\uA95F\uA9C1-\uA9CD\uA9DE\uA9DF\uAA5C-\uAA5F\uAADE\uAADF\uAAF0\uAAF1\uABEB\uFD3E\uFD3F\uFE10-\uFE19\uFE30-\uFE52\uFE54-\uFE61\uFE63\uFE68\uFE6A\uFE6B\uFF01-\uFF03\uFF05-\uFF0A\uFF0C-\uFF0F\uFF1A\uFF1B\uFF1F\uFF20\uFF3B-\uFF3D\uFF3F\uFF5B\uFF5D\uFF5F-\uFF65]+/giu;
export function containsPunctuation(str: null | string): boolean {
  if (!str) return false;
  return !!str.match(PUNCT_REGEX);
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.substr(1);
}

export function arrayShallowEquals<T>(arr1: Array<T>, arr2: Array<T>) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

// from https://stackoverflow.com/a/3959361
export function factorial(num: number) {
  let val = 1;
  for (let i = 2; i <= num; i++) {
    val = val * i;
  }
  return val;
}

// based on https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export function hashCode(str: string) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}
