import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { WordOrderAnswerDetails_answer } from './__generated__/WordOrderAnswerDetails_answer.graphql';
import { findPiecesSpanningText } from '../../lib/atomHelpers';
import './WordOrderAnswerDetails.css';
type Props = {
  answer: WordOrderAnswerDetails_answer;
};

const renderWordParts = (parts: readonly string[], isCorrect: boolean) => (
  <div
    className={classNames('WordOrderAnswerDetails-wordParts br1 ph2 pt2', {
      'is-correct': isCorrect,
      'is-incorrect': !isCorrect,
    })}
  >
    {parts.map((part, i) => (
      <div
        key={i}
        className={classNames('bg-white ph2 pv1 fl mb2', {
          mr2: i !== parts.length - 1,
        })}
      >
        {part}
      </div>
    ))}
    <div className="cb" />
  </div>
);

const WordOrderAnswerDetails = ({ answer }: Props) => {
  let correctAnswers: readonly string[] = [];
  let scrambledParts: readonly string[] = []; // need to do it this way to keep flow happy :/

  if (answer.exercise && answer.exercise.content) {
    correctAnswers = answer.exercise.content.correctAnswers;
    scrambledParts = answer.exercise.content.scrambledParts;
  }

  const correctAnswerPiecesOrder =
    findPiecesSpanningText(correctAnswers[0], scrambledParts.slice()) || [];
  const correctAnswerPieces = correctAnswerPiecesOrder.map(
    index => scrambledParts[index],
  );
  return (
    <div className="WordOrderAnswerDetails">
      <div className="gray">
        <FormattedMessage
          id="WordOrderAnswerDetails.exercise_type"
          defaultMessage="Word order"
        />
      </div>
      <div className="WordOrderAnswerDetails-responses">
        <div className="cb" />
        <div className="WordOrderAnswerDetails-response fl mr2 mt3">
          <div className="gray f7 mb1">
            <FormattedMessage
              id="WordOrderAnswerDetails.user_response"
              defaultMessage="Your response"
            />
          </div>
          {renderWordParts(answer.response || [], answer.isCorrect || false)}
        </div>
        {answer.isCorrect ? null : (
          <div className="WordOrderAnswerDetails-response fl mt3">
            <div className="gray f7 mb1">
              <FormattedMessage
                id="WordOrderAnswerDetails.correct_answer"
                defaultMessage="Correct answer"
              />
            </div>
            {renderWordParts(correctAnswerPieces || [], true)}
          </div>
        )}
        <div className="cb" />
      </div>
    </div>
  );
};

export default createFragmentContainer(WordOrderAnswerDetails, {
  answer: graphql`
    fragment WordOrderAnswerDetails_answer on WordOrderAnswer {
      isCorrect
      response
      exercise {
        ... on WordOrderExercise {
          content {
            correctAnswers
            scrambledParts
          }
        }
      }
    }
  `,
});
