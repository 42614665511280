import React, { Component } from 'react';
import { QueryRenderer, createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { WorksheetResultsTab_worksheet } from './__generated__/WorksheetResultsTab_worksheet.graphql';
import Loading from '../common/Loading';
import StudyRecord from '../common/StudyRecord';
import './WorksheetResultsTab.css';
import { exists } from '../../lib/utils';
import { WorksheetResultsTabQuery } from './__generated__/WorksheetResultsTabQuery.graphql';
import ClassroomsSelect from './ClassroomsSelect';
import FormGroup from '../common/FormGroup';

interface WorksheetResultsTabProps {
  worksheet: WorksheetResultsTab_worksheet;
  relay: RelayProp;
}
interface WorksheetResultsTabState {
  filterClassroomId: string | null;
}

const worksheetResultsTabQuery = graphql`
  query WorksheetResultsTabQuery($id: ID!, $classroomId: ID) {
    node(id: $id) {
      ... on Worksheet {
        id
        ...ClassroomsSelect_worksheet
        studyRecords(classroomId: $classroomId, first: 500) {
          edges {
            node {
              ...StudyRecord_studyRecord
              completedAt
            }
          }
        }
      }
    }
  }
`;

class WorksheetResultsTab extends Component<
  WorksheetResultsTabProps,
  WorksheetResultsTabState
> {
  state: WorksheetResultsTabState = {
    filterClassroomId: null,
  };

  render() {
    const { filterClassroomId } = this.state;
    return (
      <div className="WorksheetResultsTab">
        <QueryRenderer<WorksheetResultsTabQuery>
          environment={this.props.relay.environment}
          query={worksheetResultsTabQuery}
          variables={{
            id: this.props.worksheet.id,
            classroomId: filterClassroomId,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            }

            if (!props || !props.node || !props.node.studyRecords) {
              return <Loading />;
            }
            const completedStudyRecordEdges = (
              props.node.studyRecords.edges || []
            )
              .filter(exists)
              .filter(({ node }) => node && node.completedAt);

            if (completedStudyRecordEdges.length === 0) {
              return (
                <div className="pa5 tc gray ba b--dashed b--gray">
                  <FormattedMessage
                    id="WorksheetResultsTab.not_studied_message"
                    defaultMessage="This wordsheet has not been studied yet"
                  />
                </div>
              );
            }

            return (
              <div>
                <FormGroup
                  label={
                    <FormattedMessage
                      id="WorksheetResultsTab.analysis_by_class"
                      defaultMessage="Results by class"
                    />
                  }
                >
                  <div className="w-50-ns">
                    <ClassroomsSelect
                      value={filterClassroomId}
                      onChange={filterClassroomId =>
                        this.setState({ filterClassroomId })
                      }
                      worksheet={props.node}
                    />
                  </div>
                </FormGroup>
                {completedStudyRecordEdges.filter(exists).map(
                  ({ node }, i) =>
                    node && (
                      <div className="WorksheetResultsTab-result mt2" key={i}>
                        <StudyRecord
                          studyRecord={node}
                          showUser
                          showWorksheet={false}
                          showStudyButton={false}
                        />
                      </div>
                    ),
                )}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default createFragmentContainer(WorksheetResultsTab, {
  worksheet: graphql`
    fragment WorksheetResultsTab_worksheet on Worksheet {
      id
    }
  `,
});
