import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';
import { TranslationExerciseSummary_exercise } from './__generated__/TranslationExerciseSummary_exercise.graphql';
import ScrambledWordParts from './ScrambledWordParts';
import ExerciseSummaryContainer from './ExerciseSummaryContainer';
import CorrectAnswers from './CorrectAnswers';
type Props = {
  exercise: TranslationExerciseSummary_exercise;
  alwaysShowAnswers: boolean;
  scrambleWords: boolean;
  intl: IntlShape;
};
const messages = defineMessages({
  typeLabel: {
    id: 'TranslationExerciseSummary.type_label',
    defaultMessage: 'Translation exercise',
  },
});

const TranslationExerciseSummary = ({
  exercise,
  alwaysShowAnswers,
  scrambleWords,
  intl,
}: Props) => (
  <div className="TranslationExerciseSummary">
    <ExerciseSummaryContainer
      typeLabel={intl.formatMessage(messages.typeLabel)}
    >
      <div className="mb2">{exercise.content.prompt}</div>
      <ScrambledWordParts
        scrambleWords={scrambleWords}
        wordParts={exercise.content.scrambledParts}
      />
      <CorrectAnswers
        alwaysShowAnswers={alwaysShowAnswers}
        correctAnswers={exercise.content.correctAnswers}
      />
    </ExerciseSummaryContainer>
  </div>
); // for testing raw component

export { TranslationExerciseSummary };
export default injectIntl(
  createFragmentContainer(TranslationExerciseSummary, {
    exercise: graphql`
      fragment TranslationExerciseSummary_exercise on TranslationExercise {
        content {
          prompt
          correctAnswers
          scrambledParts
        }
      }
    `,
  }),
);
