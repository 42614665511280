import React from 'react';
import { FormattedMessage } from 'react-intl';
import './RtlLanguagesGroupingNote.css';

const RtlLanguagesGroupingNote = () => (
  <div className="RtlLanguagesGroupingNote pa2 gray lh-copy">
    <span className="red dib b mr1">
      <FormattedMessage
        id="RtlLanguagesGroupingNote.label"
        defaultMessage="A note on right-to-left languages:"
      />
    </span>
    <FormattedMessage
      id="RtlLanguagesGroupingNote.message"
      defaultMessage="Currently only Chrome and Safari are able to work with languages like Hebrew and Arabic in Wordsheet. As a result, it is not recommended to use Wordsheet with right-to-left languages. If you want to continue anyway, make sure to only use Chrome or Safari when creating and studying this wordsheet."
    />
  </div>
);

export default RtlLanguagesGroupingNote;
