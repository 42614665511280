import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';

import WordOrderPositioner from '../common/WordOrderPositioner';
import WordPart from '../common/WordPart';
import Modal from '../common/Modal';
import Button from '../common/Button';
import { shuffleArray } from '../../lib/utils';
import { findPiecesSpanningText } from '../../lib/atomHelpers';
import './EditExtraCorrectAnswerModal.css';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  correctAnswerText: string;
  otherCorrectAnswers: string[];
  answerPieces: string[];
  onChange: (answer: string) => void;
  intl: IntlShape;
};

type State = {
  correctAnswerText: null | string;
  chosenItems: number[];
};

const messages = defineMessages({
  addCorrectAnswerHeader: {
    id: 'WordOrderEditor.EditExtraCorrectAnswerModal.addCorrectAnswerHeader',
    defaultMessage: 'Add alternate correct answer',
  },
});

const getChosenText = (chosenItems: number[], props: Props) => {
  return chosenItems
    .map(index => props.answerPieces[index])
    .join('')
    .trim();
};

const isAnswerAlreadyChosen = (chosenItems: number[], props: Props) => {
  if (chosenItems.length === 0) return false;
  return (
    props.otherCorrectAnswers.indexOf(getChosenText(chosenItems, props)) !== -1
  );
};

class EditExtraCorrectAnswerModal extends Component<Props, State> {
  state: State = {
    correctAnswerText: null,
    chosenItems: [],
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      prevState.correctAnswerText !== nextProps.correctAnswerText &&
      nextProps.isOpen
    ) {
      let chosenItems =
        findPiecesSpanningText(
          nextProps.correctAnswerText,
          nextProps.answerPieces,
        ) || [];
      if (chosenItems && chosenItems.length > 0) {
        while (isAnswerAlreadyChosen(chosenItems, nextProps)) {
          chosenItems = shuffleArray(chosenItems);
        }
      }
      return {
        chosenItems,
        correctAnswerText: nextProps.correctAnswerText,
      };
    }
    return null;
  }

  onDone = () => {
    if (this.isValid()) {
      this.setState({ correctAnswerText: null });
      this.props.onChange(getChosenText(this.state.chosenItems, this.props));
    }
  };

  onCancel = () => {
    this.setState({ correctAnswerText: null });
    this.props.onCancel();
  };

  isValid = () => {
    if (this.state.chosenItems.length === 0) return false;
    return !isAnswerAlreadyChosen(this.state.chosenItems, this.props);
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="EditExtraCorrectAnswerModal">
        <Modal
          header={formatMessage(messages.addCorrectAnswerHeader)}
          isOpen={this.props.isOpen}
          onClose={this.props.onCancel}
          footer={
            <div className="EditExtraCorrectAnswerModal-footer">
              <div className="EditExtraCorrectAnswerModal-footerButton">
                <Button
                  type="success"
                  disabled={!this.isValid()}
                  onClick={this.onDone}
                >
                  <FormattedMessage
                    id="WordOrderEditor.EditExtraCorrectAnswerModal.done_button"
                    defaultMessage="Done"
                  />
                </Button>
              </div>
              <div className="EditExtraCorrectAnswerModal-footerButton">
                <Button type="default" onClick={this.onCancel}>
                  <FormattedMessage
                    id="WordOrderEditor.EditExtraCorrectAnswerModal.cancel_button"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <WordOrderPositioner
            wordPositions={this.state.chosenItems}
            onChangeWordPositions={chosenItems =>
              this.setState({ chosenItems })
            }
            container={minHeight => (
              <div
                className="EditExtraCorrectAnswerModal-chosenItems"
                style={{ height: minHeight }}
              />
            )}
            words={this.props.answerPieces.map(text => (
              <WordPart text={text} />
            ))}
          />
          <p className="EditExtraCorrectAnswerModal-hint">
            <FormattedMessage
              id="WordOrderEditor.EditExtraCorrectAnswerModal.word_order_hint"
              defaultMessage="Drag the words above to assemble an alterative correct answer to this exercise"
            />
          </p>
          <p
            className={classNames('EditExtraCorrectAnswerModal-failureReason', {
              'is-hidden': !isAnswerAlreadyChosen(
                this.state.chosenItems,
                this.props,
              ),
            })}
          >
            <FormattedMessage
              id="WordOrderEditor.EditExtraCorrectAnswerModal.already_added_error"
              defaultMessage="You already added this answer."
            />
          </p>
        </Modal>
      </div>
    );
  }
}

export default injectIntl(EditExtraCorrectAnswerModal);
