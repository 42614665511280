import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  UpdateUserMutation,
  UpdateUserInput,
} from './__generated__/UpdateUserMutation.graphql';
const mutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        accountType
      }
    }
  }
`;

const commit = (environment: any, input: UpdateUserInput) =>
  commitMutationPromise<UpdateUserMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default {
  commit,
};
