import React, { FC } from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import './DropdownLink.css';

type DropdownLinkProps = {
  children: ReactNode;
  component?: any;
  dividerTop?: boolean;
  color?: 'default' | 'red';
  [key: string]: any;
};

const DropdownLink: FC<DropdownLinkProps> = ({
  children,
  component,
  dividerTop,
  color,
  ...otherProps
}) => {
  const ComponentClass = component || 'a';
  return (
    <ComponentClass
      className={classNames('DropdownLink f6 fw4 link db pa3', {
        'bt b--black-10': dividerTop,
        red: color === 'red',
      })}
      {...otherProps}
    >
      {children}
    </ComponentClass>
  );
};

export default DropdownLink;
