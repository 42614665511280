import * as Sentry from '@sentry/browser';
import { hashCode } from './utils';
const TOKEN_NAME = 'AUTH_TOKEN';
const GUEST_IDENTIFIER = 'GUEST_IDENTIFIER';

class Auth {
  setToken(token: string) {
    localStorage.setItem(TOKEN_NAME, token);
  }

  clearToken() {
    localStorage.removeItem(TOKEN_NAME);
    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
  }

  getToken() {
    return localStorage.getItem(TOKEN_NAME);
  }

  getGuestIdentifier() {
    let guestId = localStorage.getItem(GUEST_IDENTIFIER);

    if (!guestId) {
      guestId = hashCode(
        Math.random().toString() + new Date().getTime().toString(),
      ).toString();
      localStorage.setItem(GUEST_IDENTIFIER, guestId);
    }

    return guestId;
  }

  getAuthHeaders() {
    const token = this.getToken();
    const guestIdentifier = this.getGuestIdentifier(); // intentionally lowercase, because headers can get downcased automatically

    const headers: {
      [x: string]: string;
    } = {
      identifier: guestIdentifier,
    };
    if (!token) return headers;
    headers['Authorization'] = `Bearer ${token}`;
    return headers;
  }
}

export default new Auth();
