import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../../environment';
import NavHeader from '../../components/NavHeader';
import AdminHeader from '../../components/AdminHeader';
import PageLoading from '../../components/common/PageLoading';
import AdminClassroomsList from '../../components/admin/AdminClassroomsList';
import { AdminClassroomsPageQuery } from './__generated__/AdminClassroomsPageQuery.graphql';

const adminUsersPageQuery = graphql`
  query AdminClassroomsPageQuery {
    viewer {
      ...NavHeader_viewer
      user {
        isAdmin
      }
    }
    admin {
      ...AdminClassroomsList_admin
    }
  }
`;

const AdminClassroomsPage = () => (
  <QueryRenderer<AdminClassroomsPageQuery>
    environment={environment}
    variables={{}}
    query={adminUsersPageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props) return <PageLoading />;

      if (!props.viewer || !props.viewer.user || !props.viewer.user.isAdmin) {
        return <Redirect to="/" />;
      }

      return (
        <div className="AdminClassroomsPage">
          <NavHeader viewer={props.viewer} />
          <div className="mw8 center">
            <AdminHeader activeTab="classrooms" />
            <AdminClassroomsList
              admin={
                props.admin as NonNullable<
                  AdminClassroomsPageQuery['response']['admin']
                >
              }
            />
          </div>
        </div>
      );
    }}
  />
);

export default AdminClassroomsPage;
