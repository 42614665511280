import React, { Component, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { AdminUser_user } from './__generated__/AdminUser_user.graphql';
import { exists } from '../../lib/utils';
import TeacherBadge from '../common/TeacherBadge';
import Button from '../common/Button';
import Textarea from '../common/Textarea';
import AdminUpgradeUserMutation from '../../mutations/AdminUpgradeUserMutation';
import AccountTypeSelect, { ACCOUNT_TYPE } from '../common/AccountTypeSelect';
import UpdateUserMutation from '../../mutations/UpdateUserMutation';
import Loading from '../common/Loading';
import AdminDeleteUserMutation from '../../mutations/AdminDeleteUserMutation';

interface AdminUserProps {
  user: AdminUser_user;
  relay: RelayProp;
}

interface AdminUserState {
  isUpgradePanelOpen: boolean;
  isUpgradingUser: boolean;
  isDeletingUser: boolean;
  isAccountTypeDropdownOpen: boolean;
  isUpdatingUser: boolean;
  subscriptionNote: string;
}

class AdminUser extends Component<AdminUserProps, AdminUserState> {
  state: AdminUserState = {
    isUpgradePanelOpen: false,
    isUpgradingUser: false,
    isDeletingUser: false,
    isAccountTypeDropdownOpen: false,
    isUpdatingUser: false,
    subscriptionNote: '',
  };

  onUpgradeUser = async (evt: FormEvent) => {
    evt.preventDefault();
    if (this.state.isUpgradingUser) return;
    this.setState({ isUpgradingUser: true });
    const { subscriptionNote } = this.state;
    await AdminUpgradeUserMutation.commit(
      this.props.relay.environment,
      this.props.user.id,
      subscriptionNote,
    );
    this.setState({ isUpgradingUser: false, isUpgradePanelOpen: false });
  };

  onDeleteUser = async (evt: FormEvent) => {
    evt.preventDefault();
    if (this.state.isDeletingUser) return;
    this.setState({ isDeletingUser: true });
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure you want to delete this user?')) return;
    try {
      await AdminDeleteUserMutation.commit(
        this.props.relay.environment,
        this.props.user.id,
      );
    } catch (err) {
      // not elegant, but it's an admin panel so meh
      alert(`Error: ${err.message}`);
    }
    this.setState({ isDeletingUser: false });
  };

  onUpdateUserAccountType = async (accountType: ACCOUNT_TYPE | null) => {
    if (this.state.isUpdatingUser) return;
    this.setState({ isUpdatingUser: true });
    await UpdateUserMutation.commit(this.props.relay.environment, {
      id: this.props.user.id,
      accountType,
    });
    this.setState({ isUpdatingUser: false, isAccountTypeDropdownOpen: false });
  };

  render() {
    const { user } = this.props;
    const { isAccountTypeDropdownOpen, isUpdatingUser } = this.state;
    return (
      <div className="AdminUser bg-black-10 pa2 mt2 br1">
        <div>
          <div className="flex items-center">
            <span className="gray f6 dib mr2">username:</span>
            <span className="mr2">
              <Link to={`/profile/${user.username}`}>{user.username}</Link>
            </span>
            {user.isUpgradedTeacher && <TeacherBadge size="small" />}
          </div>
        </div>
        <div>
          <span className="gray f6 dib mr2">email:</span>
          {user.email}
        </div>
        <div>
          <span className="gray f6 dib mr2">auth:</span>
          {!user.hasPassword ? null : <i className="fas fa-lock gray mr1" />}
          {!user.hasGoogle ? null : <i className="fab fa-google gray mr1" />}
        </div>
        <div>
          <span className="gray f6 dib mr2">sign up time:</span>
          {new Date(user.createdAt * 1000).toLocaleString()}
        </div>

        <div>
          <span className="gray f6 dib mr2">accountType:</span>{' '}
          {isUpdatingUser && <Loading />}
          {!isUpdatingUser &&
            isAccountTypeDropdownOpen &&
            user.accountType !== '%future added value' && (
              <div className="dib">
                <AccountTypeSelect
                  value={user.accountType}
                  includeEmpty
                  onChange={this.onUpdateUserAccountType}
                />
              </div>
            )}
          {!isAccountTypeDropdownOpen && (
            <>
              {user.accountType || 'None'}{' '}
              <a
                href="#editAccountType"
                className="f7 dib mt2"
                onClick={evt => {
                  evt.preventDefault();
                  this.setState({ isAccountTypeDropdownOpen: true });
                }}
              >
                edit
              </a>
            </>
          )}
        </div>
        {user.subscriptionStatus?.subscriptionType && (
          <div>
            <span className="gray f6 dib mr2">subscription:</span>
            {user.subscriptionStatus.subscriptionType}
          </div>
        )}
        {user.subscriptionStatus?.subscriptionNote && (
          <div>
            <span className="gray f6 dib mr2">subscription note:</span>
            {user.subscriptionStatus.subscriptionNote}
          </div>
        )}
        {!user.worksheets.edges || user.worksheets.edges.length === 0 ? null : (
          <div className="f6 mt2">
            Some sheets by this user:
            <div className="ph2">
              {user.worksheets.edges.filter(exists).map(worksheetEdge => {
                const worksheetNode = worksheetEdge.node;
                return (
                  worksheetNode && (
                    <div key={worksheetNode.id}>
                      {worksheetNode.identifier ? (
                        <Link to={`/view/${worksheetNode.identifier}`}>
                          {worksheetNode.title}
                        </Link>
                      ) : (
                        <span className="gray">{worksheetNode.title}</span>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
        {!user.isUpgradedTeacher && !this.state.isUpgradePanelOpen && (
          <a
            href="#upgrade"
            className="f7 dib mt2"
            onClick={evt => {
              evt.preventDefault();
              this.setState({ isUpgradePanelOpen: true });
            }}
          >
            Upgrade
          </a>
        )}
        <a
          href="#delete"
          className="f7 dib mt2 ml2 red"
          onClick={this.onDeleteUser}
        >
          Delete
        </a>
        {this.state.isUpgradePanelOpen && (
          <form className="pt3" onSubmit={this.onUpgradeUser}>
            <div>
              <Textarea
                placeholder="Admin upgrade note"
                value={this.state.subscriptionNote}
                onChange={evt =>
                  this.setState({ subscriptionNote: evt.target.value })
                }
              />
            </div>
            <Button
              type="submit"
              size="small"
              loading={this.state.isUpgradingUser}
            >
              Give Forever Teacher
            </Button>
          </form>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(AdminUser, {
  user: graphql`
    fragment AdminUser_user on User {
      id
      createdAt
      username
      email
      isAdmin
      hasGoogle
      hasPassword
      isUpgradedTeacher
      accountType
      subscriptionStatus {
        subscriptionType
        subscriptionNote
      }
      worksheets {
        edges {
          node {
            id
            title
            identifier
          }
        }
      }
    }
  `,
});
