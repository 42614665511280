import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { PublishWorksheetMutation } from './__generated__/PublishWorksheetMutation.graphql';
const mutation = graphql`
  mutation PublishWorksheetMutation($input: PublishWorksheetInput!) {
    publishWorksheet(input: $input) {
      worksheet {
        id
        title
        description
        identifier
        isPublished
      }
    }
  }
`;

const commit = (environment: any, id: string) =>
  commitMutationPromise<PublishWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
      },
    },
  });

export default {
  commit,
};
