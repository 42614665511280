/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardFeed_viewer = {
    readonly activityFeed: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly __typename: string;
                readonly completedAt?: number | null;
                readonly " $fragmentRefs": FragmentRefs<"FeedItem_activityFeedItem">;
            } | null;
        } | null> | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"FeedItem_viewer">;
    readonly " $refType": "DashboardFeed_viewer";
};
export type DashboardFeed_viewer$data = DashboardFeed_viewer;
export type DashboardFeed_viewer$key = {
    readonly " $data"?: DashboardFeed_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"DashboardFeed_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DashboardFeed_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "activityFeed"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 25
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "activityFeed",
      "name": "__DashboardFeed_activityFeed_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "ActivityFeedItemConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ActivityFeedItemEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": null,
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "type": "StudyRecord",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "completedAt",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "FeedItem_activityFeedItem",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "FeedItem_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'e76a5a2df806a6c1aa0d38913298b1c7';
export default node;
