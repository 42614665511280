import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayPaginationProp } from 'react-relay';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import Button from '../common/Button';
import { AdminWorksheetsList_admin } from './__generated__/AdminWorksheetsList_admin.graphql';
import Loading from '../common/Loading';
import { exists } from '../../lib/utils';

interface AdminWorksheetsListProps {
  admin: AdminWorksheetsList_admin;
  relay: RelayPaginationProp;
  publishedOnly: boolean;
}

const AdminWorksheetsList = ({ admin, relay }: AdminWorksheetsListProps) => (
  <div className="AdminWorksheetsList pt3 tl">
    <InfiniteScroll
      pageStart={1}
      loadMore={relay.loadMore}
      hasMore={relay.hasMore()}
      initialLoad={false}
      loader={<Loading />}
    >
      {admin &&
      admin.recentWorksheets.edges &&
      admin.recentWorksheets.edges.length > 0 ? null : (
        <div className="pa5 tc gray ba b--dashed b--gray" key="none-found">
          <FormattedMessage
            id="AdminWorksheetsList.no_content_message"
            defaultMessage="No worksheets found"
          />
        </div>
      )}
      {admin &&
        admin.recentWorksheets.edges &&
        admin.recentWorksheets.edges.filter(exists).map(
          ({ node }) =>
            node && (
              <div
                className="AdminWorksheetsList-worksheet bg-black-10 pa2 mt2 br1"
                key={node.id}
              >
                {!node.identifier ? null : (
                  <div className="fr">
                    <Button
                      component={Link}
                      to={`/view/${node.identifier || ''}`}
                    >
                      View sheet
                    </Button>
                  </div>
                )}
                <div>
                  <span className="gray f6 dib mr2">title:</span>
                  {node.title}
                </div>
                <div>
                  <span className="gray f6 dib mr2">creator:</span>
                  {node.creator ? (
                    <Link to={`/profile/${node.creator.username}`}>
                      {node.creator.username}
                    </Link>
                  ) : (
                    <span className="gray">Anonymous</span>
                  )}
                </div>
                <div>
                  <span className="gray f6 dib mr2">visibility:</span>
                  {node.visibility}
                </div>
                <div>
                  <span className="gray f6 dib mr2">Created at:</span>
                  {new Date(node.createdAt * 1000).toLocaleString()}
                </div>
                <div className="mt1 f6">
                  {node.isPublished ? (
                    <div>
                      <i className="fas fa-check" /> Published
                    </div>
                  ) : (
                    <div className="gray">
                      <i className="fas fa-times" /> Not published
                    </div>
                  )}
                </div>
                <pre className="f7 bg-black-10 pa2">
                  {JSON.stringify(node.exercises, null, 2)}
                </pre>
              </div>
            ),
        )}
    </InfiniteScroll>
  </div>
);

export default createPaginationContainer(
  AdminWorksheetsList,
  {
    admin: graphql`
      fragment AdminWorksheetsList_admin on Admin
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 50 }
          cursor: { type: "String" }
          publishedOnly: { type: "Boolean" }
        ) {
        recentWorksheets(
          publishedOnly: $publishedOnly
          first: $count
          after: $cursor
        ) @connection(key: "AdminWorksheetsList_recentWorksheets") {
          edges {
            node {
              id
              createdAt
              identifier
              title
              isPublished
              visibility
              creator {
                username
              }
              exercises {
                __typename
                ... on VocabExercise {
                  content {
                    terms {
                      word
                      definition
                      image {
                        id
                      }
                    }
                  }
                }
                ... on WordOrderExercise {
                  content {
                    correctAnswers
                    scrambledParts
                  }
                }
                ... on TranslationExercise {
                  content {
                    prompt
                    correctAnswers
                    scrambledParts
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: props =>
      props.admin && props.admin.recentWorksheets,
    getVariables: (props, { count, cursor }) => ({
      count,
      cursor,
      publishedOnly: props.publishedOnly,
    }),
    query: graphql`
      query AdminWorksheetsListQuery(
        $publishedOnly: Boolean
        $count: Int!
        $cursor: String
      ) {
        admin: admin {
          ...AdminWorksheetsList_admin
            @arguments(
              count: $count
              cursor: $cursor
              publishedOnly: $publishedOnly
            )
        }
      }
    `,
  },
);
