import React from 'react';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import WorksheetViewer from '../components/WorksheetViewer';
import PageLoading from '../components/common/PageLoading';
import NotFound from '../components/common/NotFound';
import { ViewWorksheetPage_worksheetQuery } from './__generated__/ViewWorksheetPage_worksheetQuery.graphql';
import WorksheetAuth from '../components/auth/WorksheetAuth';

const viewWorksheetPage_worksheetQuery = graphql`
  query ViewWorksheetPage_worksheetQuery($identifier: String!) {
    viewer {
      ...NavHeader_viewer
      ...WorksheetAuth_viewer
      ...WorksheetViewer_viewer
    }
    worksheetByIdentifier(identifier: $identifier) {
      ...WorksheetViewer_worksheet
      ...WorksheetAuth_worksheet
      isVisibleToViewer
    }
  }
`;
type Props = {
  match: match<{ identifier: string }>;
};

const ViewWorksheetPage = ({ match }: Props) => (
  <QueryRenderer<ViewWorksheetPage_worksheetQuery>
    environment={environment}
    query={viewWorksheetPage_worksheetQuery}
    variables={{
      identifier: match.params.identifier,
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props || !props.viewer) return <PageLoading />;
      const header = <NavHeader viewer={props.viewer} />;
      if (!props.worksheetByIdentifier) return <NotFound header={header} />;
      if (!props.worksheetByIdentifier.isVisibleToViewer) {
        return (
          <div className="ViewWorksheetPage">
            {header}
            <div className="pt5">
              <WorksheetAuth
                worksheet={props.worksheetByIdentifier}
                viewer={props.viewer}
              />
            </div>
          </div>
        );
      }

      return (
        <div className="ViewWorksheetPage">
          {header}
          <div className="mw8 center">
            <WorksheetViewer
              worksheet={props.worksheetByIdentifier}
              viewer={props.viewer}
            />
          </div>
        </div>
      );
    }}
  />
);

export default ViewWorksheetPage;
