/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WordOrderExerciseAnalysis_exercise = {
    readonly content: {
        readonly correctAnswers: ReadonlyArray<string>;
        readonly scrambledParts: ReadonlyArray<string>;
    };
    readonly " $refType": "WordOrderExerciseAnalysis_exercise";
};
export type WordOrderExerciseAnalysis_exercise$data = WordOrderExerciseAnalysis_exercise;
export type WordOrderExerciseAnalysis_exercise$key = {
    readonly " $data"?: WordOrderExerciseAnalysis_exercise$data;
    readonly " $fragmentRefs": FragmentRefs<"WordOrderExerciseAnalysis_exercise">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "WordOrderExerciseAnalysis_exercise",
  "type": "WordOrderExercise",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "content",
      "storageKey": null,
      "args": null,
      "concreteType": "WordOrderExerciseContent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "correctAnswers",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "scrambledParts",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '3ca85ef44c0d9aa55b324456d920b1c3';
export default node;
