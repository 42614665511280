import React, { ReactElement, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  header?: ReactElement;
}

const NotFound: FunctionComponent<Props> = ({ header }) => (
  <div key={0}>
    {header}
    <div className="pa3 pt4 tc">
      <FormattedMessage id="NotFound.text" defaultMessage="Not Found :(" />
    </div>
  </div>
);

export default NotFound;
