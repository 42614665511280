import React, { Component } from 'react';
import { createFragmentContainer, RelayProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';
import { ClassroomViewer_viewer } from './__generated__/ClassroomViewer_viewer.graphql';
import { ClassroomViewer_classroom } from './__generated__/ClassroomViewer_classroom.graphql';
import PendingMemberships from './PendingMemberships';
import Tabs, { Tab } from '../common/Tabs';
import WorksheetsTab from './WorksheetsTab';
import MembersTab from './MembersTab';
import ClassroomActions from './ClassroomActions';
import AutofocusInput from '../common/AutofocusInput';
import LabelText from '../common/LabelText';
import { externalJoinLink } from '../../lib/utils';
import JoinClassroomModal from './JoinClassroomModal';
import AddWorksheetModal from './AddWorksheetModal';
import EditClassroomModal from '../EditClassroomModal';
import Button from '../common/Button';
import SignUpModal from '../auth/SignUpModal';

interface ClassroomViewerProps extends RouteComponentProps {
  viewer: ClassroomViewer_viewer;
  classroom: ClassroomViewer_classroom;
  showJoinModal: boolean;
  relay: RelayProp;
  history: History;
}

interface ClassroomViewerState {
  tab: 'worksheets' | 'members';
  isShowingJoinModal: boolean;
  isShowingAddWorksheetModal: boolean;
  isShowingEditModal: boolean;
  prevShowJoinModal?: boolean;
}

const getCurrentMembership = ({ classroom, viewer }: ClassroomViewerProps) => {
  return (viewer.user?.classroomMemberships || []).find(
    membership => membership?.classroom.id === classroom.id,
  );
};

class ClassroomViewer extends Component<
  ClassroomViewerProps,
  ClassroomViewerState
> {
  state: ClassroomViewerState = {
    tab: 'worksheets',
    isShowingJoinModal: false,
    isShowingAddWorksheetModal: false,
    isShowingEditModal: false,
  };

  static getDerivedStateFromProps(
    nextProps: ClassroomViewerProps,
    state: ClassroomViewerState,
  ) {
    if (
      state.prevShowJoinModal === undefined &&
      nextProps.viewer.user &&
      !getCurrentMembership(nextProps)
    ) {
      return {
        isShowingJoinModal: nextProps.showJoinModal,
        prevShowJoinModal: nextProps.showJoinModal,
      };
    }
    return null;
  }

  getCurrentMembership() {
    return getCurrentMembership(this.props);
  }

  canEdit() {
    const { viewer } = this.props;
    const membership = this.getCurrentMembership();
    return (
      ['ADMIN', 'CREATOR'].includes(membership?.level || '') ||
      viewer?.user?.isAdmin ||
      false
    );
  }

  renderInfo() {
    const { classroom } = this.props;
    return (
      <div className="f7 gray mb4">
        {classroom.requiresApproval && (
          <>
            <i className="fas fa-lock mr2" />
            <span className="dn di-ns">
              <FormattedMessage
                id="ClassroomViewer.requires_approval"
                defaultMessage="Requires approval to join"
              />
            </span>
            <span className="dn-ns">
              <FormattedMessage
                id="ClassroomViewer.requires_approval_shorter"
                defaultMessage="Requires approval"
              />
            </span>
            <span className="mh2 black-10">|</span>
          </>
        )}
        <i className="fas fa-users mr2" />
        <FormattedMessage
          id="ClassroomViewer.num_members"
          defaultMessage="{numMembers, plural,
            one {# member}
            other {# members}
          }"
          values={{ numMembers: classroom.numActiveMemberships }}
        />
        <span className="mh2 black-10">|</span>
        <i className="fas fa-file mr2" />
        <FormattedMessage
          id="ClassroomViewer.num_wordsheets"
          defaultMessage="{numWordsheets, plural,
              one {# wordsheet}
              other {# wordsheets}
          }"
          values={{ numWordsheets: classroom.numClassroomWorksheets }}
        />
      </div>
    );
  }

  render() {
    const { classroom, viewer, relay, history } = this.props;
    const {
      tab,
      isShowingJoinModal,
      isShowingAddWorksheetModal,
      isShowingEditModal,
    } = this.state;

    const isShowingSignUpModal = viewer && !viewer.user?.id;
    const membership = this.getCurrentMembership();
    const isPending = membership?.level === 'PENDING';
    const isBanned = membership?.level === 'BANNED';
    const canEdit = this.canEdit();
    return (
      <div className="pa3 tl" data-testid="ClassroomViewer">
        <div className="fr mt2">
          <ClassroomActions
            classroom={classroom}
            viewer={viewer}
            canEdit={canEdit}
            onClickAddWorksheet={() =>
              this.setState({ isShowingAddWorksheetModal: true })
            }
            onClickEdit={() => this.setState({ isShowingEditModal: true })}
            onClickJoin={() => this.setState({ isShowingJoinModal: true })}
          />
        </div>
        <h1 className="f4 f3-m f2-l mt3 mb3">{classroom.title}</h1>
        {this.renderInfo()}
        {classroom.description && (
          <p className="lh-copy mv4 gray">{classroom.description}</p>
        )}
        {isPending && (
          <div className="mb4 f4 b bg-blue white dib pa2 br2">
            <i className="fas fa-clock mr2" />
            <FormattedMessage
              id="ClassroomViewer.membership_pending"
              defaultMessage="Your request to join is awaiting approval by an admin"
            />
          </div>
        )}
        {isBanned && (
          <div className="mb4 f4 b bg-red white dib pa2 br2">
            <i className="fas fa-ban mr2" />
            <FormattedMessage
              id="ClassroomViewer.you_have_been_banned"
              defaultMessage="You have been banned from this class"
            />
          </div>
        )}
        {canEdit && (
          <>
            <div className="mb4">
              <LabelText>
                <FormattedMessage
                  id="ClassroomViewer.join_link_label"
                  defaultMessage="Join class link"
                  description="Label for the shareable link to this classroom"
                />
              </LabelText>
              <AutofocusInput
                value={externalJoinLink(classroom.identifier)}
                className="f6 pa2 db w-100 w-40-l border-box"
              />
              <div className="dn-l mt3">
                <Button
                  buttonType="default"
                  id="addWorksheetsToClassroomButton"
                  size="medium"
                  fullWidth
                  onClick={() =>
                    this.setState({ isShowingAddWorksheetModal: true })
                  }
                >
                  <i className="fas fa-plus mr2" />
                  <FormattedMessage
                    id="ClassroomViewer.add_wordsheet"
                    defaultMessage="Add a wordsheet"
                  />
                </Button>
              </div>
            </div>
          </>
        )}
        {!membership && (
          <div className="dn-l mb4">
            <Button
              size="medium"
              fullWidth
              onClick={() => this.setState({ isShowingJoinModal: true })}
            >
              <i className="fa fa-plus mr2" />
              <FormattedMessage
                id="ClassroomViewer.join_button"
                defaultMessage="Join class"
              />
            </Button>
          </div>
        )}

        {canEdit && <PendingMemberships classroom={classroom} />}
        {membership && !isBanned && !isPending && (
          <>
            <Tabs>
              <Tab
                data-testid="ClassroomViewer-worksheetsTab"
                isActive={tab === 'worksheets'}
                onClick={() => this.setState({ tab: 'worksheets' })}
              >
                <i className="fas fa-file mr2" />
                <FormattedMessage
                  id="ClassroomViewer.wordsheets_tab"
                  defaultMessage="Wordsheets ({numWordsheets})"
                  values={{ numWordsheets: classroom.numClassroomWorksheets }}
                />
              </Tab>
              <Tab
                data-testid="ClassroomViewer-membersTab"
                isActive={tab === 'members'}
                onClick={() => this.setState({ tab: 'members' })}
              >
                <i className="fas fa-users mr2" />
                <FormattedMessage
                  id="ClassroomViewer.members_tab"
                  defaultMessage="Members ({numMembers})"
                  values={{ numMembers: classroom.numActiveMemberships }}
                />
              </Tab>
            </Tabs>
            {tab === 'worksheets' && (
              <WorksheetsTab classroom={classroom} canEdit={canEdit} />
            )}
            {tab === 'members' && (
              <MembersTab classroom={classroom} canEdit={canEdit} />
            )}
          </>
        )}
        <JoinClassroomModal
          classroom={classroom}
          isOpen={isShowingJoinModal}
          onClose={() => this.setState({ isShowingJoinModal: false })}
        />
        {canEdit && (
          <AddWorksheetModal
            isOpen={isShowingAddWorksheetModal}
            onClose={() => this.setState({ isShowingAddWorksheetModal: false })}
            classroom={classroom}
          />
        )}
        {canEdit && (
          <EditClassroomModal
            isOpen={isShowingEditModal}
            onClose={() => this.setState({ isShowingEditModal: false })}
            classroom={classroom}
          />
        )}
        <SignUpModal
          isOpen={isShowingSignUpModal}
          environment={relay.environment}
          modalHeader={classroom.title}
          closeButton={true}
          closeOnTapBackdrop={false}
          onClose={() => history.push('/')}
          signUpReason={
            <FormattedMessage
              id="ClassroomViewer.sign_up_reason"
              defaultMessage="Sign up to view this class"
            />
          }
          logInReason={
            <FormattedMessage
              id="ClassroomViewer.log_in_reason"
              defaultMessage="Log in to view this class"
            />
          }
        />
      </div>
    );
  }
}

export default withRouter(
  createFragmentContainer(ClassroomViewer, {
    viewer: graphql`
      fragment ClassroomViewer_viewer on Viewer {
        ...ClassroomActions_viewer
        user {
          id
          isAdmin
          classroomMemberships {
            level
            classroom {
              id
            }
          }
        }
      }
    `,
    classroom: graphql`
      fragment ClassroomViewer_classroom on Classroom {
        id
        identifier
        title
        description
        numActiveMemberships
        numClassroomWorksheets
        requiresApproval
        ...ClassroomActions_classroom
        ...PendingMemberships_classroom
        ...WorksheetsTab_classroom
        ...MembersTab_classroom
        ...JoinClassroomModal_classroom
        ...AddWorksheetModal_classroom
        ...EditClassroomModal_classroom
      }
    `,
  }),
);
