/* tslint:disable */
/* eslint-disable */
/* @relayHash f1174f8dee4e007b2bba7003d5964647 */

import { ConcreteRequest } from "relay-runtime";
export type CreateVocabExerciseInput = {
    worksheetId: string;
    content: VocabExerciseContentInput;
    position: number;
    clientMutationId?: string | null;
};
export type VocabExerciseContentInput = {
    terms: Array<VocabExerciseTermInput | null>;
};
export type VocabExerciseTermInput = {
    word: string;
    definition?: string | null;
    imageId?: string | null;
};
export type CreateVocabExerciseMutationVariables = {
    input: CreateVocabExerciseInput;
};
export type CreateVocabExerciseMutationResponse = {
    readonly createVocabExercise: {
        readonly exercise: {
            readonly id: string;
            readonly content: {
                readonly terms: ReadonlyArray<{
                    readonly word: string;
                    readonly definition: string | null;
                    readonly image: {
                        readonly id: string;
                        readonly url: string;
                    } | null;
                }>;
            };
            readonly worksheet: {
                readonly id: string;
            };
        } | null;
    } | null;
};
export type CreateVocabExerciseMutation = {
    readonly response: CreateVocabExerciseMutationResponse;
    readonly variables: CreateVocabExerciseMutationVariables;
};



/*
mutation CreateVocabExerciseMutation(
  $input: CreateVocabExerciseInput!
) {
  createVocabExercise(input: $input) {
    exercise {
      id
      content {
        terms {
          word
          definition
          image {
            id
            url(size: SMALL_SQUARE)
          }
        }
      }
      worksheet {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateVocabExerciseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createVocabExercise",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateVocabExercisePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "exercise",
        "storageKey": null,
        "args": null,
        "concreteType": "VocabExercise",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "content",
            "storageKey": null,
            "args": null,
            "concreteType": "VocabExerciseContent",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "terms",
                "storageKey": null,
                "args": null,
                "concreteType": "VocabExerciseTerm",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "word",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "definition",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "size",
                            "value": "SMALL_SQUARE"
                          }
                        ],
                        "storageKey": "url(size:\"SMALL_SQUARE\")"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "worksheet",
            "storageKey": null,
            "args": null,
            "concreteType": "Worksheet",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateVocabExerciseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateVocabExerciseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateVocabExerciseMutation",
    "id": null,
    "text": "mutation CreateVocabExerciseMutation(\n  $input: CreateVocabExerciseInput!\n) {\n  createVocabExercise(input: $input) {\n    exercise {\n      id\n      content {\n        terms {\n          word\n          definition\n          image {\n            id\n            url(size: SMALL_SQUARE)\n          }\n        }\n      }\n      worksheet {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'cc5ebd2df81f04ac0650e6e5c543e011';
export default node;
