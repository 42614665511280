/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TranslationExerciseSummary_exercise = {
    readonly content: {
        readonly prompt: string;
        readonly correctAnswers: ReadonlyArray<string>;
        readonly scrambledParts: ReadonlyArray<string>;
    };
    readonly " $refType": "TranslationExerciseSummary_exercise";
};
export type TranslationExerciseSummary_exercise$data = TranslationExerciseSummary_exercise;
export type TranslationExerciseSummary_exercise$key = {
    readonly " $data"?: TranslationExerciseSummary_exercise$data;
    readonly " $fragmentRefs": FragmentRefs<"TranslationExerciseSummary_exercise">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "TranslationExerciseSummary_exercise",
  "type": "TranslationExercise",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "content",
      "storageKey": null,
      "args": null,
      "concreteType": "TranslationExerciseContent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "prompt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "correctAnswers",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "scrambledParts",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'f0318ad90f80b7e24aeead90d272df4b';
export default node;
