import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CancelUpgradeSubscriptionMutation } from './__generated__/CancelUpgradeSubscriptionMutation.graphql';
const mutation = graphql`
  mutation CancelUpgradeSubscriptionMutation(
    $input: CancelUpgradeSubscriptionInput!
  ) {
    cancelUpgradeSubscription(input: $input) {
      viewer {
        user {
          id
          isUpgradedTeacher
          subscriptionStatus {
            subscriptionCancelAtPeriodEnd
            subscriptionCurrentPeriodEnd
            subscriptionCardEndDigits
            plan {
              price
            }
            subscriptionType
          }
        }
      }
    }
  }
`;

const commit = (environment: any) =>
  commitMutationPromise<CancelUpgradeSubscriptionMutation>(environment, {
    mutation,
    variables: {
      input: {},
    },
  });

export default {
  commit,
};
