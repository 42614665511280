/* tslint:disable */
/* eslint-disable */
/* @relayHash 527f5aa29b98b34e99744e31b887325a */

import { ConcreteRequest } from "relay-runtime";
export type CompleteStudyRecordInput = {
    id: string;
    clientMutationId?: string | null;
};
export type CompleteStudyRecordMutationVariables = {
    input: CompleteStudyRecordInput;
};
export type CompleteStudyRecordMutationResponse = {
    readonly completeStudyRecord: {
        readonly studyRecord: {
            readonly identifier: string | null;
        } | null;
    } | null;
};
export type CompleteStudyRecordMutation = {
    readonly response: CompleteStudyRecordMutationResponse;
    readonly variables: CompleteStudyRecordMutationVariables;
};



/*
mutation CompleteStudyRecordMutation(
  $input: CompleteStudyRecordInput!
) {
  completeStudyRecord(input: $input) {
    studyRecord {
      identifier
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CompleteStudyRecordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompleteStudyRecordMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "completeStudyRecord",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteStudyRecordPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "studyRecord",
            "storageKey": null,
            "args": null,
            "concreteType": "StudyRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CompleteStudyRecordMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "completeStudyRecord",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteStudyRecordPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "studyRecord",
            "storageKey": null,
            "args": null,
            "concreteType": "StudyRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CompleteStudyRecordMutation",
    "id": null,
    "text": "mutation CompleteStudyRecordMutation(\n  $input: CompleteStudyRecordInput!\n) {\n  completeStudyRecord(input: $input) {\n    studyRecord {\n      identifier\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '7259c119f42180d89cd6e05b5b052a14';
export default node;
