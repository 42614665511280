/* tslint:disable */
/* eslint-disable */
/* @relayHash 1cae9a98f474810d34ddedeb589d6b5f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorksheetsTabQueryVariables = {
    id: string;
};
export type WorksheetsTabQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly " $fragmentRefs": FragmentRefs<"ClassroomWorksheetsInfiniteScroll_classroom">;
    } | null;
};
export type WorksheetsTabQuery = {
    readonly response: WorksheetsTabQueryResponse;
    readonly variables: WorksheetsTabQueryVariables;
};



/*
query WorksheetsTabQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Classroom {
      id
      ...ClassroomWorksheetsInfiniteScroll_classroom
    }
    id
  }
}

fragment ClassroomWorksheet_classroomWorksheet on ClassroomWorksheet {
  id
  worksheet {
    ...Worksheet_worksheet
    id
  }
  classroom {
    id
  }
}

fragment ClassroomWorksheetsInfiniteScroll_classroom on Classroom {
  id
  classroomWorksheets(first: 50) {
    edges {
      node {
        id
        ...ClassroomWorksheet_classroomWorksheet
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment Worksheet_worksheet on Worksheet {
  title
  description
  identifier
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WorksheetsTabQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Classroom",
            "selections": [
              (v2/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "ClassroomWorksheetsInfiniteScroll_classroom",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WorksheetsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Classroom",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "classroomWorksheets",
                "storageKey": "classroomWorksheets(first:50)",
                "args": (v4/*: any*/),
                "concreteType": "ClassroomWorksheetConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClassroomWorksheetEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ClassroomWorksheet",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "worksheet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Worksheet",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "title",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "description",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "identifier",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "classroom",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Classroom",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ]
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "classroomWorksheets",
                "args": (v4/*: any*/),
                "handle": "connection",
                "key": "ClassroomWorksheetsInfiniteScroll_classroomWorksheets",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WorksheetsTabQuery",
    "id": null,
    "text": "query WorksheetsTabQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Classroom {\n      id\n      ...ClassroomWorksheetsInfiniteScroll_classroom\n    }\n    id\n  }\n}\n\nfragment ClassroomWorksheet_classroomWorksheet on ClassroomWorksheet {\n  id\n  worksheet {\n    ...Worksheet_worksheet\n    id\n  }\n  classroom {\n    id\n  }\n}\n\nfragment ClassroomWorksheetsInfiniteScroll_classroom on Classroom {\n  id\n  classroomWorksheets(first: 50) {\n    edges {\n      node {\n        id\n        ...ClassroomWorksheet_classroomWorksheet\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Worksheet_worksheet on Worksheet {\n  title\n  description\n  identifier\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c2ecb2d9ad03fea1b5164eb3ecd436ed';
export default node;
