import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { PurchaseUpgradeSubscriptionMutation } from './__generated__/PurchaseUpgradeSubscriptionMutation.graphql';
const mutation = graphql`
  mutation PurchaseUpgradeSubscriptionMutation(
    $input: PurchaseUpgradeSubscriptionInput!
  ) {
    purchaseUpgradeSubscription(input: $input) {
      viewer {
        user {
          id
          isUpgradedTeacher
        }
      }
    }
  }
`;

const commit = (
  environment: any,
  subscriptionPlanId: string,
  stripeToken: string,
) =>
  commitMutationPromise<PurchaseUpgradeSubscriptionMutation>(environment, {
    mutation,
    variables: {
      input: { subscriptionPlanId, stripeToken },
    },
  });

export default {
  commit,
};
