/* tslint:disable */
/* eslint-disable */
/* @relayHash f9f350a5210ad157d8e3a6f001f662b2 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateClassroomInput = {
    title: string;
    requiresApproval: boolean;
    description?: string | null;
    clientMutationId?: string | null;
};
export type CreateClassroomMutationVariables = {
    input: CreateClassroomInput;
};
export type CreateClassroomMutationResponse = {
    readonly createClassroom: {
        readonly classroom: {
            readonly id: string;
            readonly identifier: string;
        } | null;
        readonly viewer: {
            readonly " $fragmentRefs": FragmentRefs<"DashboardLayout_viewer" | "NavHeader_viewer">;
        } | null;
    } | null;
};
export type CreateClassroomMutation = {
    readonly response: CreateClassroomMutationResponse;
    readonly variables: CreateClassroomMutationVariables;
};



/*
mutation CreateClassroomMutation(
  $input: CreateClassroomInput!
) {
  createClassroom(input: $input) {
    classroom {
      id
      identifier
    }
    viewer {
      ...DashboardLayout_viewer
      ...NavHeader_viewer
    }
  }
}

fragment DashboardLayout_viewer on Viewer {
  user {
    id
    isAdmin
    accountType
    isUpgradedTeacher
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
  }
}

fragment FeedbackModal_viewer on Viewer {
  user {
    id
  }
}

fragment NavHeader_viewer on Viewer {
  ...FeedbackModal_viewer
  user {
    id
    username
    isAdmin
    createdAt
    isUpgradedTeacher
    accountType
    classroomMemberships {
      level
      classroom {
        id
        title
        identifier
      }
      id
    }
    worksheets(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateClassroomInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "identifier",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "classroom",
  "storageKey": null,
  "args": null,
  "concreteType": "Classroom",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateClassroomMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClassroom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClassroomPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "DashboardLayout_viewer",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "NavHeader_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateClassroomMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClassroom",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClassroomPayload",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Viewer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isAdmin",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accountType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isUpgradedTeacher",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "classroomMemberships",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClassroomMembership",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "level",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "classroom",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Classroom",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "worksheets",
                    "storageKey": "worksheets(first:1)",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      }
                    ],
                    "concreteType": "WorksheetConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorksheetEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Worksheet",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateClassroomMutation",
    "id": null,
    "text": "mutation CreateClassroomMutation(\n  $input: CreateClassroomInput!\n) {\n  createClassroom(input: $input) {\n    classroom {\n      id\n      identifier\n    }\n    viewer {\n      ...DashboardLayout_viewer\n      ...NavHeader_viewer\n    }\n  }\n}\n\nfragment DashboardLayout_viewer on Viewer {\n  user {\n    id\n    isAdmin\n    accountType\n    isUpgradedTeacher\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n  }\n}\n\nfragment FeedbackModal_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment NavHeader_viewer on Viewer {\n  ...FeedbackModal_viewer\n  user {\n    id\n    username\n    isAdmin\n    createdAt\n    isUpgradedTeacher\n    accountType\n    classroomMemberships {\n      level\n      classroom {\n        id\n        title\n        identifier\n      }\n      id\n    }\n    worksheets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'dbc4ccc5e1ef621448c859f558f94226';
export default node;
