import { graphql } from 'babel-plugin-relay/macro';
import {
  VocabExerciseContentInput,
  UpdateVocabExerciseMutation,
} from './__generated__/UpdateVocabExerciseMutation.graphql';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { updateExerciseUpdater } from './helpers/exerciseMutationHelpers';
const mutation = graphql`
  mutation UpdateVocabExerciseMutation($input: UpdateVocabExerciseInput!) {
    updateVocabExercise(input: $input) {
      exercise {
        id
        content {
          terms {
            word
            definition
            image {
              id
              url(size: SMALL_SQUARE)
            }
          }
        }
      }
    }
  }
`;
type fields = {
  content?: VocabExerciseContentInput | null;
  position?: number | null;
};

const commit = (
  environment: any,
  id: string,
  worksheetId: string,
  fields: fields,
) =>
  commitMutationPromise<UpdateVocabExerciseMutation>(environment, {
    mutation,
    variables: {
      input: {
        id,
        ...fields,
      },
    },
    optimisticUpdater: updateExerciseUpdater(
      'VocabExercise',
      id,
      worksheetId,
      fields,
    ),
    updater: updateExerciseUpdater('VocabExercise', id, worksheetId, fields),
  });

export default {
  commit,
};
