import React, { Component, CSSProperties } from 'react';
import { ReactNode } from 'react';
import { removeArrayItem, arrayShallowEquals } from '../../lib/utils';
import MeasurableDiv from '../common/MeasurableDiv';
import { bounds } from '../common/MeasurableDiv';
import DraggableDiv from '../common/DraggableDiv';
import { positionDelta } from '../common/DraggableDiv';
import classNames from 'classnames';
import './WordOrderPositioner.css';

type position = {
  x: number;
  y: number;
};

type Props = {
  widgetPadding: number;
  wordPartSpacing: number;

  words: ReactNode[];
  wordPositions: number[];
  container: (num: number) => ReactNode;
  onChangeWordPositions: (positions: number[]) => void;
};

type State = {
  containerBounds: null | bounds;
  draggingItem: null | number;
  draggingItemPosDelta: null | positionDelta;
  wordPartBounds: { [num: number]: bounds };
};

const updateWordPartBounds = (itemPos: number, bounds: bounds) => (
  prevState: State,
  props: Props,
) => ({
  wordPartBounds: { ...prevState.wordPartBounds, [itemPos]: bounds },
});

class WordOrderPositioner extends Component<Props, State> {
  static defaultProps = {
    widgetPadding: 10,
    wordPartSpacing: 10,
  };

  state: State = {
    containerBounds: null,
    wordPartBounds: {},
    draggingItem: null,
    draggingItemPosDelta: null,
  };

  getLineHeight(): number {
    return this.state.wordPartBounds[0].height;
  }

  getMinRequiredHeight() {
    /* Determine how tall the container must be to fit all the words */
    // TODO: have this take into account bad ordering of items too
    if (!this.boundsHaveLoaded()) return 0;
    const { widgetPadding, wordPartSpacing } = this.props;
    const positions = this.props.words.map((word, index) => index);
    const lastPos = positions[positions.length - 1];
    const { row } = this.getItemRowAndOffset(lastPos, positions.slice(0, -1));
    return (
      2 * widgetPadding +
      (row + 1) * this.getLineHeight() +
      row * wordPartSpacing
    );
  }

  onBeginDragWord = (itemPos: number, posDelta: positionDelta) => {
    this.setState({ draggingItem: itemPos, draggingItemPosDelta: posDelta });
  };

  onContinueDragWord = (posDelta: positionDelta) => {
    this.setState({ draggingItemPosDelta: posDelta });
  };

  onEndDragWord = () => {
    const { containerBounds, draggingItem } = this.state;
    if (draggingItem == null || !containerBounds) return;
    let isDroppedOnChosenArea = false;
    const newWordPositions = removeArrayItem(
      this.props.wordPositions,
      draggingItem,
    );
    for (let i = 0; i < this.props.wordPositions.length; i++) {
      if (this.isDraggedItemOnItemNum(i)) {
        newWordPositions.splice(i, 0, draggingItem);
        isDroppedOnChosenArea = true;
        break;
      }
    }
    this.setState({
      draggingItem: null,
      draggingItemPosDelta: null,
    });
    if (
      isDroppedOnChosenArea &&
      !arrayShallowEquals(newWordPositions, this.props.wordPositions)
    ) {
      this.props.onChangeWordPositions(newWordPositions);
    }
  };

  onClickWord = (itemPos: number) => {
    const newWordPositions = this.props.wordPositions.slice();
    const itemIndex = newWordPositions.indexOf(itemPos);
    if (itemIndex === 0) {
      // if this is the last item, cycle it to the end of the list
      newWordPositions.push(newWordPositions.shift() as number);
    } else {
      // otherwise, swap this item with the item before it
      newWordPositions.splice(itemIndex, 1);
      newWordPositions.splice(itemIndex - 1, 0, itemPos);
    }
    this.props.onChangeWordPositions(newWordPositions);
  };

  getPreviousItems(itemPos: number, ignorePhantomItem: boolean = false) {
    let items = this.props.wordPositions.slice(
      0,
      this.props.wordPositions.indexOf(itemPos),
    );
    const draggingItem = this.state.draggingItem;
    if (draggingItem != null) {
      items = removeArrayItem(items, draggingItem);

      if (draggingItem !== itemPos && !ignorePhantomItem) {
        for (let i = 0; i < items.length + 1; i++) {
          if (this.isDraggedItemOnItemNum(i)) {
            items.splice(i, 0, draggingItem);
            break;
          }
        }
      }
    }
    return items;
  }

  isDraggedItemOnItemNum(itemNum: number) {
    const { draggingItem, containerBounds } = this.state;
    const { wordPartSpacing, widgetPadding } = this.props;
    if (draggingItem == null || containerBounds == null) return false;

    const draggedItemCenter = this.getDraggedItemCenter();
    const remaingPartsPositions = removeArrayItem(
      this.props.wordPositions,
      draggingItem,
    );

    // checking if this is dropped at the end uses slightly different logic
    if (itemNum === this.props.wordPositions.length - 1) {
      const lastItem = remaingPartsPositions[remaingPartsPositions.length - 1];
      const lastItemPos = this.getItemPos(lastItem, true);
      const lastItemBounds = this.state.wordPartBounds[lastItem];
      const draggedItemBounds = this.state.wordPartBounds[draggingItem];
      const adjustedPos = {
        x: lastItemPos.x + lastItemBounds.width,
        y: lastItemPos.y,
      };
      // check if this will be placed on the next line
      if (adjustedPos.x + draggedItemBounds.width > containerBounds.width) {
        if (
          draggedItemCenter.x >= 0 &&
          draggedItemCenter.x <
            draggedItemBounds.width + containerBounds.width / 2 &&
          draggedItemCenter.y >=
            adjustedPos.y + draggedItemBounds.height + wordPartSpacing / 2 &&
          draggedItemCenter.y <
            adjustedPos.y + 2 * draggedItemBounds.height + wordPartSpacing
        ) {
          return true;
        }
      }
      return (
        draggedItemCenter.x >= adjustedPos.x &&
        draggedItemCenter.x <
          adjustedPos.x + draggedItemBounds.width + containerBounds.width / 2 &&
        draggedItemCenter.y >= adjustedPos.y - wordPartSpacing / 2 &&
        draggedItemCenter.y <
          adjustedPos.y + draggedItemBounds.height + wordPartSpacing / 2
      );
    }

    const itemAtPos = remaingPartsPositions[itemNum];
    const itemPos = this.getItemPos(itemAtPos, true);
    const itemBounds = this.state.wordPartBounds[itemAtPos];

    // for the first word on a line, give the user leeway if they drag off the page slightly
    const isFirstOnLine = itemPos.x === widgetPadding;
    const leftHorizLeniency = isFirstOnLine ? 70 : 0;
    const isDraggedOnItem =
      draggedItemCenter.x >=
        itemPos.x - wordPartSpacing / 2 - leftHorizLeniency &&
      draggedItemCenter.x <
        itemPos.x + itemBounds.width + wordPartSpacing / 2 &&
      draggedItemCenter.y >= itemPos.y - wordPartSpacing / 2 &&
      draggedItemCenter.y < itemPos.y + itemBounds.height + wordPartSpacing / 2;
    if (isDraggedOnItem) return true;

    // if this is the first word on a line, check if we're not dragging over nothing on
    // the previous line. That's also a reasonable place for this item.
    if (isFirstOnLine && itemNum > 0) {
      const itemAtPrevPos = remaingPartsPositions[itemNum - 1];
      const prevItemPos = this.getItemPos(itemAtPrevPos, true);
      const prevItemBounds = this.state.wordPartBounds[itemAtPrevPos];
      const prevItemXBound =
        prevItemPos.x + prevItemBounds.width + wordPartSpacing / 2;
      const res =
        draggedItemCenter.x >= prevItemXBound &&
        draggedItemCenter.x < prevItemXBound + 70 &&
        draggedItemCenter.y >= prevItemPos.y - wordPartSpacing / 2 &&
        draggedItemCenter.y <
          prevItemPos.y + prevItemBounds.height + wordPartSpacing / 2;
      return res;
    }
    return false;
  }

  getDraggedItemCenter(): position {
    const draggingItem = this.state.draggingItem;
    if (draggingItem == null) return { x: 0, y: 0 };
    const draggedItemBounds = this.state.wordPartBounds[draggingItem];
    const draggedItemPos = this.getItemPos(draggingItem, true);
    return {
      x: draggedItemPos.x + draggedItemBounds.width / 2,
      y: draggedItemPos.y + draggedItemBounds.height / 2,
    };
  }

  boundsHaveLoaded() {
    if (!this.state.containerBounds) return false;
    for (let i = 0; i < this.props.words.length; i++) {
      if (!this.state.wordPartBounds[i]) return false;
    }
    return true;
  }

  getItemRowAndOffset(itemPos: number, previousItems: number[]) {
    const { wordPartSpacing, widgetPadding } = this.props;
    let row = 0;
    let offset = widgetPadding;
    const itemsWidth =
      (this.state.containerBounds && this.state.containerBounds.width) || 0;
    previousItems.forEach(prevItemPos => {
      const bounds = this.state.wordPartBounds[prevItemPos];
      offset += bounds.width;
      if (offset > itemsWidth - widgetPadding) {
        // wrap next line
        row += 1;
        offset = widgetPadding + bounds.width + wordPartSpacing;
      } else {
        offset += wordPartSpacing;
      }
    });

    const curItemBounds = this.state.wordPartBounds[itemPos];
    if (offset + curItemBounds.width > itemsWidth - widgetPadding) {
      row += 1;
      offset = widgetPadding;
    }
    return { row, offset };
  }

  getItemPos(itemPos: number, ignorePhantomItem: boolean = false) {
    if (!this.boundsHaveLoaded()) return { x: 0, y: 0 };

    const previousItems = this.getPreviousItems(itemPos, ignorePhantomItem);
    const { row, offset } = this.getItemRowAndOffset(itemPos, previousItems);

    let dragDelta: positionDelta = { xDelta: 0, yDelta: 0 };
    if (
      this.state.draggingItem === itemPos &&
      this.state.draggingItemPosDelta
    ) {
      dragDelta = this.state.draggingItemPosDelta;
    }

    return {
      x: offset + dragDelta.xDelta,
      y:
        this.props.widgetPadding +
        row * (this.getLineHeight() + this.props.wordPartSpacing) +
        dragDelta.yDelta,
    };
  }

  getWordPartStyle(itemPos: number): CSSProperties {
    if (!this.boundsHaveLoaded()) {
      return { visibility: 'hidden' };
    }
    const { x, y } = this.getItemPos(itemPos);
    return { transform: `translate3d(${x}px, ${y}px, 0)` };
  }

  render() {
    const minRequiredHeight = this.getMinRequiredHeight();
    return (
      <div className="WordOrderPositioner">
        <MeasurableDiv
          className="WordOrderPositioner-container"
          onUpdateBounds={containerBounds => this.setState({ containerBounds })}
        >
          {this.props.container(minRequiredHeight)}
        </MeasurableDiv>

        {this.props.words.map((word, itemPos) => (
          <DraggableDiv
            className={classNames('WordOrderPositioner-wordPart', {
              'is-dragging': this.state.draggingItem === itemPos,
            })}
            key={itemPos}
            style={this.getWordPartStyle(itemPos)}
            onBeginDrag={posDelta => this.onBeginDragWord(itemPos, posDelta)}
            onContinueDrag={posDelta => this.onContinueDragWord(posDelta)}
            onClick={() => this.onClickWord(itemPos)}
            onEndDrag={this.onEndDragWord}
          >
            <MeasurableDiv
              onUpdateBounds={bounds =>
                this.setState(updateWordPartBounds(itemPos, bounds))
              }
            >
              {word}
            </MeasurableDiv>
          </DraggableDiv>
        ))}
      </div>
    );
  }
}

export default WordOrderPositioner;
