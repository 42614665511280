import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import {
  UpdateAccountSettingsMutation,
  UpdateAccountSettingsInput,
} from './__generated__/UpdateAccountSettingsMutation.graphql';
const mutation = graphql`
  mutation UpdateAccountSettingsMutation($input: UpdateAccountSettingsInput!) {
    updateAccountSettings(input: $input) {
      user {
        id
        username
        email
      }
    }
  }
`;

const commit = (environment: any, input: UpdateAccountSettingsInput) =>
  commitMutationPromise<UpdateAccountSettingsMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default {
  commit,
};
