import React, { FC } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { PendingMemberships_classroom } from './__generated__/PendingMemberships_classroom.graphql';
import { FormattedMessage } from 'react-intl';
import { exists } from '../../lib/utils';
import PendingMembership from './PendingMembership';

interface PendingMembershipsProps {
  classroom: PendingMemberships_classroom;
  relay: RelayProp;
}

const PendingMemberships: FC<PendingMembershipsProps> = ({ classroom }) => {
  const pendingMemberships =
    classroom?.pendingMemberships?.edges
      ?.map(edge => edge?.node)
      ?.filter(exists)
      ?.filter(node => node.level === 'PENDING') || [];
  return (
    <>
      {pendingMemberships.length > 0 && (
        <div className="pa3 mb4 bg-black-10" data-testid="PendingMemberships">
          <div>
            <h4 className="mt0">
              <FormattedMessage
                id="PendingMemberships.header"
                defaultMessage="Pending members"
              />
            </h4>
            <div className="flex">
              {pendingMemberships.map(node => (
                <PendingMembership key={node.id} classroomMembership={node} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default createFragmentContainer(PendingMemberships, {
  classroom: graphql`
    fragment PendingMemberships_classroom on Classroom {
      pendingMemberships(first: 100) {
        edges {
          node {
            id
            level
            ...PendingMembership_classroomMembership
          }
        }
      }
    }
  `,
});
