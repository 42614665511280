import React, { Component } from 'react';
import Modal from '../common/Modal';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'babel-plugin-relay/macro';
import * as Sentry from '@sentry/browser';
import Button from '../common/Button';
import { RelayProp, createFragmentContainer } from 'react-relay';
import ErrorBox from '../common/ErrorBox';
import { JoinClassroomModal_classroom } from './__generated__/JoinClassroomModal_classroom.graphql';
import JoinClassroomMutation from '../../mutations/JoinClassroomMutation';

interface JoinClassroomModalState {
  isSaving: boolean;
  error: null | string;
  showPendingInfo: boolean;
}

interface JoinClassroomModalProps {
  isOpen: boolean;
  onClose: () => void;
  relay: RelayProp;
  classroom: JoinClassroomModal_classroom;
}

class JoinClassroomModal extends Component<
  JoinClassroomModalProps,
  JoinClassroomModalState
> {
  state: JoinClassroomModalState = {
    isSaving: false,
    error: null,
    showPendingInfo: false,
  };

  onSubmit = async () => {
    if (this.state.isSaving) return;
    this.setState({ isSaving: true, error: null });
    try {
      const res = await JoinClassroomMutation.commit(
        this.props.relay.environment,
        this.props.classroom.id,
      );
      this.setState({ isSaving: false });
      if (res.joinClassroom?.classroomMembership?.level === 'PENDING') {
        this.setState({ showPendingInfo: true });
      } else {
        this.props.onClose();
      }
    } catch (err) {
      Sentry.captureException(err);
      // window.Rollbar.error('failed to join classroom', err);
      this.setState({ error: 'Unable to join class', isSaving: false });
    }
  };

  renderJoinBody() {
    const { isSaving, error } = this.state;
    const { classroom } = this.props;
    return (
      <>
        <div className="tc mb5">
          <h2 className="mb3">{classroom.title}</h2>
          {classroom.description && (
            <p className="mb3">{classroom.description}</p>
          )}
          {error && <ErrorBox>{error}</ErrorBox>}
        </div>
        <Button
          buttonType="success"
          data-testid="JoinClassroomModal-joinButton"
          fullWidth
          loading={isSaving}
          disabled={isSaving}
          onClick={this.onSubmit}
        >
          <FormattedMessage
            id="JoinClassroomModal.join_class"
            defaultMessage="Join class"
          />
        </Button>
      </>
    );
  }

  renderPendingInfoBody() {
    return (
      <>
        <div className="tc mb5">
          <h3 className="mv4">
            <i className="fas fa-check green mr2" />
            <FormattedMessage
              id="JoinClassroomModal.pending_headline"
              defaultMessage="You have requested to join this class."
            />
          </h3>
          <p className="gray pt4 ph4 f6 lh-copy">
            <FormattedMessage
              id="JoinClassroomModal.pending_info"
              defaultMessage="A class admin will need to approve your request before you can join. Please contact a class admin if you have trouble joining."
            />
          </p>
        </div>
        <Button
          buttonType="default"
          fullWidth
          onClick={this.props.onClose}
          data-testid="JoinClassroomModal-doneButton"
        >
          <FormattedMessage
            id="JoinClassroomModal.done"
            defaultMessage="Done"
          />
        </Button>
      </>
    );
  }

  render() {
    const { showPendingInfo } = this.state;
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          <FormattedMessage
            id="JoinClassroomModal.header"
            defaultMessage="Join class"
          />
        }
      >
        {showPendingInfo ? this.renderPendingInfoBody() : this.renderJoinBody()}
      </Modal>
    );
  }
}

export default createFragmentContainer(JoinClassroomModal, {
  classroom: graphql`
    fragment JoinClassroomModal_classroom on Classroom {
      id
      title
      description
      requiresApproval
    }
  `,
});
