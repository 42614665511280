/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedItem_viewer = {
    readonly user: {
        readonly id: string;
    } | null;
    readonly " $refType": "FeedItem_viewer";
};
export type FeedItem_viewer$data = FeedItem_viewer;
export type FeedItem_viewer$key = {
    readonly " $data"?: FeedItem_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"FeedItem_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "FeedItem_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'bde5a25258f65a2374581f931daf1313';
export default node;
