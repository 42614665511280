import React, { Component, FormEvent } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { RelayProp } from 'react-relay';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { History } from 'history';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { IntlShape } from 'react-intl';
import * as Sentry from '@sentry/browser';
import FormGroup from '../common/FormGroup';
import Input from '../common/Input';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Detail from './Detail';
import UpdateAccountSettingsMutation from '../../mutations/UpdateAccountSettingsMutation';
import {
  AccountSettings_user,
  ACCOUNT_TYPE,
} from './__generated__/AccountSettings_user.graphql';
import CancelUpgradeSubscriptionMutation from '../../mutations/CancelUpgradeSubscriptionMutation';
import ErrorBox from '../common/ErrorBox';
import './index.css';
import AccountTypeSelect from '../common/AccountTypeSelect';
import { UpdateAccountSettingsInput } from '../../mutations/__generated__/UpdateAccountSettingsMutation.graphql';

type CONCRETE_ACCOUNT_TYPE = Exclude<ACCOUNT_TYPE, '%future added value'>;

interface Props extends RouteComponentProps {
  intl: IntlShape;
  user: AccountSettings_user;
  relay: RelayProp;
  history: History;
}
type State = {
  username: string;
  password: string;
  passwordConfirmation: string;
  oldPassword: string;
  accountType: CONCRETE_ACCOUNT_TYPE | null;
  email: string;
  error: string | undefined | null;
  lastProps: Props | undefined | null;
  isCancelingSubscription: boolean;
};
const messages = defineMessages({
  missingUsernameError: {
    id: 'AccountSettings.missing_username_error',
    defaultMessage: 'Enter a username',
  },
  usernameLengthError: {
    id: 'AccountSettings.username_length_error',
    defaultMessage: 'Username must be between 3 and 20 characters long',
  },
  usernameCharsError: {
    id: 'AccountSettings.username_chars_error',
    defaultMessage: 'Username can contain only letters, numbers, and _',
  },
  missingEmailError: {
    id: 'AccountSettings.missing_email_error',
    defaultMessage: 'Enter your email address',
  },
  missingAccountTypeError: {
    id: 'AccountSettings.missing_account_type_error',
    defaultMessage: 'Select an account type',
  },
  passwordLengthError: {
    id: 'AccountSettings.password_length_error',
    defaultMessage: 'Password must be at least 8 characters',
  },
  passwordMatchError: {
    id: 'AccountSettings.password_match_error',
    defaultMessage: "Passwords don't match",
  },
  missingCurrentPasswordError: {
    id: 'AccountSettings.missing_current_password_error',
    defaultMessage: 'Enter your current password',
  },
  accountUpdatedSuccessfully: {
    id: 'AccountSettings.account_updated_successfully',
    defaultMessage: 'Account updated successfully',
  },
  cancelSubscriptionConfirm: {
    id: 'AccountSettings.cancel_subscription_confirm',
    defaultMessage:
      'Are you sure you want to cancel your Teacher Plus subscription?',
  },
  cancelSubscriptionSuccess: {
    id: 'AccountSettings.cancel_subscription_success',
    defaultMessage:
      'Your Teacher Plus subscription has been canceled and will not auto-renew, but you account will still remain upgraded until the end of this billing cycle.',
  },
});

class AccountSettings extends Component<Props, State> {
  state: State = {
    username: '',
    password: '',
    passwordConfirmation: '',
    oldPassword: '',
    email: '',
    error: null,
    lastProps: null,
    accountType: null,
    isCancelingSubscription: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.lastProps !== nextProps) {
      return {
        username: nextProps.user.username,
        email: nextProps.user.email,
        accountType: nextProps.user.accountType,
        lastProps: nextProps,
      };
    }

    return null;
  }

  onCancelSubscription = async () => {
    const { formatMessage } = this.props.intl;
    if (this.state.isCancelingSubscription) return;
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(formatMessage(messages.cancelSubscriptionConfirm))) return;

    this.setState({ isCancelingSubscription: true });
    const res = await CancelUpgradeSubscriptionMutation.commit(
      this.props.relay.environment,
    );
    if (
      res.cancelUpgradeSubscription?.viewer?.user?.isUpgradedTeacher === false
    ) {
      alert(formatMessage(messages.cancelSubscriptionSuccess));
    } else {
      Sentry.captureMessage('failed to cancel subscription');
      // window.Rollbar.error(
      //   'Failed to cancel subscription',
      //   res.cancelUpgradeSubscription,
      // );
    }
    this.setState({ isCancelingSubscription: false });
  };

  onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    const {
      username,
      password,
      passwordConfirmation,
      accountType,
      oldPassword,
      email,
    } = this.state;
    const { formatMessage } = this.props.intl;

    if (!username) {
      return this.setState({
        error: formatMessage(messages.missingUsernameError),
      });
    }

    if (username.length < 3 || username.length > 20) {
      return this.setState({
        error: formatMessage(messages.usernameLengthError),
      });
    }

    if (!username.match(/^[a-zA-Z0-9_]+$/)) {
      return this.setState({
        error: formatMessage(messages.usernameCharsError),
      });
    }

    if (!email) {
      return this.setState({
        error: formatMessage(messages.missingEmailError),
      });
    }

    if (!accountType && !this.props.user.isUpgradedTeacher) {
      return this.setState({
        error: formatMessage(messages.missingAccountTypeError),
      });
    }

    if (password && password.length < 6) {
      return this.setState({
        error: formatMessage(messages.passwordLengthError),
      });
    }

    if (password !== passwordConfirmation) {
      return this.setState({
        error: formatMessage(messages.passwordMatchError),
      });
    }

    if (!oldPassword && this.props.user.hasPassword) {
      return this.setState({
        error: formatMessage(messages.missingCurrentPasswordError),
      });
    }

    this.setState({
      error: null,
    });
    const updateFields: UpdateAccountSettingsInput = {
      username,
      email,
      oldPassword,
      accountType,
    };
    if (password) updateFields.password = password;

    try {
      await UpdateAccountSettingsMutation.commit(
        this.props.relay.environment,
        updateFields,
      );
      this.props.history.push('/dashboard', {
        flash: formatMessage(messages.accountUpdatedSuccessfully),
      });
    } catch (err) {
      this.setState({
        error: err.message,
      });
    }
  };

  renderSubscription() {
    const { user } = this.props;
    return (
      <div className="pa3 mb3 mt4 bg-black-05">
        <h4 className="mt0">
          <FormattedMessage
            id="AccountSettings.subscription"
            defaultMessage="Subscription"
          />
        </h4>

        <Detail
          label={
            <FormattedMessage
              id="AccountSettings.subscription_type"
              defaultMessage="Subscription type"
            />
          }
        >
          {user.isUpgradedTeacher ? 'TEACHER PLUS' : 'FREE'}
        </Detail>
        {!user.isUpgradedTeacher && (
          <Button component={Link} to="/upgrade">
            <FormattedMessage
              id="AccountSettings.upgrade_to_teacher"
              defaultMessage="Upgrade to Teacher Plus"
            />
          </Button>
        )}
        {user.isUpgradedTeacher &&
          user.subscriptionStatus?.subscriptionCurrentPeriodEnd && (
            <Detail
              label={
                <FormattedMessage
                  id="AccountSettings.subscription_billing_end"
                  defaultMessage="Billing period end"
                />
              }
            >
              {new Date(
                user.subscriptionStatus?.subscriptionCurrentPeriodEnd * 1000,
              ).toLocaleDateString(
                'en-US', // TODO: localize this better if more langs are added
                {
                  year: 'numeric',
                  day: 'numeric',
                  month: 'short',
                },
              )}
            </Detail>
          )}
        {user.isUpgradedTeacher &&
          user.subscriptionStatus?.subscriptionType && (
            <Detail
              label={
                <FormattedMessage
                  id="AccountSettings.subscription_period"
                  defaultMessage="Subscription period"
                />
              }
            >
              {user.subscriptionStatus?.subscriptionType.replace(
                'TEACHER_',
                '',
              )}
            </Detail>
          )}
        {user.isUpgradedTeacher &&
          user.subscriptionStatus?.subscriptionCancelAtPeriodEnd && (
            <Alert>
              <FormattedMessage
                id="AccountSettings.account_canceled_explanation"
                defaultMessage="Your subscription has been canceled and will not auto-renew, but your Teacher Plus upgrade will remain active until the end of this billing period."
              />
            </Alert>
          )}

        {user.isUpgradedTeacher &&
          user.subscriptionStatus?.subscriptionNote &&
          user.subscriptionStatus?.subscriptionNote !== '' && (
            <Alert alertType="success">
              {user.subscriptionStatus.subscriptionNote}
            </Alert>
          )}
        {user.isUpgradedTeacher &&
          user.subscriptionStatus &&
          user.subscriptionStatus.subscriptionCardEndDigits &&
          !user.subscriptionStatus.subscriptionCancelAtPeriodEnd && (
            <div className="pt3">
              <Button
                id="cancelSubscription"
                buttonType="failure"
                onClick={this.onCancelSubscription}
                loading={this.state.isCancelingSubscription}
              >
                <FormattedMessage
                  id="AccountSettings.cancel_subscription_button"
                  defaultMessage="Cancel subscription"
                />
              </Button>
            </div>
          )}
      </div>
    );
  }

  render() {
    const {
      username,
      password,
      error,
      passwordConfirmation,
      oldPassword,
      email,
      accountType,
    } = this.state;
    const { user } = this.props;
    return (
      <div className="AccountSettings pa3 tl">
        <h1 className="mb5">
          <FormattedMessage
            id="AccountSettings.account_settings"
            defaultMessage="Account settings"
          />
        </h1>
        <div className="w-50-ns">
          <form onSubmit={this.onSubmit}>
            <div className="pa3 mb3 bg-black-05">
              <h4 className="mt0">
                <FormattedMessage
                  id="AccountSettings.basic_settings"
                  defaultMessage="Basic settings"
                />
              </h4>
              <FormGroup
                label={
                  <FormattedMessage
                    id="AccountSettings.username"
                    defaultMessage="Username"
                  />
                }
              >
                <Input
                  value={username}
                  required
                  type="text"
                  id="username"
                  onChange={evt =>
                    this.setState({
                      username: evt.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup
                label={
                  <FormattedMessage
                    id="AccountSettings.email"
                    defaultMessage="Email"
                  />
                }
              >
                <Input
                  value={email}
                  required
                  id="email"
                  type="email"
                  onChange={evt =>
                    this.setState({
                      email: evt.target.value,
                    })
                  }
                />
              </FormGroup>
              {!user.isUpgradedTeacher && (
                <FormGroup
                  label={
                    <FormattedMessage
                      id="AccountSettings.account_type"
                      defaultMessage="Account type"
                    />
                  }
                  noMargin
                >
                  <AccountTypeSelect
                    value={accountType}
                    onChange={accountType => this.setState({ accountType })}
                  />
                </FormGroup>
              )}
            </div>

            <div className="pa3 mb3 bg-black-05">
              <h4 className="mt0">
                {user.hasPassword ? (
                  <FormattedMessage
                    id="AccountSettings.change_password"
                    defaultMessage="Change password"
                  />
                ) : (
                  <FormattedMessage
                    id="AccountSettings.add_password"
                    defaultMessage="Add a password"
                  />
                )}
              </h4>
              <FormGroup
                label={
                  <FormattedMessage
                    id="AccountSettings.new_password"
                    defaultMessage="New password"
                  />
                }
              >
                <Input
                  value={password}
                  id="password"
                  type="password"
                  required={!user.hasPassword}
                  onChange={evt =>
                    this.setState({
                      password: evt.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup
                noMargin
                label={
                  <FormattedMessage
                    id="AccountSettings.passwordConfirmation"
                    defaultMessage="Retype new password"
                  />
                }
              >
                <Input
                  value={passwordConfirmation}
                  id="passwordConfirmation"
                  type="password"
                  required={!user.hasPassword}
                  onChange={evt =>
                    this.setState({
                      passwordConfirmation: evt.target.value,
                    })
                  }
                />
              </FormGroup>
            </div>

            {!user.hasPassword ? null : (
              <div className="mt4">
                <FormGroup
                  label={
                    <FormattedMessage
                      id="AccountSettings.oldPassword"
                      defaultMessage="Current password"
                    />
                  }
                >
                  <Input
                    value={oldPassword}
                    required
                    id="oldPassword"
                    type="password"
                    onChange={evt =>
                      this.setState({
                        oldPassword: evt.target.value,
                      })
                    }
                  />
                </FormGroup>
              </div>
            )}
            {error && <ErrorBox>{error}</ErrorBox>}
            <div className="mt3">
              <Button type="submit" fullWidth>
                <FormattedMessage
                  id="AccountSettings.save_changes"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </form>

          {(user.isUpgradedTeacher || user.accountType === 'TEACHER') &&
            this.renderSubscription()}
        </div>
      </div>
    );
  }
}

export default withRouter(
  injectIntl(
    createFragmentContainer(AccountSettings, {
      user: graphql`
        fragment AccountSettings_user on User {
          username
          email
          hasPassword
          isUpgradedTeacher
          accountType
          subscriptionStatus {
            subscriptionCancelAtPeriodEnd
            subscriptionCurrentPeriodEnd
            subscriptionCardEndDigits
            plan {
              price
            }
            subscriptionType
            subscriptionNote
          }
        }
      `,
    }),
  ),
);
