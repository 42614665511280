/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NavFooter_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"FeedbackModal_viewer">;
    readonly " $refType": "NavFooter_viewer";
};
export type NavFooter_viewer$data = NavFooter_viewer;
export type NavFooter_viewer$key = {
    readonly " $data"?: NavFooter_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"NavFooter_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NavFooter_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FeedbackModal_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '4ffd50441d7976fb0c0952e2b7abd401';
export default node;
