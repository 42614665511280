import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { match } from 'react-router-dom';
import environment from '../environment';
import CreationCompleteModal from '../components/WorksheetEditor/CreationCompleteModal';
import { PostSignupPublishSheetPageQuery } from './__generated__/PostSignupPublishSheetPageQuery.graphql';

const postSignupPublishSheetPageQuery = graphql`
  query PostSignupPublishSheetPageQuery {
    viewer {
      user {
        id
      }
    }
  }
`;
type Props = {
  match: match<{ identifier: string }>;
};

const PostSignupPublishSheetPage = ({ match }: Props) => (
  <QueryRenderer<PostSignupPublishSheetPageQuery>
    environment={environment}
    variables={{}}
    query={postSignupPublishSheetPageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (props && (!props.viewer || !props.viewer.user)) {
        return <Redirect to="/" />;
      }

      return (
        <CreationCompleteModal
          isOpen={true}
          identifier={match.params.identifier}
        />
      );
    }}
  />
);

export default PostSignupPublishSheetPage;
