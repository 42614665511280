import React from 'react';
import { Redirect } from 'react-router-dom';
import { match } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../environment';
import NavHeader from '../components/NavHeader';
import WorksheetEditor from '../components/WorksheetEditor';
import PageLoading from '../components/common/PageLoading';
import NotFound from '../components/common/NotFound';
import NotAllowed from '../components/common/NotAllowed';
import { EditSheetPage_worksheetQuery } from './__generated__/EditSheetPage_worksheetQuery.graphql';

const editSheetPage_worksheetQuery = graphql`
  query EditSheetPage_worksheetQuery($identifier: String!) {
    viewer {
      ...NavHeader_viewer
      ...WorksheetEditor_viewer
      user {
        id
        isAdmin
      }
    }
    worksheetByIdentifier(identifier: $identifier) {
      creator {
        id
      }
      ...WorksheetEditor_worksheet
    }
  }
`;
type Props = {
  match: match<{ identifier: string }>;
};

const EditSheetPage = ({ match }: Props) => (
  <QueryRenderer<EditSheetPage_worksheetQuery>
    environment={environment}
    query={editSheetPage_worksheetQuery}
    variables={{
      identifier: match.params.identifier,
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (!props) return <PageLoading />;

      if (!props.viewer || !props.viewer.user) {
        return <Redirect to="/log-in" />;
      }

      const header = <NavHeader viewer={props.viewer} />;
      if (!props.worksheetByIdentifier) return <NotFound header={header} />;

      if (
        (!props.worksheetByIdentifier ||
          !props.worksheetByIdentifier.creator ||
          props.viewer.user.id !== props.worksheetByIdentifier.creator.id) &&
        !props.viewer.user.isAdmin
      ) {
        return <NotAllowed />;
      }

      return (
        <div className="EditSheetPage">
          {header}
          <div className="mw8 center w-100 EditSheetPage-container">
            <WorksheetEditor
              editMode
              worksheet={props.worksheetByIdentifier}
              viewer={props.viewer}
            />
          </div>
        </div>
      );
    }}
  />
);

export default EditSheetPage;
