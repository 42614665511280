/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceAnswerDetails_answer = {
    readonly isCorrect: boolean | null;
    readonly choices: ReadonlyArray<{
        readonly isChosen: boolean;
        readonly text: string;
    }> | null;
    readonly exercise: {
        readonly content?: {
            readonly prompt: string;
            readonly choices: ReadonlyArray<{
                readonly isCorrect: boolean;
                readonly text: string;
            }>;
        };
    } | null;
    readonly " $refType": "MultipleChoiceAnswerDetails_answer";
};
export type MultipleChoiceAnswerDetails_answer$data = MultipleChoiceAnswerDetails_answer;
export type MultipleChoiceAnswerDetails_answer$key = {
    readonly " $data"?: MultipleChoiceAnswerDetails_answer$data;
    readonly " $fragmentRefs": FragmentRefs<"MultipleChoiceAnswerDetails_answer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCorrect",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MultipleChoiceAnswerDetails_answer",
  "type": "MultipleChoiceAnswer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "choices",
      "storageKey": null,
      "args": null,
      "concreteType": "MultipleChoiceAnswerChoice",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isChosen",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exercise",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "type": "MultipleChoiceExercise",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "content",
              "storageKey": null,
              "args": null,
              "concreteType": "MultipleChoiceExerciseContent",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "prompt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "choices",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MultipleChoiceExerciseChoice",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'a155cc9c7e064c430b2d7506a1669adb';
export default node;
