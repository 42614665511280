import { graphql } from 'babel-plugin-relay/macro';
import { commitMutationPromise } from '../lib/graphqlUtils';
import { CreateClassroomWorksheetMutation } from './__generated__/CreateClassroomWorksheetMutation.graphql';
const mutation = graphql`
  mutation CreateClassroomWorksheetMutation(
    $input: CreateClassroomWorksheetInput!
  ) {
    createClassroomWorksheet(input: $input) {
      classroomClassroomWorksheetsEdge {
        cursor
        node {
          id
          ...ClassroomWorksheet_classroomWorksheet
          classroom {
            id
            numClassroomWorksheets
          }
        }
      }
    }
  }
`;

const commit = (environment: any, classroomId: string, worksheetId: string) =>
  commitMutationPromise<CreateClassroomWorksheetMutation>(environment, {
    mutation,
    variables: {
      input: {
        classroomId,
        worksheetId,
      },
    },
    configs: [
      {
        type: 'RANGE_ADD',
        parentID: classroomId,
        connectionInfo: [
          {
            key: 'ClassroomWorksheetsInfiniteScroll_classroomWorksheets',
            rangeBehavior: 'prepend',
          },
          {
            key: 'AddWorksheetModal_classroomWorksheets',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'classroomClassroomWorksheetsEdge',
      },
    ],
  });

export default {
  commit,
};
